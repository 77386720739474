import React from 'react';
import './style.css';

export const SVGIconAnimatedPercentage = ({ perc = 0 }) => (
  <svg
    width="50px"
    height="50px"
    viewBox="0 0 42 42"
    className="donut"
    aria-labelledby="beers-title beers-desc"
    role="img"
  >
    <circle
      className="donut-hole"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="white"
      role="presentation"
    />
    <circle
      className="donut-ring"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke="#d2d3d4"
      strokeWidth="3"
      role="presentation"
    />
    <circle
      className="donut-segment"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke="#ce4b99"
      strokeWidth="3"
      strokeDasharray={`${perc} ${100 - perc}`}
      strokeDashoffset="25"
      aria-labelledby="donut-segment-1-title donut-segment-1-desc"
    />
    <g className="chart-text">
      <text className="chart-number" x="35%" y="60%">
        {perc}
      </text>
    </g>
  </svg>
);

export const SVGIconRect = ({ perc = 10 }) => (
  <svg
    width="70"
    height="30"
    viewBox="0 0 70 30"
    className="donut"
    aria-labelledby="beers-title beers-desc"
    role="img"
  >
    <rect opacity="0.9" width="70" height="30" rx="8" fill="#943FA1" />

    <g className="chart-text">
      <text
        className="chart-number"
        dominantBaseline="middle"
        x="27%"
        y="55%"
        fill="white"
      >
        {perc}
      </text>
    </g>
  </svg>
);
