import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Footer } from 'components';

export const SearchNavbarContext = React.createContext();

const MainLayout = ({ children }) => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [children]);
  return (
    <div id="home">
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

MainLayout.propTypes = { children: PropTypes.node };

export default MainLayout;
