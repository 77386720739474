import styled from 'styled-components';

export const Divider = styled.hr`
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => margin || '0'};
  border: 1px solid #D7D6E0;
  background-color: #D7D6E0;

  @media ${({ theme }) => theme.device.laptop} {
    margin: ${({ marginM }) => marginM};
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin: ${({ marginTablet }) => marginTablet || '0'};
  }
`;
