import React from 'react';
//Styled Button
import { Button, Icon } from 'components';
//Icon
import GoogleIcon from 'assets/google.png';
//Google LogIn
import { useGoogleLogin } from 'react-google-login';

const clientId =
  '460681746467-t0134kmh70at4e3cnclun1ujhg2g8j6f.apps.googleusercontent.com';

export const GoogleLogIn = ({ onSuccess, onFailure }) => {
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: 'offline',
  });

  return (
    <Button
      onClick={signIn}
      shadow="0px 10px 50px rgba(0, 82, 180, 0.1);"
      width="140px"
      height="54px"
      display="flex"
      align="center"
      size="16px"
      color="#9791A7"
    >
      <Icon margin="0 18px 0 0" src={GoogleIcon} />
      Google
    </Button>
  );
};
