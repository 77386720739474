import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;

  .rdrMonthAndYearPickers {
    display: none;
  }

  .rdrMonthName {
    font-size: 18px;
    font-weight: 500;
    color: #353535;
    padding: 0 0 10px;
    text-align: center;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  .rdrDayNumber {
    span {
      &:after {
        background: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  .rdrDefinedRangesWrapper,
  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrStartEdge,
  .rdrEndEdge,
  .rdrInRange {
    background: ${({ theme }) => theme.colors.primary};
  }
`;

export const DateRangePickerVertical = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.tablet550} {
    display: flex;
  }
`;

export const DateRangePickerTablet = styled.div`
  display: none;
  @media ${({ theme }) => theme.deviceMin.tablet550} {
    display: block;
  }
  @media ${({ theme }) => theme.deviceMin.laptop1100} {
    display: none;
  }
`;

export const DateRangePickerDesktop = styled.div`
  display: block;
  @media ${({ theme }) => theme.device.laptop1100} {
    display: none;

    .rdrMonthAndYearWrapper {
      display: none;
    }
  }
`;

export const DateRangePickerHorizontal = styled.div`
  width: 100%;

  .rdrDateRangePickerWrapper {
    width: 100%;
  }
  .rdrCalendarWrapper {
    position: relative;
    padding: 35px 7%;
    width: 100%;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid #d7d6e0;
  }

  .rdrMonths {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }

  .rdrDay {
    background: transparent;
    line-height: 4em;
    height: 4em;
    color: #1d2429;
  }

  .rdrMonthAndYearWrapper {
    position: absolute;
    align-items: center;
    padding: 0 2%;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    z-index: 1;
  }
`;
