import React from 'react';
import moment from 'moment';
import omit from 'lodash/omit';
import { DateRangePicker } from 'react-dates';
import { CalendarHorizontalContainer } from './Calendar.style';
import {
  START_DATE,
  END_DATE,
  HORIZONTAL_ORIENTATION,
  ANCHOR_LEFT,
  NAV_POSITION_TOP,
} from './constants';
import ru from 'moment/locale/ru';
import en from 'moment/locale/en-gb';

const defaultProps = {
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,

  startDateId: START_DATE,
  startDatePlaceholderText:
    localStorage.language === 'ru' ? 'Заезд' : 'Start Date',
  endDateId: END_DATE,
  endDatePlaceholderText:
    localStorage.language === 'ru' ? 'Отъезд' : 'End Date',
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,
  block: false,
  small: false,
  regular: false,
  noBorder: true,
  readOnly: true,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,

  // navigation related props
  navPosition: NAV_POSITION_TOP,
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isDayHighlighted: () => false,

  // internationalization
  displayFormat: () => moment.localeData().longDateFormat('L'),
  monthFormat: 'MMMM YYYY',

  stateDateWrapper: (date) => date,
};

class DateRangePickerWrapper extends React.Component {
  constructor(props) {
    super(props);

    let focusedInput = null;

    if (props.autoFocus) {
      focusedInput = START_DATE;
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE;
    }

    this.state = {
      focusedInput,
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
      isCalendarReset: false,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isCalendarReset !== state.isCalendarReset) {
      return {
        startDate: null,
        endDate: null,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.autoFocus !== prevProps.autoFocus) {
      let focusedInput = this.props.autoFocus ? START_DATE : null;
      this.setState({ focusedInput });
    }
  }

  onDatesChange({ startDate, endDate }) {
    const { stateDateWrapper } = this.props;
    this.setState({
      startDate: startDate && stateDateWrapper(startDate),
      endDate: endDate && stateDateWrapper(endDate),
    });

    this.props.onChangeDate(startDate, endDate);
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  render() {
    const { focusedInput, startDate, endDate } = this.state;

    if (localStorage.language === 'ru') {
      moment.locale('ru', ru);
    } else {
      moment.locale('en-gb', en);
    }

    const props = omit(this.props, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'stateDateWrapper',
    ]);

    return (
      <div>
        <DateRangePicker
          {...props}
          autoFocus
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          noBorder
          showClearDates
          startDatePlaceholderText={
            this.props.checkinDate
              ? this.props.checkinDate
              : localStorage.language === 'ru'
              ? 'Заезд'
              : 'Start Date'
          }
          endDatePlaceholderText={
            this.props.checkoutDate
              ? this.props.checkoutDate
              : localStorage.language === 'ru'
              ? 'Отъезд'
              : 'End Date'
          }
        />
      </div>
    );
  }
}

DateRangePickerWrapper.defaultProps = defaultProps;

export { DateRangePickerWrapper };
