import styled from 'styled-components';

export const Badge = styled.div`
  min-width: 70px;
  background-color: ${({ theme, badgeColor }) => badgeColor || theme.colors.primary};
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 7px 10px;
  margin: ${({ margin }) => margin || 0};
  border-radius: 12px;
  opacity: 0.82;
`;
