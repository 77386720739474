import styled, { css } from 'styled-components';
import { DatePicker, Menu } from 'antd';
import { Link } from 'react-router-dom';

const { RangePicker } = DatePicker;

export const DropdownMenuStyled = styled(Menu)`
  &&& {
    .ant-menu {
      width: 80px;
      background: #ffffff;
      box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
      border-radius: 0px 12px 12px 12px;
    }

    .ant-dropdown-menu {
      border-radius: 10px !important;
    }

    .ant-menu-item {
      text-align: center;
    }
  }
`;

export const Nav = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  height: 100px;
  padding: ${({ padding }) => padding || '0 3vw 0 3vw'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  transition: all ease-in-out 0.3s;
  a {
    color: #353535;
    &:hover {
      color: black;
    }
  }

  @media ${({ theme }) => theme.device.laptop1100} {
    padding: ${({ padding1100 }) => padding1100};
  }

  @media ${({ theme }) => theme.device.laptop1100} {
    display: none;
  }
`;

export const MenuBlock = styled.ul`
  clear: none;
  /* float: right; */
  color: black;
  max-height: none;
  z-index: 1999;

  @media ${({ theme }) => theme.device.tablet} {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
`;

export const MenuItem = styled.li`
  float: left;
  list-style: none;
  @media ${({ theme }) => theme.device.tablet} {
    float: none;
  }
`;
export const MenuIcon = styled.label`
  display: none;
  @media ${({ theme }) => theme.device.tablet} {
    display: block;
    border: 1px solid red;
    padding: 28px;
    position: relative;
    float: right;
    cursor: pointer;
  }
`;

export const NavIcon = styled.span`
  background: #333;
  display: block;
  height: 2px;
  width: 18px;
  position: relative;
  transition: all 0.2s ease-in-out;

  &:before {
    content: '';
    background: #333;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 5px;
    transition: all 0.2s ease-in-out;
  }
  &:after {
    content: '';
    background: #333;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: -5px;
    transition: all 0.2s ease-in-out;
  }
`;

export const MenuBtn = styled.input`
  display: none;
  &:checked ~ ${MenuBlock} {
    max-height: 100%;
    min-height: 240px;
    z-index: 2000;
  }

  &:checked ~ ${MenuIcon} ${NavIcon} {
    background-color: transparent;
    &:before {
      transform: rotate(-45deg);
      top: 0;
    }
    &:after {
      transform: rotate(45deg);
      top: 0;
    }
  }
`;

export const SearchBar = styled.div`
  /* overflow: hidden; */

  width: 500px;
  max-width: ${({ maxWidth }) => maxWidth};
  height: 40px;
  margin: 0 0 0 42px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 0 0 20px;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
  /* justify-content: flex-start; */

  transition: all ease-in-out 0.3s;

  .search-icon {
    margin-top: -2px;
  }
  i {
    color: ${({ theme }) => theme.colors.text.light};
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: ${({ heightSearchBar }) => heightSearchBar};
    border: none;
    color: #333;
  }

  ${({ activeSearchPanel }) =>
    activeSearchPanel &&
    css`
      width: 80%;
      max-width: 80%;
      transition: all ease-in-out 0.3s;
    `}
`;

export const DatePickerContainer = styled.div`
  width: ${({ width }) => width};
  height: 28px;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  border-left: 1px solid #e4f0f8;
  border-right: 1px solid #e4f0f8;
  .anticon-calendar {
    display: none !important;
  }

  ${({ borders }) =>
    borders &&
    css`
      height: 54px;
      border: 1px solid
        ${({ theme, dropBorderWrapper }) =>
          dropBorderWrapper || theme.colors.borders.light};
      border-radius: 10px;
      padding-left: 20px;
      display: flex;
      align-items: center;
      .ant-form-item {
        margin: 0;
      }
    `}

  ${({ calendIconNone }) =>
    calendIconNone &&
    css`
      .anticon-calendar {
        display: none;
      }
      .ant-picker-input {
        width: 105px;
        padding: 0 10px;
      }
    `}

         @media ${({ theme }) => theme.device.tablet} {
    width: ${({ widthSM }) => widthSM};
    margin: ${({ marginM }) => marginM};
  }
`;

export const PhoneAnimatedBox = styled.div`
  transition: all ease-in-out 0.3s;
`;
export const PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
`;

export const PhoneImg = styled.img`
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.32);
  border-radius: 100%;
  margin: ${({ margin }) => margin};
  transition: all ease-in-out 0.3s;
  cursor: pointer;
`;

export const RangePickerTransparent = styled(RangePicker)`
  background-color: transparent;
  border: none;
  box-shadow: none;
  margin: ${({ margin }) => margin};
  .ant-picker-active-bar {
    background: ${({ theme }) => theme.colors.primary};
  }

  .ant-picker-input > input {
    &::placeholder {
      color: ${({ theme }) => theme.colors.text.grey};
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ theme, border }) =>
    border &&
    css`
      border-radius: 15px;
      border: 1px solid ${theme.colors.borders.light};
    `};
`;

export const GuestCounterContainer = styled.div`
  padding: 15px;
`;

export const BtnCounter = styled.div`
  width: 25px;
  height: 25px;
  color: #fff;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const FavIcon = styled(Link)`
  margin: 0 38px;
  i {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.text.light};
  }

  ${({ theme, primary }) =>
    primary &&
    css`
      i {
        color: ${({ theme }) => theme.colors.text.primary};
      }
      /*
      &:hover {
        color: #fff;
      } */
    `}
`;

export const NavItemsContainer = styled.div`
  display: contents;
  width: 0%;
  transition: all ease-in-out 0.3s;

  ${({ activeSearchPanel }) =>
    activeSearchPanel &&
    css`
      width: 100%;
      display: none;
      transition: all ease-in-out 0.3s;
    `}
`;

export const BtnContainer = styled.div`
  display: none;
  visibility: hidden;
  ${({ activeSearchPanel }) =>
    activeSearchPanel &&
    css`
      display: block;
      visibility: visible;
      transition: all ease-in-out 0.3s;
    `}
`;

export const SearchBarContainer = styled.div`
  width: 100%;
  transition: all ease-in-out 0.3s;
  /* border: 1px solid red; */

  /* &:hover ${BtnContainer} {
    display: block;
    visibility: visible;
  }
  &:hover + ${NavItemsContainer} {
    display: none;
    width: 100%;
  } */
  /* &:hover ${SearchBar} {
    width: 80%;
    max-width: 80%;
    transition: all ease-in-out 0.3s;
  } */
`;

export const SearchBarWrapper = styled.div`
  /* border: 1px solid red; */
  display: flex;
`;

export const AirbnbDatePickerContainer = styled.div`
  &&& {
    height: 28px;
    padding: ${({ padding }) => padding};
    margin: ${({ margin }) => margin};
    background: transparent;
    border-radius: 10px;

    .DayPicker_transitionContainer__horizontal,
    .DayPicker__withBorder {
      border-radius: 0px 20px 20px 20px;
    }

    .DateRangePickerInput_clearDates {
      margin: 0 1px 0 5px;
    }

    .DateRangePickerInput_clearDates__hide {
      visibility: visible;
    }

    .DateRangePicker_picker {
      box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
      border-radius: 0px 20px 20px 20px;
      z-index: 10000;
      top: 40px !important;
    }

    .DateRangePickerInput {
      display: inline-flex;
      align-items: center;
    }

    .DateRangePicker,
    .DateRangePickerInput,
    .DateInput,
    .DateInput_input {
      display: inline-flex;
      justify-content: center;
      background-color: transparent;
      height: 25px;
    }

    .DateInput {
      width: 40%;
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .DateInput_input {
      font-family: Roboto Arial, Helvetica, sans-serif;
      color: ${({ pickedDateColor }) => pickedDateColor || '#9791A7'};
      color: #9791a7;
      border: none;
      outline: none;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 5px 0;

      &::placeholder {
        color: #9791a7 !important;
      }
    }

    .DateRangePickerInput_arrow {
      &::before {
        content: '—';
        color: #9791a7;
      }
    }

    .DateRangePickerInput_arrow_svg {
      display: none;
    }

    .DateInput_input__focused {
      border-bottom: 2px solid #943fa1;
    }

    .DayPickerKeyboardShortcuts_show__topRight {
      opacity: 0;
    }
    .CalendarDay__default {
      border: none;
      color: #353535;

      &:hover {
        border-radius: 13px;
      }
    }

    .DayPicker__withBorder {
      box-shadow: none;
    }

    .CalendarMonth_table {
      width: 100%;
    }

    .CalendarDay__hovered_span,
    .CalendarDay__hovered_span:hover {
      background-color: #efe2f1;
      border-radius: 0;
    }

    .CalendarMonth_caption {
      text-transform: capitalize;

      strong {
        font-weight: normal;
      }
    }

    .DayPickerKeyboardShortcuts_buttonReset {
      display: none;
    }

    /* .CalendarMonthGrid__vertical,
    .CalendarMonthGrid__vertical_scrollable {
      width: 100% !important;
      font-size: 16px;
    } */

    /* .DayPickerNavigation__verticalScrollableDefault {
      display: none;
    } */

    .CalendarDay {
      font-size: 16px;
      outline: none !important;
    }

    .DayPicker_weekHeader_ul small {
      font-size: 14px;
      color: #9791a7;
      text-transform: capitalize;
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: #943fa1;
      border-radius: 13px;
    }

    .CalendarDay__selected {
      position: relative;
      z-index: 10;
      background-color: black;
      color: #ffffff;

      &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #efe2f1;
        border-radius: 13px 0 0 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }

      &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #943fa1;
        border-radius: 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .CalendarDay__selected_start,
    .CalendarDay__selected_start_3,
    .CalendarDay__selected_4,
    .CalendarDay__selected_start_in_hovered_span,
    .CalendarDay__selected_start_in_hovered_span_5 {
      &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #efe2f1;
        border-radius: 13px 0 0 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }

      &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #943fa1;
        border-radius: 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .CalendarDay__selected_end,
    .CalendarDay__selected_end_3 {
      &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #efe2f1;
        border-radius: 0 13px 13px 0;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }

      &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #943fa1;
        border-radius: 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .CalendarDay__selected_span {
      color: #353535;
      background: #efe2f1;
      height: 32px;
      color: #353535;
    }

    .DayPicker_weekHeader_ul {
      display: flex;
      justify-content: space-around;
    }

    .CalendarDay__blocked_out_of_range {
      color: #9791a7;
    }

    .DateRangePickerInput_clearDates svg {
      fill: #000000;
      opacity: 0.3;
    }
  }
`;
