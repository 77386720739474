import styled from 'styled-components';

export const BreadcrumbStyled = styled.div`
  &&& {
    margin: ${({ margin }) => margin};

    ant-breadcrumb-separator {
      color: ${({ theme }) => theme.colors.primary};
    }
    .ant-breadcrumb,
    .ant-breadcrumb a {
      font-size: 18px;
      line-height: 143%;
      color: ${({ theme }) => theme.colors.primary};

      @media ${({ theme }) => theme.device.laptop1100} {
        font-size: 12px;
      }
    }
    .ant-breadcrumb > span:last-child {
      font-size: 18px;
      line-height: 143%;
      a {
        color: ${({ theme }) => theme.colors.text.grey};
      }
    }
  }
`;
