import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import light from './light';
import dark from './dark';

const ThemeContext = React.createContext();

const Theme = ({ children }) => {
  const [theme, setTheme] = useState(
    localStorage.theme === 'dark' ? 'dark' : 'light',
  );

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
    localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light');
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProvider theme={theme === 'light' ? light : dark}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

Theme.propTypes = { children: PropTypes.node };

export { Theme, ThemeContext };
