import styled, { css } from 'styled-components';

export const Icon = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin: ${({ margin }) => margin};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  right: ${({ right }) => right};

  cursor: ${({ pointer }) => `${pointer}`};
  ${({ display, margin }) => display
    && css`
      display: ${display};
      margin: ${margin};
    `};
`;

export const IconBg = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  transition: all ease-in-out 0.3s;
  cursor: ${({ pointer }) => `${pointer}`};
  &:hover {
    background-image: url(${({ hover }) => hover});
  }
`;

export const IconContainer = styled.div`
  font-size: ${({ size }) => size};
  margin: ${({ margin }) => margin};
    ${({ primary }) => primary
      && css`
        color: ${({ theme }) => theme.colors.primary};
      `}
    ${({ mutedText }) => mutedText
      && css`
        color: ${({ theme }) => theme.colors.text.muted};
      `}
    ${({ greyText }) => greyText
      && css`
        color: ${({ theme }) => theme.colors.text.grey};
      `}
    ${({ lightText }) => lightText
      && css`
        color: ${({ theme }) => theme.colors.text.light};
      `};
`;
