import styled from 'styled-components';
import { Col } from 'antd';

// Masonry

export const GalleryGridWrapper = styled(Col)`
  width: 100%;
  max-width: 560px;
  height: 300px;
  float: left;
  overflow: hidden;
  margin-right: 75px;
  margin-bottom: 30px;

  .grid-container {
    padding: 0;
  }
  @media ${({ theme }) => theme.device.laptop} {
    margin-right: 0;
    width: 100%;
    float: none;
    margin-bottom: 2%;
  }
`;

export const MasonryWrapper = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(45px, auto);

  @media ${({ theme }) => theme.device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const MasonryOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  &::after {
    max-width: 400px;
    content: '';
    background: rgba(7, 7, 7, 0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`;

export const MasonryOverlayLast = styled(MasonryOverlay)`
  background: rgba(7, 7, 7, 0.25);
`;

export const MasonryItem = styled.div`
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  /* background-attachment: fixed; */
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
  position: relative;
  &:hover ${MasonryOverlay} {
    &::after {
      transition: all ease-in-out 0.3s;
      background: rgba(7, 7, 7, 0.25);
    }
  }
`;

export const MasonryItemOne = styled(MasonryItem)`
  /* grid-column: 1 / 4; */
  grid-row: 1 / 6;
`;

export const MasonryItemTwo = styled(MasonryItem)`
  /* grid-column: 2 / 4; */
  grid-row: 1 / 3;
  /* @media ${({ theme }) => theme.device.laptop} {
    grid-row: 1 / 4;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 4 / 7;
  } */
`;

export const MasonryItemThree = styled(MasonryItem)`
  /* grid-column: 1 / 4; */
  grid-row: 1 / 4;
  /* @media ${({ theme }) => theme.device.laptop} {
    grid-row: 4 / 7;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 7 / 10;
  } */
`;

export const MasonryItemFour = styled(MasonryItem)`
  grid-row: 3 / 6;
  /* @media ${({ theme }) => theme.device.laptop} {
    grid-row: 4 / 7;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 10 / 13;
  } */
`;

export const MasonryItemFive = styled(MasonryItem)`
  /* grid-column: 2 / 4; */
  grid-row: 4 / 6;
  position: relative;

  &::after {
    max-width: 400px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(7, 7, 7, 0.5);
    z-index: 990;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @media ${({ theme }) => theme.device.laptop} {
    grid-row: 4 / 6;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 13 / 16;
  }
`;

export const MasonryItemSix = styled(MasonryItem)`
  grid-row: 4 / 10;

  @media ${({ theme }) => theme.device.laptop} {
    grid-row: 7 / 10;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 16 / 19;
  }
`;

export const CarouselWrapperStyles = styled.div`
  .slick-list {
    height: 100%;
    padding: ${({ padding }) => padding};
  }
`;

export const SearchBarVillasWrapper = styled.div`
  /* flex-direction: column; */
  @media ${({ theme }) => theme.deviceMin.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;
