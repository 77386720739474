import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Button = styled.button`
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};
  background: ${({ background }) => background || 'transparent'};
  color: ${({ color }) => color || '#000'};
  padding: ${({ padding }) => padding || '10px 12px'};
  display: ${({ display }) => display};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  margin: ${({ margin }) => margin || '0'};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: center;
  font-weight: 400;
  border-radius: 10px;
  border: none;
  box-shadow: ${({ shadow }) => shadow};

  display: flex;
  align-items: center;
  justify-content: center;

  position: ${({ position }) => position};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  bottom: ${({ bottom }) => bottom};

  z-index: ${({ zIndex }) => zIndex};
  transition: all ease 0.5s;
  font-size: ${({ size }) => size};

  cursor: pointer;

  i {
    margin-right: ${({ marginIcon }) => marginIcon || '5px'};
  }

  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    background: ${({ backgroundHover }) => backgroundHover || '#772584'};
    color: ${({ theme }) => theme.colors.text.muted};
    color: ${({ colorHover }) => colorHover};
    cursor: pointer;

    &:disabled {
      cursor: auto;
      background: rgba(148, 63, 161, 0.7);
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: ${({ widthSm }) => widthSm};
    height: ${({ heightSm }) => heightSm || '40px'};
    margin: ${({ marginSM }) => marginSM};
    display: ${({ displayM }) => displayM};
    font-size: ${({ sizeM }) => sizeM || '14px'};
  }
  @media ${({ theme }) => theme.device.mobileL} {
    font-size: ${({ sizeMobL }) => sizeMobL || '14px'};
    width: ${({ widthMobL }) => widthMobL};
    height: ${({ heightMobL }) => heightMobL};
    padding: ${({ paddingM }) => paddingM || '8px 10px'};
    margin: ${({ marginMobL }) => marginMobL};
  }

  @media ${({ theme }) => theme.device.mobileM} {
    font-size: ${({ sizeM }) => sizeM || '14px'};
    padding: ${({ paddingM }) => paddingM || '8px 10px'};
  }

  @media ${({ theme }) => theme.device.mobileS} {
    font-size: ${({ sizeM }) => sizeM || '14px'};
    padding: ${({ padding }) => padding || '8px 20px'};
  }

  ${({ theme, primary }) =>
    primary &&
    css`
      color: #fff;
      background-color: ${theme.colors.primary};
      &:hover {
        color: #fff;
      }
    `}

  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: #fff;
      background-color: rgba(148, 63, 161, 0.7);
    `}


  ${({ bg, color, bgHover, colorHover }) =>
    bg &&
    color &&
    css`
      color: ${color};
      background-color: ${bg};
      &:hover {
        color: ${colorHover || '#fff'};
        background-color: ${bgHover || '#f7f7f7'};
      }
    `}


  ${({ theme, outlined }) =>
    outlined &&
    css`
      color: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
      background-color: transpernt;
      border-radius: 10px;
      &:hover {
        background-color: 1px solid ${theme.colors.primary};
        color: #fff;
        /* transform: translateY(-6px); */
      }
    `}
`;

export const ButtonLink = styled(Link)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  font-size: ${({ size }) => size};
  background: transparent;
  color: ${({ color }) => color || '#000'};
  padding: ${({ padding }) => padding || '10px 12px'};
  display: ${({ display }) => display || 'block'};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  margin: ${({ margin }) => margin || '0'};
  text-align: center;
  font-weight: 400;
  border-radius: 10px;
  border: none;
  position: ${({ position }) => position};
  right: ${({ right }) => right};
  z-index: ${({ zIndex }) => zIndex};
  transition: all ease 0.5s;
  box-shadow: ${({ shadow }) => shadow};

  i {
    margin-right: 5px;
  }

  &:focus {
    border: none;
    outline: none;
    color: #fff;
  }

  &:active {
    color: #fff;
  }

  &:hover {
    /* background: ${({ theme }) => theme.colors.primary}; */
    background: #772584;
    color: ${({ theme }) => theme.colors.text.muted};
    /* transform: translateY(-1px); */
    cursor: pointer;
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: ${({ widthSm }) => widthSm || '165px'};
    height: ${({ heightSm }) => heightSm || '40px'};
    margin: ${({ marginSM }) => marginSM};
    display: ${({ displayM }) => displayM};
    font-size: ${({ sizeTablet }) => sizeTablet || '16px'};
  }

  @media ${({ theme }) => theme.device.mobileM} {
    font-size: ${({ sizeM }) => sizeM || '12px'};
    padding: ${({ paddingM }) => paddingM || '8px 10px'};
    margin: ${({ marginM }) => marginM};
  }

  @media ${({ theme }) => theme.device.mobileS} {
    font-size: ${({ sizeM }) => sizeM || '14px'};
    padding: ${({ padding }) => padding || '8px 20px'};
    margin: ${({ marginS }) => marginS};
  }

  ${({ theme, primary }) =>
    primary &&
    css`
      color: #fff;
      background-color: ${theme.colors.primary};
      &:hover {
        color: #fff;
      }
    `}

  ${({ theme, outlined }) =>
    outlined &&
    css`
      color: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
      background-color: transpernt;
      border-radius: 10px;
      &:hover {
        background-color: 1px solid ${theme.colors.primary};
        color: #fff;
        transform: translateY(-6px);
      }
    `}
`;

export const ButtonIcon = styled.div`
  color: ${({ theme, iconColor }) => iconColor || theme.colors.primary};
  background-color: 0 3px 38px 0px rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 100%;
  transition: all ease 0.5s;
  font-size: 20px;

  &:hover {
    color: ${({ theme }) => theme.colors.light};
    /* box-shadow: 0 3px 38px 0px rgba(0, 0, 0, 0.3); */
    cursor: pointer;
  }
`;

export const BtnCounter = styled.div`
  width: 28px;
  height: 28px;
  color: ${({ theme }) => theme.colors.text.main};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.borders.light};
  transition: all ease-in-out 0.3s;
  cursor: pointer;

  &:hover {
    color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
