import React from 'react';
import DiscountImg from 'assets/icons/feat/discount.svg';
import VideoPlay from 'assets/icons/media/video-play.svg';
import { Icon } from 'components';

export const LikeSVG = () => <i className="fas fa-thumbs-up" />;
export const PlusSVG = () => <i className="fas fa-plus" />;
export const QuestionSVG = () => <i className="fas fa-question-circle" />;
export const PhoneSVG = () => <i className="fas fa-phone-alt" />;
export const BackSVG = () => <i className="fas fa-angle-left" />;
export const SearchSVG = () => <i className="fas fa-search" />;
export const BeachSVG = () => <i className="fas fa-umbrella-beach" />;
export const SquareMetersSVG = () => <i className="fas fa-vector-square" />;
export const PeopleSVG = () => <i className="fas fa-user-friends" />;
export const BathSVG = () => <i className="fas fa-bath" />;
export const BedSVG = () => <i className="fas fa-bed" />;
export const LocationSVG = () => <i className="fas fa-map-marker-alt" />;
export const LocationSVGRed = () => (
  <i style={{ color: '#943FA1' }} className="fas fa-map-marker-alt" />
);
export const HeartSVG = () => <i className="far fa-heart" />;
export const HeartSolidSVG = () => <i className="fas fa-heart" />;
export const HeartSolidBgSVG = () => (
  <i class="fa fa-heart" aria-hidden="true"></i>
);
export const CaretSVG = () => <i className="fas fa-caret-up" />;
export const CalendarSVG = () => <i className="fas fa-calendar-day" />;

export const FacebookSVG = () => <i className="fab fa-facebook-square" />;
export const VkSVG = () => <i className="fab fa-vk" />;
export const YoutubeSVG = () => <i className="fab fa-youtube" />;
export const InstagramSVG = () => <i className="fab fa-instagram" />;

export const SofaSVG = () => <i className="fas fa-couch" />;
export const ConciergeSVG = () => <i className="fas fa-concierge-bell" />;
export const SettingSVG = () => <i className="fas fa-cog" />;
export const DiscountSVG = () => <Icon src={DiscountImg} alt="" />;

export const DotSVG = () => <i className="fas fa-circle" />;
export const PoolSVG = () => <i className="fas fa-swimming-pool" />;
export const HouseSVG = () => <i className="fas fa-home" />;
export const CheckedSVG = () => <i className="fas fa-check" />;
export const CrossSVG = () => <i className="fas fa-times" />;
export const ClockSVG = () => <i className="fas fa-clock" />;
export const MoonSVG = () => <i className="fas fa-moon" />;
export const UserSVG = () => <i className="fas fa-user-alt" />;
export const StarSVG = () => <i className="fas fa-star" />;

export const ChairSVG = () => <i className="fas fa-chair" />;
export const BabySVG = () => <i className="fas fa-baby" />;
export const ParkingSVG = () => <i className="fas fa-parking" />;
export const VideoPlaySVG = () => <Icon src={VideoPlay} alt="play icon" />;
