import React from 'react';
import {
  ContainerCustom, Cover, Title, TitleLink, Span,
} from 'components';
import { useIntl } from 'react-intl';
import { Row } from 'antd';
import { useHistory } from 'react-router-dom'

const PopupShortInfo = ({
  id, url, img, title, price, days, nights,
}) => {
  // const intl = useIntl();
  const history = useHistory();
  console.log(history)
  console.log(url)
  React.useEffect(() => {
    console.log('img: ', img);
  }, [img]);

  return (
    <ContainerCustom>
      <Cover
        width="213px !important"
        height="135px"
        img={
          Array.isArray(img)
            ? `${process.env.REACT_APP_API_MEDIA_URL}${img[0].photo}`
            : `${process.env.REACT_APP_API_MEDIA_URL}${img}`
        }
      />
      <ContainerCustom padding="12px 10px">
        {/* <Link to={`${hotel.urls.filterUrl}`} target="_blank"> */}
        <TitleLink to={`${url}`} target="blank">
          <Title size="14px" margin="0 0 10px 0">
            {title}
          </Title>
        </TitleLink>
        <Row align="middle">
          <Title fontFamily="Rubik" weight="500" margin="0 5px 0 0" size="18px" primary>
            {price}
            {' '}
            €
          </Title>
          {nights !== 0 ? (
            <Span size="12px" mutedText>
              /
              {' '}
              {nights}
              {/* {intl.formatMessage({
                id: 'popup.shortinfo.days',
              })} */}
            </Span>
          ) : null}
        </Row>
      </ContainerCustom>
    </ContainerCustom>
  );
};

export { PopupShortInfo };
