import React, { useState } from 'react';
import { Modal } from 'antd';
import { Button, ButtonIcon } from 'components';

const ModalWindow = ({
  children,
  iconBtn,
  iconColor,
  modalBtnOkText,
  modalBtnCancelText,
  modalTitle,
  btnOk,
  hideOkBtn,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (e) => {
    // setLoading(true);
    btnOk();
    setVisible(false);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 3000);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <>
      <div>
        {iconBtn ? (
          <ButtonIcon onClick={showModal} iconColor={iconColor}>
            {iconBtn}
          </ButtonIcon>
        ) : (
          <Button size="16px" onClick={showModal} primary>
            {modalBtnOkText}
          </Button>
        )}
        <Modal
          title={modalTitle}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <div style={{ display: 'flex' }}>
              <Button size="16px" key="back" onClick={handleCancel} primary>
                {modalBtnCancelText || 'Cancel'}
              </Button>
              {!hideOkBtn && (
                <Button
                  size="16px"
                  key="submit"
                  primary
                  loading={loading}
                  onClick={handleOk}
                >
                  {modalBtnOkText || 'Ok'}
                </Button>
              )}
            </div>,
            // btnOk,
          ]}
        >
          {children}
        </Modal>
      </div>
    </>
  );
};

export { ModalWindow };
