import styled, { css } from 'styled-components';

export const Input = styled.input`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  box-sizing: border-box;
  color: ${({ theme, placeholderColor }) => theme.colors.text[placeholderColor] || theme.colors.text.muted};
  background: ${({ bgInput }) => bgInput};
  border: none;
  outline: none;
  box-shadow: ${({boxshadow}) => boxshadow};

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ size }) => size || "16px"};
  line-height: 140%;

  &::placeholder {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: ${({ theme, placeholderCustomColor, placeholderColor }) => placeholderCustomColor || theme.colors.text[placeholderColor] || theme.colors.text.leght};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    /* Internet Explorer 10-11 */
    color: ${({ theme, placeholderCustomColor, placeholderColor }) => placeholderCustomColor || theme.colors.text[placeholderColor] || theme.colors.text.leght};
  }

  ::-ms-input-placeholder {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    /* Microsoft Edge */
    color: ${({ theme, placeholderCustomColor, placeholderColor }) => placeholderCustomColor || theme.colors.text[placeholderColor] || theme.colors.text.leght};
  }

  ${({ theme, border, borderColor }) => border
    && css`
      border: 1px solid ${borderColor || theme.colors.borders.light};
      &:focus {
        border: 1px solid ${borderColor || theme.colors.borders.light} !important;
      }
    `}

  ${({ theme, borderRadius }) => borderRadius
    && css`
      border-radius: ${({ borderRadius }) => borderRadius}; /* borderRadius */
    `}

    ${({ theme, overflow }) => overflow
    && css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  &:focus {
    border: none;
    outline: none;
  }

  .has-error {
    border: 1px solid red;
  }

  ${({ theme, error }) => error
    && css`
      border: 1px solid ${theme.colors.borders.error};
    `}

  @media (max-width: 1250px) {
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: ${({ widthTablet }) => widthTablet};
  }

  @media ${({ theme }) => theme.device.mobileL} {
    width: ${({ widthSm }) => widthSm};
  }
`;

export const TextArea = styled.textarea`
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  border: 1px solid
    ${({ theme, borderColor }) => borderColor || theme.colors.borders.light};
  box-sizing: border-box;
  border-radius: 10px;
  color: #000;
  
  &:focus {
    outline:none !important;
  }

  &::placeholder {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #9791a7;
    color: ${({ theme, placeholderColor }) => theme.colors.text[placeholderColor] || theme.colors.text.leght};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #9791a7

    /* Internet Explorer 10-11 */
    color: ${({ theme, placeholderColor }) => theme.colors.text[placeholderColor] || theme.colors.text.leght};
  }

  &::-ms-input-placeholder {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #9791a7

    /* Microsoft Edge */
    color: ${({ theme, placeholderColor }) => theme.colors.text[placeholderColor] || theme.colors.text.leght};
  }

  ${({ theme, error }) => error
    && css`
      border: 1px solid ${theme.colors.borders.error};
    `}

  @media (max-width: 1250px) {
  }
  @media ${({ theme }) => theme.device.laptop} {
  }

  @media ${({ theme }) => theme.device.mobileL} {
  }
`;

export const Form = styled.form`
  /* border: 1px solid red; */
  width: 100%;
`;
