import gql from 'graphql-tag';

export const GET_HOTEL = gql`
  query singleProp($url: String!) {
    singleProp(url: $url) {
      id
      code
      name
      parent
      isComplex
      isInWishlist
      coverPhoto
      levels
      livingSpace
      pax
      descrOutside
      descrInside
      country
      xtype
      detailType
      video
      virtualTour
      region {
        id
        name
      }
      minimumStay
      checkinAfter
      checkoutBefore
      nbedrooms
      nbathrooms
      ratingResult {
        ratingText
        averageRating
        reviewsCount
        ratingList {
          ratingCategory
          ratingValue
        }
      }
      bedrooms {
        id
        hasWc
        hasConditioning
        priority
        roomType {
          id
          name
        }
        beds {
          id
          bedType {
            id
            name
          }
        }
      }
      rules {
        id
        priority
        rule {
          id
          name
        }
        ruleType
      }
      location {
        id
        postalCode
        longitude
        latitude
        accuracy
        street
      }
      gallery {
        id
        photo
        priority
      }
      price
      priceStr
      searchParams {
        checkinDate
        checkoutDate
        adults
        children
        infants
        pax
        price
        nights
      }
      distances {
        id
        priority
        destination {
          id
          name
        }
        distanceUnit {
          id
          name
        }
        distanceValue
      }
      breadcrumbs {
        priority
        anchor
        url
      }
    }
  }
`;

export const GET_HOTEL_PRICE = gql`
  query getHotelPrice(
    $url: String!
    $checkinDate: String
    $checkoutDate: String
    $adults: Int
    $children: Int
    $infants: Int
  ) {
    calendarPrice(
      url: $url
      checkinDate: $checkinDate
      checkoutDate: $checkoutDate
      adults: $adults
      children: $children
      infants: $infants
    ) {
      price
      priceStr
      nights
    }
  }
`;

export const GET_HOTELS = gql`
  query mockProp($propertyId: Int!) {
    mockProp(propertyId: $propertyId) {
      propertyId
      propType
      propTypeDetail
      levels
      livingSpace
      validFrom
      validTo
      gallery {
        priority
        url
      }
      location {
        street
        postalCode
        accuracy
        location {
          latitude
          longitude
        }
      }
    }
  }
`;

export const SEARCH_HOTELS_AUTOCOMPLETE = gql`
  query regionListPro($lang: String!, $searchString: String!) {
    regionList(lang: $lang, searchString: $searchString) {
      id
      name
      parents
      region {
        id
        name
        slug
        regionType {
          id
          name
          icon
        }
        tourismType {
          id
          name
          icon
        }
      }
    }
  }
`;

export const SEARCH_HOTELS = gql`
  query foundProps(
    $lang: String!
    $regionId: Int!
    $checkinDate: String
    $checkoutDate: String
    $adults: Int
    $children: Int
    $infants: Int
    $offset: Int!
    $limit: Int!
    $ordering: Int!
  ) {
    foundProps(
      lang: $lang
      regionId: $regionId
      checkinDate: $checkinDate
      checkoutDate: $checkoutDate
      adults: $adults
      children: $children
      infants: $infants
      offset: $offset
      limit: $limit
      ordering: $ordering
    ) {
      propsCount
      nights
      propsList {
        id
        name
        isComplex
        levels
        livingSpace
        nbedrooms
        nbathrooms
        ndistance
        reviewsCount
        averageRating
        oPax
        pPax
        xtype
        detailType
        price
        priceStr
        location {
          latitude
          longitude
        }
        breadcrumbs {
          priority
          anchor
          url
        }
        urls {
          canonicalUrl
          filterUrl
        }
        isInWishlist
        coverPhoto {
          id
          priority
          photo
        }
      }
    }
  }
`;

export const GET_REGION_ID_FROM_SLUG = gql`
  query regionId($lang: String!, $regionSlug: String!) {
    regionId(lang: $lang, regionSlug: $regionSlug) {
      id
      name
    }
  }
`;

export const VIEWD_HOTELS = gql`
  query viewedProps($lang: String!, $limit: Int!, $offset: Int!) {
    viewedProps(lang: $lang, limit: $limit, offset: $offset) {
      propsCount
      propsList {
        id
        name
        isComplex
        levels
        livingSpace
        nbedrooms
        nbathrooms
        ndistance
        averageRating
        reviewsCount
        country
        xtype
        detailType
        isInWishlist
        oPax
        searchParams {
          checkinDate
          checkoutDate
          adults
          children
          infants
          pax
          price
        }
        coverPhoto {
          id
          priority
          photo
        }
        location {
          latitude
          longitude
        }
        urls {
          canonicalUrl
          filterUrl
        }
        breadcrumbs {
          priority
          anchor
          url
        }
      }
    }
  }
`;

export const RECOMENDED_HOTELS = gql`
  query featuredProps($regionUrl: String!, $resultsNumber: Int!) {
    featuredProps(regionUrl: $regionUrl, resultsNumber: $resultsNumber) {
      id
      name
      isComplex
      levels
      livingSpace
      shortDescription
      nbedrooms
      nbathrooms
      ndistance
      averageRating
      reviewsCount
      country
      xtype
      detailType
      oPax
      price
      priceStr
      isInWishlist
      coverPhoto {
        id
        priority
        photo
      }
      location {
        latitude
        longitude
      }
      urls {
        canonicalUrl
        filterUrl
      }
      breadcrumbs {
        priority
        anchor
        url
      }
    }
  }
`;

export const HISTORY_HOTELS = gql`
  query foundProps($lang: String!, $resultsNumber: Int!) {
    searchHistory(lang: $lang, resultsNumber: $resultsNumber) {
      id
      date
      region {
        id
        name
      }
      checkinDate
      checkoutDate
      adults
      children
      infants
      url
    }
  }
`;

export const GET_WISHLIST_HOTELS = gql`
  query wishlist($lang: String!, $offset: Int!, $limit: Int!) {
    wishlist(lang: $lang, offset: $offset, limit: $limit) {
      propsCount
      propsList {
        id
        name
        isComplex
        levels
        livingSpace
        nbedrooms
        nbathrooms
        ndistance
        averageRating
        reviewsCount
        country
        xtype
        detailType
        oPax
        price
        priceStr
        location {
          latitude
          longitude
        }
        urls {
          canonicalUrl
          filterUrl
        }
        breadcrumbs {
          priority
          anchor
          url
        }
        coverPhoto {
          id
          priority
          photo
        }
      }
    }
  }
`;

export const POPULAR_HOTELS = gql`
  query featuredRegions($resultsNumber: Int!, $regionUrl: String!) {
    featuredRegions(resultsNumber: $resultsNumber, regionUrl: $regionUrl) {
      id
      name
      country
      url
      coverPhoto
      price
      propertiesCount
    }
  }
`;

export const COLLECTIONS_HOTELS = gql`
  query collection($url: String!, $offset: Int!, $limit: Int!) {
    collection(url: $url, offset: $offset, limit: $limit) {
      collectionName
      propsCount
      seoMeta {
        seoTitle
        seoDescription
        seoKeywords
      }
      propsList {
        id
        name
        isComplex
        levels
        livingSpace
        nbedrooms
        nbathrooms
        ndistance
        averageRating
        reviewsCount
        shortDescription
        country
        xtype
        detailType
        oPax
        price
        priceStr
        isInWishlist
        location {
          latitude
          longitude
        }
        urls {
          canonicalUrl
          filterUrl
        }
        breadcrumbs {
          priority
          anchor
          url
        }
        coverPhoto {
          id
          priority
          photo
        }
      }
    }
  }
`;

export const LandingInfoRegion_HOTELS = gql`
  query landingInfo($regionUrl: String!) {
    landingInfo(regionUrl: $regionUrl) {
      regionName
      h1
      h2 {
        text
      }
      video {
        cover
        link
      }
      seoMeta {
        seoTitle
        seoDescription
        seoKeywords
      }
      collectionList {
        propsInCollection
        collectionName
        collectionCover
        collectionUrl
      }
      faqList {
        faqCount
        categoryName
        faqList {
          question
          answer
        }
      }
    }
  }
`;
