import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Row, Col, Container, Menu, Form, Input, Collapse
} from 'antd';

import {
  Logo,
  Title,
  DropdownMenu,
  DropdownWhithoutList,
  Button,
  AutoCompleteInput,
  ColCustom,
  RowCustom,
  ContainerCustom,
  Icon,
} from 'components';
import { useMainUrl } from 'hooks/useMainUrl';
import PhoneSVG from 'assets/icons/phone.svg';
import { LocationSVG } from 'lib/icons';

import { useIntl } from 'react-intl';

import FooterLogoSVG from 'assets/logo/FooterLogo.svg';
import {
  NavMobile,
  SearchBar,
  DatePickerContainer,
  PhoneContainer,
  PhoneImg,
  RangePickerTransparent,
  BtnCounter,
  MenuBtn,
  MenuIcon,
  NavIcon,
  MenuBlock,
  MenuItem,
  MobileTopFilters,
} from './NavbarMobile.styles';

const rangeConfig = {
  rules: [
    {
      type: 'array',
      required: true,
      message: 'Please select time!',
    },
  ],
};

const NavbarMobile = () => {
  const intl = useIntl();
  const [mainUrl] = useMainUrl();
  const [guestNumber, setGuestNumber] = useState({
    adults: 1,
    children: 0,
  });

  return (
    <>
      <NavMobile>
        {/* <Logo margin="10px 0 0 0" /> */}
        <Link to={mainUrl}>
          <Icon
            width="115"
            height="33"
            src={FooterLogoSVG}
            alt="logo"
            margin="22px 5.7vw"
            pointer
          />
        </Link>
        {/* <MenuBtn type="checkbox" id="menu-btn" />
        <MenuIcon htmlFor="menu-btn">
          <NavIcon />
        </MenuIcon> */}

        {/* ul .menu class */}
        <MenuBlock>
          {/* li */}
          <MenuItem>
            <RowCustom justify="space-between">
              <PhoneContainer>
                {/* <PhoneImg src={PhoneSVG} />
                <DropdownMenu title="+ 7 (495) 580 - 80 - 46 " margin="0 5px">
                  <Menu.Item key="1">+ 7 (495) 580 - 80 - 46 </Menu.Item>
                  <Menu.Item key="2">+ 7 (495) 580 - 80 - 46 </Menu.Item>
                </DropdownMenu> */}
              </PhoneContainer>

              <Button size="16px" primary margin="0 5px" sizeM="12px" padding="0 10px">
                {intl.formatMessage({
                  id: 'buttons.login',
                })}
              </Button>
            </RowCustom>
          </MenuItem>
          <MenuItem>
            <RowCustom justify="space-between" align="middle">
              {/* <Link to="/">FAQ</Link>

              <DropdownMenu title="USD" margin="0 0 0 40px">
                <Menu.Item key="1">RUB</Menu.Item>
                <Menu.Item key="2">EUR</Menu.Item>
              </DropdownMenu>

              <DropdownMenu title="Eng">
                <Menu.Item key="1">Eng</Menu.Item>
                <Menu.Item key="2">Ru</Menu.Item>
              </DropdownMenu>
            */}
            </RowCustom>
          </MenuItem>
        </MenuBlock>
      </NavMobile>
      <MobileTopFilters>
        {/* !!!!!!TODO: REMOVE IT */}
        <RowCustom style={{ display: 'none' }}>
          <ColCustom span={24} lg={24} md={24} sm={24} padding="0px 0">
            {/* <SearchBar width="50%">
              <LocationSVG />
              <AutoCompleteInput
                style={{ width: '50%', border: 'none' }}
                placeholder="City"
                options={[{ value: 'Кипр' }, { value: 'Мальдивы' }]}
                onChange={(e) => console.log(e)}
              />
            </SearchBar> */}
          </ColCustom>

          <ColCustom span={24} lg={24} md={24} sm={24} padding="0px 0">
            {/* <DatePickerContainer margin="15px 0">
              <Form.Item name="range-picker" {...rangeConfig}>
                <RangePickerTransparent />
              </Form.Item>
            </DatePickerContainer> */}
          </ColCustom>

          <ColCustom span={24} lg={24} md={24} sm={24} padding="0px 0">
            {/* <DropdownWhithoutList
              title={`${guestNumber.adults} ${
                guestNumber.adults > 1 ? 'Взрослых' : 'Взрослый'
              }  / ${guestNumber.children} Ребёнка`}
            >
              <ContainerCustom >
                <Row>
                  <Title width="100px">Взрослые:</Title>
                  <BtnCounter
                    onClick={() =>
                      setGuestNumber((prevState) => ({
                        ...prevState,
                        adults: ++prevState.adults,
                      }))
                    }
                  >
                    +
                  </BtnCounter>
                  <Title width="30px" center>
                    {guestNumber.adults}
                  </Title>
                  <BtnCounter
                    onClick={() =>
                      setGuestNumber((prevState) => ({
                        ...prevState,
                        adults: --prevState.adults < 0 ? 0 : --prevState.adults,
                      }))
                    }
                  >
                    -
                  </BtnCounter>
                </Row>
                <br />
                <Row>
                  <Title width="100px">Дети:</Title>
                  <BtnCounter
                    onClick={() =>
                      setGuestNumber((prevState) => ({
                        ...prevState,
                        children: ++prevState.children,
                      }))
                    }
                  >
                    +
                  </BtnCounter>
                  <Title width="30px" center>
                    {guestNumber.children}
                  </Title>
                  <BtnCounter
                    onClick={() =>
                      setGuestNumber((prevState) => ({
                        ...prevState,
                        children:
                          --prevState.children < 0 ? 0 : --prevState.children,
                      }))
                    }
                  >
                    -
                  </BtnCounter>
                </Row>
              </ContainerCustom>
            </DropdownWhithoutList>
            <Button size="16px"
              onClick={() => console.log('SEARCH')}
              padding="4px 14px"
              margin="0 0 0 15px"
              primary
            >
              {intl.formatMessage({
                id: 'buttons.search',
              })}
            </Button> */}
          </ColCustom>
        </RowCustom>
      </MobileTopFilters>
    </>
  );
};

export { NavbarMobile };
