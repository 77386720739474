import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Tooltip } from 'antd';
import {
  Stars,
  CarouselAnt,
  Divider,
  Title,
  Favourite,
  Badge,
  IconSvgContainer,
  RowCustom,
} from 'components';
import { useIntl } from 'react-intl';

// import {
//   PeopleSVG,
//   BathSVG,
//   BedSVG,
//   SquareMetersSVG,
//   BeachSVG,
// } from 'lib/icons';

import { ReactComponent as BeachSVG } from 'assets/icons/bigModal/beach.svg';
import { ReactComponent as BathroomSVG } from 'assets/icons/bigModal/bathroom.svg';
import { ReactComponent as BedSVG } from 'assets/icons/bigModal/bed.svg';
import { ReactComponent as UsersSVG } from 'assets/icons/bigModal/users.svg';
import { ReactComponent as SquareSVG } from 'assets/icons/bigModal/square.svg';

import { LikeSVG } from 'lib/icons';

import { Wrapper } from 'components/Wrappers/Wrappers.styles';
import { products } from 'mockData';
import {
  CardContainer,
  CardInfo,
  UserRate,
  TotalRate,
  FeaturesContainer,
  FeatureItem,
  Price,
  PriceInfo,
  SlideTopBlock,
  SliderWrapper,
  SlideInfo,
  SlideAdditionalInfo,
  VoteBtn,
} from './Card.styles';

const CardPreviewFav = ({ hotel }) => {
  const intl = useIntl();
  const { title, images, location } = products;
  const {
    id,
    coverPhoto,
    detailType,
    name,
    nights,
    oPax,
    livingSpace,
    ndistance,
    pPax,
    price,
    priceStr,
    xtype,
    breadcrumbs,
  } = hotel;

  return (
    <CardContainer height="567px">
      <SliderWrapper height="291px">
        <SlideTopBlock justify="space-between">
          {/* {badge && <Badge>{badge}</Badge>} */}
          <Badge style={{ opacity: 0 }}>
            {intl.formatMessage({
              id: 'card.villa',
            })}
          </Badge>
          <i>
            <Favourite
              hotel={hotel}
              iconColor={hotel.isInWishlist ? 'purple' : '#fff'}
            />
          </i>
        </SlideTopBlock>
        <div style={{ position: 'relative' }}>
          <CarouselAnt
            arrows
            images={[`${process.env.REACT_APP_API_MEDIA_URL}${coverPhoto}`]}
            height="291px"
            title={title}
            location={location}
            badge="Вилла"
            favorite
          />
          <div
            style={{
              width: '100%',
              position: 'absolute',
              bottom: 0,
              border: '1px solid red',
            }}
          >
            <Link to={`${hotel.urls.filterUrl}`} target="_blank">
              <SlideInfo>
                <Title size="18px" color="#fff" margin="0 0 10px 0">
                  {name}
                </Title>
                <SlideAdditionalInfo>
                  <i className="fas fa-map-marker-alt" />
                  {breadcrumbs.length > 0 &&
                    breadcrumbs.map(({ anchor }) => anchor).join(', ')}
                </SlideAdditionalInfo>
              </SlideInfo>
            </Link>
          </div>
        </div>
      </SliderWrapper>
      <Link to={`${hotel.urls.filterUrl}`} target="_blank">
        <CardInfo height="100%" padding="12px 25px">
          <FeaturesContainer justifyBetween>
            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.guests',
              })} ${pPax}`}
            >
              <FeatureItem size="25px" bricks>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <UsersSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {pPax} ps
                </Title>
              </FeatureItem>
            </Tooltip>

            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.bedrooms',
              })} ${hotel.nbedrooms}`}
            >
              <FeatureItem size="25px" bricks>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <BedSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {' '}
                  {hotel.nbedrooms} bd
                </Title>
              </FeatureItem>
            </Tooltip>

            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.bathrooms',
              })} ${hotel.nbathrooms}`}
            >
              <FeatureItem size="25px" bricks>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <BathroomSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {hotel.nbathrooms}{' '}
                  {intl.formatMessage({
                    id: 'card.bathrooms-short',
                  })}
                </Title>
              </FeatureItem>
            </Tooltip>

            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.living-space',
              })} ${livingSpace}`}
            >
              <FeatureItem size="25px" bricks>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <SquareSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {livingSpace} m2
                </Title>
              </FeatureItem>
            </Tooltip>

            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.beach-distance',
              })} ${ndistance}m`}
            >
              <FeatureItem size="25px" bricks>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <BeachSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {ndistance} m
                </Title>
              </FeatureItem>
            </Tooltip>
          </FeaturesContainer>

          <Divider margin="16px 0 0 0" />

          <Row
            justify="space-between"
            align="middle"
            // style={{ border: '1px solid green' }}
          >
            {/* <RowCustom
              align="bottom"
              height="22px"
              style={{ opacity: 0 }}
              // style={{ border: '1px solid red' }}
            >
              <Stars count={1} defaultValue={1} disabled />
              <UserRate>4,5</UserRate>
              <TotalRate>(135)</TotalRate>
            </RowCustom> */}

            <Wrapper margin="17px 0 0 0">
              {/* <Discount>2,800€</Discount> */}
              <Price>{priceStr}€</Price>
            </Wrapper>
            <RowCustom
              width="100%"
              mergin="11px 0 0 0"
              align="middle"
              justify="space-between"
            >
              {2 > 0 ? (
                <VoteBtn primary>
                  <LikeSVG />
                  <Title size="14px" lineHeight="13px" primary>
                    Vote (2)
                  </Title>
                </VoteBtn>
              ) : (
                <VoteBtn>
                  <LikeSVG />
                  <Title size="14px" lineHeight="13px" primary>
                    Vote (0)
                  </Title>
                </VoteBtn>
              )}
              <PriceInfo>
                6
                {intl.formatMessage({
                  id: 'card.nights',
                })}
                , {hotel.price}
                €/
                {intl.formatMessage({
                  id: 'card.day',
                })}
              </PriceInfo>
            </RowCustom>
          </Row>

          <Title size="18px" color="#fff" margin="0 0 10px 0">
            {intl.formatMessage({
              id: 'card.comments',
            })}
            (0)
          </Title>
        </CardInfo>
      </Link>
    </CardContainer>
  );
};

CardPreviewFav.propTypes = {
  product: PropTypes.object, // TODO: isRequired,
};

export { CardPreviewFav };
