import gql from 'graphql-tag';

export const ADD_WISHLIST_HOTELS = gql`
  mutation addToWishlist($propertyId: Int!) {
    addToWishlist(propertyId: $propertyId) {
      wishlist {
        id
        date
      }
    }
  }
`;


export const REMOVE_WISHLIST_HOTELS = gql`
  mutation removeFromWishlist($propertyId: Int!) {
    removeFromWishlist(propertyId: $propertyId) {
      itemId
    }
  }
`;
