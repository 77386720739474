import React, { useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import {
  SEARCH_HOTELS_AUTOCOMPLETE,
  GET_WISHLIST_HOTELS,
} from 'graphql/queries/getHotels';

import { Menu } from 'antd';

import { DropdownMenu, RowCustom, Logo, Button, Icon, Text } from 'components';

import { HeartSolidSVG } from 'lib/icons';

import { useIntl } from 'react-intl';

import { FavIcon } from './Navbar.styles';
import {
  NavMobile,
  MenuBtn,
  MenuIcon,
  NavIcon,
  MenuBlock,
  MenuBlockSecondLayer,
  MenuBtnSecondLayer,
  MenuBlockCurrencyLayer,
  MenuBtnCurrencyLayer,
  MenuItem,
  MenuItemNavbar,
} from './NavbarMobile.styles';

import ArrowLeft from 'assets/icons/mobileNavbar/ArrowLeft.svg';
import ArrowRight from 'assets/icons/mobileNavbar/ArrowRight.svg';
import ApproveMark from 'assets/icons/mobileNavbar/ApproveMark.svg';

const rangeConfig = {
  rules: [
    {
      type: 'array',
      required: true,
      message: 'Please select time!',
    },
  ],
};

const NavbarMobileMainPage = () => {
  const history = useHistory();
  const intl = useIntl();

  const [guestNumber, setGuestNumber] = useState({
    adults: 0,
    children: 0,
  });

  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const [isSecondNavbarOpen, setSecondNavbarOpen] = useState(false);
  const [isCurrencyNavbarOpen, setCurrencyNavbarOpen] = useState(false);

  React.useEffect(() => {
    console.log('guestNumber: ', guestNumber);
  }, [guestNumber]);

  const [searchInputValue, setSearchInputValue] = useState({
    region: {
      id: 368,
      value: '',
    },
    checkinDate: '2020-08-01',
    checkoutDate: '2020-08-30',
    pax: guestNumber.adults + guestNumber.children,
    pageNumber: null,
    guestNumber,
  });

  const [searchResult, setSearchResult] = useState([]);

  // const {
  //   loading: loadingAutocomplete,
  //   error: errorAutocomplete,
  //   data: dataAutocomplete,
  // } = useQuery(SEARCH_HOTELS_AUTOCOMPLETE, {
  //   variables: {
  //     lang: `${localStorage.language ? localStorage.language : 'en'}`,
  //     searchString: searchInputValue.region.value,
  //   },
  // });

  // Autocomplete method start
  const onChangeSearchAutocomplete = (e) => {
    console.log('E: ', e.target.value);
    setSearchInputValue({
      ...searchInputValue,
      region: {
        ...searchInputValue.region,
        value: e.target.value,
      },
    });
  };

  const onSelectSearchAutocomplete = (id, title) => {
    console.log('onSelectSearchAutocomplete: ', id, ' + ', title);
    setSearchInputValue({
      ...searchInputValue,
      region: {
        id,
        value: title,
      },
    });
  };
  // Autocomplete method end

  const onChangeDate = (e) => {
    if (e && e.length > 0) {
      setSearchInputValue({
        ...searchInputValue,
        checkinDate: e[0].format('YYYY-MM-DD'),
        checkoutDate: e[1].format('YYYY-MM-DD'),
      });
    }
  };

  const onLaguageChangeHandler = (e) => {
    console.log(e);
    const currentLanguage = history.location.pathname.split('/')[1];

    if (e === currentLanguage) {
      return;
    }
    const newPath = history.location.pathname.replace(currentLanguage, e);

    localStorage.setItem('language', e);
    history.push({
      pathname: newPath,
    });
    window.location.reload(true);
  };

  // useEffect(() => {
  //   if (
  //     !loadingAutocomplete
  //     && dataAutocomplete
  //     && dataAutocomplete.regionList
  //   ) {
  //     console.log('dataAutocomplete: ', dataAutocomplete);
  //     console.log('dataAutocomplete er: ', errorAutocomplete);

  //     setSearchResult(
  //       dataAutocomplete.regionList.map(
  //         ({ region: { id, regionType }, name, parents }) => ({
  //           id,
  //           title: name,
  //           subtitle: parents,
  //           type: regionType.name,
  //         }),
  //       ),
  //     );
  //   }
  // }, [loadingAutocomplete, dataAutocomplete]);

  const onSubmitSearch = () => {
    const {
      region: { id: regionId },
      checkinDate,
      checkoutDate,
      pax,
    } = searchInputValue;

    window.location.href = `/${history.location.pathname.slice(
      1
    )}/search/${regionId}/${checkinDate}/${checkoutDate}/${pax}`;

    // window.location.href = `/hotels?regionId=${regionId}&checkinDate=${checkinDate}&checkoutDate=${checkoutDate}&pax=${
    //   guestNumber.adults + guestNumber.children
    // }&pageNumber=1`;
  };

  const {
    loading: loadingWishlist,
    error: errorWishlist,
    data: dataWishlist,
  } = useQuery(GET_WISHLIST_HOTELS, {
    variables: { lang: 'ru', offset: 0, limit: 15 },
    fetchPolicy: 'network-only',
  });
  console.log('inbo', isNavbarOpen);
  return (
    <>
      <NavMobile padding="0 10px 10px 10px">
        {/* <Logo margin="20px 0 0 15px" /> */}
        <MenuBtn
          checked={isNavbarOpen}
          type="checkbox"
          id="menu-btn"
          style={{ color: 'red' }}
        />
        <MenuIcon
          padding="28px 5.7vw 28px 28px"
          onClick={() => setNavbarOpen(!isNavbarOpen)}
          // htmlFor="menu-btn"
        >
          {dataWishlist &&
          dataWishlist.wishlist &&
          dataWishlist.wishlist.length > 0 ? (
            <FavIcon
              to={`/${localStorage.language}/my/favourite`}
              primary
              style={{ margin: 0 }}
            >
              <HeartSolidSVG />
            </FavIcon>
          ) : (
            <FavIcon
              to={`/${localStorage.language}/my/favourite`}
              style={{ margin: 0 }}
            >
              <HeartSolidSVG />
            </FavIcon>
          )}
          <NavIcon
            background={isNavbarOpen ? '#353535' : '#ffffff '}
            style={{ marginTop: '12px', marginLeft: '15px' }}
          />
        </MenuIcon>

        <MenuBlock>
          <MenuItemNavbar>
            <RowCustom
              padding="0 29px 0 22px"
              borderBottom="1px solid #D7D6E0"
              margin="0 10px"
            >
              <MenuBtn checked={isNavbarOpen} type="checkbox" id="menu-btn" />
              <MenuIcon
                onClick={() => {
                  setNavbarOpen(!isNavbarOpen);
                }}
                padding="21px 5px"
                margin="0 0 0 auto"
              >
                <NavIcon
                  background={isNavbarOpen ? '#353535' : '#ffffff '}
                  margin="0"
                />
              </MenuIcon>
            </RowCustom>
            <RowCustom
              padding="0"
              direction="column"
              alignItems="flex-start"
              fontSize="16px"
            >
              <Link
                style={{ marginTop: '22px', padding: '0 0 0 33px' }}
                // to={`/${localStorage.language}/auth`}
                to={`/${localStorage.language}/`}
              >
                {intl.formatMessage({
                  id: 'navbar-mobile-login',
                })}
              </Link>
              <Link
                padding="0 0 0 33px"
                to={`/${localStorage.language}/info/faq`}
                style={{
                  color: '#353535',
                  marginTop: '22px',
                  marginBottom: '22px',
                  padding: '0 0 0 33px',
                }}
              >
                {intl.formatMessage({
                  id: 'navbar-mobile-help',
                })}
              </Link>
              {/* TODO: return currency dropdown */}
              {/* <RowCustom
                onClick={() => setCurrencyNavbarOpen(true)}
                width="100%"
                justify="space-between"
                alignItems="baseline"
                padding="0 33px 0 33px"
              >
                <Text>
                  {intl.formatMessage({
                    id: 'navbar-mobile-currency',
                  })}
                </Text>
                <Text color="#943FA1">
                  {`USD $`}
                  <Icon margin="0 0 0 17px" src={ArrowRight} />
                </Text>
              </RowCustom> */}

              <RowCustom
                onClick={() => setSecondNavbarOpen(true)}
                width="100%"
                justify="space-between"
                alignItems="baseline"
                marginTop="22px 0 0 0"
                padding="0 33px 0 33px"
              >
                <Text>
                  {intl.formatMessage({
                    id: 'navbar-mobile-language',
                  })}
                </Text>
                <Text color="#943FA1">
                  {localStorage.language
                    ? localStorage.language.toUpperCase()
                    : 'EN'}
                  <Icon margin="0 0 0 17px" src={ArrowRight} />
                </Text>
              </RowCustom>
            </RowCustom>
          </MenuItemNavbar>
        </MenuBlock>

        <MenuBtnSecondLayer checked={isSecondNavbarOpen} type="checkbox" />
        <MenuBlockSecondLayer>
          <MenuItemNavbar>
            <RowCustom
              padding="0 29px 0 22px"
              alignItems="baseline"
              borderBottom="1px solid #D7D6E0"
              margin="0 10px"
            >
              <Text
                onClick={() => setSecondNavbarOpen(false)}
                color="#943FA1"
                size="18px"
              >
                <Icon margin="0 13px 0 0" src={ArrowLeft} />
                {intl.formatMessage({
                  id: 'navbar-mobile-back',
                })}
              </Text>
              <MenuBtn checked={isNavbarOpen} type="checkbox" id="menu-btn" />
              <MenuIcon
                onClick={() => {
                  setNavbarOpen(!isNavbarOpen);
                  setSecondNavbarOpen(false);
                }}
                padding="21px 5px"
                margin="0 0 0 auto"
              >
                <NavIcon
                  background={isNavbarOpen ? '#353535' : '#ffffff '}
                  margin="0"
                />
              </MenuIcon>
            </RowCustom>
            <RowCustom
              direction="column"
              alignItems="flex-start"
              fontSize="16px"
              margin="22px 0 0 0"
            >
              <RowCustom
                onClick={() => onLaguageChangeHandler('en')}
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                background={
                  localStorage.language === 'en'
                    ? 'rgba(58%, 25%, 63%, 0.08)'
                    : null
                }
                size="16px"
              >
                {intl.formatMessage({
                  id: 'navbar-mobile.language-english',
                })}
              </RowCustom>
              <RowCustom
                onClick={() => onLaguageChangeHandler('ru')}
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                background={
                  localStorage.language === 'ru'
                    ? 'rgba(58%, 25%, 63%, 0.08)'
                    : null
                }
                size="16px"
              >
                {intl.formatMessage({
                  id: 'navbar-mobile.language-russian',
                })}
              </RowCustom>
              <RowCustom
                onClick={() => onLaguageChangeHandler('ro')}
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                background={
                  localStorage.language === 'ro'
                    ? 'rgba(58%, 25%, 63%, 0.08)'
                    : null
                }
                size="16px"
              >
                {intl.formatMessage({
                  id: 'navbar-mobile.language-romanian',
                })}
              </RowCustom>
              <RowCustom
                onClick={() => onLaguageChangeHandler('de')}
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                background={
                  localStorage.language === 'de'
                    ? 'rgba(58%, 25%, 63%, 0.08)'
                    : null
                }
                size="16px"
              >
                {intl.formatMessage({
                  id: 'navbar-mobile.language-german',
                })}
              </RowCustom>
              <RowCustom
                onClick={() => onLaguageChangeHandler('sr')}
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                background={
                  localStorage.language === 'sr'
                    ? 'rgba(58%, 25%, 63%, 0.08)'
                    : null
                }
                size="16px"
              >
                {intl.formatMessage({
                  id: 'navbar-mobile.language-serbian',
                })}
              </RowCustom>
              <RowCustom
                onClick={() => onLaguageChangeHandler('bg')}
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                background={
                  localStorage.language === 'bg'
                    ? 'rgba(58%, 25%, 63%, 0.08)'
                    : null
                }
                size="16px"
              >
                {intl.formatMessage({
                  id: 'navbar-mobile.language-bulgarian',
                })}
              </RowCustom>
            </RowCustom>
          </MenuItemNavbar>

          <MenuItem />
        </MenuBlockSecondLayer>

        <MenuBtnCurrencyLayer checked={isCurrencyNavbarOpen} type="checkbox" />
        <MenuBlockCurrencyLayer>
          {/* TODO: return currency dropdown */}
          {/* <MenuItemNavbar>
            <RowCustom
              padding="0 29px 0 22px"
              alignItems="baseline"
              borderBottom="1px solid #D7D6E0"
              margin="0 10px"
            >
              <Text
                onClick={() => setCurrencyNavbarOpen(false)}
                color="#943FA1"
                size="18px"
              >
                <Icon margin="0 13px 0 0" src={ArrowLeft} />
                Back
              </Text>
              <MenuBtn checked={isNavbarOpen} type="checkbox" id="menu-btn" />
              <MenuIcon
                onClick={() => {
                  setNavbarOpen(!isNavbarOpen);
                  setCurrencyNavbarOpen(false);
                }}
                padding="21px 5px"
                margin="0 0 0 auto"
              >
                <NavIcon
                  background={isNavbarOpen ? '#353535' : '#ffffff '}
                  margin="0"
                />
              </MenuIcon>
            </RowCustom>
            <RowCustom
              padding="0"
              direction="column"
              alignItems="flex-start"
              fontSize="16px"
              margin="22px 0 0 0"
            >
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
                background="rgba(58%, 25%, 63%, 0.08)"
              >
                USD
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                RUB
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                EUR
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                NOK
              </RowCustom>
            </RowCustom>
          </MenuItemNavbar> */}

          <MenuItem />
        </MenuBlockCurrencyLayer>
      </NavMobile>
    </>
  );
};

export { NavbarMobileMainPage };
