import styled from 'styled-components';

export const ImgCard = styled.div`
  /* one-third - two margins divided equally among 3 items */
  width: calc((100% / 3) - (6rem / 3));
  height: 300px;
  margin-right: 3rem;
  margin-bottom: 3rem;
  background-image: url(${({ img }) => img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${({ theme }) => theme.device.desktop} {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    width: calc((100% / 2) - (1rem / 2));
    margin-right: 1rem;
    margin-bottom: 1rem;
    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(3n) {
      margin-right: 1rem;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    margin: 0 0 3rem 0;
  }

  border-radius: 20px;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
  transition: all ease-in-out 0.2s;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(206, 206, 206, 0.2),
      0px -1px 16px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(206, 206, 206, 0.25);
    transform: translateY(-0.5rem);
  }
`;

export const CategoryBtnWrapper = styled.div`
  width: 100%;
  padding: 0px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media ${({ theme }) => theme.deviceMin.laptopL} {
    padding: 0 0 50px 0;
  }
`;

export const CategoryBtn = styled.label`
  width: 130px;
  border-radius: 25px;
  background: #f7f4f8;
  color: ${({ theme }) => theme.colors.text.muted};
  padding: 10px;
  margin-right: 30px;
  text-align: center;
  font-family: Rubik;
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  @media ${({ theme }) => theme.device.laptopL} {
    width: 50%;
    margin: 0 0 25px 0;
  }
`;

export const CategoryBtnController = styled.input`
  display: none;
  transition: all ease-in-out 0.5s;

  input[type='radio']&:checked + ${CategoryBtn} {
    color: #fff;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
