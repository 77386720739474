import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Calendar,
  Title,
  Text,
  TabWrapper,
  Button,
  BtnCounter,
  DropdownClickOutside,
  ContainerCustom,
  ColCustom,
  RowCustom,
  Divider,
} from 'components';

import { Row, Col } from 'antd';
import { GoodPriceBox } from './Tabs.styles';

const TabCalendar = () => {
  const intl = useIntl();

  const [guestNumber, setGuestNumber] = useState({
    adults: 1,
    children: 0,
  });
  return (
    <TabWrapper margin="50px 0">
      <Title weight="500" size="24px" margin="50px 0 0 0">
        {intl.formatMessage({
          id: 'hotel.calendar-tab.calendar',
        })}
      </Title>

      <RowCustom margin="0 0 25px 0">
        <Col span={24} lg={12} md={12} sm={24}>
          <Title weight="500" size="16px" margin="40px 0 25px 0">
            {intl.formatMessage({
              id: 'hotel.calendar-tab.visitors',
            })}
          </Title>
          <DropdownClickOutside
            onHover
            borders
            bordersHeight="54px"
            dropBtnWidth="200px"
            btnTitle={`${guestNumber.adults} ${
              guestNumber.adults > 1
                ? intl.formatMessage({
                    id: 'filters.adults',
                  })
                : intl.formatMessage({
                    id: 'filters.adult',
                  })
            }  / ${guestNumber.children} ${intl.formatMessage({
              id: 'filters.children',
            })}`}
            btnPadding="0"
          >
            <ContainerCustom padding="15px">
              <Row>
                <Title width="100px">
                  {intl.formatMessage({
                    id: 'filters.adults',
                  })}
                </Title>
                <BtnCounter
                  onClick={() =>
                    setGuestNumber((prevState) => ({
                      ...prevState,
                      adults: ++prevState.adults,
                    }))
                  }
                >
                  +
                </BtnCounter>
                <Title width="30px" center>
                  {guestNumber.adults}
                </Title>
                <BtnCounter
                  onClick={() =>
                    setGuestNumber((prevState) => ({
                      ...prevState,
                      adults: --prevState.adults < 0 ? 0 : --prevState.adults,
                    }))
                  }
                >
                  -
                </BtnCounter>
              </Row>
              <br />
              <Row>
                <Title width="100px">
                  {intl.formatMessage({
                    id: 'filters.children',
                  })}
                </Title>
                <BtnCounter
                  onClick={() =>
                    setGuestNumber((prevState) => ({
                      ...prevState,
                      children: ++prevState.children,
                    }))
                  }
                >
                  +
                </BtnCounter>
                <Title width="30px" center>
                  {guestNumber.children}
                </Title>
                <BtnCounter
                  onClick={() =>
                    setGuestNumber((prevState) => ({
                      ...prevState,
                      children:
                        --prevState.children < 0 ? 0 : --prevState.children,
                    }))
                  }
                >
                  -
                </BtnCounter>
              </Row>
            </ContainerCustom>
          </DropdownClickOutside>
        </Col>
      </RowCustom>

      <Row>
        <ColCustom
          span={24}
          lg={10}
          md={10}
          sm={10}
          padding="0 25px 0 0 "
          margin="0 0 30px 0"
        >
          <Text>
            Минимальная продолжительность бронирования - 5 дней. При наведении
            на доступные даты, вы можете увидеть сумму с учетом всех сборов и
            налогов.
          </Text>
        </ColCustom>
        <Col span={24} lg={5} md={4} sm={4}>
          <Row>
            <GoodPriceBox />
            <Title weight="400" size="16px" margin="0 0 25px 10px">
              {intl.formatMessage({
                id: 'hotel.calendar-tab.good-price',
              })}
            </Title>
          </Row>
        </Col>
        <Col span={24} lg={5} md={4} sm={4}>
          <Row>
            <GoodPriceBox available />
            <Title weight="400" size="16px" margin="0 0 25px 10px">
              {intl.formatMessage({
                id: 'hotel.calendar-tab.bad-price',
              })}
            </Title>
          </Row>
        </Col>
      </Row>
      <Row>
        <Calendar
          autoFocusEndDate
          initialStartDate={1587600000}
          initialEndDate={1587772800}
        />

        {/* <CalendarAirbnb /> */}
      </Row>
      <Divider margin="60px 0 15px 0" />

      <Row align="middle" justify="space-between">
        <Row align="middle">
          <Title weight="500" size="24px" margin="20px 10px 25px 0">
            {intl.formatMessage({
              id: 'common.total',
            })}
          </Title>
          <Title weight="500" size="18px" margin="20px 0 25px 0">
            €3 090 (
            {intl.formatMessage({
              id: 'common.night-number',
            })}
            : 5)
          </Title>
        </Row>
        <Button size="16px" primary>
          {intl.formatMessage({
            id: 'buttons.order',
          })}
        </Button>
      </Row>
    </TabWrapper>
  );
};

export { TabCalendar };
