import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Row, Menu } from 'antd';
import { useIntl } from 'react-intl';
import {
  DropdownMenu,
  Button,
  Title,
  Icon,
  DeviceBreakpoint,
} from 'components';
import { HeartSolidSVG } from 'lib/icons';
import { useMainUrl } from 'hooks/useMainUrl';

import FooterLogoSVG from 'assets/logo/FooterLogo.svg';

import { useQuery } from '@apollo/react-hooks';
import { GET_WISHLIST_HOTELS } from 'graphql/queries/getHotels';
import { Nav, FavIcon, DropdownMenuStyled } from './Navbar.styles';

const Navbar = () => {
  const intl = useIntl();
  const history = useHistory();
  const [mainUrl] = useMainUrl();
  console.log('mainurl', mainUrl);
  const {
    loading: loadingWishlist,
    error: errorWishlist,
    data: dataWishlist,
  } = useQuery(GET_WISHLIST_HOTELS, {
    variables: { lang: localStorage.language || 'ru', offset: 0, limit: 15 },
    fetchPolicy: 'network-only',
  });

  const onLaguageChangeHandler = (e) => {
    console.log(e);
    const currentLanguage = history.location.pathname.split('/')[1];

    if (e.key === currentLanguage) {
      return;
    }
    const newPath =
      history.location.pathname.replace(currentLanguage, e.key) +
      history.location.search;

    localStorage.setItem('language', e.key);
    history.push({
      pathname: newPath,
    });
    window.location.reload(true);
  };

  React.useEffect(() => {
    dataWishlist &&
      dataWishlist.wishlist &&
      console.log('dataWishlist: ', dataWishlist.wishlist.propsList);
  }, [dataWishlist]);

  return (
    <Nav>
      <Link to={mainUrl}>
        <Icon width="152" height="44" src={FooterLogoSVG} alt="logo" pointer />
      </Link>
      <Row align="middle">
        {/* <PhoneContainer>
          <PhoneImg margin="0 15px 0 0">
            <PhoneSVG />
          </PhoneImg>
          <PhoneAnimatedBox>
            <SelectMenu
              defaultValue="+ 7 (495) 580 - 80 - 46"
              optionList={[
                { icon: '', title: '+ 7 (495) 580 - 80 - 46 ' },
                { icon: '', title: '+ 8 (495) 580 - 80 - 46' },
              ]}
            />
          </PhoneAnimatedBox>
        </PhoneContainer> */}

        <Link to={`/${localStorage.language}/info/faq`}>
          <Title size="14px" color="#fff">
            {intl.formatMessage({
              id: 'footer.faq',
            })}
          </Title>
        </Link>

        {/* <DropdownMenu
          fill="#ffffff"
          title="USD"
          margin="0 0 0 40px"
          colorTitle="#fff"
          hoverBg="transparent"
        >
          <Menu.Item key="1">RUB</Menu.Item>
          <Menu.Item key="2">EUR</Menu.Item>
          <Menu.Item key="2">USD</Menu.Item>
        </DropdownMenu> */}

        {
          dataWishlist &&
          dataWishlist.wishlist &&
          dataWishlist.wishlist.propsList.length > 0 ? (
            <FavIcon to={`/${localStorage.language}/my/favourite`} primary>
              <HeartSolidSVG />
            </FavIcon>
          ) : (
            <FavIcon to={`/${localStorage.language}/my/favourite`}>
              <HeartSolidSVG />
            </FavIcon>
          )
          //
        }

        <Button
          size="16px"
          primary
          margin="0 55px 0 0px"
          padding="9px 24px"
          onClick={() => history.push(`/${localStorage.language}/auth`)}
        >
          {intl.formatMessage({
            id: 'buttons.login',
          })}
        </Button>

        {/* <DeviceBreakpoint desktopHiddenFrom1440> */}
        <DropdownMenu
          colorTitle="#fff"
          hoverBg="transparent"
          title={localStorage.language.toUpperCase()}
          onChangeHandler={onLaguageChangeHandler}
          fill="#ffffff"
        >
          <DropdownMenuStyled>
            <Menu.Item
              style={{
                backgroundColor:
                  localStorage.language === 'en'
                    ? 'rgba(148, 63, 161, 0.08)'
                    : 'transparent',
              }}
              key="en"
            >
              EN
            </Menu.Item>
            <Menu.Item
              style={{
                backgroundColor:
                  localStorage.language === 'ru'
                    ? 'rgba(148, 63, 161, 0.08)'
                    : 'transparent',
              }}
              key="ru"
            >
              RU
            </Menu.Item>
            <Menu.Item
              style={{
                backgroundColor:
                  localStorage.language === 'de'
                    ? 'rgba(148, 63, 161, 0.08)'
                    : 'transparent',
              }}
              key="de"
            >
              DE
            </Menu.Item>
            <Menu.Item
              style={{
                backgroundColor:
                  localStorage.language === 'bg'
                    ? 'rgba(148, 63, 161, 0.08)'
                    : 'transparent',
              }}
              key="bg"
            >
              BG
            </Menu.Item>
            <Menu.Item
              style={{
                backgroundColor:
                  localStorage.language === 'sr'
                    ? 'rgba(148, 63, 161, 0.08)'
                    : 'transparent',
              }}
              key="sr"
            >
              SR
            </Menu.Item>
            <Menu.Item
              style={{
                backgroundColor:
                  localStorage.language === 'ro'
                    ? 'rgba(148, 63, 161, 0.08)'
                    : 'transparent',
              }}
              key="ro"
            >
              RO
            </Menu.Item>
          </DropdownMenuStyled>
        </DropdownMenu>
        {/* </DeviceBreakpoint> */}
      </Row>
    </Nav>
  );
};

export { Navbar };
