import styled from 'styled-components';
import { Dropdown, Button, Menu } from 'antd';
import { Title } from 'components';

export const DropButton = styled(Button)`
  &&& {
    color: ${({ colorTitle }) => colorTitle || '#353535'};
  }
`;



export const DropdownTransparent = styled(Dropdown)`
  color: ${({ theme, color }) => color || theme.colors.text.muted};
  background-color: transparent;
  border: none;
  box-shadow: none;
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    background: ${({ hoverBg }) => hoverBg};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
    background: ${({ hoverBg }) => hoverBg};
  }

  &&& {
    .anticon.anticon-down svg{
      fill: ${({ fill }) => fill};;
    }

    .ant-menu-item {
      text-align: center;
    }
  }
`;

export const TitleSearchDrop = styled(Title)`
  /* color: rgba(255, 255, 255, 0.4); */
`;
