import React from 'react';
import PropTypes from 'prop-types';
import { Footer } from 'components';
import Navbar from 'pages/Layouts/MainPageLayout/Navbar';
import { NavbarMobileMainPage } from '../MainPageLayout/Navbar/NavbarMobileMainPage';

const VillaPageLayout = ({ children }) => (
  <div id="home">
    <Navbar />
    <NavbarMobileMainPage style={{ border: '3px solid blue' }} />
    {children}
    <Footer />
  </div>
);

VillaPageLayout.propTypes = { children: PropTypes.node };

export default VillaPageLayout;
