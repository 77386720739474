import React from 'react';
import { Breadcrumb } from 'antd';
import { BreadcrumbStyled } from './Breadcrumb.styles';

const BreadcrumbCustom = ({ crumbs, margin }) => (
  <BreadcrumbStyled margin={margin}>
    <Breadcrumb separator=">">
      {crumbs.map(({ title, link }) => (
        <Breadcrumb.Item href={link}>{title}</Breadcrumb.Item>
      ))}
      {/* <Breadcrumb.Item>An Application</Breadcrumb.Item> */}
    </Breadcrumb>
  </BreadcrumbStyled>
);

export default BreadcrumbCustom;
