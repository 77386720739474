import React, { useRef, useState } from 'react';
import { useOutsideClick } from 'hooks/useClickOutside';
import { DropWrapper, DropBtn, DropContent } from './DropClickOutside.styles';

const DropdownClickOutside = ({
  children,
  boxShadow,
  dropWrapperHeight,
  onHover,
  btnTitle,
  dropBtnWidth,
  dropBtnWidthLaptop,
  dropContainerWidth,
  dropBtnHeight,
  dropBtnColor,
  dropBtnBg,
  dropBtnBlur,
  dropBtnBorderRadius,
  dropBorderWrapper,
  dropBtnPadding,
  dropBtnMarginWrapper,
  dropBtnPaddingWrapper,
  dropWrapperWidth,
  dropWrapperWidthSM,
  dropWrapperMarginM,
  borders,
  bordersHeight,
  dropBtnMarginLaptop,
  dropWrapperWidth1100,
}) => {
  const [show, setSate] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (show) setSate(false);
  });

  return (
    <DropWrapper
      boxShadow={boxShadow}
      borders={borders}
      bordersHeight={bordersHeight}
      dropWrapperWidth1100={dropWrapperWidth1100}
      dropBorderWrapper={dropBorderWrapper}
      dropBtnPaddingWrapper={dropBtnPaddingWrapper}
      dropBtnMarginWrapper={dropBtnMarginWrapper}
      dropWrapperWidth={dropWrapperWidth}
      dropWrapperHeight={dropWrapperHeight}
      dropWrapperWidthSM={dropWrapperWidthSM}
      dropWrapperMarginM={dropWrapperMarginM}
    >
      <DropBtn
        onClick={() => setSate(!show)}
        dropBtnWidth={dropBtnWidth}
        dropBtnWidthLaptop={dropBtnWidthLaptop}
        dropBtnMarginLaptop={dropBtnMarginLaptop}
        dropBtnHeight={dropBtnHeight}
        dropBtnColor={dropBtnColor}
        dropBtnBg={dropBtnBg}
        dropBtnBlur={dropBtnBlur}
        dropBtnBorderRadius={dropBtnBorderRadius}
        dropBtnPadding={dropBtnPadding}

        // onMouseEnter={() => setSate(true)}
      >
        {btnTitle}
      </DropBtn>
      {show && (
        // <DropContentk>
        // <div>
        // <Portal>
        <DropContent
          onMouseLeave={() => onHover && setSate(false)}
          ref={ref}
          dropContainerWidth={dropContainerWidth}
          // id="portal"
        >
          {children}
        </DropContent>
        // </Portal>
        // </div>
      )}
    </DropWrapper>
  );
};

export { DropdownClickOutside };
