import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { PropTypes } from 'prop-types';
import { CardFlexGridWrapper } from 'components';

import {
  ImgCard,
  CategoryBtnWrapper,
  CategoryBtnController,
  CategoryBtn,
} from './Gallery.styles';

const GalleryWithFilter = ({ images }) => {
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState('');
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    setCategories([...new Set(images.map((img) => img.type))]);
    console.log('filteredImages: ', images);
    setFilteredImages(images);
  }, []);

  useEffect(() => {
    // const fbImages = images.map((img) => img.src);
    setFilteredImages(images.filter((img) => img.type === currentCategory));
    console.log('CURRENT: ', currentCategory);
  }, [currentCategory]);

  if (filteredImages.length === 0) setFilteredImages(images);

  return (
    <>
      <CategoryBtnWrapper>
        {currentCategory == '' ? (
          <>
            <CategoryBtnController
              type="radio"
              id="category-filter-all-modal"
              name="category"
              value=""
              onChange={(e) => setCurrentCategory(e.target.value)}
              checked
            />
            <CategoryBtn htmlFor="category-filter-all-modal">All</CategoryBtn>
          </>
        ) : (
          <>
            <CategoryBtnController
              type="radio"
              id="category-filter-all-modal"
              name="category"
              value="all"
              onChange={(e) => setCurrentCategory(e.target.value)}
            />
            <CategoryBtn htmlFor="category-filter-all-modal">All</CategoryBtn>
          </>
        )}

        {categories.map((filterBtn) => (
          <>
            <CategoryBtnController
              type="radio"
              id={`category-filter-${filterBtn}`}
              name="category"
              value={filterBtn}
              onChange={(e) => setCurrentCategory(e.target.value)}
            />
            <CategoryBtn htmlFor={`category-filter-${filterBtn}`}>
              {filterBtn}
            </CategoryBtn>
          </>
        ))}
      </CategoryBtnWrapper>
      {/* <Masonry imagesArray={images} /> */}

      {/* <FbImageLibrary
        images={[filteredImages.map((img) => img.src)]}
        countFrom={5}
      /> */}

      <CardFlexGridWrapper>
        {filteredImages
          && filteredImages.map((item) => <ImgCard key={uuid()} img={item.src} />)}
      </CardFlexGridWrapper>
    </>
  );
};

GalleryWithFilter.propTypes = {
  images: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export { GalleryWithFilter };
