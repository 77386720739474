import React from 'react';
import { CalendarClass } from 'components';
import { Row, Col } from 'antd';
import { CalendarContainer } from './Calendar.style';

const CalendarStyled = ({ onChangeDate, isCalendarReset, position = "", margin="-6px 0" }) => {

  return (
    <Row style={{ margin, height: '100vh', position, width: "101%", zIndex: "100000", left: "0"}}>
      <CalendarContainer style={{ height: '800px', width: '100%' }}>
        <Col style={{ height: '800px', width: '100%' }}>
          <CalendarClass onChangeDate={onChangeDate} isCalendarReset={isCalendarReset} />
        </Col>
      </CalendarContainer>
    </Row>
  );
};

export { CalendarStyled };
