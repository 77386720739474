import React from 'react';
import { Title } from 'components';

import { Row } from 'antd';
import { ProgressBar } from './ProgressBar';
import { ProgressWrapper } from './ProgressBar.styles';

const ProgressComposition = ({ margin, title, rate }) => {
  const progressValue = (100 / 5) * rate;

  return (
    <ProgressWrapper margin={margin}>
      <Row justify="space-evently" align="middle">
        <Title size="12px" width="95px">
          {title}
        </Title>
        <ProgressBar progressValue={progressValue} />
        <Title size="12px" width="24px" margin="0 0 0 15px">
          {rate}
        </Title>
      </Row>
    </ProgressWrapper>
  );
};

export { ProgressComposition };
