import styled from 'styled-components';
import NotFoundBg from 'assets/404.jpg';

export const HeaderBg = styled.div`
  /* max-height: 100vh; */
  position: relative;
  background-image: url(${NotFoundBg});
  background-size: cover;
  background-repeat: no-repeat;
  /* border-radius: 0px 0px 60px 60px; */
  position: relative;
  overflow: hidden;
  z-index: 1;
  /* 
  &::after {
    content: '';
    background: rgba(40, 40, 40, 0.48);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  } */
`;

export const HeaderContainer = styled.div`
  width: 100%;
  /* height: 945px; */
  height: 100vh;
  padding-left: 80px;
  color: #fff;

  background-size: cover;
  background-repeat: no-repeat;
  /* border-radius: 0px 0px 60px 60px; */
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  z-index: 1;
  /* &::after {
    content: '';
    background: rgba(40, 40, 40, 0.48);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  } */
  @media ${({ theme }) => theme.device.laptop} {
    height: 100vh;
    padding: 50px 25px;
  }
`;

export const Headings = styled.div`
  margin: 50% 0 0 0;
  max-width: 1400px;
  padding-right: 2%;
`;

export const SearchContainer = styled.div`
  margin: 140px 0;
`;

export const SocialContainer = styled.div`
  height: 100%;
  padding: 0 28px;
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #ffffff;
  transition: all ease-in-out 0.3s;
  i {
    color: #ffffff;
    opacity: 0.5;
    font-size: 24px;
    margin-top: 70px;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  @media ${({ theme }) => theme.device.laptop} {
    display: none;
  }
`;
