import React, { useState, useEffect } from 'react';
import moment from 'moment';
import AlertSVG from 'assets/icons/Alert.svg';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import {
  SEARCH_HOTELS_AUTOCOMPLETE,
  GET_WISHLIST_HOTELS,
  GET_REGION_ID_FROM_SLUG,
} from 'graphql/queries/getHotels';

import { Row, Menu } from 'antd';

import {
  Icon,
  Title,
  Text,
  Alert,
  DropdownMenu,
  Button,
  ColCustom,
  RowCustom,
  ContainerCustom,
  DropdownClickOutsidePortal,
  AutoCompletePortalMobile,
  CalendarStyled,
} from 'components';

import { LocationSVG, CalendarSVG, UserSVG, HeartSolidSVG } from 'lib/icons';

import { useIntl } from 'react-intl';

import { FavIcon } from './Navbar.styles';
import {
  NavMobile,
  SearchBar,
  DatePickerContainer,
  RangePickerTransparent,
  BtnCounter,
  MenuBtn,
  MenuIcon,
  NavIcon,
  MenuBlock,
  MenuItem,
  MobileTopFilters,
  DropBtnStyles,
  MenuBlockSecondLayer,
  MenuBtnSecondLayer,
  MenuBlockCurrencyLayer,
  MenuBtnCurrencyLayer,
  MenuItemNavbar,
} from './NavbarMobile.styles';
import { LogoMobile } from './parts/LogoMobile';
import ArrowLeft from 'assets/icons/mobileNavbar/ArrowLeft.svg';
import ArrowRight from 'assets/icons/mobileNavbar/ArrowRight.svg';
import ApproveMark from 'assets/icons/mobileNavbar/ApproveMark.svg';

const pluralize = require('numeralize-ru').pluralize;

const rangeConfig = {
  rules: [
    {
      type: 'array',
      required: true,
      message: 'Please select time!',
    },
  ],
};

const NavbarMobile = () => {
  const intl = useIntl();
  const history = useHistory();
  const queryStringParams = new URLSearchParams(history.location.search);

  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const [isSecondNavbarOpen, setSecondNavbarOpen] = useState(false);
  const [isCurrencyNavbarOpen, setCurrencyNavbarOpen] = useState(false);

  const [searchVisible, seSearchVisible] = useState(true);
  const [isCalendarOpen, setCalendarOpen] = useState(false);

  const [guestNumber, setGuestNumber] = useState({
    adults: queryStringParams.get('adults') || 0,
    children: queryStringParams.get('children') || 0,
    infants: queryStringParams.get('infants') || 0,
  });

  React.useEffect(() => {
    console.log('ACTION guestNumber', guestNumber);
  }, [guestNumber]);

  const [searchInputValue, setSearchInputValue] = useState({
    region: {
      id: null,
      value: '',
      slug: history.location.pathname.split('/')[3],
    },
    checkinDate: queryStringParams.get('checkindate') || '',
    checkoutDate: queryStringParams.get('checkoutdate') || '',
    adults: guestNumber.adults,
    children: guestNumber.children,
    infants: guestNumber.infants,
    isCalendarReset: false,
  });

  const [searchResult, setSearchResult] = useState([]);

  const {
    loading: loadingAutocomplete,
    error: errorAutocomplete,
    data: dataAutocomplete,
  } = useQuery(SEARCH_HOTELS_AUTOCOMPLETE, {
    variables: {
      lang: `${localStorage.language ? localStorage.language : 'en'}`,
      searchString: searchInputValue.region.value,
    },
  });

  const { loading, error, data } = useQuery(GET_REGION_ID_FROM_SLUG, {
    variables: {
      lang: history.location.pathname.split('/')[1],
      regionSlug: history.location.pathname.split('/')[3],
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    console.log('ACTION queryStringParams: ', history.location.search);

    console.log('ACTION adults', queryStringParams.get('adults'));
    console.log('ACTION children', queryStringParams.get('children'));
    console.log('ACTION infants', queryStringParams.get('infants'));
    console.log('ACTION checkinDate', queryStringParams.get('checkindate'));
    console.log('ACTION checkoutDate', queryStringParams.get('checkoutdate'));
    console.log('searchInputValue.region.value', searchInputValue);
    console.log('DATA', data);
    data &&
      data.regionId &&
      setSearchInputValue({
        ...searchInputValue,
        region: {
          ...searchInputValue.region,
          id: data.regionId.id,
          value: data.regionId.name,
        },
      });
  }, [data, loading]);

  // Autocomplete method start
  const onChangeSearchAutocomplete = (e) => {
    console.log('E: ', e.target.value);
    setIsAlertShown(false);
    setSearchInputValue({
      ...searchInputValue,
      region: {
        ...searchInputValue.region,
        value: e.target.value,
      },
    });
  };

  const onSelectSearchAutocomplete = (id, title, slug) => {
    console.log('onSelectSearchAutocomplete: ', id, ' + ', title);
    setIsAlertShown(false);
    setSearchInputValue({
      ...searchInputValue,
      region: {
        id,
        value: title,
        slug,
      },
    });
  };
  // Autocomplete method end

  const [isAlertShown, setIsAlertShown] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isSearchBarOpen, setSearchBarOpen] = useState(false);

  const onChangeDate = (startDate, endDate) => {
    console.log('123', startDate, endDate);
    setSearchInputValue(() => ({
      ...searchInputValue,
      isCalendarReset: false,
    }));

    if (startDate && !endDate) {
      setSearchInputValue(() => ({
        ...searchInputValue,
        checkinDate: moment(startDate._d).format('YYYY-MM-DD'),
        // checkoutDate: intl.formatMessage({
        //   id: 'search-panel.end-date',
        // }),
        isCalendarReset: false,
      }));
    }

    if (startDate && endDate) {
      setSearchInputValue(() => ({
        ...searchInputValue,
        checkinDate: moment(startDate._d).format('YYYY-MM-DD'),
        checkoutDate: moment(endDate._d).format('YYYY-MM-DD'),
        isCalendarReset: false,
      }));

      setCalendarOpen(false);
    }

    if (!startDate && !endDate) {
      setSearchInputValue({
        ...searchInputValue,
        checkinDate: null,
        checkoutDate: null,
      });
    }
  };

  useEffect(() => {
    if (
      !loadingAutocomplete &&
      dataAutocomplete &&
      dataAutocomplete.regionList
    ) {
      console.log('dataAutocomplete: ', dataAutocomplete);
      console.log('dataAutocomplete er: ', errorAutocomplete);

      setSearchResult(
        dataAutocomplete.regionList.map(
          ({ region: { id, regionType, slug }, name, parents }) => ({
            id,
            title: name,
            subtitle: parents,
            type: regionType.name,
            slug: regionType.slug,
            slug,
            icon: regionType.icon,
          })
        )
      );
    }
  }, [loadingAutocomplete, dataAutocomplete]);

  React.useEffect(() => {
    console.log('ACTION SELECT', searchInputValue);
  }, [searchInputValue]);

  const onLaguageChangeHandler = (e) => {
    console.log(e);
    const currentLanguage = history.location.pathname.split('/')[1];

    if (e === currentLanguage) {
      return;
    }
    const newPath = history.location.pathname.replace(currentLanguage, e);

    localStorage.setItem('language', e);
    history.push({
      pathname: newPath,
    });
    window.location.reload(true);
  };

  const onSubmitSearch = () => {
    const {
      region: { id: regionId, slug, value },
      checkinDate,
      checkoutDate,
    } = searchInputValue;

    const { adults, children, infants } = guestNumber;
    console.log('ACTION INPUTS ', searchInputValue);

    if (!value) {
      setIsAlertShown(true);
    } else {
      console.log(
        'ACTION searchInputValue',
        regionId,
        checkinDate,
        checkoutDate,
        adults,
        children,
        infants
      );

      const params = {
        checkinDate,
        checkoutDate,
        adults: adults === 0 ? 2 : adults,
        children,
        infants,
      };

      const urls = {
        checkinDate: `checkindate=${checkinDate}`,
        checkoutDate: `&checkoutdate=${checkoutDate}`,
        adults: `&adults=${adults === 0 ? 2 : adults}`,
        children: `&children=${children}`,
        infants: `&children=${infants}`,
      };

      let url = `/${localStorage.language}/search/${slug}?`;

      for (let prop in params) {
        if (params[prop]) {
          url += urls[prop];
        }
      }
      window.location.href = url;
    }
  };
  const {
    loading: loadingWishlist,
    error: errorWishlist,
    data: dataWishlist,
  } = useQuery(GET_WISHLIST_HOTELS, {
    variables: { lang: 'ru', offset: 0, limit: 15 },
    fetchPolicy: 'network-only',
  });

  const openSearchBar = () => {
    setSearchBarOpen(true);
  };

  return (
    <>
      <NavMobile
        height1100={isSearchBarOpen ? '240px' : '80px'}
        paddingMD="0 5.7vw 10px 5.7vw"
        padding1100="0 7vw"
      >
        {/* <RowCustom align="middle" justify="space-between"> */}
        {/* <Logo margin="25px 0 0 15px" /> */}
        <LogoMobile margin="20px 0 0 0" />
        <MenuBtn checked={isNavbarOpen} type="checkbox" id="menu-btn" />
        <MenuIcon
          onClick={() => setNavbarOpen(!isNavbarOpen)}
          htmlFor="menu-btn"
        >
          {dataWishlist &&
          dataWishlist.wishlist &&
          dataWishlist.wishlist.length > 0 ? (
            <FavIcon
              to={`/${localStorage.language}/my/favourite`}
              primary
              style={{ margin: 0 }}
            >
              <HeartSolidSVG />
            </FavIcon>
          ) : (
            <FavIcon
              to={`/${localStorage.language}/my/favourite`}
              style={{ margin: 0 }}
            >
              <HeartSolidSVG />
            </FavIcon>
          )}
          <NavIcon margin="0 0 0 20px" />
        </MenuIcon>

        {/* ---###--- */}
        <MenuBlock>
          <MenuItemNavbar>
            <RowCustom
              padding="0 29px 0 22px"
              borderBottom="1px solid #D7D6E0"
              margin="0 10px"
            >
              <MenuBtn checked={isNavbarOpen} type="checkbox" id="menu-btn" />
              <MenuIcon
                onClick={() => {
                  setNavbarOpen(!isNavbarOpen);
                }}
                padding="21px 5px"
                margin="0 0 0 auto"
              >
                <NavIcon background="#353535" margin="0" />
              </MenuIcon>
            </RowCustom>
            <RowCustom
              padding="0"
              direction="column"
              alignItems="flex-start"
              fontSize="16px"
            >
              <Link
                style={{ marginTop: '22px', padding: '0 0 0 33px' }}
                // to={`/${localStorage.language}/auth`}
                to={`/${localStorage.language}/`}
              >
                {intl.formatMessage({
                  id: 'navbar-mobile-login',
                })}
              </Link>
              <Link
                padding="0 0 0 33px"
                to={`/${localStorage.language}/info/faq`}
                style={{
                  color: '#353535',
                  marginTop: '22px',
                  marginBottom: '22px',
                  padding: '0 0 0 33px',
                }}
              >
                {intl.formatMessage({
                  id: 'navbar-mobile-help',
                })}
              </Link>
              {/* <RowCustom
                onClick={() => setCurrencyNavbarOpen(true)}
                width="100%"
                justify="space-between"
                alignItems="baseline"
                padding="0 33px 0 33px"
              >
                <Text>
                  {intl.formatMessage({
                    id: 'navbar-mobile-currency',
                  })}
                </Text>
                <Text color="#943FA1">
                  {`USD $`}
                  <Icon margin="0 0 0 17px" src={ArrowRight} />
                </Text>
              </RowCustom> */}

              <RowCustom
                onClick={() => setSecondNavbarOpen(true)}
                width="100%"
                justify="space-between"
                alignItems="baseline"
                marginTop="22px 0 0 0"
                padding="0 33px 0 33px"
              >
                <Text>
                  {intl.formatMessage({
                    id: 'navbar-mobile-language',
                  })}
                </Text>
                <Text color="#943FA1">
                  {localStorage.language
                    ? localStorage.language.toUpperCase()
                    : 'EN'}
                  <Icon margin="0 0 0 17px" src={ArrowRight} />
                </Text>
              </RowCustom>
            </RowCustom>
          </MenuItemNavbar>
        </MenuBlock>

        <MenuBtnSecondLayer checked={isSecondNavbarOpen} type="checkbox" />
        <MenuBlockSecondLayer>
          <MenuItemNavbar>
            <RowCustom
              padding="0 29px 0 22px"
              alignItems="baseline"
              borderBottom="1px solid #D7D6E0"
              margin="0 10px"
            >
              <Text
                onClick={() => setSecondNavbarOpen(false)}
                color="#943FA1"
                size="18px"
              >
                <Icon margin="0 13px 0 0" src={ArrowLeft} />
                {intl.formatMessage({
                  id: 'navbar-mobile-back',
                })}
              </Text>
              <MenuBtn checked={isNavbarOpen} type="checkbox" id="menu-btn" />
              <MenuIcon
                onClick={() => {
                  setNavbarOpen(!isNavbarOpen);
                  setSecondNavbarOpen(false);
                }}
                padding="21px 5px"
                margin="0 0 0 auto"
              >
                <NavIcon
                  background={isNavbarOpen ? '#353535' : '#ffffff '}
                  margin="0"
                />
              </MenuIcon>
            </RowCustom>
            <RowCustom
              direction="column"
              alignItems="flex-start"
              fontSize="16px"
              margin="22px 0 0 0"
            >
              {/* <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                English (UK)
              </RowCustom> */}
              <RowCustom
                onClick={() => onLaguageChangeHandler('en')}
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                background={
                  localStorage.language === 'en'
                    ? 'rgba(58%, 25%, 63%, 0.08)'
                    : null
                }
                size="16px"
              >
                {intl.formatMessage({
                  id: 'navbar-mobile.language-english',
                })}
              </RowCustom>
              <RowCustom
                onClick={() => onLaguageChangeHandler('ru')}
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                background={
                  localStorage.language === 'ru'
                    ? 'rgba(58%, 25%, 63%, 0.08)'
                    : null
                }
                size="16px"
              >
                {intl.formatMessage({
                  id: 'navbar-mobile.language-russian',
                })}
              </RowCustom>
              <RowCustom
                onClick={() => onLaguageChangeHandler('ro')}
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                background={
                  localStorage.language === 'ro'
                    ? 'rgba(58%, 25%, 63%, 0.08)'
                    : null
                }
                size="16px"
              >
                {intl.formatMessage({
                  id: 'navbar-mobile.language-romanian',
                })}
              </RowCustom>
              <RowCustom
                onClick={() => onLaguageChangeHandler('de')}
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                background={
                  localStorage.language === 'de'
                    ? 'rgba(58%, 25%, 63%, 0.08)'
                    : null
                }
                size="16px"
              >
                {intl.formatMessage({
                  id: 'navbar-mobile.language-german',
                })}
              </RowCustom>
              <RowCustom
                onClick={() => onLaguageChangeHandler('sr')}
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                background={
                  localStorage.language === 'sr'
                    ? 'rgba(58%, 25%, 63%, 0.08)'
                    : null
                }
                size="16px"
              >
                {intl.formatMessage({
                  id: 'navbar-mobile.language-serbian',
                })}
              </RowCustom>
              <RowCustom
                onClick={() => onLaguageChangeHandler('bg')}
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                background={
                  localStorage.language === 'bg'
                    ? 'rgba(58%, 25%, 63%, 0.08)'
                    : null
                }
                size="16px"
              >
                {intl.formatMessage({
                  id: 'navbar-mobile.language-bulgarian',
                })}
              </RowCustom>
              {/* <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                Deutch
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                Espanol
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                Italiano
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                Francais
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                Norsk
              </RowCustom> */}
            </RowCustom>
          </MenuItemNavbar>
        </MenuBlockSecondLayer>

        {/* <MenuBtnCurrencyLayer checked={isCurrencyNavbarOpen} type="checkbox" />
        <MenuBlockCurrencyLayer>
          <MenuItemNavbar>
            <RowCustom
              padding="0 29px 0 22px"
              alignItems="baseline"
              borderBottom="1px solid #D7D6E0"
              margin="0 10px"
            >
              <Text
                onClick={() => setCurrencyNavbarOpen(false)}
                color="#943FA1"
                size="18px"
              >
                <Icon margin="0 13px 0 0" src={ArrowLeft} />
                {intl.formatMessage({
                  id: 'navbar-mobile-back',
                })}
              </Text>
              <MenuBtn checked={isNavbarOpen} type="checkbox" id="menu-btn" />
              <MenuIcon
                onClick={() => {
                  setNavbarOpen(!isNavbarOpen);
                  setCurrencyNavbarOpen(false);
                }}
                padding="21px 5px"
                margin="0 0 0 auto"
              >
                <NavIcon
                  background={isNavbarOpen ? '#353535' : '#ffffff '}
                  margin="0"
                />
              </MenuIcon>
            </RowCustom>
            <RowCustom
              padding="0"
              direction="column"
              alignItems="flex-start"
              fontSize="16px"
              margin="22px 0 0 0"
            >
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
                background="rgba(58%, 25%, 63%, 0.08)"
              >
                USD
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                RUB
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                EUR
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                NOK
              </RowCustom>
            </RowCustom>
          </MenuItemNavbar>
        </MenuBlockCurrencyLayer> */}

        {/* ---###--- */}

        {searchVisible && !isSearchBarOpen && data && data.regionId ? (
          <MobileTopFilters>
            <RowCustom
              margin="35px 0 58px 0"
              width="100%"
              bottom="-20px"
              onClick={openSearchBar}
              className="collapsed"
            >
              <ColCustom padding="50px 0 0 0" className="mobile-search">
                <SearchBar
                  minWidth="100px"
                  maxWidth="158px"
                  size="14px"
                  marginIcon="0 7px"
                >
                  <i className="fas fa-search icon-light" />
                  <div
                    style={{
                      width: '100%',
                      // width: '115px',
                      display: 'block',
                      alignItems: 'center',
                      color: 'grey',
                      paddingRight: '10px',
                      flexWrap: 'nowrap',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflowY: 'hidden',
                    }}
                  >
                    {searchInputValue.region.value}
                  </div>
                </SearchBar>
              </ColCustom>

              <ColCustom
                className="mobile-datepicker"
                padding="30px 15px"
                height="inherit"
                self="stretch"
                display="flex"
                align="center"
                justifyContent="center"
                grow="1"
              >
                <ColCustom
                  display="flex"
                  align="center"
                  justifyContent="center"
                  calendIconNone
                >
                  <Text
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    margin="0"
                    color="#9791A7"
                    padding="0 0 0 3px"
                  >
                    {searchInputValue.checkinDate
                      ? `${moment(searchInputValue.checkinDate).format(
                          'D MMM'
                        )}`
                      : intl.formatMessage({
                          id: 'search-panel.start-date',
                        })}
                    —
                    {searchInputValue.checkoutDate
                      ? `${moment(searchInputValue.checkoutDate).format(
                          'D MMM'
                        )}`
                      : intl.formatMessage({
                          id: 'search-panel.end-date',
                        })}
                  </Text>
                </ColCustom>
              </ColCustom>

              <ColCustom
                padding="0px 15px 40px 15px"
                height="150px"
                className="mobile-guests-number"
                height="inherit"
                self="stretch"
                display="flex"
                align="center"
                justifyContent="center"
                overflow="hidden"
                grow="1"
              >
                <Title
                  overflow="hidden"
                  textOverflow="ellipsis"
                  size="14px"
                  margin="0"
                  greyText
                  whiteSpace="nowrap"
                >
                  {localStorage.language === 'ru'
                    ? `${
                        +guestNumber.adults + +guestNumber.children
                      } ${pluralize(
                        +guestNumber.adults + +guestNumber.children,
                        'Гость',
                        'Гостя',
                        'Гостей'
                      )}`
                    : `${+guestNumber.adults + +guestNumber.children} ${
                        +guestNumber.adults + +guestNumber.children === 1
                          ? 'Guest'
                          : 'Guests'
                      }`}
                </Title>
              </ColCustom>
            </RowCustom>
          </MobileTopFilters>
        ) : null}

        {/* ---###--- */}

        {searchVisible && isSearchBarOpen && data && data.regionId ? (
          <MobileTopFilters>
            <RowCustom width="100%">
              <ColCustom
                span={24}
                lg={24}
                md={24}
                sm={24}
                padding="50px 0 0 0"
                className="mobile-search"
              >
                <SearchBar width="100%">
                  <LocationSVG />
                  <div
                    style={{
                      width: '100%',
                      // width: '115px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AutoCompletePortalMobile
                      placeholderColor={isAlertShown ? 'error' : 'grey'}
                      dropContainerHeight="315px"
                      // dropWrapperWidth="90px"
                      dropWrapperWidth="100%"
                      dropContainerWidth="329px"
                      dropContentLeftPosition="-49vw"
                      placeholder={
                        isAlertShown
                          ? intl.formatMessage({
                              id: 'error-message.region-not-choosen',
                            })
                          : intl.formatMessage({
                              id: 'filters.city',
                            })
                      }
                      name="region"
                      value={searchInputValue.region.value}
                      defaultValue={data.regionId.name}
                      options={[...searchResult]}
                      onChange={onChangeSearchAutocomplete}
                      onSelect={onSelectSearchAutocomplete}
                    />
                  </div>
                </SearchBar>
              </ColCustom>

              <ColCustom
                className="mobile-datepicker"
                span={24}
                lg={24}
                md={24}
                sm={24}
                padding="30px 0"
              >
                <DatePickerContainer
                  onClick={() => setCalendarOpen(true)}
                  position="relative"
                  calendIconNone
                >
                  {isSearchBarOpen ? <CalendarSVG /> : null}

                  {/* <Form.Item name="range-picker" {...rangeConfig}> */}
                  <ContainerCustom name="range-picker" {...rangeConfig}>
                    {/* <Form.Item name="range-picker"> */}

                    <ColCustom
                      className="mobile-datepicker"
                      span={24}
                      lg={24}
                      md={24}
                      sm={24}
                      // padding="30px 15px"
                      height="inherit"
                      self="stretch"
                      display="flex"
                      align="center"
                      justifyContent="center"
                    >
                      <ColCustom
                        display="flex"
                        align="center"
                        justifyContent="center"
                        calendIconNone
                      >
                        <Text margin="0" color="#9791A7">
                          {searchInputValue.checkinDate
                            ? `${moment(searchInputValue.checkinDate).format(
                                'DD.MM.YYYY'
                              )}`
                            : intl.formatMessage({
                                id: 'search-panel.start-date',
                              })}
                          —
                          {searchInputValue.checkoutDate
                            ? `${moment(searchInputValue.checkoutDate).format(
                                'DD.MM.YYYY'
                              )}`
                            : intl.formatMessage({
                                id: 'search-panel.end-date',
                              })}
                        </Text>
                      </ColCustom>
                    </ColCustom>

                    {isCalendarOpen ? (
                      <CalendarStyled
                        onChangeDate={onChangeDate}
                        isCalendarReset={searchInputValue.isCalendarReset}
                        position="absolute"
                        margin="18px 0 0 -1px"
                      />
                    ) : null}
                    {/* </Form.Item> */}
                  </ContainerCustom>
                  {/* </Form.Item> */}
                </DatePickerContainer>
              </ColCustom>

              <ColCustom
                span={24}
                lg={24}
                md={24}
                sm={24}
                padding="0px 0 40px 0"
                height="100px"
                className="mobile-guests-number"
              >
                <RowCustom
                  flexFlow="nowrap"
                  justify="space-between"
                  // margin="0 0 30px 0"
                >
                  <DropdownClickOutsidePortal
                    boxShadow
                    dropBtnWidth="100%"
                    dropBtnMinWidth="200px"
                    dropBtnHeight="40px"
                    dropBtnColor="red"
                    dropBtnBg="#fff"
                    dropBtnBlur="blur(20px)"
                    dropWrapperWidthSM="100%"
                    dropBtnBorderRadius="10px"
                    dropBtnPadding="0 25px"
                    dropBorderWrapper="transparent"
                    btnTitle={
                      guestNumber.adults > 0 ? (
                        <DropBtnStyles>
                          <RowCustom flexFlow="nowrap" align="middle">
                            {isSearchBarOpen ? <UserSVG /> : null}
                            <Title
                              overflow="hidden"
                              textOverflow="ellipsis"
                              size="14px"
                              margin="0 0 0 17px"
                              greyText
                            >
                              {localStorage.language === 'ru'
                                ? `${
                                    +guestNumber.adults + +guestNumber.children
                                  } ${pluralize(
                                    +guestNumber.adults + +guestNumber.children,
                                    'Гость',
                                    'Гостя',
                                    'Гостей'
                                  )}`
                                : `${
                                    +guestNumber.adults + +guestNumber.children
                                  } ${
                                    +guestNumber.adults +
                                      +guestNumber.children ===
                                    1
                                      ? 'Guest'
                                      : 'Guests'
                                  }`}
                            </Title>
                          </RowCustom>
                        </DropBtnStyles>
                      ) : (
                        <Row align="middle">
                          <DropBtnStyles>
                            {isSearchBarOpen ? <UserSVG /> : null}
                          </DropBtnStyles>
                          <Title size="14px" margin="0 0 0 17px" greyText>
                            {intl.formatMessage({
                              id: 'main-page.guest-number-mob',
                            })}
                          </Title>
                        </Row>
                      )
                    }
                    margin="0 0 0 40px"
                  >
                    {/* <DropPortalContainer> */}
                    <ContainerCustom padding="15px">
                      <ContainerCustom display="flex">
                        <Title width="100px">Взрослые:</Title>
                        <BtnCounter
                          onClick={() =>
                            setGuestNumber({
                              ...guestNumber,
                              adults:
                                guestNumber.adults - 1 < 0
                                  ? 0
                                  : --guestNumber.adults,
                            })
                          }
                        >
                          -
                        </BtnCounter>
                        <Title width="30px" center>
                          {guestNumber.adults}
                        </Title>
                        <BtnCounter
                          onClick={() =>
                            setGuestNumber({
                              ...guestNumber,
                              adults: ++guestNumber.adults,
                            })
                          }
                        >
                          +
                        </BtnCounter>
                      </ContainerCustom>
                      <br />
                      <ContainerCustom display="flex">
                        <Title width="100px">Дети:</Title>
                        <BtnCounter
                          onClick={() =>
                            setGuestNumber({
                              ...guestNumber,
                              children:
                                guestNumber.children - 1 < 0
                                  ? 0
                                  : --guestNumber.children,
                            })
                          }
                        >
                          -
                        </BtnCounter>
                        <Title width="30px" center>
                          {guestNumber.children}
                        </Title>
                        <BtnCounter
                          onClick={() =>
                            setGuestNumber({
                              ...guestNumber,
                              children: ++guestNumber.children,
                            })
                          }
                        >
                          +
                        </BtnCounter>
                      </ContainerCustom>
                      <br />
                      <ContainerCustom display="flex">
                        <Title width="100px">
                          {intl.formatMessage({
                            id: 'main-page.guests.infants',
                          })}
                          :
                        </Title>
                        <BtnCounter
                          onClick={() =>
                            setGuestNumber({
                              ...guestNumber,
                              infants:
                                guestNumber.infants - 1 < 0
                                  ? 0
                                  : --guestNumber.infants,
                            })
                          }
                        >
                          -
                        </BtnCounter>
                        <Title width="30px" center>
                          {guestNumber.infants}
                        </Title>
                        <BtnCounter
                          onClick={() =>
                            setGuestNumber({
                              ...guestNumber,
                              infants: ++guestNumber.infants,
                            })
                          }
                        >
                          +
                        </BtnCounter>
                      </ContainerCustom>
                    </ContainerCustom>
                    {/* </DropPortalContainer> */}
                  </DropdownClickOutsidePortal>

                  {isSearchBarOpen && (
                    <Button
                      size="16px"
                      onClick={onSubmitSearch}
                      width="90px"
                      height="40px"
                      size="16px"
                      margin="0 0 0 20px"
                      primary
                    >
                      {intl.formatMessage({
                        id: 'buttons.search',
                      })}
                    </Button>
                  )}
                </RowCustom>
              </ColCustom>
            </RowCustom>
          </MobileTopFilters>
        ) : null}
      </NavMobile>
    </>
  );
};

export { NavbarMobile };
