import React, { useRef, useState } from 'react';
import { useOutsideClick } from 'hooks/useClickOutside';
import { Portal } from 'components';
import { DropWrapper, DropBtn, DropContent } from './DropClickOutside.styles';

const DropdownClickOutsidePortal = ({
  children,
  boxShadow,
  dropWrapperHeight,
  onHover,
  btnTitle,
  dropBtnWidth,
  dropBtnMinWidth,
  dropContainerWidth,
  dropBtnHeight,
  dropBtnColor,
  dropBtnBg,
  dropBtnBlur,
  dropBtnBorderRadius,
  dropBorderWrapper,
  dropBtnPadding,
  dropBtnMarginWrapper,
  dropBtnPaddingWrapper,
  dropWrapperWidth,
  dropWrapperWidthSM,
  dropWrapperMarginM,
  borders,
  bordersHeight,
}) => {
  const [show, setSate] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (show) setSate(false);
  });

  return (
    <DropWrapper
      boxShadow={boxShadow}
      borders={borders}
      bordersHeight={bordersHeight}
      dropBorderWrapper={dropBorderWrapper}
      dropBtnPaddingWrapper={dropBtnPaddingWrapper}
      dropBtnMarginWrapper={dropBtnMarginWrapper}
      dropWrapperWidth={dropWrapperWidth}
      dropWrapperHeight={dropWrapperHeight}
      dropWrapperWidthSM={dropWrapperWidthSM}
      dropWrapperMarginM={dropWrapperMarginM}
    >
      <DropBtn
        onClick={() => setSate(!show)}
        dropBtnWidth={dropBtnWidth}
        dropBtnMinWidth={dropBtnMinWidth}
        dropBtnHeight={dropBtnHeight}
        dropBtnColor={dropBtnColor}
        dropBtnBg={dropBtnBg}
        dropBtnBlur={dropBtnBlur}
        dropBtnBorderRadius={dropBtnBorderRadius}
        dropBtnPadding={dropBtnPadding}
        // onMouseEnter={() => setSate(true)}
      >
        {btnTitle}
      </DropBtn>
      {show && (
        <Portal>
          <DropContent
            onMouseLeave={() => onHover && setSate(false)}
            ref={ref}
            dropContainerWidth={dropContainerWidth}
            // id="portal"
          >
            {children}
          </DropContent>
        </Portal>
      )}
    </DropWrapper>
  );
};

export { DropdownClickOutsidePortal };
