/*eslint-disable  spaced-comment */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow*/
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from '../index';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userContext = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        userContext.auth === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )}
    />
  );
};

PrivateRoute.propTypes = { component: PropTypes.node };

export { PrivateRoute };
