import React, { useState, useCallback } from 'react';
import mockImg from 'assets/mockImg.png';
import { Title, RowCustom, ColCustom } from 'components';
import { Wrapper } from 'components/Wrappers/Wrappers.styles';
import { useIntl } from 'react-intl';
import { Price, PriceInfo } from './Card.styles';
import { Link } from 'react-router-dom';
const pluralize = require('numeralize-ru').pluralize;

const MockCardInfo = {
  img: mockImg,
  regionName: 'Kerethium & Astarte',
  price: 2440,
  nightsNumber: 6,
};

const MapCard = ({ hotel, onCardHover, nightsNumber }) => {
  const intl = useIntl();
  const [isCardHovered, setCardHover] = useState(false);
  console.log('gdggdg ');
  const cardHoverHandler = useCallback(
    (data) => {
      if (isCardHovered) {
        return;
      } else {
        setCardHover(true);
        console.log('CardHover', data);
        onCardHover(data);
      }
    },
    [isCardHovered]
  );

  return (
    <RowCustom
      margin="0 0 0 11px"
      // padding="22px 18px"
      wrap="nowrap"
      width="310px"
      overflow="hidden"
      minWidth="310px"
      height="104px"
      position="relative"
      borderRadius="20px"
      // onClick={() => cardHoverHandler(null)}
      onClick={() => cardHoverHandler(hotel)}
    >
      <Link to={`${hotel.urls.filterUrl}`} target="_blank">
        <RowCustom width="137px">
          <img
            src={`${process.env.REACT_APP_API_MEDIA_URL}${hotel.coverPhoto[0].photo}`}
            alt="hotel"
            width="100%"
          />
        </RowCustom>
      </Link>
      <RowCustom padding="22px 18px" width="173px" background="#ffffff">
        <Title
          width="100%"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {hotel.name}
        </Title>
        <Wrapper margin="0 0 0 0">
          {nightsNumber != 0 ? (
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Price weight="500">
                  €{hotel.price}
                  <PriceInfo>
                    {' /'}
                    {localStorage.language === 'ru'
                      ? `${+nightsNumber} ${pluralize(
                          +nightsNumber,
                          'день',
                          'дня',
                          'дней'
                        )}`
                      : `${+nightsNumber} ${
                          +nightsNumber === 1 ? 'day' : 'days'
                        }`}
                  </PriceInfo>
                </Price>
              </div>
            </>
          ) : (
            <Price size="18px" sizeM="16px" weightM="normal">
              {intl.formatMessage({
                id: 'card.from',
              })}{' '}
              €{hotel.price}/
              {intl.formatMessage({
                id: 'card.days',
              })}
            </Price>
          )}
        </Wrapper>
      </RowCustom>
    </RowCustom>
  );
};

export { MapCard };
