import styled from 'styled-components';

export const List = styled.ul`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin || '0'};
  padding-left: 0;
  color: ${({ theme }) => theme.colors.text || '#353535'};
  list-style: none;
`;

export const ListItem = styled.li`
  width: ${({ width }) => width};
  display: ${({ display }) => display};
  align-items: ${({ align }) => align};
  font-size: ${({ size }) => size};
  margin: ${({ margin }) => margin || '0'};
  a {
    color: ${({ theme, color }) => color || theme.colors.text.main};
  }
  i {
    width: 14px;
    margin-top: 6px;
    margin-right: 10px;
    margin: ${({ marginIcon }) => marginIcon};
    font-size: ${({ iconSize }) => iconSize};
    color: ${({ theme, colorIcon }) => colorIcon || theme.colors.primary};
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: ${({ widthTablet }) => widthTablet};
  }
`;
