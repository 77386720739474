import styled, { css } from 'styled-components';
import { AutoComplete } from 'antd';

export const AutoCompleteInput = styled(AutoComplete)`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding || '0 20px'};

  ${({ theme, border }) => border
    && css`
      border-radius: 15px;
      border: 1px solid ${theme.colors.borders.light};
    `};
`;

export const Input = styled.input`
  &:focus {
    border: none;
    outline: none;
  }
`;
