import styled, { css } from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export const LinckAnchorWrapper = styled(AnchorLink)`
  ${({ tabletHidden }) => tabletHidden
    && css`
      @media (min-width: 750px) {
        display: none;
      }
    `}
`;

export const ButtonUpWrapper = styled.div`
  position: fixed;
  top: 83vh;
  right: 10px;
  width: ${({ size }) => size || '60px'};
  height: ${({ size }) => size || '60px'};
  margin: ${({ margin }) => margin};
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color || '#ffffff'};
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
`;
