import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { PropTypes } from 'prop-types';
import { CardFlexGridWrapper } from 'components';
import { useIntl } from 'react-intl';

import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css'; //

import {
  ImgCard,
  CategoryBtnWrapper,
  CategoryBtnController,
  CategoryBtn,
} from './Gallery.styles';

const GalleryWithFilter = ({ images }) => {
  const intl = useIntl();
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState('');
  const [filteredImages, setFilteredImages] = useState([]);

  const [lightboxImages, setLightboxImages] = useState([]);
  useEffect(() => {
    setLightboxImages(images.map(({ src }) => src));
  }, [images]);

  const [stateLightbox, setStateLightbox] = useState({
    photoIndex: 0,
    isOpen: false,
  });
  const { photoIndex, isOpen } = stateLightbox;

  useEffect(() => {
    setCategories([...new Set(images.map((img) => img.type))]);
    console.log('filteredImages: ', images);
    setFilteredImages(images);
  }, []);

  useEffect(() => {
    // const fbImages = images.map((img) => img.src);
    setFilteredImages(images.filter((img) => img.type === currentCategory));
    console.log('CURRENT: ', currentCategory);
  }, [currentCategory]);

  if (filteredImages.length === 0) setFilteredImages(images);

  return (
    <>
      <CategoryBtnWrapper>
        {currentCategory == '' ? (
          <>
            <CategoryBtnController
              type="radio"
              id="category-filter-all"
              name="category"
              value=""
              onChange={(e) => setCurrentCategory(e.target.value)}
              checked
            />
            <CategoryBtn htmlFor="category-filter-all">
              {intl.formatMessage({
                id: 'hotel.gallery-tab.all',
              })}
            </CategoryBtn>
          </>
        ) : (
          <>
            <CategoryBtnController
              type="radio"
              id="category-filter-all"
              name="category"
              value="all"
              onChange={(e) => setCurrentCategory(e.target.value)}
            />
            <CategoryBtn htmlFor="category-filter-all">
              {intl.formatMessage({
                id: 'hotel.gallery-tab.all',
              })}
            </CategoryBtn>
          </>
        )}

        {categories.map((filterBtn) => (
          <>
            <CategoryBtnController
              type="radio"
              id={`category-filter-${filterBtn}`}
              name="category"
              value={filterBtn}
              onChange={(e) => setCurrentCategory(e.target.value)}
            />
            <CategoryBtn htmlFor={`category-filter-${filterBtn}`}>
              {filterBtn}
            </CategoryBtn>
          </>
        ))}
      </CategoryBtnWrapper>

      <CardFlexGridWrapper>
        {filteredImages &&
          filteredImages.map((item, index) => (
            <ImgCard
              key={uuid()}
              img={item.src}
              onClick={() =>
                setStateLightbox({
                  ...stateLightbox,
                  isOpen: true,
                  photoIndex: index,
                })
              }
            />
          ))}
      </CardFlexGridWrapper>

      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[photoIndex]}
          nextSrc={lightboxImages[(photoIndex + 1) % lightboxImages.length]}
          prevSrc={
            lightboxImages[
              (photoIndex + lightboxImages.length - 1) % lightboxImages.length
            ]
          }
          onCloseRequest={() =>
            setStateLightbox({ ...stateLightbox, isOpen: false })
          }
          onMovePrevRequest={() =>
            setStateLightbox({
              ...stateLightbox,
              photoIndex:
                (photoIndex + lightboxImages.length - 1) %
                lightboxImages.length,
            })
          }
          onMoveNextRequest={() =>
            setStateLightbox({
              ...stateLightbox,
              photoIndex: (photoIndex + 1) % lightboxImages.length,
            })
          }
        />
      )}
    </>
  );
};

GalleryWithFilter.propTypes = {
  images: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export { GalleryWithFilter };
