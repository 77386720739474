import React from 'react';
import { NavbarMobile } from './NavbarMobile';
import { Navbar } from './Navbar';

const NavbarCombine = () => (
  <>
    <Navbar />
    <NavbarMobile />
  </>
);

export default NavbarCombine;
