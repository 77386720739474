import styled from 'styled-components';

export const LogoContainer = styled.div`
  float: left;
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
`;

export const LogoTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const LogoSymbol = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-image: url(${({ img }) => img});
  background-color: ${({ theme }) => theme.colors.primary};
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoTitle = styled.div`
  width: 59px;
  height: 22px;
  background-image: url(${({ img }) => img});
  background-position: center;
  background-size: cover;
  margin: 0 6px;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
`;
