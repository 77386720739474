import styled from 'styled-components';
import { Row } from 'antd';

export const RowCustom = styled(Row)`
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height}; /*|| '100%'*/
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  flex-flow: ${({ flexFlow }) => flexFlow};
  position: ${({ position }) => position};
  bottom: ${({ bottom }) => bottom};
  overflow: ${({ overflow }) => overflow};
  align: ${({ alignCustom }) => alignCustom};
  flex-grow: ${({ flexGrow }) => flexGrow};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justify }) => justify};
  flex-wrap: ${({ wrap }) => wrap};
  flex-direction: ${({ direction }) => direction};
  border-bottom: ${({ borderBottom }) => borderBottom};
  border-top: ${({ borderTop }) => borderTop};
  border-radius: ${({ borderRadius }) => borderRadius};
  font-size: ${({ fontSize }) => fontSize};
  background-color: ${({ background }) => background};
  z-index: ${({ zInedx }) => zInedx};

  @media ${({ theme }) => theme.deviceMin.desktop1890} {
    padding: ${({ paddingFrom1890 }) => paddingFrom1890};
  }

  @media ${({ theme }) => theme.device.desktop1890} {
    padding: ${({ padding1890 }) => padding1890};
  }

  @media ${({ theme }) => theme.device.laptop1100} {
    max-width: ${({ maxWidth1100 }) => maxWidth1100};
    margin: ${({ margin1100 }) => margin1100};
    flex-direction: ${({ direction1100 }) => direction1100};
    align-items: ${({ alignItems1100 }) => alignItems1100};
    padding: ${({ padding1100 }) => padding1100};
  }

  @media ${({ theme }) => theme.device.laptop} {
    padding: ${({ paddingLaptop }) => paddingLaptop};
    justify-content: ${({ justifyLaptop }) => justifyLaptop};
    flex-grow: ${({ growLaptop }) => growLaptop};
  }
  @media ${({ theme }) => theme.device.tablet} {
    margin: ${({ marginTablet }) => marginTablet};
    display: ${({ displayM }) => displayM};
    justify-content: ${({ justifyM }) => justifyM};
    flex-flow: ${({ flexFlowTablet }) => flexFlowTablet};
    width: ${({ widthM }) => widthM};
  }
  @media ${({ theme }) => theme.device.mobileL} {
    height: ${({ heightMobL }) => heightMobL};
  }
`;
