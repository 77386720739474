import React from 'react';
import styled, { css } from 'styled-components';
import SignIn from './parts/SignIn';
import SignUp from './parts/SignUp';
import {
  Title,
  Text,
  ContainerCustom,
  RowCustom,
  DeviceBreakpoint,
} from 'components';
import { TabsWrapper } from './Auth.style';
import { Tabs } from 'antd';
import { useIntl } from 'react-intl';

import AuthImg from 'assets/auth.png';

const { TabPane } = Tabs;

const ImageWrapper = styled(ContainerCustom)`
  background-image: linear-gradient(
      rgba(56, 49, 66, 0.6) 0%,
      rgba(56, 49, 66, 0.6) 100%
    ),
    url(${AuthImg});
  background-size: cover;
  @media (max-width: 790px) {
    display: none;
  }
`;

const Auth = () => {
  const intl = useIntl();

  return (
    <RowCustom min-height="100vh">
      <ImageWrapper
        width="50%"
        alignSelf="stretch"
        padding="184px 30px 0 66px"
        display="flex"
        justify="flex-start"
        align="center"
        direction="column"
      >
        <Title
          lineHeight="45px"
          margin="0 0 34px 0"
          size="36px"
          color="#ffffff"
          ubuntu
        >
          {intl.formatMessage({
            id: 'forms.auth-welcome',
          })}
        </Title>
        <Text size="20px" color="#ffffff">
          {intl.formatMessage({
            id: 'forms.auth-welcome-p',
          })}
        </Text>
      </ImageWrapper>
      <TabsWrapper padding="60px 0 0 0">
        <Tabs defaultActiveKey="1" style={{ height: '100%' }}>
          <TabPane tab="Войти" key="1">
            <SignIn />
          </TabPane>
          <TabPane tab="Зарегистрироваться" key="2">
            <SignUp />
          </TabPane>
        </Tabs>
      </TabsWrapper>
    </RowCustom>
  );
};

export default Auth;
