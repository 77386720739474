export const sizeMin = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet550: '550px',
  tabletSmall: '750px',
  tablet: '768px',
  laptop: '1024px',
  laptop1100: '1100px',
  laptopL: '1440px',
  desktop1890: '1890px',
  desktop1920: '1920px',
  desktop2100: '2100px',
  desktop: '2560px',
};

export const size = {
  mobileS: '319px',
  mobileM: '374px',
  mobileL: '424px',
  tablet550: '549px',
  tabletSmall: '749px',
  tablet: '767px',
  laptop: '1023px',
  laptop1100: '1099px',
  laptopL: '1439px',
  desktop1890: '1890px',
  desktop1920: '1919px',
  desktop2100: '2099px',
  desktop: '2559px',
};

export const deviceBreakpoints = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  tablet550: `(max-width: ${size.tablet550})`,
  tabletSmall: `(max-width: ${size.tabletSmall})`,
  laptop: `(max-width: ${size.laptop})`,
  laptop1100: `(max-width: ${size.laptop1100})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktop1890: `(max-width: ${size.desktop1890})`,
  desktop1920: `(max-width: ${size.desktop1920})`,
  desktop2100: `(max-width: ${size.desktop2100})`,
  desktopL: `(max-width: ${size.desktop})`,
};

export const deviceBreakpointsMin = {
  mobileS: `(min-width: ${sizeMin.mobileS})`,
  mobileM: `(min-width: ${sizeMin.mobileM})`,
  mobileL: `(min-width: ${sizeMin.mobileL})`,
  tablet: `(min-width: ${sizeMin.tablet})`,
  tablet550: `(min-width: ${sizeMin.tablet550})`,
  laptop: `(min-width: ${sizeMin.laptop})`,
  laptop1100: `(min-width: ${sizeMin.laptop1100})`,
  laptopL: `(min-width: ${sizeMin.laptopL})`,
  desktop: `(min-width: ${sizeMin.desktop})`,
  desktop1890: `(min-width: ${sizeMin.desktop1890})`,
  desktop1920: `(min-width: ${sizeMin.desktop1920})`,
  desktop2100: `(min-width: ${sizeMin.desktop2100})`,
  desktopL: `(min-width: ${sizeMin.desktop})`,
};
