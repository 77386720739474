import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { ContainerCustom } from 'components';


const ScrollContainer = ({
  zInedx,
  bottom,
  position,
  itemsList,
  children,
  width,
  height = '336px',
  Trigger,
  Loader,
}) => {
  return (
    <ContainerCustom
      zIndex={zInedx}
      bottom={bottom}
      position={position}
      height={height}
      width={width}
      display="flex"
      wrap="nowrap"
      overflowX="scroll"
    >
      {itemsList.map((hotel, i) => {
        return (
          <>
            {React.cloneElement(children, {
              isMobile: true,
              key: uuid(),
              hotel: hotel,
            })}
            {i === itemsList.length - 6 && Trigger}
          </>
        );
      })}
      {Loader}
    </ContainerCustom>
  );
};

export { ScrollContainer };
