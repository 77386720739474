import React, { useState, useEffect } from 'react';
import moment from 'moment';
import AlertSVG from 'assets/icons/Alert.svg';

import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_WISHLIST_HOTELS } from 'graphql/queries/getHotels';

import { Menu } from 'antd';

import { Logo, DropdownMenu, RowCustom, Icon, Text } from 'components';

import { HeartSolidSVG } from 'lib/icons';

import { useIntl } from 'react-intl';

import { FavIcon } from './Navbar.styles';
import {
  NavMobile,
  MenuBtn,
  MenuIcon,
  NavIcon,
  MenuBlock,
  MenuItem,
  MenuBlockSecondLayer,
  MenuBtnSecondLayer,
  MenuBlockCurrencyLayer,
  MenuBtnCurrencyLayer,
  MenuItemNavbar,
} from './NavbarMobile.styles';

import ArrowLeft from 'assets/icons/mobileNavbar/ArrowLeft.svg';
import ArrowRight from 'assets/icons/mobileNavbar/ArrowRight.svg';
import ApproveMark from 'assets/icons/mobileNavbar/ApproveMark.svg';

const SearchBarLessMobile = () => {
  const intl = useIntl();
  const history = useHistory();

  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const [isSecondNavbarOpen, setSecondNavbarOpen] = useState(false);
  const [isCurrencyNavbarOpen, setCurrencyNavbarOpen] = useState(false);

  const onLaguageChangeHandler = (e) => {
    console.log(e);
    const currentLanguage = history.location.pathname.split('/')[1];

    if (e === currentLanguage) {
      return;
    }
    const newPath = history.location.pathname.replace(currentLanguage, e);

    localStorage.setItem('language', e);
    history.push({
      pathname: newPath,
    });
    window.location.reload(true);
  };

  const {
    loading: loadingWishlist,
    error: errorWishlist,
    data: dataWishlist,
  } = useQuery(GET_WISHLIST_HOTELS, {
    variables: { lang: 'ru', offset: 0, limit: 15 },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <NavMobile padding="0 5.7vw 10px 5.7vw" padding1100="0 5.7vw 10px 5.7vw">
        <Logo margin="20px 0 0 0" />
        <MenuBtn checked={isNavbarOpen} type="checkbox" id="menu-btn" />
        <MenuIcon
          onClick={() => setNavbarOpen(!isNavbarOpen)}
          htmlFor="menu-btn"
        >
          <NavIcon margin="45px 0 0 0" />
        </MenuIcon>

        {/* ---###--- */}
        <MenuBlock>
          <MenuItemNavbar>
            <RowCustom
              padding="0 29px 0 22px"
              borderBottom="1px solid #D7D6E0"
              margin="0 10px"
            >
              <MenuBtn checked={isNavbarOpen} type="checkbox" id="menu-btn" />
              <MenuIcon
                onClick={() => {
                  setNavbarOpen(!isNavbarOpen);
                }}
                padding="21px 5px"
                margin="0 0 0 auto"
              >
                <NavIcon background="#353535" margin="0" />
              </MenuIcon>
            </RowCustom>
            <RowCustom
              padding="0"
              direction="column"
              alignItems="flex-start"
              fontSize="16px"
            >
              <Link
                style={{ marginTop: '22px', padding: '0 0 0 33px' }}
                // to={`/${localStorage.language}/auth`}
                to={`/${localStorage.language}/`}
              >
                {intl.formatMessage({
                  id: 'navbar-mobile-login',
                })}
              </Link>
              <Link
                padding="0 0 0 33px"
                to={`/${localStorage.language}/info/faq`}
                style={{
                  color: '#353535',
                  marginTop: '22px',
                  marginBottom: '22px',
                  padding: '0 0 0 33px',
                }}
              >
                {intl.formatMessage({
                  id: 'navbar-mobile-help',
                })}
              </Link>
              <RowCustom
                onClick={() => setCurrencyNavbarOpen(true)}
                width="100%"
                justify="space-between"
                alignItems="baseline"
                padding="0 33px 0 33px"
              >
                <Text>
                  {intl.formatMessage({
                    id: 'navbar-mobile-currency',
                  })}
                </Text>
                <Text color="#943FA1">
                  {`USD $`}
                  <Icon margin="0 0 0 17px" src={ArrowRight} />
                </Text>
              </RowCustom>

              <RowCustom
                onClick={() => setSecondNavbarOpen(true)}
                width="100%"
                justify="space-between"
                alignItems="baseline"
                marginTop="22px 0 0 0"
                padding="0 33px 0 33px"
              >
                <Text>
                  {intl.formatMessage({
                    id: 'navbar-mobile-language',
                  })}
                </Text>
                <Text color="#943FA1">
                  {localStorage.language
                    ? localStorage.language.toUpperCase()
                    : 'EN'}
                  <Icon margin="0 0 0 17px" src={ArrowRight} />
                </Text>
              </RowCustom>
            </RowCustom>
          </MenuItemNavbar>
        </MenuBlock>

        <MenuBtnSecondLayer checked={isSecondNavbarOpen} type="checkbox" />
        <MenuBlockSecondLayer>
          <MenuItemNavbar>
            <RowCustom
              padding="0 29px 0 22px"
              alignItems="baseline"
              borderBottom="1px solid #D7D6E0"
              margin="0 10px"
            >
              <Text
                onClick={() => setSecondNavbarOpen(false)}
                color="#943FA1"
                size="18px"
              >
                <Icon margin="0 13px 0 0" src={ArrowLeft} />
                {intl.formatMessage({
                  id: 'navbar-mobile-back',
                })}
              </Text>
              <MenuBtn checked={isNavbarOpen} type="checkbox" id="menu-btn" />
              <MenuIcon
                onClick={() => {
                  setNavbarOpen(!isNavbarOpen);
                  setSecondNavbarOpen(false);
                }}
                padding="21px 5px"
                margin="0 0 0 auto"
              >
                <NavIcon
                  background={isNavbarOpen ? '#353535' : '#ffffff '}
                  margin="0"
                />
              </MenuIcon>
            </RowCustom>
            <RowCustom
              onClick={() => onLaguageChangeHandler('en')}
              padding="0 0 0 33px"
              alignItems="center"
              margin="0"
              height="50px"
              width="100%"
              background={
                localStorage.language === 'en'
                  ? 'rgba(58%, 25%, 63%, 0.08)'
                  : null
              }
              size="16px"
            >
              {intl.formatMessage({
                id: 'navbar-mobile.language-english',
              })}
            </RowCustom>
            <RowCustom
              onClick={() => onLaguageChangeHandler('ru')}
              padding="0 0 0 33px"
              alignItems="center"
              margin="0"
              height="50px"
              width="100%"
              background={
                localStorage.language === 'ru'
                  ? 'rgba(58%, 25%, 63%, 0.08)'
                  : null
              }
              size="16px"
            >
              {intl.formatMessage({
                id: 'navbar-mobile.language-russian',
              })}
            </RowCustom>
            <RowCustom
              onClick={() => onLaguageChangeHandler('ro')}
              padding="0 0 0 33px"
              alignItems="center"
              margin="0"
              height="50px"
              width="100%"
              background={
                localStorage.language === 'ro'
                  ? 'rgba(58%, 25%, 63%, 0.08)'
                  : null
              }
              size="16px"
            >
              {intl.formatMessage({
                id: 'navbar-mobile.language-romanian',
              })}
            </RowCustom>
            <RowCustom
              onClick={() => onLaguageChangeHandler('de')}
              padding="0 0 0 33px"
              alignItems="center"
              margin="0"
              height="50px"
              width="100%"
              background={
                localStorage.language === 'de'
                  ? 'rgba(58%, 25%, 63%, 0.08)'
                  : null
              }
              size="16px"
            >
              {intl.formatMessage({
                id: 'navbar-mobile.language-german',
              })}
            </RowCustom>
            <RowCustom
              onClick={() => onLaguageChangeHandler('sr')}
              padding="0 0 0 33px"
              alignItems="center"
              margin="0"
              height="50px"
              width="100%"
              background={
                localStorage.language === 'sr'
                  ? 'rgba(58%, 25%, 63%, 0.08)'
                  : null
              }
              size="16px"
            >
              {intl.formatMessage({
                id: 'navbar-mobile.language-serbian',
              })}
            </RowCustom>
            <RowCustom
              onClick={() => onLaguageChangeHandler('bg')}
              padding="0 0 0 33px"
              alignItems="center"
              margin="0"
              height="50px"
              width="100%"
              background={
                localStorage.language === 'bg'
                  ? 'rgba(58%, 25%, 63%, 0.08)'
                  : null
              }
              size="16px"
            >
              {intl.formatMessage({
                id: 'navbar-mobile.language-bulgarian',
              })}
            </RowCustom>
          </MenuItemNavbar>
        </MenuBlockSecondLayer>

        <MenuBtnCurrencyLayer checked={isCurrencyNavbarOpen} type="checkbox" />
        <MenuBlockCurrencyLayer>
          <MenuItemNavbar>
            <RowCustom
              padding="0 29px 0 22px"
              alignItems="baseline"
              borderBottom="1px solid #D7D6E0"
              margin="0 10px"
            >
              <Text
                onClick={() => setCurrencyNavbarOpen(false)}
                color="#943FA1"
                size="18px"
              >
                <Icon margin="0 13px 0 0" src={ArrowLeft} />
                {intl.formatMessage({
                  id: 'navbar-mobile-back',
                })}
              </Text>
              <MenuBtn checked={isNavbarOpen} type="checkbox" id="menu-btn" />
              <MenuIcon
                onClick={() => {
                  setNavbarOpen(!isNavbarOpen);
                  setCurrencyNavbarOpen(false);
                }}
                padding="21px 5px"
                margin="0 0 0 auto"
              >
                <NavIcon
                  background={isNavbarOpen ? '#353535' : '#ffffff '}
                  margin="0"
                />
              </MenuIcon>
            </RowCustom>
            <RowCustom
              padding="0"
              direction="column"
              alignItems="flex-start"
              fontSize="16px"
              margin="22px 0 0 0"
            >
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
                background="rgba(58%, 25%, 63%, 0.08)"
              >
                USD
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                RUB
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                EUR
              </RowCustom>
              <RowCustom
                padding="0 0 0 33px"
                alignItems="center"
                margin="0"
                height="50px"
                width="100%"
                size="16px"
              >
                NOK
              </RowCustom>
            </RowCustom>
          </MenuItemNavbar>
        </MenuBlockCurrencyLayer>

        {/* ---###--- */}

        {/* ---###--- */}
      </NavMobile>
    </>
  );
};

export { SearchBarLessMobile };
