import styled from 'styled-components';

export const Tag = styled.div`
  width: ${({ width }) => width};
  height: 40px;
  padding: ${({ padding }) => padding || '0 20px'};
  margin: ${({ margin }) => margin || '0 20px 20px 0'};
  border: 1px solid ${({ theme }) => theme.colors.text.light};
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.text.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  &:last-child {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin: ${({ marginTablet }) => marginTablet || '0 20px 20px 0'};
  }
`;

export const TagNumContainer = styled.div`
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.text.light};
  padding-left: 8px;
  margin-left: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
