import React from 'react';
import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';
import { Title, TabWrapper } from 'components';

const TabComforts = () => {
  const intl = useIntl();
  return (
    <TabWrapper margin="50px 0">
      <Title weight="500" size="24px" margin="50px 0 25px 0">
        {intl.formatMessage({
          id: 'hotel.tab.comforts',
        })}
      </Title>
      <Row>
        <Col lg={4}>
          <Title size="18px" weight="500">
            Featured
          </Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
        </Col>
        <Col lg={4}>
          <Title size="18px" weight="500">
            Featured
          </Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
        </Col>
        <Col lg={4}>
          <Title size="18px" weight="500">
            Featured
          </Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
        </Col>
        <Col lg={4}>
          <Title size="18px" weight="500">
            Featured
          </Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
        </Col>
        <Col lg={4}>
          <Title size="18px" weight="500">
            Featured
          </Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
          <Title size="18px">Featured</Title>
        </Col>
      </Row>
    </TabWrapper>
  );
};
export { TabComforts };
