import styled, { css } from 'styled-components';
import { Row, Col } from 'antd';
import { Title } from 'components';

export const BookingCards = styled.div`
  width: 176px;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  background: #ffffff;
  ${({ theme }) => theme.colors.borders.main} 
  border-radius: 8px;1px solid rgba(0, 82, 180, 0.1);
  border-radius: 20px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;

  i {
    margin-bottom: 15px;
    font-size: ${({ iconSize }) => iconSize};
    color: #d1dde5;
  }

  &:hover {
    box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
    transform: perspective(400px) rotateX(10deg);
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    margin: ${({ marginM }) => marginM || '0 0 25px 0'};
    box-shadow: none;
    ${({ theme }) => theme.colors.borders.main} 
    border-radius: 8px;1px solid rgba(0, 82, 180, 0.1);
  }
`;

export const GalleryGridWrapper = styled(Col)`
  width: 100%;
  max-width: 560px;
  height: 300px;
  float: left;
  overflow: hidden;
  margin-right: 50px;

  .grid-container {
    padding: 0;
  }
  @media ${({ theme }) => theme.device.laptop} {
    margin-right: 0;
    width: 100%;
    float: none;
    margin-bottom: 2%;
  }
`;

export const FeaturesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ margin }) => margin};
`;

export const FeatureItem = styled.div`
  width: 20%;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ size }) => size || '14px'};
  line-height: 143%;
  text-align: left;
  display: flex;
  align-items: center;
  i {
    float: left;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.light};
  }
  @media ${({ theme }) => theme.device.tablet} {
    text-align: center;
    i {
      float: none;
    }
  }
`;

export const AdditionalFeaturesContainer = styled(Row)`
  margin: ${({ margin }) => margin || '0'};
`;

export const ServiceCard = styled.div`
  width: 330px;
  min-height: 200px;
  padding: 15px;
  border-radius: 12px;
  ${({ theme }) => theme.colors.borders.main} 
  border-radius: 8px;1px solid rgba(0, 82, 180, 0.1);
  /* 
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  img {
    border-radius: 10px;
    margin-bottom: 20px;
  } */
  transition: all ease-in-out 0.3s;
  &:hover {
    cursor: pointer;
    transform: perspective(400px) rotateY(10deg);
    ${({ theme }) => theme.colors.borders.main} 
    border-radius: 8px;1px solid rgba(0, 0, 0, 0.2);
    /* box-shadow: 0 3px 38px 0px rgba(0, 0, 0, 0.3); */
  }
`;

export const ServiceCardWrapper = styled(Col)`
  /* margin: ${({ margin }) => margin}; */
  width: 100%;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* width: 25%; */
  padding: 1%;

`;

export const GridWrapper = styled.div`
  width: 100vw;
  max-width: 100%;
  /* margin: ${({ margin }) => margin};
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-column-gap: 2%;
  grid-row-gap: 4%; */
`;

export const GoodPriceBox = styled.div`
  width: 23px;
  height: 23px;
  background-color: #943fa1;
  border-radius: 8px;
  opacity: 0.1;

  ${({ theme, available }) => available
    && css`
      background-color: #f1253d;
    `}
`;

export const RatingWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  border: 1px solid ${({ theme }) => theme.colors.borders.main};
  border-radius: 8px;
  padding: 20px;
`;

export const CommentBlock = styled(RatingWrapper)`
  transition: all ease-in-out 0.3s;
  border: 1px solid transparent;
  margin: 20px 0;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.borders.main};
    border-radius: 8px;
  }
`;

export const CommentInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 15px 0;
  padding: 1px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const TogglePersonalRating = styled.div`
  width: 100%;
  min-width: 725px;
  background-color: #fff;
  /* visibility: hidden; */
  opacity: 0;
  position: absolute;
  top: 35px;
  left: 15px;
  transition: all ease-in-out 0.4s;
`;

export const CommentInfo = styled(Title)`
  padding-right: 20px;
  position: relative;
  transition: all ease-in-out 0.4s;
  &:last-child {
    &:hover ${TogglePersonalRating} {
      /* visibility: visible; */
      opacity: 0.9;
    }
  }

  @media ${({ theme }) => theme.deviceMin.tablet} {
    &:not(:first-child) {
      padding: 0 20px;
    }
    &:not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.colors.borders.main};
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    &:last-child {
      &:hover ${TogglePersonalRating} {
        opacity: 0;
      }
    }
  }
`;

export const CommentInfoHover = styled(CommentInfo)``;

export const RateValueBox = styled.div`
  width: 66px;
  height: 52px;
  background: #f7f4f8;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProgressContainer = styled.div`
  /* height: 120px;
  display: flex;
  justify-content: center;
  align-items: center; */
`;

export const ProgressCol = styled.div`
  width: 100%;
  max-width: 330px;
`;

// Masonry

export const MasonryWrapper = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(45px, auto);

  @media ${({ theme }) => theme.device.laptop} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const MasonryItem = styled.div`
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  /* background-attachment: fixed; */
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
  &:hover {
    box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
    transform: scale(0.9);
    /* transform: perspective(400px) rotateX(5deg); */
  }
`;

export const MasonryItemOne = styled(MasonryItem)`
  /* grid-column: 1 / 4; */
  grid-row: 1 / 6;
`;

export const MasonryItemTwo = styled(MasonryItem)`
  /* grid-column: 2 / 4; */
  grid-row: 1 / 3;
  /* @media ${({ theme }) => theme.device.laptop} {
    grid-row: 1 / 4;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 4 / 7;
  } */
`;

export const MasonryItemThree = styled(MasonryItem)`
  /* grid-column: 1 / 4; */
  grid-row: 1 / 4;
  /* @media ${({ theme }) => theme.device.laptop} {
    grid-row: 4 / 7;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 7 / 10;
  } */
`;

export const MasonryItemFour = styled(MasonryItem)`
  grid-row: 3 / 6;
  /* @media ${({ theme }) => theme.device.laptop} {
    grid-row: 4 / 7;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 10 / 13;
  } */
`;

export const MasonryItemFive = styled(MasonryItem)`
  /* grid-column: 2 / 4; */
  grid-row: 4 / 6;
  @media ${({ theme }) => theme.device.laptop} {
    grid-row: 7 / 10;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 13 / 16;
  }
`;

export const MasonryItemSix = styled(MasonryItem)`
  grid-row: 4 / 10;
  @media ${({ theme }) => theme.device.laptop} {
    grid-row: 7 / 10;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 16 / 19;
  }
`;

export const CarouselWrapperStyles = styled.div`
  .slick-list {
    height: 100%;
    padding: 0 0px 50px 00px;
  }
`;
