import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Row, Tooltip, Skeleton, List } from 'antd';
import { Helmet } from 'react-helmet';
import {
  Stars,
  CarouselAnt,
  Title,
  Favourite,
  Badge,
  DeviceBreakpoint,
  RowCustom,
} from 'components';

import {
  PeopleSVG,
  BathSVG,
  BedSVG,
  SquareMetersSVG,
  BeachSVG,
} from 'lib/icons';

import { Wrapper } from 'components/Wrappers/Wrappers.styles';
import { products } from 'mockData';
import {
  CardContainer,
  CardInfo,
  FeaturesContainer,
  FeatureItem,
  Price,
  SlideTopBlock,
  SliderWrapper,
  SlideInfo,
  ImgWrapper,
  SlideAdditionalInfo,
  UserRate,
  TotalRate,
} from './Card.styles';
import MockImage from 'assets/mockImage.png';
import { ContainerCustom } from 'components/Grid/ContainerCustom.styles';

const CardViewed = ({
  hotel,
  isMobile = false,
  loading,
  setFavoriteVillas,
  favoriteVillas,
}) => {
  const intl = useIntl();
  const { price, title, images, location } = products;
  const history = useHistory();
  // const queryStringParams = new URLSearchParams(history.location.search);
  // console.log("queryStringParams", queryStringParams.has("/ru/"))
  // console.log("queryStringParams", history)
  console.log('hstr', history.location.pathname);
  console.log('hstr', history.location.pathname.includes('en'));

  React.useEffect(() => {
    console.log('ACTION hotel Rec: ', hotel);
  }, [hotel]);

  const {
    id,
    coverPhoto,
    name,
    oPax,
    livingSpace,
    breadcrumbs,
    nbedrooms,
    nbathrooms,
    ndistance,
    averageRating,
    searchParams,
    urls,
  } = hotel;

  return (
    <CardContainer
      width1920="29vw"
      widthM="100%"
      widthL1100="100%"
      marginRight3n2100="0"
      marginBottom="0"
      marginRight="0"
    >
      <SliderWrapper>
        <SlideTopBlock justify="space-between">
          <Badge style={{ opacity: 0 }}>
            {intl.formatMessage({
              id: 'card.villa',
            })}
          </Badge>
          <i>
            <Favourite
              setFavoriteVillas={setFavoriteVillas}
              favoriteVillas={favoriteVillas}
              hotel={hotel}
              isFav={hotel.isInWishlist ? true : false}
            />
          </i>
        </SlideTopBlock>
        <ImgWrapper>
          <Link to={`${hotel.urls.filterUrl}`} target="_blank">
            {!isMobile ? (
              <CarouselAnt
                arrows
                images={[
                  ...coverPhoto.map(
                    ({ photo }) =>
                      `${process.env.REACT_APP_API_MEDIA_URL}${photo}`
                  ),
                ]}
                heightM="255px"
                // height="291px"
                title={title}
                location={location}
                badge="Вилла"
                favorite
              />
            ) : (
              <img
                className="image"
                loading="lazy"
                width="103%"
                height="auto"
                src={`${process.env.REACT_APP_API_MEDIA_URL}${coverPhoto[0].photo}`}
                // src={MockImage}
                alt="productImage"
              />
            )}
          </Link>
          <ContainerCustom
            width="100%"
            position="absolute"
            bottom="0"
            bottom1100="0"
          >
            <Link to={`${hotel.urls.filterUrl}`} target="_blank">
              <SlideInfo bottomTablet="116px">
                <Title size="18px" weight="500" color="#fff" margin="0 0 5px 0">
                  {hotel.name}
                </Title>
                <SlideAdditionalInfo>
                  <i className="fas fa-map-marker-alt" />
                  {breadcrumbs.length > 0 &&
                    breadcrumbs.map(({ anchor }) => anchor).join(', ')}
                  {/* {breadcrumbs.length > 0  && breadcrumbs.map((({anchor}) => anchor)).join(", ") } */}
                </SlideAdditionalInfo>
              </SlideInfo>
            </Link>
          </ContainerCustom>
        </ImgWrapper>
      </SliderWrapper>
      <Link to={`${hotel.urls.filterUrl}`} target="_blank">
        <CardInfo paddingSm="15px 11px">
          <DeviceBreakpoint
            tabletFrom1100Hidden
            style={{ border: '0px solid green' }}
          >
            <FeaturesContainer justifyBetween margin="0 0 15px 0">
              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.guests',
                })} ${oPax}`}
              >
                <FeatureItem oneline>
                  <PeopleSVG />
                  <Title size="14px" lineHeight="13px" mutedText>
                    {hotel.oPax}{' '}
                    {intl.formatMessage({
                      id: 'card.guests-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.bedrooms',
                })} ${hotel.nbedrooms}`}
              >
                <FeatureItem oneline>
                  <BedSVG />
                  <Title size="14px" lineHeight="13px" mutedText>
                    {hotel.nbedrooms}{' '}
                    {intl.formatMessage({
                      id: 'card.bedrooms-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.bathrooms',
                })} ${hotel.nbathrooms}`}
              >
                <FeatureItem oneline>
                  <BathSVG />
                  <Title size="14px" lineHeight="13px" mutedText>
                    {nbathrooms}{' '}
                    {intl.formatMessage({
                      id: 'card.bathrooms-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>
            </FeaturesContainer>
          </DeviceBreakpoint>

          <DeviceBreakpoint tabletTo1100Hidden>
            <FeaturesContainer justifyBetween margin="0 0 15px 0">
              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.guests',
                })} ${oPax}`}
              >
                <FeatureItem bricks>
                  <PeopleSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    lineHeight="13px"
                    mutedText
                  >
                    {hotel.oPax}{' '}
                    {intl.formatMessage({
                      id: 'card.guests-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.bedrooms',
                })} ${hotel.nbedrooms}`}
              >
                <FeatureItem bricks>
                  <BedSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    lineHeight="13px"
                    mutedText
                  >
                    {nbedrooms}{' '}
                    {intl.formatMessage({
                      id: 'card.bedrooms-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.bathrooms',
                })} ${hotel.nbathrooms}`}
              >
                <FeatureItem bricks>
                  <BathSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    sizeM="16px"
                    lineHeight="13px"
                    mutedText
                  >
                    {nbathrooms}{' '}
                    {intl.formatMessage({
                      id: 'card.bathrooms-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.living-space',
                })} ${livingSpace}`}
              >
                <FeatureItem bricks>
                  <SquareMetersSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    sizeM="16px"
                    lineHeight="13px"
                    mutedText
                  >
                    {livingSpace} m2
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.beach-distance',
                })} ${ndistance}m`}
              >
                <FeatureItem bricks>
                  <BeachSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    sizeM="16px"
                    lineHeight="13px"
                    mutedText
                  >
                    {ndistance} m
                  </Title>
                </FeatureItem>
              </Tooltip>
            </FeaturesContainer>
          </DeviceBreakpoint>

          <RowCustom
            borderTop="1px solid #D7D6E0"
            alignItems="baseline"
            justify="space-between"
          >
            {/* <Row align="bottom">
              <Title size="14px" sizeM="12px" color="#9791A7">
                {hotel.searchParams.checkinDate}{' '}
                {hotel.searchParams.checkinDate !== null && '-'}{' '}
                {hotel.searchParams.checkoutDate}
              </Title>
            </Row> */}

            <RowCustom
              alignItems="baseline"
              height="22px"
              // style={{ display: 'none', opacity: 0 }}
            >
              <Stars count={1} defaultValue={1} disabled />
              <UserRate>{averageRating}</UserRate>
              {/* <TotalRate>(135)</TotalRate> */}
            </RowCustom>

            <Wrapper margin="10px 0 0 0">
              <Price size="18px" sizeM="16px" weightM="normal">
                {intl.formatMessage({
                  id: 'card.from',
                })}{' '}
                {searchParams.price}€ /
                {intl.formatMessage({
                  id: 'card.day',
                })}
              </Price>
            </Wrapper>
          </RowCustom>
        </CardInfo>
      </Link>
    </CardContainer>
  );
};

export { CardViewed };
