import styled from 'styled-components';
import {
  Title,
  Text,
  ContainerCustom,
  ImgContainer,
  RowCustom,
  ColCustom,
  Input,
  Form,
  TextArea,
  Button,
} from 'components';

export const CheckboxWrapper = styled.div`
  &&& {
    margin: 0;
    .ant-checkbox {
      outline: 0;
      &:hover {
        outline: 0;
      }
      &:focus {
        outline: 0;
      }
    }
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;

      &:hover {
        outline: 0;
        border: 0;
      }
      &:focus {
        outline: 0;
        border: 0;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      border-radius: 7px;
      background-color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
      outline: 0;
    }
    .ant-checkbox + span {
      font-weight: 400;
    }
  }
`;

export const TabsWrapper = styled(ContainerCustom)`
  width: 50%;
  @media (max-width: 790px) {
    width: 100%;
  }

  &&& {
    .ant-tabs {
      padding: 0 50px;
    }

    .ant-tabs-ink-bar {
      background-color: #943fa1;
    }

    .ant-tabs-tab-btn {
      font-size: 20px;
      color: #9791a7;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #943fa1;
    }

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      border-radius: 7px;
    }

    button {
      height: 54px;
    }

    @media (max-width: 790px) {
      .ant-tabs {
        padding: 0 28px 45px 28px;
      }

      .ant-tabs-tab-btn {
        font-size: 18px;
      }

      .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
        width: 100%;
        justify-content: flex-start;
      }

      .ant-tabs-tab {
        margin: 0 17px 0 0;
        align-items: center;
        justify-content: center;
        width: 50%
      }
    }

    @media (max-width: 479px) {
      .ant-tabs-tab {
        margin: 0 17px 0 0;
        width: auto;
      }

      .ant-tabs-tab:first-child {
        width: 30%;
      }
      .ant-tabs-tab:nth-child(2) {
        width: 70%;
        margin-right: 5px;
      }
    }
  }
`;
