import ru from './ru.json';
import en from './en.json';
import de from './de.json';
import bg from './bg.json';
import ro from './ro.json';
import sr from './sr.json';
const languages = {
  ru,
  en,
  de,
  bg,
  ro,
  sr,
};

const local = navigator.language;
let currentLang = 'en';
// localStorage.setItem('language', window.location.pathname.slice(1, 3));
// if (!localStorage.language) {
//   // localStorage.setItem('language', local.split(/[-_]/));
//   // localStorage.setItem('language', 'en');
//   currentLang = 'en';
// } else
if (
  window.location.pathname.slice(1, 3) === 'ru' ||
  window.location.pathname.slice(1, 3) === 'en' ||
  window.location.pathname.slice(1, 3) === 'de' ||
  window.location.pathname.slice(1, 3) === 'bg' ||
  window.location.pathname.slice(1, 3) === 'ro' ||
  window.location.pathname.slice(1, 3) === 'sr'
) {
  localStorage.setItem('language', window.location.pathname.slice(1, 3));
  currentLang = window.location.pathname.slice(1, 3);
} else if (
  window.location.pathname.slice(1, 3) !== 'ru' ||
  window.location.pathname.slice(1, 3) !== 'en' ||
  window.location.pathname.slice(1, 3) !== 'de' ||
  window.location.pathname.slice(1, 3) !== 'bg' ||
  window.location.pathname.slice(1, 3) !== 'ro' ||
  window.location.pathname.slice(1, 3) !== 'sr'
) {
  localStorage.setItem('language', 'en');
  currentLang = 'en';
}

console.log('currentLang: ', window.location.pathname.slice(1, 3));

// const currentLang = localStorage.language.split(/[,]/)[0];

const messages = languages[currentLang];
console.log('currentLang: ', currentLang, local, messages);

export { local, messages };
