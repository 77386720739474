import React from 'react';
import { ModalWindow, Title } from 'components';
import { HeartSVG, HeartSolidBgSVG } from 'lib/icons';
// import HeartSVG from 'assets/icons/card/heart.svg';

import { products } from 'mockData';
import {
  AdditionalInfo,
  ModalFavWrapper,
  HeartSVGStyled,
} from './Favourite.styles';
import { useMutation } from '@apollo/react-hooks';
import {
  ADD_WISHLIST_HOTELS,
  REMOVE_WISHLIST_HOTELS,
} from 'graphql/mutations/wishlist';

const Favourite = ({
  hotel,
  iconColor,
  iconBg,
  isFav,
  setFavoriteVillas,
  refetchVillas,
  favoriteVillas = [],
}) => {
  const { id, name, coverPhoto, isInWishlist } = hotel;

  //GraphQl mutations
  const [
    addWishList,
    { data: dataWishList, error: errorWishList },
  ] = useMutation(ADD_WISHLIST_HOTELS, {
    variables: {
      propertyId: id,
    },
    onCompleted(dta) {
      console.log('dta', dta);
      setFavoriteVillas([id, ...favoriteVillas]);
    },
  });

  const [
    removeWishList,
    { data: dataRemoveWishList, error: errorRemoveWishList },
  ] = useMutation(REMOVE_WISHLIST_HOTELS, {
    variables: {
      propertyId: id,
    },
    onCompleted(dta) {
      refetchVillas();
      setFavoriteVillas([id, ...favoriteVillas]);
    },
  });

  //Remove or add villas to wish list
  const toggleAddWish = () => {
    if (favoriteVillas.includes(id)) {
      removeWishList({
        variables: {
          propertyId: id,
        },
      });
      console.warn('remove');
    } else {
      addWishList({
        variables: {
          propertyId: id,
        },
      });
      console.log('add');
    }
  };

  const { location } = products;
  const isAuth = false;
  return isAuth ? (
    <ModalWindow
      modalBtnOkText="Создать"
      modalBtnCancelText="Отмена"
      modalTitle="Добавить в избранное"
      btnOk={() =>
        addWishList({
          variables: {
            propertyId: id,
          },
        })
      }
      iconBtn={
        <HeartSVGStyled
          iconColor="#ffffff"
          iconBg={
            favoriteVillas.includes(id)
              ? 'rgba(148, 63, 161, 0.5)'
              : 'rgba(53, 53, 53, 0.38)'
          }
        >
          {/* hotel.isInWishlist */}
          {favoriteVillas.includes(id) ? <HeartSolidBgSVG /> : <HeartSVG />}
        </HeartSVGStyled>
      }
    >
      <ModalFavWrapper>
        <img
          loading="lazy"
          width="300"
          height="170"
          src={
            Array.isArray(coverPhoto)
              ? `${process.env.REACT_APP_API_MEDIA_URL}${coverPhoto[0].photo}`
              : `${process.env.REACT_APP_API_MEDIA_URL}${coverPhoto}`
          }
          alt=""
        />
        <Title size="18px" color="#000" margin="10px 0">
          {name}
        </Title>
        <AdditionalInfo>
          <i className="fas fa-map-marker-alt" />
          {`${location.country}, ${location.city}`}
        </AdditionalInfo>
      </ModalFavWrapper>
    </ModalWindow>
  ) : (
    <ModalWindow
      modalBtnOkText={favoriteVillas.includes(id) ? 'Удалить' : 'Добавить'}
      modalBtnCancelText="Отмена"
      modalTitle="Добавить в избранное"
      iconBtn={
        // <Icon src={HeartSVG} width="40px" />
        <HeartSVGStyled
          iconColor="#ffffff"
          iconBg={
            favoriteVillas.includes(id)
              ? 'rgba(148, 63, 161, 0.5)'
              : 'rgba(53, 53, 53, 0.38)'
          }
        >
          {/* <HeartSVG />
           */}
          {favoriteVillas.includes(id) ? <HeartSolidBgSVG /> : <HeartSVG />}
        </HeartSVGStyled>
      }
      iconColor={iconColor}
      btnOk={toggleAddWish}
    >
      <ModalFavWrapper>
        <img
          loading="lazy"
          width="300"
          height="170"
          src={
            Array.isArray(coverPhoto)
              ? `${process.env.REACT_APP_API_MEDIA_URL}${coverPhoto[0].photo}`
              : `${process.env.REACT_APP_API_MEDIA_URL}${coverPhoto}`
          }
          alt=""
        />
        <Title size="18px" color="#000" margin="10px 0">
          {name}
        </Title>
        <AdditionalInfo>
          <i className="fas fa-map-marker-alt" />
          {/* {`${location.country}, ${location.city}`} */}

          {hotel.breadcrumbs.length > 0 &&
            hotel.breadcrumbs.map(({ anchor }) => anchor).join(', ')}
        </AdditionalInfo>
      </ModalFavWrapper>
    </ModalWindow>
  );
};

export { Favourite };
