import React from 'react';
import { hydrate, render } from 'react-dom';
import { IntlProvider } from 'react-intl';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/es/locale/ru_RU'; // https://ant.design/docs/react/i18n
import en_US from 'antd/es/locale/en_US'; // https://ant.design/docs/react/i18n
import gql from 'graphql-tag';
import { FAKE_SIGNUP } from 'graphql/mutations/fakeSignUp';
import { local, messages } from './locales';
// import resolvers from 'graphql/resolvers';

import { Theme } from './theme';
import GlobalStyle from './theme/globalStyles';

import App from './App';
import 'antd/dist/antd.css';
import * as serviceWorker from './serviceWorker';

// alekos ================================================
// alekos ================================================

export const UserContext = React.createContext();
const userProps = { auth: false };

let client = {};

const API_URL = process.env.REACT_APP_API_URL;

const cache = new InMemoryCache();

const auth = JSON.parse(localStorage.getItem('auth'));

if (auth && auth.accessToken) {
  client = new ApolloClient({
    // typeDefs: schema,
    // resolvers,
    defaultOptions: {
      mutate: { errorPolicy: 'ignore' },
    },
    link: new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          authorization: `Bearer ${auth.accessToken}`, // alekos
        },
      });
      return forward(operation);
    }).concat(
      new HttpLink({
        uri: API_URL, // Server URL
      })
    ),
    cache,
    // clientState: {
    //   defaults: {
    //     searchnav: {
    //       lang: '',
    //       regionId: '',
    //       checkinDate: '',
    //       checkoutDate: '',
    //       pax: '',
    //       __typename: 'Searchnav',
    //     },
    //   },
    // },
  });
  userProps.auth = true;
  if (JSON.parse(localStorage.auth).expiresIn > Date.now() / 1000) {
    localStorage.removeItem('auth');
    userProps.auth = false;
  }
} else {
  userProps.auth = false;
  client = new ApolloClient({
    // typeDefs: schema,
    // resolvers,
    link: new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          authorization: '', // Your Auth token extraction logic
        },
      });
      return forward(operation);
    }).concat(
      new HttpLink({
        uri: API_URL, // Server URL
      })
    ),
    cache,
    // clientState: {
    //   defaults: {
    //     searchnav: {
    //       lang: '',
    //       regionId: '',
    //       checkinDate: '',
    //       checkoutDate: '',
    //       pax: '',
    //       __typename: 'Searchnav',
    //     },
    //   },
    // },
  });

  // alekos ================================================
  client
    .mutate({
      mutation: FAKE_SIGNUP,
      variables: {},
    })
    .then((result) => {
      console.debug(result.data.createFakeUser.token);
      const auth1 = { accessToken: result.data.createFakeUser.token };
      localStorage.setItem('auth', JSON.stringify(auth1));
    });
  // alekos ================================================

  const initData = () =>
    client.writeData({
      data: {
        searchValues: {
          __typename: 'SearchValues',
          lang: localStorage.language || 'en',
          regionId: 14005, // Откуда мне получить регион id
          checkinDate: '2020-08-01',
          checkoutDate: '2020-08-30',
          pax: 2,
          pageNumber: 1,
        },
      },
    });

  initData();
}

const APP = (
  <UserContext.Provider value={userProps}>
    <ApolloProvider client={client}>
      <Theme>
        <GlobalStyle />
        <IntlProvider locale={local} messages={messages}>
          <ConfigProvider
            locale={localStorage.language === 'en' ? en_US : ru_RU}
          >
            <App />
          </ConfigProvider>
        </IntlProvider>
      </Theme>
    </ApolloProvider>
  </UserContext.Provider>
);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}

serviceWorker.unregister();
