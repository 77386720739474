import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';

import {
  Title,
  Text,
  Divider,
  TabWrapper,
  CarouselResponsive,
  Button,
  ContainerCustom,
  PureCard,
  RowCustom,
  TitleSearchDrop,
  IconContainer,
  Calendar,
  BtnCounter,
  DropdownClickOutside,
  ColCustom,
  MasonryLightbox,
  IconSvgContainer,
} from 'components';

import { UserSVG, QuestionSVG } from 'lib/icons';

import { ReactComponent as BeachSVG } from 'assets/icons/bigModal/beach.svg';
import { ReactComponent as BathroomSVG } from 'assets/icons/bigModal/bathroom.svg';
import { ReactComponent as BedSVG } from 'assets/icons/bigModal/bed.svg';
import { ReactComponent as UsersSVG } from 'assets/icons/bigModal/users.svg';
import { ReactComponent as SquareSVG } from 'assets/icons/bigModal/square.svg';
import { ReactComponent as BabySVG } from 'assets/icons/bigModal/baby.svg';
import { ReactComponent as BathSVG } from 'assets/icons/bigModal/bath.svg';
import { ReactComponent as KingSizeSVG } from 'assets/icons/bigModal/kingsize.svg';
import { ReactComponent as OnePersonSVG } from 'assets/icons/bigModal/oneperson.svg';
import {
  GoodPriceBox,
  GalleryGridWrapper,
  FeaturesContainer,
  FeatureItem,
  CarouselWrapperStyles,
} from './Tabs.styles';

import Accordion from '../Accordion/Accordion';

const images = [
  'https://tabairarealestate.com/media/images/properties/thumbnails/o_1cggkfceo1s0mq7ireblsjrj71c_w_1300x650.jpg',
  'https://tabairarealestate.com/media/images/properties/thumbnails/o_1cggkfceo1s0mq7ireblsjrj71c_w_1300x650.jpg',
  'https://q-cf.bstatic.com/images/hotel/max1024x768/145/145832015.jpg',
  'https://q-cf.bstatic.com/images/hotel/max1024x768/145/145832015.jpg',
  'https://media-cdn.tripadvisor.com/media/vr-splice-j/09/e7/7c/ad.jpg',
  'https://q-cf.bstatic.com/images/hotel/max1024x768/145/145832015.jpg',
];

const TabAbout = () => {
  const intl = useIntl();
  const [guestNumber, setGuestNumber] = useState({
    adults: 1,
    children: 0,
  });

  return (
    <TabWrapper margin="50px 0 0 0">
      <GalleryGridWrapper>
        <MasonryLightbox images={images} />
      </GalleryGridWrapper>
      <FeaturesContainer margin="35px 0">
        <FeatureItem size="25px">
          <IconSvgContainer fillColor="#D7D6E0">
            <UsersSVG />
          </IconSvgContainer>
          <Title size="18px" margin="0 0 0 10px">
            18 ps
          </Title>
        </FeatureItem>
        <FeatureItem size="25px">
          <IconSvgContainer fillColor="#D7D6E0">
            <BedSVG />
          </IconSvgContainer>
          <Title size="18px" margin="0 0 0 10px">
            2 bd
          </Title>
        </FeatureItem>
        <FeatureItem size="25px">
          <IconSvgContainer fillColor="#D7D6E0">
            <BathroomSVG />
          </IconSvgContainer>
          <Title size="18px" margin="0 0 0 10px">
            9 ba
          </Title>
        </FeatureItem>
        <FeatureItem size="25px">
          <IconSvgContainer fillColor="#D7D6E0">
            <SquareSVG />
          </IconSvgContainer>
          <Title size="18px" margin="0 0 0 10px">
            185 m2
          </Title>
        </FeatureItem>
        <FeatureItem size="25px">
          <IconSvgContainer fillColor="#D7D6E0">
            <BeachSVG />
          </IconSvgContainer>
          <Title size="18px" margin="0 0 0 10px">
            500 m
          </Title>
        </FeatureItem>
      </FeaturesContainer>
      <Text size="16px">
        Равным образом рамки и место обучения кадров влечет за собой процесс
        внедрения и модернизации системы обучения кадров, соответствует насущным
        потребностям. Таким образом реализация намеченных плановых заданий
        позволяет оценить значение новых предложений. Повседневная практика
        показывает, что реализация намеченных плановых заданий в значительной
        степени обуславливает создание модели развития. Равным образом
        консультация с широким активом требуют определения и уточнения модели
        развития. Равным образом рамки и место обучения кадров влечет за собой
        процесс внедрения и модернизации системы обучения кадров, соответствует
        насущным потребностям.
      </Text>
      <Text size="16px">
        Равным образом рамки и место обучения кадров влечет за собой процесс
        внедрения и модернизации системы обучения кадров, соответствует насущным
        потребностям. Таким образом реализация намеченных плановых заданий
        позволяет оценить значение новых предложений. Повседневная практика
        показывает, что реализация намеченных плановых заданий в значительной
        степени обуславливает создание модели развития. Равным образом
        консультация с широким активом требуют определения и уточнения модели
        развития. Равным образом рамки и место обучения кадров влечет за собой
        процесс внедрения и модернизации системы обучения кадров, соответствует
        насущным потребностям.
      </Text>

      <CarouselWrapperStyles>
        <CarouselResponsive
          arrows
          dotsNot
          top="35%"
          left="-100px"
          right="0px"
          responsive={[
            {
              breakpoint: 4000,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true,
                dots: false,
                // centerMode: true,
                // centerPadding: '90px',
              },
            },
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true,
                dots: false,
                // centerMode: true,
                // centerPadding: '90px',
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: false,
                centerMode: true,
                centerPadding: '90px',
              },
            },

            {
              breakpoint: 450,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {[
            { title: 'Спальня 1', imgs: [<OnePersonSVG />, <BathSVG />] },
            { title: 'Спальня 2', imgs: [<BathSVG />, <BabySVG />] },
            { title: 'Спальня 3', imgs: [<OnePersonSVG />, <BathSVG />] },
            {
              title: 'Спальня 4',
              imgs: [<KingSizeSVG />, <OnePersonSVG />, <BathSVG />],
            },
            {
              title: 'Спальня 5',
              imgs: [<KingSizeSVG />, <OnePersonSVG />, <BathSVG />],
            },
            {
              title: 'Спальня 6 Fake',
              imgs: [<KingSizeSVG />, <OnePersonSVG />, <BathSVG />],
            },
            {
              title: 'Спальня 6 Fake',
              imgs: [<KingSizeSVG />, <OnePersonSVG />, <BathSVG />],
            },
            {
              title: 'Спальня 6 Fake',
              imgs: [<KingSizeSVG />, <OnePersonSVG />, <BathSVG />],
            },
          ].map(({ imgs, title }) => (
            <div>
              <PureCard
                noHover
                border
                width="197px"
                height="209"
                padding="25px 20px 20px 20px"
                margin="0 25px 0 0"
              >
                <Row>
                  {imgs.map((img) => (
                    <IconSvgContainer
                      key={uuid()}
                      fillColor="#D7D6E0"
                      margin=" 0 10px 0 0 "
                    >
                      {img}
                    </IconSvgContainer>

                    // <Icon key={uuid()} src={img} width="40px" />
                  ))}
                </Row>
                <Title size="18px" margin=" 36px 0 15px 0">
                  {title}
                </Title>
                <Title size="14px" mutedText>
                  1 кровать Queen size <br /> 1 односпальная кровать
                </Title>
              </PureCard>
            </div>
          ))}
        </CarouselResponsive>
      </CarouselWrapperStyles>
      <Title weight="500" size="24px" margin="50px 0 25px 0">
        {intl.formatMessage({
          id: 'big-modal.floor-plan',
        })}
      </Title>
      <Row>
        <img
          loading="lazy"
          src="https://s3-alpha-sig.figma.com/img/e61f/dbf8/eab2c2ff5a85813cd1362d189d66d8da?Expires=1593993600&Signature=N0A35WPwjl4wDVMJfO1qSHwv-P1rvjGxsurB~tnRx3UJNxf3UduBcl~Z8QnLYeu7vFlKh203KgmRCI1ji2BmRWq46U-wtxpi9qNM3klJ9Py1uiydGemPG~HIdyNu~lbnM4YO7QgBDR9oIZKQ6h7gCZLDqBa2lwDF5pecbjunlhVqHE2nLu3X36Y-6nc~czSgjW7ZYrl~pAxfTj8T5tEFGPx~1Yr8b7ZNWdXLl-UvIMTRvzcsK-uLQqlO13bHZqA9LNvQD2UxCHaszygBZZtw-oSOk8lz7A1FBxInHioqoFRJFpePjQQ7RjWuQFYmdzlmWspwt1lT7JFnE7AdyypVVw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
          alt=""
        />
        <img
          loading="lazy"
          src="https://s3-alpha-sig.figma.com/img/e61f/dbf8/eab2c2ff5a85813cd1362d189d66d8da?Expires=1593993600&Signature=N0A35WPwjl4wDVMJfO1qSHwv-P1rvjGxsurB~tnRx3UJNxf3UduBcl~Z8QnLYeu7vFlKh203KgmRCI1ji2BmRWq46U-wtxpi9qNM3klJ9Py1uiydGemPG~HIdyNu~lbnM4YO7QgBDR9oIZKQ6h7gCZLDqBa2lwDF5pecbjunlhVqHE2nLu3X36Y-6nc~czSgjW7ZYrl~pAxfTj8T5tEFGPx~1Yr8b7ZNWdXLl-UvIMTRvzcsK-uLQqlO13bHZqA9LNvQD2UxCHaszygBZZtw-oSOk8lz7A1FBxInHioqoFRJFpePjQQ7RjWuQFYmdzlmWspwt1lT7JFnE7AdyypVVw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
          alt=""
        />
      </Row>

      <Divider margin="60px 0 15px 0" />

      <Title weight="500" size="24px" margin="50px 0 25px 0">
        {intl.formatMessage({
          id: 'big-modal.comforts',
        })}
      </Title>

      <Row justify="space-between">
        <Col span={24} lg={24} md={24} sm={12}>
          <Title size="18px" weight="500" margin="0 0 30px 0" capitalize>
            {intl.formatMessage({
              id: 'big-modal.accordion.featured',
            })}
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
        </Col>
        <Col span={24} lg={24} md={24} sm={12}>
          <Title size="18px" weight="500" margin="0 0 30px 0" capitalize>
            {intl.formatMessage({
              id: 'big-modal.accordion.bed-dath',
            })}{' '}
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
        </Col>
        <Col span={24} lg={24} md={24} sm={12}>
          <Title size="18px" weight="500" margin="0 0 30px 0" capitalize>
            {intl.formatMessage({
              id: 'big-modal.accordion.kitchen',
            })}
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
        </Col>
        <Col span={24} lg={24} md={24} sm={12}>
          <Title size="18px" weight="500" margin="0 0 30px 0" capitalize>
            {intl.formatMessage({
              id: 'big-modal.accordion.comforts',
            })}
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
        </Col>
        <Col span={24} lg={24} md={24} sm={12}>
          <Title size="18px" weight="500" margin="0 0 30px 0" capitalize>
            {intl.formatMessage({
              id: 'big-modal.accordion.outdors',
            })}
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
          <Title size="18px" margin="0 0 16px 0">
            Featured
          </Title>
        </Col>
      </Row>

      <Accordion>
        <Row justify="space-between">
          <Col span={24} lg={24} md={24} sm={12}>
            <Title size="18px" weight="500" margin="0 0 30px 0">
              Featured
            </Title>
            <Title size="18px" margin="0 0 16px 0">
              Featured
            </Title>
            <Title size="18px" margin="0 0 16px 0">
              Featured
            </Title>
            <Title size="18px" margin="0 0 16px 0">
              Featured
            </Title>
            <Title size="18px" margin="0 0 16px 0">
              Featured
            </Title>
            <Title size="18px" margin="0 0 16px 0">
              Featured
            </Title>
          </Col>
        </Row>
      </Accordion>

      <Divider margin="60px 0" />

      <Title weight="500" size="24px">
        {intl.formatMessage({
          id: 'big-modal.calendar',
        })}
      </Title>
      <RowCustom margin="40px 0 25px 0">
        <Col span={24} lg={10} md={10} sm={10}>
          <Title size="16px" margin="0 0 10px 0">
            {intl.formatMessage({
              id: 'hotel.calendar-tab.visitors',
            })}
          </Title>

          <DropdownClickOutside
            borders
            bordersHeight="54px"
            dropBtnWidth="300px"
            dropBtnPaddingWrapper="3px 15px"
            btnTitle={
              guestNumber.adults > 0 ? (
                <Row align="middle">
                  <IconContainer lightText>
                    <UserSVG />
                  </IconContainer>
                  <Title size="16px" margin="0 0 0 17px">
                    {guestNumber.adults}{' '}
                    {intl.formatMessage({
                      id: 'main-page.guests.adults',
                    })}
                  </Title>
                  {guestNumber.children > 0 && (
                    <Title size="16px" margin="0 0 0 17px">
                      {guestNumber.children}{' '}
                      {intl.formatMessage({
                        id: 'main-page.guests.children',
                      })}
                    </Title>
                  )}
                </Row>
              ) : (
                <Row align="middle">
                  <IconContainer lightText>
                    <UserSVG />
                  </IconContainer>
                  <TitleSearchDrop size="16px" margin="0 0 0 17px">
                    {intl.formatMessage({
                      id: 'main-page.guest-number',
                    })}
                  </TitleSearchDrop>
                </Row>
              )
            }
            margin="0 0 0 40px"
            btnPadding="0"
          >
            <ContainerCustom padding="15px">
              <ContainerCustom display="flex">
                <Title width="100px">
                  {intl.formatMessage({
                    id: 'search-panel.adults.few',
                  })}
                  :
                </Title>
                <BtnCounter
                  onClick={() =>
                    setGuestNumber({
                      ...guestNumber,
                      adults:
                        guestNumber.adults - 1 < 0 ? 0 : --guestNumber.adults,
                    })
                  }
                >
                  -
                </BtnCounter>
                <Title width="30px" center>
                  {guestNumber.adults}
                </Title>
                <BtnCounter
                  onClick={() =>
                    setGuestNumber({
                      ...guestNumber,
                      adults: ++guestNumber.adults,
                    })
                  }
                >
                  +
                </BtnCounter>
              </ContainerCustom>
              <br />
              <ContainerCustom display="flex">
                <Title width="100px">
                  {intl.formatMessage({
                    id: 'hotelComplex-page.children',
                  })}
                  :
                </Title>
                <BtnCounter
                  onClick={() =>
                    setGuestNumber({
                      ...guestNumber,
                      children:
                        guestNumber.children - 1 < 0
                          ? 0
                          : --guestNumber.children,
                    })
                  }
                >
                  -
                </BtnCounter>
                <Title width="30px" center>
                  {guestNumber.children}
                </Title>
                <BtnCounter
                  onClick={() =>
                    setGuestNumber({
                      ...guestNumber,
                      children: ++guestNumber.children,
                    })
                  }
                >
                  +
                </BtnCounter>
              </ContainerCustom>
            </ContainerCustom>
          </DropdownClickOutside>
        </Col>
      </RowCustom>

      <RowCustom margin="60px 0 30px 0">
        <ColCustom span={24} lg={10} md={10} sm={10} padding="0 25px 0 0 ">
          <Text size="16px">
            Минимальная продолжительность бронирования - 5 дней. При наведении
            на доступные даты, вы можете увидеть сумму с учетом всех сборов и
            налогов.
          </Text>
        </ColCustom>
        <Col span={24} lg={6} md={5} sm={4}>
          <RowCustom flexFlow="nowrap">
            <GoodPriceBox />
            <Title weight="400" size="16px" margin="0 0 25px 10px">
              {intl.formatMessage({
                id: 'hotel.calendar-tab.good-price',
              })}
            </Title>
          </RowCustom>
        </Col>
        <Col span={24} lg={6} md={5} sm={4}>
          <RowCustom flexFlow="nowrap">
            <GoodPriceBox available />
            <Title weight="400" size="16px" margin="0 0 25px 10px">
              {intl.formatMessage({
                id: 'hotel.calendar-tab.bad-price',
              })}
            </Title>
          </RowCustom>
        </Col>
      </RowCustom>
      <Row>
        <Calendar
          autoFocusEndDate
          initialStartDate={1587600000}
          initialEndDate={1587772800}
        />
      </Row>

      <RowCustom align="middle" margin="0 0 58px 0">
        <Title size="18px" greyText>
          {intl.formatMessage({
            id: 'common.licence',
          })}
        </Title>
        <Title size="18px" margin="0 10px">
          00000435519
        </Title>
        <QuestionSVG />
      </RowCustom>

      <Row align="middle" justify="space-between">
        <Title weight="500" size="24px" margin="0 10px 25px 0">
          {intl.formatMessage({
            id: 'common.total',
          })}
        </Title>
        <Row align="middle">
          <Title weight="500" size="18px" margin="20px 7px 25px 0">
            €3 090
          </Title>
          <Title size="18px" margin="20px 7px 25px 0">
            (
            {intl.formatMessage({
              id: 'common.night-number',
            })}
            : 5)
          </Title>
          <IconContainer size="14px" margin="20px 46px 25px 0" lightText>
            <QuestionSVG />
          </IconContainer>
          <Button size="16px" primary width="182px" height="54px">
            {intl.formatMessage({
              id: 'buttons.order',
            })}
          </Button>
        </Row>
      </Row>
    </TabWrapper>
  );
};

export { TabAbout };
