import React from 'react';
import { Select } from 'antd';
import { useIntl } from 'react-intl';
import { SelectCustomStyles } from './SelectCheckbox.styles';

const { Option } = Select;

const SelectСheckbox = ({
  children,
  defaultValue,
  optionList,
  width,
  borders,
}) => {
  const intl = useIntl();

  function handleChange(value) {
    console.log(value); // { key: "lucy", label: "Lucy (101)" }
  }

  return borders ? (
    <SelectCustomStyles borders>
      <Select
        mode="tags"
        style={{ width: '100%' }}
        placeholder={intl.formatMessage({
          id: 'filters.any',
        })}
        labelInValue
        width={width}
        onChange={handleChange}
      >
        {optionList.map(({ icon, title }) => (
          <Option value={title} key={title}>
            {icon}
            {' '}
            {title}
          </Option>
        ))}
      </Select>
    </SelectCustomStyles>
  ) : (
    <SelectCustomStyles>
      <Select
        mode="tags"
        style={{ width: '100%' }}
        placeholder={intl.formatMessage({
          id: 'filters.any',
        })}
        labelInValue
        width={width}
        onChange={handleChange}
      >
        {optionList.map(({ icon, title }) => (
          <Option value={title} key={title}>
            {icon}
            {' '}
            {title}
          </Option>
        ))}
      </Select>
    </SelectCustomStyles>
  );
};

export { SelectСheckbox };
