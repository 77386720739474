import styled, { css } from 'styled-components';
import { DatePicker, Menu } from 'antd';
import { Link } from 'react-router-dom';

const { RangePicker } = DatePicker;

export const DropdownMenuStyled = styled(Menu)`
  &&& {
    .ant-menu {
      width: 80px;
      background: #ffffff;
      box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
      border-radius: 0px 12px 12px 12px;
    }

    .ant-dropdown-menu {
      border-radius: 10px !important;
    }

    .ant-menu-item {
      text-align: center;
      color: #353535;
    }
  }
`;

export const Nav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  padding: 0 5vw 0 5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  transition: all ease-in-out 0.5s;
  a {
    color: #353535;
    &:hover {
      color: black;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    display: none;
  }

  @media (min-width: 1440px) and (max-width: 1479px) {
    width: 1440px;
  }

  @media (min-width: 1480px) {
    padding: 0 6.5% 0 5vw;
  }
`;

export const FavIcon = styled(Link)`
  margin: 0 38px;
  i {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.text.light};
  }

  ${({ theme, primary }) =>
    primary &&
    css`
      i {
        color: ${({ theme }) => theme.colors.text.primary};
      }
      /*
      &:hover {
        color: #fff;
      } */
    `}
`;

export const MenuBlock = styled.ul`
  clear: none;
  /* float: right; */
  color: black;
  max-height: none;
  z-index: 1999;

  @media ${({ theme }) => theme.device.tablet} {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
`;

export const MenuItem = styled.li`
  float: left;
  list-style: none;
  @media ${({ theme }) => theme.device.tablet} {
    float: none;
  }
`;
export const MenuIcon = styled.label`
  display: none;
  @media ${({ theme }) => theme.device.tablet} {
    display: block;
    border: 1px solid red;
    padding: 28px;
    position: relative;
    float: right;
    cursor: pointer;
  }
`;

export const NavIcon = styled.span`
  background: #333;
  display: block;
  height: 2px;
  width: 18px;
  position: relative;
  transition: all 0.2s ease-in-out;

  &:before {
    content: '';
    background: #333;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 5px;
    transition: all 0.2s ease-in-out;
  }
  &:after {
    content: '';
    background: #333;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: -5px;
    transition: all 0.2s ease-in-out;
  }
`;

export const MenuBtn = styled.input`
  display: none;
  &:checked ~ ${MenuBlock} {
    max-height: 100%;
    min-height: 240px;
    z-index: 2000;
  }

  &:checked ~ ${MenuIcon} ${NavIcon} {
    background-color: transparent;
    &:before {
      transform: rotate(-45deg);
      top: 0;
    }
    &:after {
      transform: rotate(45deg);
      top: 0;
    }
  }
`;

export const SearchBar = styled.div`
  width: ${({ width }) => width};
  height: 40px;
  margin: 0 32px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
  border-radius: 10px;

  i {
    color: ${({ theme }) => theme.colors.text.light};
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    color: #333;
  }
`;

export const DatePickerContainer = styled.div`
  height: 28px;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  border-left: 1px solid #e4f0f8;
  border-right: 1px solid #e4f0f8;
`;

export const PhoneAnimatedBox = styled.div`
  /* width: 0px; */
  /* margin-top: -300px; */
  color: #fff;
  transition: all ease-in-out 0.5s;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    color: #fff;
  }
  .ant-select-arrow {
    color: #fff;
    top: 17px;
    right: -1px;
  }
`;
export const PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 35px;
  i {
    color: #fff;
    font-size: 10px;
  }
  /* &:hover ${PhoneAnimatedBox} {
    width: 100%;
    visibility: visible;
    margin-top: 0;
  } */
`;

export const PhoneImg = styled.div`
  width: 22px;
  height: 22px;
  padding: 6px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  margin: ${({ margin }) => margin};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 0.5s;
  cursor: pointer;
`;

export const RangePickerTransparent = styled(RangePicker)`
  background-color: transparent;
  border: none;
  box-shadow: none;
  margin: ${({ margin }) => margin};
  .ant-picker-active-bar {
    background: ${({ theme }) => theme.colors.primary};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
  }
  ${({ theme, border }) =>
    border &&
    css`
      border-radius: 15px;
      border: 1px solid ${theme.colors.borders.light};
    `};
`;

export const GuestCounterContainer = styled.div`
  padding: 15px;
`;

export const BtnCounter = styled.div`
  width: 25px;
  height: 25px;
  color: #fff;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  &:hover {
    cursor: pointer;
  }
`;
