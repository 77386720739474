import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Icon, divIcon } from 'leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import ReactDOMServer from 'react-dom/server';
import { SVGIconRect } from './SvgMarker';
import { v4 as uuid } from 'uuid';

export const icon = new Icon({
    iconUrl: '/mapMarker.svg',
    iconSize: [32, 39],
  });
  

const MapCluster = ({places, setActivePark}) => {
  // console.info(data);
  return (
      <MarkerClusterGroup>
        {places.map((place) => (
            <Marker
            key={place.id}
            position={[place.geo[0], place.geo[1]]}
            onMouseOver={() => {
              setActivePark(place);
            }}
            onClick={() => {
              setActivePark(place);
            }}
            icon={
              places.length > 1
                ? new divIcon({
                  className: 'custom-icon',
                  html: ReactDOMServer.renderToString(
                    <SVGIconRect perc={`${place.price} €`} />,
                  ),
                })
                : icon
            }
          />
        ))}
      </MarkerClusterGroup>
  );
}

export default MapCluster;