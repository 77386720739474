import styled from 'styled-components';
import { Col } from 'antd';

export const ColCustom = styled(Col)`
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ align }) => align};
  align-self: ${({ self }) => self};
  overflow: ${({ overflow }) => overflow};
  flex-direction: ${({ direction }) => direction};
  flex-grow: ${({ grow }) => grow};
  background: ${({ background }) => background};

  @media (max-width: 992px) {
    padding: ${({ paddingLaptop }) => paddingLaptop};
  }

  @media ${({ theme }) => theme.device.laptop} {
    padding: ${({ paddingLaptop }) => paddingLaptop};
  }
`;
