import React from 'react';
import PropTypes from 'prop-types';
import { TabsStyled } from './TabPanel.styles';

const TabPanel = ({ children, margin }) => {
  const callback = (key) => {
    console.log(key);
  };

  return (
    <TabsStyled defaultActiveKey="1" onChange={callback} margin={margin}>
      {children}
    </TabsStyled>
  );
};

TabPanel.propTypes = { children: PropTypes.node.isRequired };

export { TabPanel };
