import React, { useRef, useState } from 'react';
import { useOutsideClick } from 'hooks/useClickOutside';
import { Portal, Title, Input } from 'components';
import { Row, Col } from 'antd';

import { ReactComponent as CitySVG } from 'assets/icons/autocomplete/city.svg';
import {
  DropWrapper,
  DropContent,
  OptionList,
  OptionItem,
  OptionIcon,
} from './AutoCompletePortal.styles';

const AutoCompletePortalMobile = ({
  children,
  dropContLeft,
  boxShadow,
  dropWrapperHeight,
  onHover,
  btnTitle,
  dropBtnWidth,
  dropBtnMinWidth,
  dropContainerWidth,
  dropBtnHeight,
  dropBtnColor,
  dropBtnBg,
  dropBtnBlur,
  dropBtnBorderRadius,
  dropBorderWrapper,
  dropBtnPadding,
  dropBtnMarginWrapper,
  dropBtnPaddingWrapper,
  dropWrapperWidth,
  dropWrapperWidthSM,
  dropWrapperMarginM,
  borders,
  bordersHeight,
  placeholder,
  value,
  options,
  onChange,
  onSelect,
  dropContainerHeight,
  bgInput,
  placeholderColor,
  dropContentLeftPosition,
}) => {
  React.useEffect(() => {
    console.log('options tesst', options);
  }, [options]);

  const [show, setSate] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (show) setSate(false);
  });

  const checkWordStartLetters = (word, startLetters = '') => {
    let restWord = word.slice(startLetters.length);

    if (word.toLowerCase().startsWith(startLetters.toLowerCase())) {
      return (
        <>
          <span
            style={{
              color: '#000000',
              textTransform: 'capitalize',
            }}
          >
            {startLetters}
          </span>
          {restWord}
        </>
      );
    }
    return word;
  };

  const checkIconColor = (word, startLetters = '', icon) => {
    if (word.toLowerCase().startsWith(startLetters.toLowerCase())) {
      return <OptionIcon icon={icon} color="#943FA1"></OptionIcon>;
    }
    return <OptionIcon icon={icon} color="gray"></OptionIcon>;
  };

  return (
    <DropWrapper
      boxShadow={boxShadow}
      borders={borders}
      bordersHeight={bordersHeight}
      dropBorderWrapper={dropBorderWrapper}
      dropBtnPaddingWrapper={dropBtnPaddingWrapper}
      dropBtnMarginWrapper={dropBtnMarginWrapper}
      dropWrapperWidth={dropWrapperWidth}
      dropWrapperHeight={dropWrapperHeight}
      dropWrapperWidthSM={dropWrapperWidthSM}
      dropWrapperMarginM={dropWrapperMarginM}
    >
      <Input
        bgInput={bgInput}
        width={dropWrapperWidth}
        type="text"
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        value={value}
        onChange={onChange}
        onClick={() => setSate(!show)}
      />
      {/* <DropBtn
        onClick={() => setSate(!show)}
        dropBtnWidth={dropBtnWidth}
        dropBtnMinWidth={dropBtnMinWidth}
        dropBtnHeight={dropBtnHeight}
        dropBtnColor={dropBtnColor}
        dropBtnBg={dropBtnBg}
        dropBtnBlur={dropBtnBlur}
        dropBtnBorderRadius={dropBtnBorderRadius}
        dropBtnPadding={dropBtnPadding}
        // onMouseEnter={() => setSate(true)}
      >
        AutoCompletePortal
      </DropBtn> */}
      {show && options.length > 0 && (
        <Portal>
          <DropContent
            dropContLeft={dropContLeft}
            dropContentLeftPosition={dropContentLeftPosition}
            onMouseLeave={() => onHover && setSate(false)}
            ref={ref}
            dropContainerWidth={dropContainerWidth}
            // dropContainerHeight={dropContainerHeight}
            // id="portal"
            padding="18px 0"
          >
            {/* {children} */}

            <OptionList optionListHeight={dropContainerHeight}>
              {options.map(({ id, title, slug, subtitle, type, icon }) => (
                <OptionItem
                  width="97%"
                  height="88px"
                  maxHeight="95px"
                  // padding="20px 8px 20px 20px"
                  onClick={() => {
                    console.log('ACTION child slug', slug);
                    onSelect(id, title, slug);
                    setSate(!show);
                  }}
                >
                  <Row>
                    <Col span={24} lg={2} md={2} sm={2} xs={2}>
                      {checkIconColor(title, value, icon)}
                    </Col>
                    <Col span={24} lg={21} md={21} sm={21} xs={21}>
                      <Row justify="space-between">
                        <Title size="16px" lineHeight="140%" greyText>
                          {checkWordStartLetters(title, value)}
                        </Title>
                        <Title size="14px" lineHeight="143%" greyText>
                          {type}
                        </Title>
                      </Row>
                      <Title size="14px" lineHeight="143%" greyText>
                        {subtitle}
                      </Title>
                    </Col>
                  </Row>
                </OptionItem>
              ))}
            </OptionList>
          </DropContent>
        </Portal>
      )}
    </DropWrapper>
  );
};

export { AutoCompletePortalMobile };
