import React from 'react';
import { Row } from 'antd';
import { Avatar, Text, Title } from 'components';
import {
  CardTestimonialWrapper,
  AvaTestimonialWrapper,
  CardTestimonialInfo,
} from './Card.styles';

const CardTestimonial = ({ reviewItem }) => {
  const { rentDate, guestName, guestAvatar, review } = reviewItem;
  console.log('guestAvatar', guestAvatar);
  return (
    <CardTestimonialWrapper>
      <AvaTestimonialWrapper>
        {guestAvatar ? (
          <Avatar
            border="18px solid #fff"
            position="absolute"
            top="-57px"
            avatarCenter
            img={`${process.env.REACT_APP_API_MEDIA_URL}${guestAvatar}`}
            size="115px"
            borderColor="#fff"
            display="block"
            margin="auto"
            marginM="auto"
            rounded
          />
        ) : null}
      </AvaTestimonialWrapper>
      <CardTestimonialInfo style={{ position: 'relative' }}>
        <Text
          overflow="auto"
          textOverflow="ellipsis"
          size="16px"
          sizeM="14px"
          margin="0"
          padding="0 4px 0 0"
        >
          {review}
        </Text>
        <Row justify="center" style={{ bottom: '14px', left: '28%' }}>
          <Title size="16px" weight="500" margin="0 5px 0 0">
            {guestName}
          </Title>
          <Title size="16px" color="#9791A7" margin="0 0 0 5px">
            {rentDate}
          </Title>
        </Row>
      </CardTestimonialInfo>
    </CardTestimonialWrapper>
  );
};

export { CardTestimonial };
