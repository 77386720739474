import React from 'react';
//Styled Button
import { Button, Icon } from 'components';
// Icon
import FacebookIcon from 'assets/facebook.png';
//Facebook button
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const clientId = '331664654816090';

export const FacebookLogIn = ({ callback }) => {
  return (
    <FacebookLogin
      appId={clientId}
      fields="name,email,picture"
      callback={callback}
      render={(renderProps) => (
        <Button
          shadow="0px 10px 50px rgba(0, 82, 180, 0.1);"
          width="140px"
          height="54px"
          display="flex"
          align="center"
          size="16px"
          color="#9791A7"
          onClick={renderProps.onClick}
        >
          <Icon margin="0 18px 0 0" src={FacebookIcon} />
          Facebook
        </Button>
      )}
    />
  );
};
