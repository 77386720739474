import styled from 'styled-components';
import MapSVG from 'assets/img/main/map.png';
import { Title } from 'components';

export const TitleMain = styled(Title)`
  font-family: 'Ubuntu', sans-serif;
  font-size: ${({ size }) => size};

  @media ${({ theme }) => theme.device.tablet} {
    margin: ${({ marginM }) => marginM};
    font-size: ${({ sizeM }) => sizeM};
  }
`;

export const FeaturesContainer = styled.div`
  background-image: url(${MapSVG});
  background-repeat: no-repeat;
  background-size: auto;
  padding: ${({ padding }) => padding || '50px 80px'};
`;

export const FeatureItem = styled.div`
  width: 178px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    font-size: 42px;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: 158px;
    margin-bottom: 30px;
  }
`;

export const FeatureIcon = styled.div`
  width: 105px;
  height: 105px;
  background: #ffffff;
  box-shadow: 0px 6px 40px rgba(0, 82, 180, 0.16);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    width: 77px;
    height: 77px;
    i {
      font-size: 28px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(206, 206, 206, 0.2),
      0px -1px 16px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(206, 206, 206, 0.25);
    transform: scale(0.98);
    /* transform: translateY(-0.5rem); */
  }
`;

export const CardPopularWrapper = styled.div`
  /* width: 100%; */
  /* max-width: 400px; */
  /* margin: 20px 0 70px 0; */
`;

export const CardPopular = styled.div`
  width: 100%;
  max-width: 400px;
  height: 400px;
  display: block;
  margin: auto;
  background-image: url(${({ bgImg }) => bgImg});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  cursor: grab;
  transition: all ease-in-out 0.3s;
  &:hover {
    transform: scale(0.9);
  }
`;

export const CardPopularInfo = styled.div`
  max-width: 400px;
  padding: 30px;
  position: relative;
  z-index: 2;
  &::after {
    max-width: 400px;
    content: '';
    background: linear-gradient(
      360deg,
      rgba(7, 7, 7, 0.75) -24.34%,
      rgba(52, 52, 52, 0) 100%
    );
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`;

export const MasonryWrapper = styled.div`
  width: 100%;
  height: auto;

  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(110px, auto);

  @media ${({ theme }) => theme.device.laptop} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const MasonryItem = styled.div`
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  /* background-attachment: fixed; */
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
    /* margin-top: -10px; */
    transform: scale(0.98);
    /* transform: perspective(400px) rotateX(5deg); */
  }
`;

export const MasonryItemOne = styled(MasonryItem)`
  /* grid-column: 1 / 4; */
  grid-row: 1 / 4;
`;

export const MasonryItemTwo = styled(MasonryItem)`
  /* grid-column: 2 / 4; */
  grid-row: 1 / 6;
  @media ${({ theme }) => theme.device.laptop} {
    grid-row: 1 / 4;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 4 / 7;
  }
`;

export const MasonryItemThree = styled(MasonryItem)`
  /* grid-column: 1 / 4; */
  grid-row: 1 / 4;
  @media ${({ theme }) => theme.device.laptop} {
    grid-row: 4 / 7;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 7 / 10;
  }
`;

export const MasonryItemFour = styled(MasonryItem)`
  grid-row: 4 / 10;
  @media ${({ theme }) => theme.device.laptop} {
    grid-row: 4 / 7;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 10 / 13;
  }
`;

export const MasonryItemFive = styled(MasonryItem)`
  /* grid-column: 2 / 4; */
  grid-row: 6 / 10;
  @media ${({ theme }) => theme.device.laptop} {
    grid-row: 7 / 10;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 13 / 16;
  }
`;

export const MasonryItemSix = styled(MasonryItem)`
  grid-row: 4 / 10;
  @media ${({ theme }) => theme.device.laptop} {
    grid-row: 7 / 10;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-row: 16 / 19;
  }
`;

export const MasonryInfo = styled.div`
  width: 100%;
  padding: 30px;
  position: relative;
  z-index: 2;
  &::after {
    width: 100%;
    content: '';
    background: linear-gradient(
      360deg,
      rgba(7, 7, 7, 0.75) -24.34%,
      rgba(52, 52, 52, 0) 100%
    );
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`;

export const OtherProduct = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 0 20px;
  border-left: 1px solid ${({ theme }) => theme.colors.borders.main};
`;
