import { deviceBreakpoints } from './breakpoints';

const theme = {
  colors: {
    bgBody: '#007bff',
    bgColor: '#222',
    primary: '#007ced',
    secondary: '#2DD6AD',
    text: '#fff',
  },
  device: deviceBreakpoints,
};

export default theme;
