import styled from 'styled-components';
import { Collapse } from 'antd';

export const CollapseStyled = styled(Collapse)`
  &&& {
    span {
      color: ${({ theme }) => theme.colors.greyLightText}};
    }

    .ant-collapse-borderless
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      padding-top: 0;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }

    .ant-collapse-header {
      padding-left: 0 !important;
    }
  }
`;
