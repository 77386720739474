import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: ${({ margin }) => margin};
`;

export const WrapperFrom = styled.div`
  margin: ${({ margin }) => margin};
  text-align: end;
`;
