import React from 'react';
import { Select } from 'antd';
import { useIntl } from 'react-intl';
import { SelectCustomStyles } from './SelectCheckboxGroup.styles';

const { Option } = Select;

const SelectCheckboxGroup = ({
  optionList,
  width,
  widthInput,
  borders,
}) => {
  const intl = useIntl();

  function handleChange(value) {
    console.log(value); // { key: "lucy", label: "Lucy (101)" }
  }

  return borders ? (
    <SelectCustomStyles borders width={width} widthInput={widthInput}>
      <Select
        mode="tags"
        placeholder={intl.formatMessage({
          id: 'filters.any',
        })}
        labelInValue
        onChange={handleChange}
        options={optionList}
      />
    </SelectCustomStyles>
  ) : (
    <SelectCustomStyles width={width} widthInput={widthInput}>
      <Select
        mode="tags"
        placeholder={intl.formatMessage({
          id: 'filters.any',
        })}
        labelInValue
        onChange={handleChange}
        options={optionList}
      />
    </SelectCustomStyles>
  );
};

export { SelectCheckboxGroup };
