import styled, { css } from 'styled-components';

export const DropContent = styled.div`
  width: ${({ dropContainerWidth }) => dropContainerWidth};
  min-width: 25px;
  min-height: 25px;
  background-color: #fff;
  border: 1px solid rgba(0, 82, 180, 0.1);
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
  /* transform: translateX(50px); */

  position: absolute;
  top: 52px;
  transition: all ease-in-out 0.3s;
  z-index: 1000;
`;

export const DropWrapper = styled.div`
  width: ${({ dropWrapperWidth }) => dropWrapperWidth};
  height: ${({ dropWrapperHeight }) => dropWrapperHeight};
  padding: ${({ dropBtnPaddingWrapper }) => dropBtnPaddingWrapper};
  margin: ${({ dropBtnMarginWrapper }) => dropBtnMarginWrapper};
  position: relative;

  ${({ borders, bordersHeight }) => borders
    && css`
      height: ${bordersHeight};

      border: 1px solid
        ${({ theme, dropBorderWrapper }) => dropBorderWrapper || theme.colors.borders.light};
      border-radius: 10px;
      padding-left: 20px;
    `};

  ${({ boxShadow }) => boxShadow
    && css`
      box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
      border-radius: 10px;
    `};

    @media ${({ theme }) => theme.device.laptop1100} {
    width: ${({ dropWrapperWidth1100 }) => dropWrapperWidth1100};
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: ${({ dropWrapperWidthSM }) => dropWrapperWidthSM};
    margin: ${({ dropWrapperMarginM }) => dropWrapperMarginM};
  }
`;

export const DropBtn = styled.div`
  &&& {
    width: ${({ dropBtnWidth }) => dropBtnWidth || '100%'};
    height: ${({ dropBtnHeight }) => dropBtnHeight || '100%'};
    color: ${({ theme, dropBtnColor }) => dropBtnColor || theme.colors.text.muted};

    background-color: ${({ dropBtnBg }) => dropBtnBg};
    backdrop-filter: ${({ dropBtnBlur }) => dropBtnBlur};
    border-radius: ${({ dropBtnBorderRadius }) => dropBtnBorderRadius};
    padding: ${({ dropBtnPadding }) => dropBtnPadding};
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;

    @media ${({ theme }) => theme.device.laptop1100} {
      width: ${({ dropBtnWidthLaptop }) => dropBtnWidthLaptop};
      margin: ${({ dropBtnMarginLaptop }) => dropBtnMarginLaptop};
    }
  }
`;
