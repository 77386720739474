import React from 'react';
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';

const Chart = () => {
  const data = [
    {
      name: 'Январь',
      minPrice: 4000,
      maxPrice: 2400,
      amt: 2400,
    },
    {
      name: 'Февраль',
      minPrice: 3000,
      maxPrice: 1398,
      amt: 2210,
    },
    {
      name: 'Март',
      minPrice: 2000,
      maxPrice: 9800,
      amt: 0,
    },
    {
      name: 'Апрель',
      minPrice: 2780,
      maxPrice: 3908,
      amt: 2000,
    },
    {
      name: 'Май',
      minPrice: 1890,
      maxPrice: 4800,
      amt: 2181,
    },
    {
      name: 'Июнь',
      minPrice: 2390,
      maxPrice: 3800,
      amt: 2500,
    },
    {
      name: 'Июль',
      minPrice: 3490,
      maxPrice: 4300,
      amt: 2100,
    },
    {
      name: 'Август',
      minPrice: 1890,
      maxPrice: 4800,
      amt: 2181,
    },
    {
      name: 'Сентябрь',
      minPrice: 2390,
      maxPrice: 3800,
      amt: 2500,
    },
    {
      name: 'Октябрь',
      minPrice: 3490,
      maxPrice: 4300,
      amt: 2100,
    },
    {
      name: 'Ноябрь',
      minPrice: 3490,
      maxPrice: 4300,
      amt: 2100,
    },
    {
      name: 'Декабрь',
      minPrice: 3490,
      maxPrice: 4300,
      amt: 2100,
    },
  ];

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          margin={{
            top: 20, right: 30, left: 0, bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FFDA44  " stopOpacity={0.8} />
              <stop
                offset="95%"
                stopColor="rgba(255, 218, 68, 0)"
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#943FA1" stopOpacity={0.8} />
              <stop
                offset="95%"
                stopColor="rgba(148, 63, 161, 0) 93.15%);"
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="minPrice"
            stroke="#F4C211"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          <Area
            type="monotone"
            dataKey="maxPrice"
            stroke="#943FA1"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default Chart;
