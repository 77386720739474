import React, { useState } from 'react';
import { Collapse } from 'antd';
import { useIntl } from 'react-intl';
import { Title, RowCustom } from 'components';
import ArrowCaretSVG from 'assets/icons/arrowCaret.svg';
import { CollapseStyled } from './AccordionStyled';
// import Caret from '../../assets/icons/caret.svg';

const { Panel } = Collapse;

const customPanelStyle = {
  background: '#ffffffcc',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};

const Accordion = (props) => {
  const intl = useIntl();
  const [isActivePanel, setIsActivePanel] = useState(true);

  return (
    <CollapseStyled
      bordered={false}
      onChange={() => {
        setIsActivePanel(!isActivePanel);
      }}
      //   expandIcon={({ isActive }) => (
      //     <Icon type="caret-right" rotate={isActive ? 90 : 0} />
      //   )}
      accordion
    >
      <Panel
        style={customPanelStyle}
        showArrow={false}
        header={
          isActivePanel ? (
            <div>
              <RowCustom margin="40px 0 0 0">
                <Title sixe="18px" weight="500" primary>
                  {intl.formatMessage({
                    id: 'big-modal.accordion.not-active',
                  })}
                </Title>

                <img
                  loading="lazy"
                  src={ArrowCaretSVG}
                  rotate={!isActivePanel ? 90 : 0}
                  style={{ margin: '0px 0 0 20px' }}
                  alt=""
                />
              </RowCustom>
            </div>
          ) : (
            <div>
              <RowCustom margin="40px 0 0 0">
                <Title sixe="18px" weight="500" primary>
                  {intl.formatMessage({
                    id: 'big-modal.accordion.active',
                  })}
                </Title>

                <img
                  loading="lazy"
                  src={ArrowCaretSVG}
                  style={{
                    margin: '0px 0 0 20px',
                    transform: 'rotate(-180deg)',
                  }}
                  alt=""
                />
              </RowCustom>
            </div>
          )
        }
        key="1"
      >
        {props.children}
      </Panel>
    </CollapseStyled>
  );
};

export default Accordion;
