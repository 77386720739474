import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

import moment from 'moment';

const CalendarAirbnb = () => {
  const [state, setState] = React.useState({
    startDate: null,
    endDate: null,
    focusedInput: moment(1591228800).format('YYYY-MM-DD'),
  });

  console.log('MOMENT: ', moment.unix(1591228800).format('YYYY-MM-DD'));

  // const startDateString = moment.unix(1591228800).format('YYYY-MM-DD');
  // const endDateString = endDate && endDate.format('YYYY-MM-DD');
  return (
    <DateRangePicker
      startDate={state.startDate} // momentPropTypes.momentObj or null,
      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
      endDate={state.endDate} // momentPropTypes.momentObj or null,
      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
      onDatesChange={({ startDate, endDate }) => setState({ startDate, endDate })} // PropTypes.func.isRequired,
      focusedInput={state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={(focusedInput) => setState({ focusedInput })} // PropTypes.func.isRequired,
    />
  );
};

export { CalendarAirbnb };
