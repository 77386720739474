import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import { DateRangePicker } from 'react-date-range';
import { addDays, format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import {
  DatePickerWrapper,
  DateRangePickerVertical,
  DateRangePickerHorizontal,
  DateRangePickerDesktop,
  DateRangePickerTablet,
} from './Calendar.styles';

const Calendar = ({
  direction = 'horizontal',
  isScroll = false,
  //Update price query
  onDatesUpdate,
  // Initial dates
  startDate,
  endDate,
  disabled,
}) => {
  const [state, setState] = useState([
    {
      startDate: startDate ? new Date(startDate) : new Date(),
      endDate: endDate ? new Date(endDate) : new Date(),
      key: 'selection',
      disabled: disabled,
      color: '#9E50A9',
    },
  ]);

  useEffect(() => {
    onDatesUpdate &&
      onDatesUpdate(
        format(state[0].startDate, 'yyyy-MM-dd'),
        format(state[0].endDate, 'yyyy-MM-dd')
      );
  }, [state]);

  return (
    <DatePickerWrapper>
      <DateRangePickerVertical>
        <DateRangePicker
          onChange={(item) => setState([item.selection])}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={state}
          direction="vertical"
          scroll={{ enabled: true }}
          locale={
            localStorage.language &&
            (locales[localStorage.language] || locales.en)
          }
          monthDisplayFormat="LLLL yyyy"
        />
      </DateRangePickerVertical>
      <DateRangePickerHorizontal>
        <DateRangePickerTablet>
          <DateRangePicker
            onChange={(item) => setState([item.selection])}
            showSelectionPreview
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction={direction}
            scroll={{ enabled: isScroll }}
            locale={
              localStorage.language &&
              (locales[localStorage.language] || locales.en)
            }
            monthDisplayFormat="LLLL yyyy"
          />
        </DateRangePickerTablet>
        <DateRangePickerDesktop>
          <DateRangePicker
            onChange={(item) => setState([item.selection])}
            showSelectionPreview
            moveRangeOnFirstSelection={false}
            months={3}
            ranges={state}
            direction={direction}
            scroll={{ enabled: isScroll }}
            locale={
              localStorage.language &&
              (locales[localStorage.language] || locales.en)
            }
            monthDisplayFormat="LLLL yyyy"
          />
        </DateRangePickerDesktop>
      </DateRangePickerHorizontal>
    </DatePickerWrapper>
  );
};

export { Calendar };
