import styled from 'styled-components';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export const NavMobile = styled.div`
  width: 100%;
  /* min-height: 100px; */
  padding: 0;
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  a {
    color: #353535;
    &:hover {
      color: black;
    }
  }

  display: none;

  @media ${({ theme }) => theme.device.laptop} {
    display: block;
  }
`;

export const MobileTopFilters = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.laptop} {
    display: block;
  }
`;

export const MenuBlock = styled.ul`
  clear: none;
  color: black;
  z-index: 99;
  position: fixed;
  width: 0vw;
  right: -200px;
  top: 0;
  height: 100vh;
  /* transform: translateX(100%); */
  transition: all 0.2s ease-in-out ;
  /* margin-left: -1000px; */
  /* border: 1px solid red; */

  @media ${({ theme }) => theme.device.laptop} {
    clear: both;
    transition: all 0.2s ease-out;
    margin-left: -10px;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
`;

export const MenuBlockSecondLayer = styled.ul`
  clear: none;
  color: black;
  z-index: 99;
  position: fixed;
  width: 0vw;
  right: -200px;
  top: 0;
  height: 100vh;
  /* transform: translateX(100%); */
  transition: all 0.2s ease-in-out ;
  /* margin-left: -1000px; */
  /* border: 1px solid red; */

  @media ${({ theme }) => theme.device.laptop} {
    clear: both;
    transition: all 0.2s ease-out;
    margin-left: -10px;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
`;

export const MenuBlockCurrencyLayer = styled.ul`
  clear: none;
  color: black;
  z-index: 99;
  position: fixed;
  width: 0vw;
  right: -200px;
  top: 0;
  height: 100vh;
  /* transform: translateX(100%); */
  transition: all 0.2s ease-in-out ;
  /* margin-left: -1000px; */
  /* border: 1px solid red; */

  @media ${({ theme }) => theme.device.laptop} {
    clear: both;
    transition: all 0.2s ease-out;
    margin-left: -10px;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
`;

export const MenuItemNavbar = styled.li`
  list-style: none;
  background-color: #ffffff;
  padding: 5px 0 3px;
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const MenuItem = styled.li`
  float: left;
  list-style: none;
  margin-top: -20px;
  background-color: #ffffff;
  padding: 36px 24px 3px;
  @media ${({ theme }) => theme.device.laptop} {
    float: none;
  }
`;

export const MenuIcon = styled.label`
  display: none;
  @media ${({ theme }) => theme.device.laptop} {
    display: flex;
    /* border: 1px solid red; */
    padding: ${({ padding }) => padding || '28px'};
    margin: ${({ margin }) => margin};
    position: relative;
    float: right;
    cursor: pointer;
  }
`;

export const NavIcon = styled.span`
  background: ${({ background }) => background || '#fff'};
  background: transparent;
  display: block;
  height: 11px;
  width: 22px;
  margin: ${({ margin }) => margin || '45px 0 0 0'};
  position: relative;
  transition: all 0.2s ease-in-out;
  /* z-index: 100000; */
  margin-top: 5px !important;

  &:before {
    content: '';
    background: ${({ background }) => background || '#fff'};
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 7px;
    transition: all 0.5s ease-in-out;
  }
  &:after {
    content: '';
    background: ${({ background }) => background || '#fff'};
    display: block;
    height: 3px;
    width: 70%;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.5s ease-in-out;
  }
`;

export const MenuBtn = styled.input`
  display: none;
  transition: all ease-in-out 0.5s;
  &:checked ~ ${MenuBlock} {
    transform: translateX(0%);
    right: 0;
    top: 0;
    height: 100vh;
    position: fixed;
    z-index: 2000;
    width: min(400px, 80vw);
  }

  &:checked ~ ${MenuIcon} ${NavIcon} {
    background-color: transparent;
    &:before {
      transform: rotate(-45deg);
      top: 0;
    }
    &:after {
      width: 100%;
      transform: rotate(45deg);
      top: 0;
    }
  }
`;

export const MenuBtnSecondLayer = styled.input`
  display: none;
  transition: all ease-in-out 0.5s;
  &:checked ~ ${MenuBlockSecondLayer} {
    transform: translateX(0%);
    right: 0;
    top: 0;
    height: 100vh;
    position: fixed;
    z-index: 2000;
    width: min(400px, 80vw);
  }
`;

export const MenuBtnCurrencyLayer = styled.input`
  display: none;
  transition: all ease-in-out 0.5s;
  &:checked ~ ${MenuBlockCurrencyLayer} {
    transform: translateX(0%);
    right: 0;
    top: 0;
    height: 100vh;
    position: fixed;
    z-index: 2000;
    width: min(400px, 80vw);
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
`;

export const LogoContainer = styled.div`
  float: left;
  display: block;
`;

export const LogoTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const LogoSymbol = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-image: url(${({ img }) => img});
  background-color: ${({ theme }) => theme.colors.primary};
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoTitle = styled.div`
  width: 59px;
  height: 22px;
  background-image: url(${({ img }) => img});
  background-position: center;
  background-size: cover;
  margin: 0 6px;
`;

export const SearchBar = styled.div`
  height: 40px;
  background: #ffffff;
  display: flex;
  align-items: center;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
  border-radius: 10px;
  i {
    color: ${({ theme }) => theme.colors.text.muted};
    font-size: 18px;
    margin: 0 15px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    color: #333;
  }
`;

export const DatePickerContainer = styled.div`
  height: 28px;
  border-left: 1px solid #e4f0f8;
  border-right: 1px solid #e4f0f8;
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
`;

export const PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PhoneImg = styled.img`
  padding: 6px;
  border: 1px solid ${({ theme }) => theme.colors.text.muted};
  border-radius: 100%;
`;

export const RangePickerTransparent = styled(RangePicker)`
  background-color: transparent;
  border: none;
  box-shadow: none;
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  .ant-picker-active-bar {
    background: ${({ theme }) => theme.colors.primary};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const BtnCounter = styled.div`
  width: 25px;
  height: 25px;
  color: #fff;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  &:hover {
    cursor: pointer;
  }
`;
