import styled from 'styled-components';
import { Collapse } from 'antd';

export const CollapseStyled = styled.div`
  &&& {
    width: ${({ width }) => width};
    margin: ${({ margin }) => margin};

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 30px 0 0 0;
      /* margin-bottom: 76px; */
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      color: ${({ theme }) => theme.colors.text.main};

      font-family: Ubuntu;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 128.3%;

      @media ${({ theme }) => theme.device.laptop1100} {
        font-size: 18px;
      }
    }
    @media ${({ theme }) => theme.device.laptop1100} {
      width: ${({ widthTablet }) => widthTablet};
    }
    @media ${({ theme }) => theme.device.tablet} {
      margin: ${({ marginTablet }) => marginTablet};

      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 143%;
        margin-top: 30px;
      }

      .ant-collapse
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-arrow {
        top: -2px;
      }
    }

    .ant-collapse-content-box {
      p {
        color: ${({ theme }) => theme.colors.text.grey};
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 143%;
        @media ${({ theme }) => theme.device.laptop1100} {
          font-size: 16px;
        }
      }
    }
    .ant-collapse {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      -webkit-font-feature-settings: 'tnum';
      font-feature-settings: 'tnum', 'tnum';
      background-color: transparent;
      border: none;
      border-bottom: 0;
      border-radius: 2px;
    }

    .ant-collapse > .ant-collapse-item {
      border-bottom: none !important;
    }

    .ant-collapse-content {
      border-top: none !important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
      outline: none;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 0;
    }

    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      right: 0;
      top: 0;
      width: 18px;
      height: 10px;

      @media ${({ theme }) => theme.device.laptop1100} {
        width: 9px;
        height: 5px;
      }
    }

    @media ${({ theme }) => theme.device.tablet} {
      .ant-collapse-content-box {
        p {
          font-size: ${({ fontSizeTablet }) => fontSizeTablet};
          color: ${({ theme }) => theme.colors.text.grey};
        }
      }
      .ant-collapse-header {
        font-weight: normal;
        font-size: 16px;
        color: ${({ theme }) => theme.colors.text.main};
        .ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
          outline: none;
          color: ${({ theme }) => theme.colors.text.main};
        }
      }

      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        font-size: 16px;
      }
    }
  }
`;

export const PanelStyled = styled(Collapse.Panel)`
  &&& {
    font-weight: 500;
    font-size: 24px;
    color: #353535;
    font-family: Ubuntu;
    margin: ${({ margin }) => margin};
    @media ${({ theme }) => theme.device.tablet} {
      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        font-size: 16px;
      }
    }
  }
`;
