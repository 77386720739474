import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { BtnCounter, Title } from 'components';
import { Row } from 'antd';
import { CardOptionContainer, CardIcon } from './CardOption.styles';

const CardOption = ({
  title, price, days, icon,
}) => {
  const intl = useIntl();
  const [counter, setCounter] = useState(0);
  return (
    <CardOptionContainer>
      <CardIcon>{icon}</CardIcon>
      <Title size="14px" margin="0 0 28px 0">
        {title}
      </Title>
      <Title size="14px" margin="0 0 15px 0" mutedText>
        {days}
        {intl.formatMessage({
          id: 'reservation-page.option-card.days',
        })}
      </Title>

      <Row align="middle">
        <BtnCounter
          onClick={() => setCounter(counter - 1 < 0 ? 0 : counter - 1)}
        >
          -
        </BtnCounter>
        <Title size="14px" margin="0 12px">
          {counter}
        </Title>
        <BtnCounter onClick={() => setCounter(counter + 1)}>+</BtnCounter>
      </Row>
    </CardOptionContainer>
  );
};

export { CardOption };
