import styled, { css } from 'styled-components';

export const DeviceBreakpoint = styled.div`
  ${({ theme, desktopHidden }) =>
    desktopHidden &&
    css`
      @media ${({ theme }) => theme.deviceMin.tablet} {
        display: none !important;
      }
    `}

    ${({ theme, desktopHiddenFrom1440 }) =>
      desktopHiddenFrom1440 &&
      css`
        @media ${({ theme }) => theme.deviceMin.laptopL} {
          display: none !important;
        }
      `}

    ${({ theme, desktopHiddenTo1440 }) =>
      desktopHiddenTo1440 &&
      css`
        @media ${({ theme }) => theme.device.laptopL} {
          display: none !important;
        }
      `}
    

    ${({ theme, desktopHiddenFrom1200 }) =>
      desktopHiddenFrom1200 &&
      css`
        @media (min-width: 1250px) {
          display: none;
        }
      `}

      ${({ theme, tabletHiddenFrom1200 }) =>
        tabletHiddenFrom1200 &&
        css`
          @media (max-width: 1250px) {
            display: none;
          }
        `}

        ${({ theme, tablet550Hidden }) =>
          tablet550Hidden &&
          css`
            @media ${({ theme }) => theme.device.tablet} {
              display: none;
            }
          `}

          ${({ theme, tabletTo1100Hidden }) =>
            tabletTo1100Hidden &&
            css`
              @media ${({ theme }) => theme.device.laptop1100} {
                display: none;
              }
            `}

  ${({ theme, tabletHidden }) =>
    tabletHidden &&
    css`
      @media ${({ theme }) => theme.device.tablet} {
        display: none;
      }
    `}

  ${({ theme, mobileMHidden }) =>
    mobileMHidden &&
    css`
      @media ${({ theme }) => theme.device.mobileM} {
        display: none;
      }
    `}

    ${({ theme, tabletFrom1100Hidden }) =>
    tabletFrom1100Hidden &&
    css`
      @media ${({ theme }) => theme.deviceMin.laptop1100} {
        display: none !important;
      }
    `}
  
`;
