import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import ArrowLeftSVG from 'assets/icons/ArrPng.png';
import ArrowRightSVG from 'assets/icons/ArrRightPng.png';
import {
  ArrowBtn,
  CarouselAntStyled,
  SlideItem,
  SlideImg,
} from './CarouselAnt.styles';
import MockImage from 'assets/mockImage.png';
import MockVillaImage from 'assets/mock/image-19.png';

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <ArrowBtn
      top="44%"
      className="slick-arrow"
      style={{
        ...style,
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={onClick}
      left="10px"
    >
      <img
        loading="lazy"
        width="40"
        height="40"
        src={ArrowLeftSVG}
        alt="arrow_left"
      />
    </ArrowBtn>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <ArrowBtn
      top="44%"
      className="slick-arrow"
      style={{
        ...style,
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={onClick}
      right="10px"
    >
      <img
        loading="lazy"
        width="40"
        height="40"
        src={ArrowRightSVG}
        alt="arrow_left"
      />
    </ArrowBtn>
  );
};

const CarouselAnt = ({
  children,
  imgHeight,
  arrows,
  height,
  heightM,
  images,
  badge,
  favorite,
  title,
  location,
  dotPosition,
  fullscreen,
}) => {
  let settings = {
    swipeToSlide: true,
  };

  if (dotPosition) {
    settings = {
      ...settings,
      dotPosition,
    };
  }
  if (arrows) {
    settings = {
      ...settings,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  }

  return (
    <CarouselAntStyled
      autoplay={false}
      {...settings}
      height={height}
      heightM={heightM ? heightM : ''}
      dotPosition={dotPosition}
    >
      {images.map((image) => (
        <SlideItem key={uuid()}>
          {fullscreen ? (
            <SlideImg
              // src={image}
              // height={height}
              heightM={heightM}
              imgHeight={imgHeight}
            >
              <img
                loading="lazy"
                width="100%"
                // height="auto"
                src={image}
                // src={MockImage}
                alt="productImage"
              />
            </SlideImg>
          ) : (
            <img
              loading="lazy"
              width="90vw"
              height="auto"
              src={image}
              // src={MockImage}
              alt="productImage"
            />
          )}
          {children}
        </SlideItem>
      ))}
    </CarouselAntStyled>
  );
};

CarouselAnt.propTypes = {
  images: PropTypes.array.isRequired,
  badge: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.any,
};

export { CarouselAnt };
