import styled, { css } from 'styled-components';

export const SelectCustomStyles = styled.div`
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
    background-color: #fff9e6;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-state {
    color: #8618ff;
  }

  .ant-select {
    max-width: ${({ width }) => width || '100%'};
  }

  .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: none;
    border-radius: 2px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    /* width: 100%; */
    width: 200px;
    height: 32px;
    padding-left: 0;
    overflow: hidden;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
    box-shadow: none;
  }
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
    outline: none;
    box-shadow: none;
  }

  ${({ borders }) => borders
    && css`
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid ${({ theme }) => theme.colors.borders.light};
        border-radius: 10px;
        padding: 0 15px;
      }
    `}
`;
