import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { useIntl } from 'react-intl';
import { SearchBarLess } from './SearchBarLess';
import { Logo, DropdownMenu, Button, SelectMenu } from 'components';
import PhoneSVG from 'assets/icons/phone.svg';

import { HeartSolidSVG } from 'lib/icons';

import { useQuery } from '@apollo/react-hooks';

import { GET_WISHLIST_HOTELS } from 'graphql/queries/getHotels';
import { SearchBarPanel } from './parts/SearchBarPanel';
import {
  Nav,
  PhoneContainer,
  PhoneImg,
  PhoneAnimatedBox,
  FavIcon,
  NavItemsContainer,
  SearchBarContainer,
  DropdownMenuStyled,
} from './Navbar.styles';

const Navbar = ({ setSearchValues }) => {
  const {
    loading: loadingWishlist,
    error: errorWishlist,
    data: dataWishlist,
  } = useQuery(GET_WISHLIST_HOTELS, {
    variables: { lang: 'ru', offset: 0, limit: 15 },
    fetchPolicy: 'network-only',
  });

  const intl = useIntl();
  const history = useHistory();

  const [searchVisible, seSearchVisible] = useState(true);
  const [activeSearchPanel, seActiveSearchPanel] = useState(false);

  const onLaguageChangeHandler = (e) => {
    console.log(e);
    const currentLanguage = history.location.pathname.split('/')[1];

    if (e.key === currentLanguage) {
      return;
    }
    const newPath = history.location.pathname.replace(currentLanguage, e.key);

    localStorage.setItem('language', e.key);
    history.push({
      pathname: newPath,
    });
    window.location.reload(true);
  };

  React.useEffect(() => {
    console.log('history: ', history.location.pathname);
    if (
      history.location.pathname.includes('/faq') ||
      history.location.pathname.includes('/policy') ||
      history.location.pathname.includes('/contacts') ||
      history.location.pathname.includes('/for-owner') ||
      history.location.pathname.includes('/about') ||
      history.location.pathname.includes('/destinations')
    ) {
      return <SearchBarLess />;
    }
  }, []);

  return (
    <Nav>
      <Logo />
      <SearchBarContainer>
        {searchVisible && (
          <SearchBarPanel seActiveSearchPanel={seActiveSearchPanel} />
        )}
      </SearchBarContainer>
      <NavItemsContainer activeSearchPanel={activeSearchPanel}>
        {/* <PhoneContainer>
          <PhoneImg src={PhoneSVG} margin="0 7px 0 0" />
          <PhoneAnimatedBox>
            <SelectMenu
              width="184px"
              defaultValue="+ 7 (495) 580 - 80 - 46"
              optionList={[
                { icon: '', title: '+ 7 (495) 580 - 80 - 46 ' },
                { icon: '', title: '+ 8 (495) 580 - 80 - 46' },
              ]}
            />
          </PhoneAnimatedBox>
        </PhoneContainer> */}

        <Link to={`/${localStorage.language}/info/faq`}>
          {intl.formatMessage({
            id: 'footer.faq',
          })}
        </Link>
        {/*
        <DropdownMenu title="USD" margin="0 0 0 38px">
          <Menu.Item key="1">RUB</Menu.Item>
          <Menu.Item key="2">EUR</Menu.Item>
        </DropdownMenu> */}

        {
          dataWishlist &&
          dataWishlist.wishlist &&
          dataWishlist.wishlist.propsList.length > 0 ? (
            <FavIcon to={`/${localStorage.language}/my/favourite`} primary>
              <HeartSolidSVG />
            </FavIcon>
          ) : (
            <FavIcon to={`/${localStorage.language}/my/favourite`}>
              <HeartSolidSVG />
            </FavIcon>
          )
          //
        }

        <Button
          size="16px"
          primary
          margin="0 40px 0 0"
          padding="0"
          size="16px"
          minWidth="89px"
          height="40px"
          onClick={() => history.push(`/${localStorage.language}/auth`)}
        >
          {intl.formatMessage({
            id: 'buttons.login',
          })}
        </Button>
      </NavItemsContainer>

      <DropdownMenu
        title={localStorage.language.toUpperCase()}
        onChangeHandler={onLaguageChangeHandler}
      >
        <DropdownMenuStyled>
          <Menu.Item
            style={{
              backgroundColor:
                localStorage.language === 'en'
                  ? 'rgba(148, 63, 161, 0.08)'
                  : 'transparent',
            }}
            key="en"
          >
            EN
          </Menu.Item>
          <Menu.Item
            style={{
              backgroundColor:
                localStorage.language === 'ru'
                  ? 'rgba(148, 63, 161, 0.08)'
                  : 'transparent',
            }}
            key="ru"
          >
            RU
          </Menu.Item>
          <Menu.Item
            style={{
              backgroundColor:
                localStorage.language === 'de'
                  ? 'rgba(148, 63, 161, 0.08)'
                  : 'transparent',
            }}
            key="de"
          >
            DE
          </Menu.Item>
          <Menu.Item
            style={{
              backgroundColor:
                localStorage.language === 'bg'
                  ? 'rgba(148, 63, 161, 0.08)'
                  : 'transparent',
            }}
            key="bg"
          >
            BG
          </Menu.Item>
          <Menu.Item
            style={{
              backgroundColor:
                localStorage.language === 'sr'
                  ? 'rgba(148, 63, 161, 0.08)'
                  : 'transparent',
            }}
            key="sr"
          >
            SR
          </Menu.Item>
          <Menu.Item
            style={{
              backgroundColor:
                localStorage.language === 'ro'
                  ? 'rgba(148, 63, 161, 0.08)'
                  : 'transparent',
            }}
            key="ro"
          >
            RO
          </Menu.Item>
        </DropdownMenuStyled>
      </DropdownMenu>
    </Nav>
  );
};

export { Navbar };
