import styled from 'styled-components';
import { Slider } from 'antd';

export const SliderRange = styled(Slider)`
  &&& {
    width: ${({ width }) => width || '200px'};
    .ant-slider-track {
      background: ${({ theme }) => theme.colors.primary};
    }
    .ant-slider {
      background: ${({ theme }) => theme.colors.primary};
      &:hover {
        background: ${({ theme }) => theme.colors.primary};
      }
    }
    .ant-slider-handle {
      border: solid 2px #943fa1 !important;
    }
    .ant-slider-track {
      background-color: ${({ theme }) => theme.colors.primary} !important;
    }

    .ant-slider-handle:focus {
      border-color: #46a6ff;
      outline: none;
      -webkit-box-shadow: 0 0 0 5px rgba(219, 24, 255, 0.12);
      box-shadow: 0 0 0 5px rgba(177, 24, 255, 0.12);
    }
  }
`;
