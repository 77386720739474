import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const CategoryBtnWrapper = styled.div`
  width: 100%;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media ${({ theme }) => theme.deviceMin.laptop} {
    padding: 0 0 50px 0;
  }
`;

export const CategoryBtnLink = styled(Link)`
  min-width: 130px;
  border-radius: 25px;
  background: #f7f4f8;
  color: ${({ theme }) => theme.colors.text.muted};
  padding: 10px;
  margin-right: 30px;
  text-align: center;
  font-family: Rubik;
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  
  ${({ theme, active }) => active
    && css`
      color: #fff;
      background-color: ${theme.colors.primary};
    `}

  @media ${({ theme }) => theme.device.laptop} {
    width: 50%;
    margin: 0 0 25px 0;
  }
`;

// export const CategoryBtn = styled.label`
//   width: 130px;
//   border-radius: 25px;
//   background: #f7f4f8;
//   color: ${({ theme }) => theme.colors.text.muted};
//   padding: 10px;
//   margin-right: 30px;
//   text-align: center;
//   font-family: Rubik;
//   font-weight: normal;
//   font-size: 16px;
//   cursor: pointer;
//   text-transform: capitalize;
//   -webkit-transition: all ease-in-out 0.5s;
//   transition: all ease-in-out 0.5s;
//   &:hover {
//     color: #fff;
//     background-color: ${({ theme }) => theme.colors.primary};
//   }
//   @media ${({ theme }) => theme.device.laptop} {
//     width: 50%;
//     margin: 0 0 25px 0;
//   }
// `;

// export const CategoryBtnController = styled.input`
//   display: none;
//   transition: all ease-in-out 0.5s;

//   input[type='radio']&:checked + ${CategoryBtn} {
//     color: #fff;
//     background-color: ${({ theme }) => theme.colors.primary};
//   }
// `;
