import styled from 'styled-components';
import FooterBgSVG from 'assets/footerImg.png';

export const FooterBg = styled.div`
  position: relative;
  background-image: url(${FooterBgSVG});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 30px 30px 0px 0px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::after {
    content: '';
    /* background: rgba(40, 40, 40, 0.48); */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  min-height: 290px;
  background-size: cover;
  background-image: url(${FooterBg});
  background-position: center;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  /* padding: 70px 80px 30px 80px; */
  font-size: 1.4rem;
  position: relative;

  &::after {
    content: '';
    background: rgba(40, 40, 40, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      transition: all ease-in-out 0.5s;
      margin-bottom: 10px;
      &:hover {
        /* transform: translateX(6px); */
        color: ${({ theme }) => theme.colors.text.light};
        cursor: pointer;
      }
      a {
        color: #fff;
        &:hover {
          color: ${({ theme }) => theme.colors.text.light};
          text-decoration: none;
        }
      }
    }
  }

  display: block;
  @media ${({ theme }) => theme.device.tablet} {
    display: none;
    padding: 26px;
  }
`;

export const FooterContainerMobile = styled.div`
  width: 100%;
  min-height: 323px;
  background-size: cover;
  background-image: url(${FooterBg});
  background-position: center;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 70px 24px 30px 24px;
  font-size: 1.4rem;

  &::after {
    content: '';
    background: rgba(40, 40, 40, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      transition: all ease-in-out 0.5s;
      &:hover {
        transform: translateX(6px);
        cursor: pointer;
      }
      a {
        color: #fff;
        &:hover {
          color: #eee;
          text-decoration: none;
        }
      }
    }
  }

  display: block;
  @media ${({ theme }) => theme.deviceMin.tablet} {
    display: none;
    padding: 26px;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ paddingM }) => paddingM || '8px 0 0 20px'};
  }
`;

export const Socials = styled.div`
  width: 164px;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  i {
    color: rgba(255, 255, 255, 0.5);
    margin: 20px;
    transition: all ease-in 0.3s;
    cursor: pointer;

    &:nth-child(1) {
      margin: 20px 20px 20px 0;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.text.muted};
    }
  }
`;
