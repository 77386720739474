import styled, { keyframes } from 'styled-components';

const ringAnimation = keyframes`
   0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

export const Loader = styled.div`
  display: inline-block;
  width: ${({ width }) => width || '80px'};
  height: ${({ height }) => height || '80px'};
  z-index: 999;

  &:after {
    content: ' ';
    display: block;
    width: ${({ widthL }) => widthL || '64px'};
    height: ${({ heightL }) => heightL || '64px'};
    margin: 8px;
    margin-left: ${({ marginLeft }) => marginLeft};
    margin-top: ${({ marginTop }) => marginTop};
    border-radius: 50%;
    border: ${({ borderWidth }) => borderWidth || '6px'} solid
      ${({ theme, color }) => color || theme.colors.primary || '#fff'};
    border-color: ${({ theme, color }) =>
        color || theme.colors.primary || '#fff'}
      transparent
      ${({ theme, color }) => color || theme.colors.primary || '#fff'}
      transparent;
    animation: ${ringAnimation} 1.2s linear infinite;
  }
`;

export const LoaderScreenWrapper = styled.div`
  background: ${({ bg }) => bg};
  /* width: 100vw;
  height: 100vh; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderSmallWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 998;
  backdrop-filter: blur(1.5px);

  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    position: absolute;
    content: '';
    z-index: 998;
    width: ${({ size = '100px' }) => size};
    height: ${({ size = '100px' }) => size};
    background: ${({ bg = 'white' }) => bg};
    border-radius: 25px;
    box-shadow: 0px 10px 50px
      ${({ boxShadow = 'rgb(0 82 180 / 10%)' }) => boxShadow};
  }
`;

export const LoadingText = styled.div`
  width: ${({ width }) => width || '100%'};
  min-width: ${({ minWidth }) => minWidth};
  display: ${({ display }) => display || 'block'};
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 18px;
  text-align: center;
  align-items: ${({ align }) => align};
  /* border: 1px solid ${({ theme }) => theme.colors.text.light}; */
`;
