import React from 'react';
import PropTypes from 'prop-types';
import { Footer } from 'components';
import { NavbarMobileMainPage } from './Navbar/NavbarMobileMainPage';

const MainPageLayout = ({ children }) => (
  <div id="home">
    <NavbarMobileMainPage style={{ border: '3px solid blue' }} />
    {children}
    <Footer />
  </div>
);

MainPageLayout.propTypes = { children: PropTypes.node };

export default MainPageLayout;
