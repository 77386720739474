import React from 'react';
import { Icon } from 'components';
import ArrowUp from 'assets/icons/Chevron-Right.svg';
import { ButtonUpWrapper, LinckAnchorWrapper } from './ButtonUp.style';

export const ButtonUp = () => (
  <LinckAnchorWrapper tabletHidden class="nav-link" href="#home">
    <ButtonUpWrapper>
      <Icon src={ArrowUp} />
    </ButtonUpWrapper>
  </LinckAnchorWrapper>
);
