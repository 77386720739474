import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Tooltip } from 'antd';
import { Helmet } from 'react-helmet';
import {
  Stars,
  CarouselAnt,
  Divider,
  Title,
  Favourite,
  Badge,
  IconSvgContainer,
  DeviceBreakpoint,
  RowCustom,
} from 'components';
import { useIntl } from 'react-intl';

// import {
//   PeopleSVG,
//   BathSVG,
//   BedSVG,
//   SquareMetersSVG,
//   BeachSVG,
// } from 'lib/icons';

import { ReactComponent as BeachSVG } from 'assets/icons/bigModal/beach.svg';
import { ReactComponent as BathroomSVG } from 'assets/icons/bigModal/bathroom.svg';
import { ReactComponent as BedSVG } from 'assets/icons/bigModal/bed.svg';
import { ReactComponent as UsersSVG } from 'assets/icons/bigModal/users.svg';
import { ReactComponent as SquareSVG } from 'assets/icons/bigModal/square.svg';

import { Wrapper } from 'components/Wrappers/Wrappers.styles';
import { products } from 'mockData';
import {
  CardWithMapContainer,
  CardInfo,
  UserRate,
  TotalRate,
  FeaturesContainer,
  FeatureItem,
  Price,
  Discount,
  PriceInfo,
  SlideTopBlock,
  SliderWrapper,
  SlideInfo,
  SlideAdditionalInfo,
} from './Card.styles';
const pluralize = require('numeralize-ru').pluralize;

const CardPreviewWithMap = (props) => {
  const {
    cardWidth,
    hotel,
    nights,
    onCardHover,
    setFavoriteVillas,
    favoriteVillas,
  } = props;
  console.log('htl', hotel);
  const [isCardHovered, setCardHover] = useState(false);

  React.useEffect(() => {
    console.log('hotelhotelhotel: ', hotel);
  }, [hotel]);

  const intl = useIntl();
  const { title, images, location } = products;

  const cardHoverHandler = useCallback(
    (data) => {
      if (isCardHovered) {
        return;
      } else {
        setCardHover(true);
        console.log('CardHover', data);
        onCardHover(data);
      }
    },
    [isCardHovered]
  );

  const {
    id,
    coverPhoto,
    detailType,
    name,
    livingSpace,
    // nights,
    oPax,
    pPax,
    price,
    priceStr,
    xtype,
    breadcrumbs,
    nbedrooms,
    nbathrooms,
    ndistance,
    averageRating,
    urls,
  } = hotel;

  console.log('jdakls', hotel);

  return (
    <CardWithMapContainer
      onMouseLeave={() => cardHoverHandler(null)}
      onMouseEnter={() => cardHoverHandler(hotel)}
      marginRight="3vw"
      marginBottom="3vw"
      marginRight1100="1.4vw"
      marginBottom1100="1.4vw"
      marginRight2n1100="0"
      height1100="445px"
      heightLaptop="543px"
      widthL1100="42vw"
      widthM="90vw"
      width2100="30.5vw"
      widthFrom2100="22.5vw"
    >
      <Helmet>
        <link rel="canonical" href={urls.canonicalUrl} />
      </Helmet>
      <SlideTopBlock justify="space-between">
        <Badge style={{ opacity: 0 }}>
          {intl.formatMessage({
            id: 'card.villa',
          })}
        </Badge>
        <i>
          <Favourite
            setFavoriteVillas={setFavoriteVillas}
            favoriteVillas={favoriteVillas}
            hotel={hotel}
            iconColor={hotel.isInWishlist ? 'purple' : '#fff'}
          />
        </i>
      </SlideTopBlock>
      <SliderWrapper>
        <div style={{ position: 'relative' }}>
          <Link to={`${hotel.urls.filterUrl}`} target="_blank">
            <CarouselAnt
              style={{ zIndex: '-1' }}
              arrows
              images={[
                ...coverPhoto.map(
                  ({ photo }) =>
                    `${process.env.REACT_APP_API_MEDIA_URL}${photo}`
                ),
              ]}
              // height="291px"
              title={title}
              location={location}
              badge="Вилла"
              favorite
            />
          </Link>
          <div
            style={{
              width: '100%',
              position: 'absolute',
              bottom: '0',
              // border: '1px solid red',
            }}
          >
            <Link to={`${hotel.urls.filterUrl}`} target="_blank">
              <SlideInfo>
                <Title size="18px" color="#fff" margin="0 0 10px 0">
                  {name}
                </Title>
                <SlideAdditionalInfo>
                  <i className="fas fa-map-marker-alt" />
                  {breadcrumbs.length > 0 &&
                    breadcrumbs.map(({ anchor }) => anchor).join(', ')}
                </SlideAdditionalInfo>
              </SlideInfo>
            </Link>
          </div>
        </div>
      </SliderWrapper>
      <Link to={`${hotel.urls.filterUrl}`} target="_blank">
        <CardInfo
          heightSm="96px"
          height="auto"
          paddingSm="10px 17px"
          padding="12px 25px"
        >
          <FeaturesContainer justifyBetween>
            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.guests',
              })} ${oPax}`}
            >
              <FeatureItem size="25px" line>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <UsersSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {oPax} ps
                </Title>
              </FeatureItem>
            </Tooltip>

            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.bedrooms',
              })} ${nbedrooms}`}
            >
              <FeatureItem size="25px" line>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <BedSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {nbedrooms} bd
                </Title>
              </FeatureItem>
            </Tooltip>

            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.bathrooms',
              })} ${hotel.nbathrooms}`}
            >
              <FeatureItem size="25px" line>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <BathroomSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {hotel.nbathrooms} ba
                </Title>
              </FeatureItem>
            </Tooltip>

            <DeviceBreakpoint tabletTo1100Hidden>
              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.living-space',
                })} ${livingSpace}`}
              >
                <FeatureItem size="25px" bricks>
                  <IconSvgContainer fillColor="#D7D6E0" size="24px">
                    <SquareSVG />
                  </IconSvgContainer>
                  <Title
                    size="14px"
                    margin="3px 0 0 0"
                    lineHeight="143%"
                    greyText
                  >
                    {livingSpace} m2
                  </Title>
                </FeatureItem>
              </Tooltip>
            </DeviceBreakpoint>
            <DeviceBreakpoint tabletTo1100Hidden>
              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.beach-distance',
                })} ${ndistance} m`}
              >
                <FeatureItem size="25px" bricks>
                  <IconSvgContainer fillColor="#D7D6E0" size="24px">
                    <BeachSVG />
                  </IconSvgContainer>
                  <Title
                    size="14px"
                    margin="3px 0 0 0"
                    lineHeight="143%"
                    greyText
                  >
                    {ndistance} m
                  </Title>
                </FeatureItem>
              </Tooltip>
            </DeviceBreakpoint>
          </FeaturesContainer>

          {/* <Divider margin="16px 0 0 0" /> */}

          <Row
            style={{
              borderTop: '1px solid #d7d6e0',
              marginTop: '7px',
              paddingTop: nights ? '12px' : '22px',
            }}
            justify="space-between"
            align="middle"
          >
            <RowCustom
              alignItems={nights ? 'center' : 'baseline'}
              height="22px"
              // style={{ display: 'none', opacity: 0 }}
            >
              <Stars
                style={{ marginTop: '-6px' }}
                count={1}
                defaultValue={1}
                disabled
              />
              <UserRate>{averageRating}</UserRate>
              {/* <TotalRate>(135)</TotalRate> */}
            </RowCustom>

            <Wrapper margin="0 0 0 0">
              {nights != 0 ? (
                <>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Price weight="500">{priceStr}€</Price>
                  </div>
                  <PriceInfo>
                    {localStorage.language === 'ru'
                      ? `${+nights} ${pluralize(
                          +nights,
                          'ночь',
                          'ночи',
                          'ночей'
                        )}`
                      : `${+nights} ${+nights === 1 ? 'night' : 'nights'}`}
                    , {Math.ceil(price / nights)}
                    €/
                    {intl.formatMessage({
                      id: 'card.day',
                    })}
                  </PriceInfo>
                </>
              ) : (
                <Price size="18px" sizeM="16px" weightM="normal">
                  {intl.formatMessage({
                    id: 'card.from',
                  })}{' '}
                  €{price}/
                  {intl.formatMessage({
                    id: 'card.day',
                  })}
                </Price>
              )}
            </Wrapper>
          </Row>
        </CardInfo>
      </Link>
    </CardWithMapContainer>
  );
};

CardPreviewWithMap.propTypes = {
  product: PropTypes.object, // TODO: isRequired,
};

export { CardPreviewWithMap };
