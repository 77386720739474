import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  CategoryBtnWrapper,
  CategoryBtnLink,
} from './CategoryCloudComponents.styles';

const CategoryCloud = () => {
  const intl = useIntl();
  const history = useHistory();
console.log("Location ", history.location.pathname)
  return (
    <CategoryBtnWrapper>
      {[
        {
          value: intl.formatMessage({
            id: 'cloud-btn.favourite',
          }),
          link: '/my/favourite',
        },
        {
          value: intl.formatMessage({
            id: 'cloud-btn.recommended',
          }),
          link: '/my/recommended',
        },
        {
          value: intl.formatMessage({
            id: 'cloud-btn.viewed',
          }),
          link: '/my/viewed',
        },
        // {
        //   value: intl.formatMessage({
        //     id: 'cloud-btn.popular',
        //   }),
        //   link: '/my/popular',
        // },
      ].map((filterBtn) => (
        <>
          <CategoryBtnLink
            to={`/${localStorage.language}${filterBtn.link}`}
            active={`/${localStorage.language}${filterBtn.link}` === history.location.pathname}
          >
            {filterBtn.value}
          </CategoryBtnLink>
        </>
      ))}
    </CategoryBtnWrapper>
  );
};

export { CategoryCloud };
