import styled from 'styled-components';

export const CardFlexGridWrapper = styled.div`
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding || '0'};
  width: ${({ width }) => width};
  display: ${({ display }) => display || 'flex'};
  grid-gap: ${({ gridGap }) => gridGap};
  grid-template: ${({ gridTemplate }) => gridTemplate};
  grid-auto-rows: ${({ gridAutoRows }) => gridAutoRows};
  grid-template-columns: ${({ gridColumns }) => gridColumns};
  justify-items: ${({ justifyItems }) => justifyItems};
  place-items: ${({ placeItems }) => placeItems};
  justify-content: ${({ justify }) => justify};
  flex-wrap: wrap;
  box-sizing: border-box;
  @media ${({ theme }) => theme.device.tablet} {
    margin: ${({ marginM }) => marginM};
    margin-right: 0;
  }

  @media ${({ theme }) => theme.device.desktop} {
    margin-right: 0px;
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin-right: 0px;
    padding: ${({ paddingTablet }) => paddingTablet};
  }
`;

export const CardWrapper = styled.div`
  margin: ${({ margin }) => margin};
  display: block;
  @media ${({ theme }) => theme.device.tablet} {
    margin: ${({ marginM }) => marginM};
    margin-right: 0;
  }

  @media ${({ theme }) => theme.device.desktop} {
    /* border: 1px solid red; */
    /* margin-right: -25px; */
    margin-right: 0px;
  }

  @media ${({ theme }) => theme.device.tablet} {
    /* border: 1px solid green; */
    margin-right: 0px;
  }
`;
