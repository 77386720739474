import styled from 'styled-components';

export const ProgressCapsule = styled.div`
  width: 100%;
  max-width: 175px;
  height: 5px;
  background: ${({ theme }) => theme.colors.borders.main};
  border-radius: 25px;

  @media ${({ theme }) => theme.device.laptop} {
    max-width: 100px;
  }

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 90px;
  }
  @media ${({ theme }) => theme.device.mobileL} {
    max-width: 175px;
  }

  @media ${({ theme }) => theme.device.mobileM} {
    max-width: 120px;
  }
`;

export const Progress = styled.div`
  width: ${({ progressValue }) => `${progressValue}%` || '0%'};
  height: 5px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 25px;
`;

export const ProgressWrapper = styled.div`
  margin: ${({ margin }) => margin};
`;
