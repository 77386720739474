import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import {
  CarouselAnt,
  Divider,
  Title,
  Favourite,
  TabPanel,
  Tab,
  ContainerCustom,
  Badge, IconSvgContainer,
} from 'components';

import { Wrapper } from 'components/Wrappers/Wrappers.styles';
import { products } from 'mockData';

import { useIntl } from 'react-intl';

import { ReactComponent as BeachSVG } from 'assets/icons/bigModal/beach.svg';
import { ReactComponent as BathroomSVG } from 'assets/icons/bigModal/bathroom.svg';
import { ReactComponent as BedSVG } from 'assets/icons/bigModal/bed.svg';
import { ReactComponent as UsersSVG } from 'assets/icons/bigModal/users.svg';
import { ReactComponent as SquareSVG } from 'assets/icons/bigModal/square.svg';

import { PageContainer } from 'components/PageContainer/PageContainer.styles';
import { TabGallery } from './Tabs/TabGallery';
import { TabComforts } from './Tabs/TabComforts';
import { TabCalendar } from './Tabs/TabCalendar';
import {
  CardContainerNotGrid,
  CardInfo,
  FeaturesContainer,
  FeatureItem,
  Price,
  PriceInfo,
  SlideTopBlock,
  SliderWrapper,
  SlideInfo,
  SlideAdditionalInfo,
  CarrouselPaddingWrapper,
  // ModalFavWrapper,
} from '../Card.styles';
import { TabAbout } from './Tabs/TabAbout';
import { BigModalInfo } from './BigModal/BigModalInfo';

const CardReserveNotGrid = ({ hotel, badgeColor }) => {
  const intl = useIntl();
  const { title, images, location } = products;
  const [isOpenControl, setIsOpenControl] = useState(true);

  const toggleBigModal = (setOpen) => {
    setOpen(false);
  };

  return (
    <CarrouselPaddingWrapper>
      <CardContainerNotGrid height="386px">
        <SliderWrapper height="221px">
          <SlideTopBlock justify="space-between">
            <Badge badgeColor={badgeColor}>
              {intl.formatMessage({
                id: 'hotel.villas-tab.for-you',
              })}
            </Badge>
            <i>
              <Favourite hotel={hotel} iconBg="transparent" />
            </i>
          </SlideTopBlock>
          <div style={{ position: 'relative' }}>
            <CarouselAnt
              height="221px"
              arrows
              images={images.covers}
              title={title}
              location={location}
              badge={intl.formatMessage({
                id: 'filters.accommodation.villa',
              })}
              favorite
            />
            <div
              style={{
                width: '100%',
                position: 'absolute',
                bottom: 0,
                border: '1px solid red',
              }}
            >
              <SlideInfo>
                <Title size="18px" color="#fff" margin="0 0 10px 0">
                  {title}
                </Title>
                <SlideAdditionalInfo>
                  <i className="fas fa-map-marker-alt" />
                  {`${location.country}, ${location.city}`}
                </SlideAdditionalInfo>
              </SlideInfo>
            </div>
          </div>
        </SliderWrapper>
        <CardInfo height="165px" padding="13px 20px 20px 20px">
          <FeaturesContainer justifyBetween>
            <FeatureItem size="25px" bricks>
              <IconSvgContainer fillColor="#D7D6E0" size="24px">
                <UsersSVG />
              </IconSvgContainer>
              <Title size="14px" margin="3px 0 0 0" lineHeight="143%" greyText>
                18 ps
              </Title>
            </FeatureItem>
            <FeatureItem size="25px" bricks>
              <IconSvgContainer fillColor="#D7D6E0" size="24px">
                <BedSVG />
              </IconSvgContainer>
              <Title size="14px" margin="3px 0 0 0" lineHeight="143%" greyText>
                {' '}
                2 bd
              </Title>
            </FeatureItem>
            <FeatureItem size="25px" bricks>
              <IconSvgContainer fillColor="#D7D6E0" size="24px">
                <BathroomSVG />
              </IconSvgContainer>
              <Title size="14px" margin="3px 0 0 0" lineHeight="143%" greyText>
                9 ba
              </Title>
            </FeatureItem>
            <FeatureItem size="25px" bricks>
              <IconSvgContainer fillColor="#D7D6E0" size="24px">
                <SquareSVG />
              </IconSvgContainer>
              <Title size="14px" margin="3px 0 0 0" lineHeight="143%" greyText>
                185 m2
              </Title>
            </FeatureItem>
            <FeatureItem size="25px" bricks>
              <IconSvgContainer fillColor="#D7D6E0" size="24px">
                <BeachSVG />
              </IconSvgContainer>
              <Title size="14px" margin="3px 0 0 0" lineHeight="143%" greyText>
                500 m
              </Title>
            </FeatureItem>
          </FeaturesContainer>

          <Divider margin="20px 0" />

          <Row justify="space-between" align="bottom">
            <BigModalInfo
              modalBtnOkText={intl.formatMessage({
                id: 'buttons.order',
              })}
              toggleBigModal={toggleBigModal}
            >
              <div>
                <PageContainer padding="24px 56px" paddingMD="24px">
                  <Row justify="space-between">
                    <Row>
                      <ContainerCustom>
                        <Title
                          size="36px"
                          sizeM="24px"
                          weight="500"
                          lineHeight="100%"
                          margin="0 20px 0 0"
                          ubuntu
                        >
                          Kerethium & Astarte
                        </Title>
                      </ContainerCustom>
                    </Row>
                    {/* <Favourite hotel ={hotel}/> */}
                  </Row>
                  <TabPanel margin="60px 0">
                    <Tab
                      tab={intl.formatMessage({
                        id: 'hotel.tab.about',
                      })}
                      key="1"
                    >
                      <TabAbout />
                    </Tab>

                    <Tab
                      tab={intl.formatMessage({
                        id: 'hotel.tab.gallery',
                      })}
                      key="4"
                    >
                      <TabGallery />
                    </Tab>
                    <Tab
                      tab={intl.formatMessage({
                        id: 'hotel.tab.services',
                      })}
                      key="5"
                    >
                      <TabComforts />
                    </Tab>
                    <Tab
                      tab={intl.formatMessage({
                        id: 'hotel.tab.calendar',
                      })}
                      key="7"
                    >
                      <TabCalendar />
                    </Tab>
                  </TabPanel>
                </PageContainer>
              </div>
            </BigModalInfo>

            <Wrapper margin="0 0 0 0">
              {/* <Discount>2,800€</Discount> */}
              <Price>2,400€</Price>
              <br />
              <PriceInfo>
                6
                {intl.formatMessage({
                  id: 'card.nights',
                })}
                ,
                {' '}
                {hotel.price}
                €/
                {intl.formatMessage({
                  id: 'card.day',
                })}
              </PriceInfo>
            </Wrapper>
          </Row>
        </CardInfo>
      </CardContainerNotGrid>
    </CarrouselPaddingWrapper>
  );
};

CardReserveNotGrid.propTypes = {
  product: PropTypes.object, // TODO: isRequired,
};

export { CardReserveNotGrid };
