import styled, { css } from 'styled-components';

export const Avatar = styled.div`
         width: ${({ size }) => size};
         height: ${({ size }) => size};
         display: ${({ display }) => display};
         background: url(${({ img }) => img});
         background-size: cover;
         background-color: #444;
         border: ${({ border }) => border};
         border-radius: ${({ rounded }) => rounded && '100%'};
         margin: ${({ margin }) => margin || '0'};
         position: ${({ position }) => position};
         top: ${({ top }) => top};

         
           ${({ theme, avatarCenter }) => avatarCenter
             && css`
               left: calc(50% - (115px / 2) + 9px);
             `}
       
       
           @media
           ${({ theme }) => theme.device.mobileM} {
           width: ${({ sizeM }) => sizeM};
           height: ${({ sizeM }) => sizeM};
           margin: ${({ marginM }) => marginM || '0'};
         }

         @media ${({ theme }) => theme.device.mobileS} {
           width: ${({ sizeS }) => sizeS};
           height: ${({ sizeS }) => sizeS};
         }
       `;

export const AvaShadow = styled.div`
         width: ${({ size }) => size};
         height: ${({ size }) => size};
         display: ${({ display }) => display};
         background: url(${({ img }) => img});
         background-size: cover;
         background-color: #444;
         border: 18px solid ${({ borderColor }) => borderColor || '#fff'};
         border-radius: ${({ rounded }) => rounded && '100%'};
         
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.8);
   box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.8);
         margin: ${({ margin }) => margin || '0'};
         position: ${({ position }) => position};
         top: ${({ top }) => top};
         z-index: 1;

         
           ${({ theme, avatarCenter }) => avatarCenter
             && css`
               left: calc(50% - (115px / 2) + 9px);
             `}
       
       
           @media
           ${({ theme }) => theme.device.mobileM} {
           width: ${({ sizeM }) => sizeM};
           height: ${({ sizeM }) => sizeM};
           margin: ${({ marginM }) => marginM || '0'};
         }

         @media ${({ theme }) => theme.device.mobileS} {
           width: ${({ sizeS }) => sizeS};
           height: ${({ sizeS }) => sizeS};
         }
       `;
