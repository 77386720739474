import styled from 'styled-components';

export const CardIcon = styled.div`
  width: 44px;
  height: 44px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -12px;
  right: -6px;
  border: 1px solid ${({ theme }) => theme.colors.borders.light};
  color: ${({ theme }) => theme.colors.borders.light};
  border-radius: 100%;
  transition: all ease-in-out 0.3s;
  path {
    fill: ${({ theme }) => theme.colors.borders.light};
  }
`;

export const CardOptionContainer = styled.div`
  /* display: block;
  margin: auto; */
  width: 190px;
  height: 150px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.colors.borders.light};
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &:hover ${CardIcon} {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    transition: all ease-in-out 0.3s;

    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;
