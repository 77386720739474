import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
      html {
        font-size: 10px;


        body {
          background: ${({ theme }) => theme.colors.bgBody};
          color: #fff;
          font-family: 'Rubik', sans-serif, Helvetica, Arial;
          margin: 0;
          padding: 0;
          overflow-x: hidden;

          .anticon svg {
            color: white;
          }

          .ant-select-dropdown {
            border-radius: 20px;
          }

          .ant-dropdown-menu {
            min-width: 80px;
            border-radius: 10px 0 10px 10px;
            margin-right: -7px;
            overflow: hidden;
          }

          .custom-message-class > div {
            width: fit-content;
            min-height: 80px;
            display: flex;
            margin: auto;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            border-radius: 7px;
            padding: 0 30px;
          }

          .custom-message-class svg{
            color: #943FA1;
            width: 20px;
            height: 20px;
          }

          .ant-dropdown-trigger .anticon-down svg {
            fill: #353535;
          }

          .ant-picker-separator {
            display: none;
          }

          .ant-picker-range-separator {
            position: relative;
            color: transparent;

          }
          .ant-picker-range-separator:after {

            color: white;
          content: "-";
        }

        .ant-checkbox-wrapper:hover .ant-checkbox::after {
    visibility: visible;
    border: none;
}

.ant-checkbox-checked::after {
  border: transparent,
}

.anticon-close-circle svg {
  color: inherit;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #943FA1;
}

.ant-picker-range .ant-picker-clear {
    right: 1px;
    background-color: transparent
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #943fa1;
    border-radius: 2px;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
  background-color: rgb(237, 199, 243);
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner {
  color: #ffffff;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  background-color: rgb(237, 199, 243);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::after {
  background-color: rgb(237, 199, 243);
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
    background-color: rgb(237, 199, 243);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #edc7f3;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background-color: rgb(237, 199, 243);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #943fa1;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    position: absolute;
    top: 50%;
    z-index: 0;
    height: 24px;
    border-top: 1px dashed #943fa1;
    border-bottom: 1px dashed #943fa1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: '';
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after, tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    left: 6px;
    border-left: 1px dashed #943fa1;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after, tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    right: 6px;
    border-right: 1px dashed #943fa1;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}



.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff;
    font-weight: 600;
    background-color: #b853c7;
}



        .ant-select-focused .ant-select-selector,
        .ant-select-selector:focus,
        .ant-select-selector:active,
        .ant-select-open .ant-select-selector {
          border-color: #d9d9d9 !important;
          box-shadow: none !important;
          /* padding-left: 0 !important; */
        }

        .ant-select-selection-item {
            padding: 0 !important;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
        }


        .ant-collapse
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-arrow {
          transform: rotate(90deg);
        }

      .ant-collapse-item-active {
        span[role='img'] {
          svg {
            transform: rotate(180deg) !important;
          }
        }
      }

      .ant-pagination-item {
        font-family: "Rubik", Arial, sans-serif;
        border-radius: 5px;
      }

      .ant-pagination-item-active {
              font-weight: 500;
              background: #fff;
              border-color: #CF3AA0;
              border-radius: 7px;

              &:hover{
                border-color: #CF3AA0;
              }

          }
      .ant-pagination-item-active a {
        color: #CF3AA0;
          &:hover{
            color: #CF3AA0;
          }
      }

      .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
        border-radius: 7px;
      }

      .ant-pagination-item-link {
        border-radius: 7px;
      }

      .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
        color: #CF3AA0;
        border-color: #CF3AA0;
      }


      .ant-pagination-item:focus, .ant-pagination-item:hover {
          border-color: #CF3AA0;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
      }

      .ant-pagination-item:focus a, .ant-pagination-item:hover a {
          color: #CF3AA0;

      }

        .lightText {
            i {

            color: red !important;
            }
        }

        &::-webkit-scrollbar {
          width: 14px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
          transition: all ease-in-out 0.3s;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #772584;
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          cursor: grab;
          background: #999;
        }



        }

      }

      @media ${({ theme }) => theme.device.mobileS} {
        html {
          font-size: 8px;
        }
      }

      @media (max-width: 770px) {
        }

        .ant-picker-panel-container .ant-picker-panels {
          display: inline-flex;
            -ms-flex-wrap: nowrap;
            flex-wrap: wrap;
            direction: ltr;
            flex-direction: column;
            background-color: #ffffff
        }

        .ant-picker-dropdown {
            height: 63vh;
            overflow-y: scroll;
            width: 100%;
            font-size: 18px;

            &::-webkit-scrollbar {
          width: 14px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
          transition: all ease-in-out 0.3s;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #772584;
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          cursor: grab;
          background: #999;
        }
        }

        .ant-picker-panel-container {
          margin: 0 auto !important;
          width: 90%;
          background-color: transparent;
          box-shadow: none;
        }

        .ant-picker-date-panel .ant-picker-content,
        .ant-picker-date-panel .ant-picker-body,
        .ant-picker-date-panel,
        .ant-picker-panel-container .ant-picker-panel,
        .ant-picker-panel-container .ant-picker-panels {
            width: 100%;
        }

      }
`;

export default GlobalStyle;
