import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Row, Tooltip } from 'antd';
import { Helmet } from 'react-helmet';
import {
  Stars,
  CarouselAnt,
  Title,
  Text,
  Favourite,
  Badge,
  DeviceBreakpoint,
  RowCustom,
} from 'components';

import {
  PeopleSVG,
  BathSVG,
  BedSVG,
  SquareMetersSVG,
  BeachSVG,
} from 'lib/icons';

import { Wrapper } from 'components/Wrappers/Wrappers.styles';
import { products } from 'mockData';
import {
  CardContainer,
  CardInfo,
  FeaturesContainer,
  FeatureItem,
  Price,
  SlideTopBlock,
  SliderWrapper,
  SlideInfo,
  SlideAdditionalInfo,
  UserRate,
  TotalRate,
  ImgWrapper,
} from './Card.styles';
import { ContainerCustom } from 'components/Grid/ContainerCustom.styles';
import MockImage from 'assets/mockImage.png';

const CardViewedDescr = ({
  hotel,
  arrow = true,
  isMobile = false,
  setFavoriteVillas,
  favoriteVillas,
}) => {
  const intl = useIntl();
  const { title, images, location } = products;

  React.useEffect(() => {
    console.log('ACTION hotel Viewed: ', hotel);
  }, [hotel]);

  const {
    id,
    coverPhoto,
    name,
    oPax,
    livingSpace,
    breadcrumbs,
    nbedrooms,
    nbathrooms,
    ndistance,
    averageRating,
    shortDescription,
    urls,
  } = hotel;

  const setDescriptionLength = (text) => {
    if (text.length < 100) {
      return text;
    }
    const shortDescr = text.slice(0, 100);
    return `${shortDescr}...`;
  };

  return (
    <CardContainer
      width="32.2%"
      width1920="28.7vw"
      widthM="100%"
      widthL1100="100%"
      marginBottomlaptop="0"
      marginRight3n2100="0"
    >
      <Helmet>
        <link rel="canonical" href={urls.canonicalUrl} />
      </Helmet>
      <SliderWrapper>
        <SlideTopBlock justify="space-between">
          <Badge style={{ opacity: 0 }}>
            {intl.formatMessage({
              id: 'card.villa',
            })}
          </Badge>
          <i>
            <Favourite
              setFavoriteVillas={setFavoriteVillas}
              favoriteVillas={favoriteVillas}
              hotel={hotel}
              isFav={hotel.isInWishlist ? true : false}
            />
          </i>
        </SlideTopBlock>
        <ImgWrapper>
          <Link to={`${hotel.urls.filterUrl}`} target="_blank">
            {!isMobile ? (
              <CarouselAnt
                arrows={arrow}
                images={[
                  ...coverPhoto.map(
                    ({ photo }) => `https://aktis.villas/media/${photo}`
                  ),
                ]}
                heightM="255px"
                title={title}
                location={location}
                badge="Вилла"
                favorite
              />
            ) : (
              <img
                loading="lazy"
                width="103%"
                height="auto"
                src={`${process.env.REACT_APP_API_MEDIA_URL}${coverPhoto[0].photo}`}
                alt="productImage"
                // style={{ position: 'absolute', top: '118px' }}
              />
            )}
          </Link>
          <ContainerCustom
            width="100%"
            position="absolute"
            bottom="0"
            bottom1100="0"
          >
            <Link to={`${hotel.urls.filterUrl}`} target="_blank">
              <SlideInfo bottomTablet="116px">
                <Title size="18px" weight="500" color="#fff" margin="0 0 5px 0">
                  {hotel.name}
                </Title>
                <SlideAdditionalInfo>
                  <i className="fas fa-map-marker-alt" />
                  {breadcrumbs.length > 0 &&
                    breadcrumbs.map(({ anchor }) => anchor).join(', ')}
                </SlideAdditionalInfo>
              </SlideInfo>
            </Link>
          </ContainerCustom>
        </ImgWrapper>
      </SliderWrapper>
      <Link to={`${hotel.urls.filterUrl}`} target="_blank">
        <CardInfo paddingSm="15px 11px">
          <DeviceBreakpoint
            tabletFrom1100Hidden
            style={{ border: '0px solid green' }}
          >
            <FeaturesContainer justifyBetween margin="0 0 15px 0">
              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.guests',
                })} ${oPax}`}
              >
                <FeatureItem oneline>
                  <PeopleSVG />
                  <Title size="14px" lineHeight="13px" mutedText>
                    {hotel.oPax}{' '}
                    {intl.formatMessage({
                      id: 'card.guests-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.bedrooms',
                })} ${hotel.nbedrooms}`}
              >
                <FeatureItem oneline>
                  <BedSVG />
                  <Title size="14px" lineHeight="13px" mutedText>
                    {hotel.nbedrooms}{' '}
                    {intl.formatMessage({
                      id: 'card.bedrooms-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.bathrooms',
                })} ${hotel.nbathrooms}`}
              >
                <FeatureItem oneline>
                  <BathSVG />
                  <Title size="14px" lineHeight="13px" mutedText>
                    {nbathrooms}{' '}
                    {intl.formatMessage({
                      id: 'card.bathrooms-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>
            </FeaturesContainer>
          </DeviceBreakpoint>

          <DeviceBreakpoint tabletTo1100Hidden>
            <FeaturesContainer justifyBetween margin="0 0 15px 0">
              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.guests',
                })} ${oPax}`}
              >
                <FeatureItem bricks>
                  <PeopleSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    lineHeight="13px"
                    mutedText
                  >
                    {hotel.oPax}{' '}
                    {intl.formatMessage({
                      id: 'card.guests-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.bedrooms',
                })} ${hotel.nbedrooms}`}
              >
                <FeatureItem bricks>
                  <BedSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    lineHeight="13px"
                    mutedText
                  >
                    {nbedrooms}{' '}
                    {intl.formatMessage({
                      id: 'card.bedrooms-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.bathrooms',
                })} ${hotel.nbathrooms}`}
              >
                <FeatureItem bricks>
                  <BathSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    sizeM="16px"
                    lineHeight="13px"
                    mutedText
                  >
                    {nbathrooms}{' '}
                    {intl.formatMessage({
                      id: 'card.bathrooms-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.living-space',
                })} ${livingSpace}`}
              >
                <FeatureItem bricks>
                  <SquareMetersSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    sizeM="16px"
                    lineHeight="13px"
                    mutedText
                  >
                    {livingSpace} m2
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.beach-distance',
                })} ${ndistance}m`}
              >
                <FeatureItem bricks>
                  <BeachSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    sizeM="16px"
                    lineHeight="13px"
                    mutedText
                  >
                    {ndistance} m
                  </Title>
                </FeatureItem>
              </Tooltip>
            </FeaturesContainer>
          </DeviceBreakpoint>

          <div style={{ maxHeight: '82px', overflow: 'hidden' }}>
            <Text color="#9791A7">
              {setDescriptionLength(shortDescription)}
            </Text>
          </div>

          <RowCustom alignItems="baseline" justify="space-between">
            <RowCustom
              alignItems="baseline"
              height="22px"
              // style={{ display: 'none', opacity: 0 }}
            >
              <Stars count={1} defaultValue={1} disabled />
              <UserRate>{averageRating}</UserRate>
              {/* <TotalRate>(135)</TotalRate> */}
            </RowCustom>

            <Wrapper margin="10px 0 0 0">
              <Price size="18px" sizeM="16px" weightM="normal">
                {intl.formatMessage({
                  id: 'card.from',
                })}{' '}
                {hotel.priceStr}€ /
                {intl.formatMessage({
                  id: 'card.day',
                })}
              </Price>
            </Wrapper>
          </RowCustom>
        </CardInfo>
      </Link>
    </CardContainer>
  );
};

export { CardViewedDescr };
