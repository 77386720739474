import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import ImageOne from 'assets/mock/111.png'
import ImageTwo from 'assets/mock/222.png'
import ImageThree from 'assets/mock/333.png'

import { Title } from 'components';
import {
  GalleryGridWrapper,
  MasonryWrapper,
  MasonryItemOne,
  MasonryItemTwo,
  MasonryItemThree,
  MasonryItemFour,
  MasonryItemFive,
  MasonryOverlay,
  MasonryOverlayLast,
} from './MasonryLightbox.styles';

const MasonryLightbox = ({ lightboxController, images = [] }) => {
  const [stateLightbox, setStateLightbox] = useState({
    photoIndex: 0,
    isOpen: false,
  });

  const { photoIndex, isOpen } = stateLightbox;
  return (
    <>
      {/* <button
        type="button"
        onClick={() => setStateLightbox({ ...stateLightbox, isOpen: true })}
      >
        Open Lightbox
      </button> */}
      <GalleryGridWrapper>
        <MasonryWrapper>
          <MasonryItemOne
            onClick={() => setStateLightbox({
              ...stateLightbox,
              isOpen: true,
              photoIndex: 0,
            })}
            bg={images.length > 0 ? images[0] : null}
          >
            <MasonryOverlay />
          </MasonryItemOne>
          <MasonryItemTwo
            onClick={() => setStateLightbox({
              ...stateLightbox,
              isOpen: true,
              photoIndex: 1,
            })}
            bg={images.length > 1 ? images[1] : null}
          >
            <MasonryOverlay />
          </MasonryItemTwo>
          <MasonryItemThree
            onClick={() => setStateLightbox({
              ...stateLightbox,
              isOpen: true,
              photoIndex: 2,
            })}
            bg={images.length > 2 ? images[2] : null}
          >
            <MasonryOverlay />
          </MasonryItemThree>
          <MasonryItemFour
            onClick={() => setStateLightbox({
              ...stateLightbox,
              isOpen: true,
              photoIndex: 3,
            })}
            bg={images.length > 3 ? images[3] : null}
          >
            <MasonryOverlay />
          </MasonryItemFour>
          <MasonryItemFive
            onClick={() => setStateLightbox({
              ...stateLightbox,
              isOpen: true,
              photoIndex: 4,
            })}
            bg={images.length > 4 ? images[4] : null}
          >
            <MasonryOverlayLast>
              <Title size="24px" color="#F7F4F8">
                {`+ ${images.length}`}
              </Title>
            </MasonryOverlayLast>
          </MasonryItemFive>
        </MasonryWrapper>
        {/* <FbImageLibrary images={images} /> */}
      </GalleryGridWrapper>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setStateLightbox({ ...stateLightbox, isOpen: false })}
          onMovePrevRequest={() => setStateLightbox({
            ...stateLightbox,
            photoIndex: (photoIndex + images.length - 1) % images.length,
          })}
          onMoveNextRequest={() => setStateLightbox({
            ...stateLightbox,
            photoIndex: (photoIndex + 1) % images.length,
          })}
        />
      )}
    </>
  );
};

export { MasonryLightbox };
