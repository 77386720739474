import React from 'react';
import { TabWrapper } from 'components';
import { GalleryWithFilter } from '../GalleryWithFilter/GalleryWithFilter';

const TabGallery = () => {
  const images = [
    {
      type: 'гостинная',
      src:
        'https://q-cf.bstatic.com/images/hotel/max1024x768/145/145832015.jpg',
    },
    {
      type: 'гостинная',
      src:
        'https://cdn.rusticaltravel.com//images/cache2/Villas_in_spain_with_pools_small.jpg_540_380_cf_q100_co-1.jpg',
    },
    {
      type: 'Спальня',
      src:
        'https://tabairarealestate.com/media/images/properties/thumbnails/o_1cggkfceo1s0mq7ireblsjrj71c_w_1300x650.jpg',
    },
    {
      type: 'гостинная',
      src:
        'https://q-cf.bstatic.com/images/hotel/max1024x768/145/145832015.jpg',
    },
    {
      type: 'Кухня',
      src:
        'https://media-cdn.tripadvisor.com/media/vr-splice-j/09/e7/7c/ad.jpg',
    },
    {
      type: 'гостинная',
      src:
        'https://tabairarealestate.com/media/images/properties/thumbnails/o_1cggkfceo1s0mq7ireblsjrj71c_w_1300x650.jpg',
    },
    {
      type: 'санузел',
      src:
        'https://q-cf.bstatic.com/images/hotel/max1024x768/145/145832015.jpg',
    },
    {
      type: 'гостинная',
      src:
        'https://media-cdn.tripadvisor.com/media/vr-splice-j/09/e7/7c/ad.jpg',
    },
    {
      type: 'гостинная',
      src:
        'https://tabairarealestate.com/media/images/properties/thumbnails/o_1cggkfceo1s0mq7ireblsjrj71c_w_1300x650.jpg',
    },
  ];

  return (
    <TabWrapper margin="50px 0 0 0">
      <GalleryWithFilter images={images} />
    </TabWrapper>
  );
};

export { TabGallery };
