import styled from 'styled-components';

export const CalendarContainer = styled.div`
  &&& {
    .DayPickerKeyboardShortcuts_show__topRight {
      opacity: 0;
    }
    .CalendarDay__default {
      border: none;
      color: #353535;

      &:hover {
        border-radius: 13px;
      }
    }

    .DayPicker__withBorder {
      box-shadow: none;
    }

    .CalendarMonth_table {
      width: 100%;
    }

    .DayPicker_weekHeaders {
      
    }

    .DayPicker_weekHeader__verticalScrollable {
      border-bottom: none;
      /* position: relative; */
      z-index: 10000001;

      &::after {
        content: '';
        display: inline-block;
        height: 25px;
        border-bottom: 1px solid #d7d6e0;
        position: absolute;
        left: -20px;
        right: -20px;
        top: 18px;
        z-index: -1;
      }
    }

    .CalendarDay__hovered_span,
    .CalendarDay__hovered_span:hover {
      background-color: #efe2f1;
      border-radius: 0;
    }

    .CalendarMonth_caption {
      text-transform: capitalize;

      strong {
        font-weight: normal;
      }
    }

    .CalendarMonthGrid__vertical,
    .CalendarMonthGrid__vertical_scrollable {
      width: 100% !important;
      font-size: 16px;
    }

    .DayPickerNavigation__verticalScrollableDefault {
      display: none;
    }

    .CalendarDay {
      font-size: 16px;
    }

    .DayPicker_weekHeader_ul small {
      font-size: 14px;
      color: #9791a7;
      text-transform: capitalize;
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: #943fa1;
      border-radius: 13px;
    }

    .CalendarDay__selected {
      position: relative;
      z-index: 10;
      background-color: black;
      color: #ffffff;

      &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #efe2f1;
        border-radius: 13px 0 0 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }

      &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #943fa1;
        border-radius: 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .CalendarDay__selected_start,
    .CalendarDay__selected_start_3,
    .CalendarDay__selected_4,
    .CalendarDay__selected_start_in_hovered_span,
    .CalendarDay__selected_start_in_hovered_span_5 {
      &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #efe2f1;
        border-radius: 13px 0 0 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }

      &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #943fa1;
        border-radius: 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .CalendarDay__selected_end,
    .CalendarDay__selected_end_3 {
      &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #efe2f1;
        border-radius: 0 13px 13px 0;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }

      &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #943fa1;
        border-radius: 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .CalendarDay__selected_span {
      color: #353535;
      background: #efe2f1;
      height: 32px;
      color: #353535;
    }

    .DayPicker_weekHeader_ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 40px;
    }

    @media (min-width: 610px) {
      .CalendarDay {
        font-size: 22px;
        height: 44px !important;
      }

      .CalendarMonth_caption {
        text-transform: capitalize;

        strong {
          font-weight: normal;
          font-size: 22px;
        }
      }
    }
  }
`;

export const CalendarHorizontalContainer = styled.div`
  &&& {
    /* .DayPickerKeyboardShortcuts_show__topRight {
      opacity: 0;
    }
    .CalendarDay__default {
      border: none;
      color: #353535;

      &:hover {
        border-radius: 13px;
      }
    } */

    /* .DayPicker__withBorder {
      box-shadow: none;
    } */

    /* .CalendarMonth_table {
      width: 100%;
    } */

    .CalendarDay__hovered_span,
    .CalendarDay__hovered_span:hover {
      background-color: #efe2f1;
      border-radius: 0;
    }

    .CalendarMonth_caption {
      text-transform: capitalize;

      strong {
        font-weight: normal;
      }
    }

    /* .CalendarMonthGrid__vertical,
    .CalendarMonthGrid__vertical_scrollable {
      width: 100% !important;
      font-size: 16px;
    } */

    /* .DayPickerNavigation__verticalScrollableDefault {
      display: none;
    } */

    .CalendarDay {
      font-size: 16px;
    }

    .DayPicker_weekHeader_ul small {
      font-size: 14px;
      color: #9791a7;
      text-transform: capitalize;
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: #943fa1;
      border-radius: 13px;
    }

    .CalendarDay__selected {
      position: relative;
      z-index: 10;
      background-color: black;
      color: #ffffff;

      &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #efe2f1;
        border-radius: 13px 0 0 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }

      &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #943fa1;
        border-radius: 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .CalendarDay__selected_start,
    .CalendarDay__selected_start_3,
    .CalendarDay__selected_4,
    .CalendarDay__selected_start_in_hovered_span,
    .CalendarDay__selected_start_in_hovered_span_5 {
      &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #efe2f1;
        border-radius: 13px 0 0 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }

      &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #943fa1;
        border-radius: 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .CalendarDay__selected_end,
    .CalendarDay__selected_end_3 {
      &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #efe2f1;
        border-radius: 0 13px 13px 0;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }

      &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #943fa1;
        border-radius: 13px;

        position: absolute;
        right: 0;
        z-index: -1;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .CalendarDay__selected_span {
      color: #353535;
      background: #efe2f1;
      height: 32px;
      color: #353535;
    }

    .DayPicker_weekHeader_ul {
      display: flex;
      justify-content: space-around;
    }
  }
`;
