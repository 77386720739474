import styled from 'styled-components';

export const PageContainer = styled.div`
  min-height: ${({ minHeight }) => minHeight || '100vh'};
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ width }) => width};
  background-color: ${({ theme, bg }) => bg || theme.colors.bgColor};
  padding: ${({ padding }) => padding || '50px 80px'};
  margin: ${({ margin }) => margin || '0'};
  box-sizing: ${({ boxSizing }) => boxSizing};

  position: ${({ position }) => position};
  top: ${({ top }) => top};
  z-index: ${({ zIndex }) => zIndex};

  // @media only screen and (min-width: 600px) {
  //   padding: 0 25px;
  // }

  /* overflow: hidden; */
  @media ${({ theme }) => theme.device.laptop1100} {
    padding: ${({ padding1100 }) => padding1100};
    max-width: ${({ width1100 }) => width1100};
    width: ${({ width1100 }) => width1100};
    margin: ${({ margin1100 }) => margin1100};
  }

  @media ${({ theme }) => theme.device.laptop} {
    max-width: ${({ maxWidthLaptop }) => maxWidthLaptop};
    margin: ${({ marginSm }) => marginSm || '0px'};
    padding: ${({ paddingLaptop }) => paddingLaptop};
  }

  @media ${({ theme }) => theme.device.tabletSmall} {
    max-width: ${({ width750 }) => width750};
    margin: ${({ marginSm }) => marginSm || '0px'};
    padding: ${({ paddingMD }) => paddingMD};
  }

  @media ${({ theme }) => theme.device.tablet} {
    max-width: ${({ maxWidthSm }) => maxWidthSm};
  }

  @media ${({ theme }) => theme.device.mobileL} {
    max-width: ${({ maxWidthSm }) => maxWidthSm};
    padding: ${({ paddingMD }) => paddingMD};
    padding: ${({ paddingM }) => paddingM};
  }

  @media ${({ theme }) => theme.device.mobileM} {
    margin: ${({ marginSm }) => marginSm || '0px'};
    padding: ${({ paddingMD }) => paddingMD};
    padding: ${({ paddingM }) => paddingM};
  }

  @media ${({ theme }) => theme.device.mobileS} {
    margin: 5% 0px;
    padding: ${({ paddingMD }) => paddingMD};
    padding: ${({ paddingM }) => paddingM};
  }

  @media ${({ theme }) => theme.deviceMin.desktop1890} {
    padding: ${({ paddingFrom1890 }) => paddingFrom1890};
  }
`;
