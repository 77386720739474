import styled from 'styled-components';

export const FilterBarWrapper = styled.div`
  margin-right: -22px;
  display: flex;
  z-index: 9999;
  padding: ${({ padding }) => padding || '50px 80px 0 80px'};

  overflow-x: scroll;
  z-index: -1;
  &::-webkit-scrollbar {
    width: 20px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    box-shadow: none;
    border-radius: 10px;
    transition: all ease-in-out 0.3s;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    cursor: grab;
    background: #999;
    /* background: none; */
  }

  @media ${({ theme }) => theme.device.laptop} {
    display: none;
  }
`;

export const SliderWrapper = styled.div`
  width: 250px;
  padding: 0 20px;
`;

export const FilterWrapper = styled.div`
  /* display: flex; */
  flex-grow: 1;
  padding: 0 20px;
  border-left: 1px solid ${({ theme }) => theme.colors.borders.main};
`;

// export const SelectCheckboxGroupStyled = styled.div`
//   &&& {
//     .ant-select .ant-select-multiple .ant-select-open .ant-select-show-search {
//       border: 1px solid red;
//     }
//   }
// `;

export const FilterBarHideScrollContainer = styled.div`
  overflow: hidden;
`;
