import styled from 'styled-components';

export const MobileFilterWrapper = styled.div`
  @media ${({ theme }) => theme.deviceMin.laptop} {
    display: none;
  }
`;

export const FilterBarWrapper = styled.div`
  position: absolute;
  padding: 15px;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  margin-left: ${({ isActive }) => (isActive ? '0' : '-100%')};
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  z-index: 9000;
  background: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @media ${({ theme }) => theme.deviceMin.laptop} {
    display: none;
  }
`;

export const SliderWrapper = styled.div`
  width: 250px;
  padding: 0;

  @media ${({ theme }) => theme.device.tablet} {
    /* display: flex;
    justify-content: center; */
    margin: 40px auto;
    padding: 0;
  }
`;
export const FilterWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 0 20px;
  border-left: 1px solid ${({ theme }) => theme.colors.borders.main};
  /* position: absolute; */
`;

export const FilterSidebarWrapper = styled.div``;
