import React, { useState } from 'react';
import { ModalWindow, Title } from 'components';
import { HeartSVG, HeartSolidBgSVG } from 'lib/icons';
import { ReactComponent as FavIcon } from 'assets/FavIcon.svg';

import { products } from 'mockData';
import {
  AdditionalInfo,
  ModalFavWrapper,
  HeartSVGStyled,
} from './Favourite.styles';
import { useMutation } from '@apollo/react-hooks';
import {
  ADD_WISHLIST_HOTELS,
  REMOVE_WISHLIST_HOTELS,
} from 'graphql/mutations/wishlist';

const FavouriteWithoutBackground = ({
  hotel,
  iconColor,
  iconBg,
  defaultFav = false,
}) => {
  const { id, name, coverPhoto, isInWishlist } = hotel;
  console.log('asdfasd', hotel);
  const { location } = products;
  const isAuth = false;
  const [isFav, setFav] = useState(defaultFav);

  const [
    addWishList,
    { data: dataWishList, error: errorWishList },
  ] = useMutation(ADD_WISHLIST_HOTELS, {
    variables: {
      propertyId: id,
    },
    onCompleted() {
      setFav(true);
    },
  });

  const [
    removeWishList,
    { data: dataRemoveWishList, error: errorRemoveWishList },
  ] = useMutation(REMOVE_WISHLIST_HOTELS, {
    variables: {
      propertyId: id,
    },
    onCompleted() {
      setFav(false);
    },
  });

  //Remove or add villas to wish list
  const toggleAddWish = () => {
    if (isFav) {
      removeWishList({
        variables: {
          propertyId: id,
        },
      });
    } else {
      addWishList({
        variables: {
          propertyId: id,
        },
      });
    }
  };

  return isAuth ? (
    <ModalWindow
      modalBtnOkText="Создать"
      modalBtnCancelText="Отмена"
      modalTitle="Добавить в избранное"
      iconBtn={
        <HeartSVGStyled
          color="#943FA1"
          width="28px"
          height="24px"
          iconBg="transparent"
        >
          {/* hotel.isInWishlist */}
          {isFav || isInWishlist ? <HeartSolidBgSVG /> : <FavIcon />}
        </HeartSVGStyled>
      }
    >
      <ModalFavWrapper>
        <img
          loading="lazy"
          width="300"
          height="170"
          src={
            Array.isArray(coverPhoto)
              ? `${process.env.REACT_APP_API_MEDIA_URL}${coverPhoto[0].photo}`
              : `${process.env.REACT_APP_API_MEDIA_URL}${coverPhoto}`
          }
          alt=""
        />
        <Title size="18px" color="#000" margin="10px 0">
          {name}
        </Title>
        <AdditionalInfo>
          <i className="fas fa-map-marker-alt" />
          {`${location.country}, ${location.city}`}
        </AdditionalInfo>
      </ModalFavWrapper>
    </ModalWindow>
  ) : (
    <ModalWindow
      modalBtnOkText={isFav ? 'Удалить' : 'Добавить'}
      modalBtnCancelText="Отмена"
      modalTitle="Добавить в избранное"
      iconBtn={
        // <Icon src={HeartSVG} width="40px" />
        <HeartSVGStyled
          color="#943FA1"
          widthM="18px"
          heightM="15px"
          width="28px"
          height="24px"
          iconBg="transparent"
        >
          {/* <HeartSVG />
           */}
          {isFav || isInWishlist ? <HeartSolidBgSVG /> : <FavIcon />}
        </HeartSVGStyled>
      }
      iconColor={iconColor}
      btnOk={toggleAddWish}
    >
      <ModalFavWrapper>
        <img
          loading="lazy"
          width="300"
          height="170"
          src={
            Array.isArray(coverPhoto)
              ? `${process.env.REACT_APP_API_MEDIA_URL}${coverPhoto[0].photo}`
              : `${process.env.REACT_APP_API_MEDIA_URL}${coverPhoto}`
          }
          alt=""
        />
        <Title size="18px" color="#000" margin="10px 0">
          {name}
        </Title>
        <AdditionalInfo>
          <i className="fas fa-map-marker-alt" />
          {/* {`${location.country}, ${location.city}`} */}

          {hotel.breadcrumbs.length > 0 &&
            hotel.breadcrumbs.map(({ anchor }) => anchor).join(', ')}
        </AdditionalInfo>
      </ModalFavWrapper>
    </ModalWindow>
  );
};

export { FavouriteWithoutBackground };
