export const products = {
  id: 1,
  title: 'Kerethium & Astarte',
  type: 'Вилла',
  images: {
    covers: [
      'https://villas-dream.com/wp-content/uploads/2017/07/villas-2.jpg',
      'https://static.homesoverseas.ru/i/726x485/pic/obj/1/3/3/4/13340606.jpg',
      'https://www.amaryllistour.ro/uploads/images/danube-delta-river-green-village.jpg-1.jpg',
    ],
    roomImages: [
      'https://villas-dream.com/wp-content/uploads/2017/07/villas-2.jpg',
      'https://static.homesoverseas.ru/i/726x485/pic/obj/1/3/3/4/13340606.jpg',
      'https://www.amaryllistour.ro/uploads/images/danube-delta-river-green-village.jpg-1.jpg',
    ],
  },
  score: 4.5,
  location: { country: 'France', city: 'Rouen' },
  price: '2,400',
  features: {
    people: 18,
    bed: 4,
    bath: 9,
    sqm: 185,
    beach: 500,
  },
};

export const product = {
  id: 1,
  title: 'Kerethium & Astarte',
  type: 'Вилла',
  images: {
    covers: [
      'https://villas-dream.com/wp-content/uploads/2017/07/villas-2.jpg',
      'https://static.homesoverseas.ru/i/726x485/pic/obj/1/3/3/4/13340606.jpg',
      'https://www.amaryllistour.ro/uploads/images/danube-delta-river-green-village.jpg-1.jpg',
    ],
    roomImages: [
      'https://villas-dream.com/wp-content/uploads/2017/07/villas-2.jpg',
      'https://static.homesoverseas.ru/i/726x485/pic/obj/1/3/3/4/13340606.jpg',
      'https://www.amaryllistour.ro/uploads/images/danube-delta-river-green-village.jpg-1.jpg',
    ],
  },
  score: 4.5,
  location: { country: 'France', city: 'Rouen' },
  price: '2,400',
  features: {
    main: {
      people: 18,
      bed: 4,
      bath: 9,
      sqm: 185,
      beach: 500,
    },
    extended: [
      {
        title: 'wifi',
        isAvailable: true,
      },
      {
        title: 'tv',
        isAvailable: false,
      },
    ],
  },

  description:
    'Равным образом рамки и место обучения кадров влечет за собой процесс внедрения и модернизации системы обучения кадров, соответствует насущным потребностям. Таким образом реализация намеченных плановых заданий позволяет оценить значение новых предложений.Повседневная практика показывает, что реализация намеченных плановых заданий в значительной степени обуславливает создание модели развития. Равным образом консультация с широким активом требуют определения и уточнения модели развития. Равным образом рамки и место обучения кадров влечет за собой процесс внедрения и модернизации системы обучения кадров, соответствует насущным потребностям.',
};
