import React from 'react';
import styled from 'styled-components';
import { Icon, Title } from 'components';

const AlertStyles = styled.div`
  height: ${({ height }) => height};
  background: ${({ bg }) => bg};
  position: absolute;
  top: -100%;
  padding: ${({ padding }) => padding};
  border-radius: 10px;
  display: flex;
  align-items: center;

  @media ${({ theme }) => theme.device.laptop} {
    top: ${({ topTablet }) => topTablet};
  }
`;

const Alert = ({
  bg, text, icon, height, padding, topTablet,
}) => (
  <AlertStyles
    bg={bg}
    height={height}
    padding={padding}
    topTablet={topTablet}
  >
    <Icon src={icon} alt="" margin="0 10px 0 0" />
    <Title size="16px" lineHeight="140%" margin="0 40px 0 0" color="white">
      {' '}
      {text}
    </Title>
  </AlertStyles>
);

export { Alert };
