import styled from 'styled-components';
import { Carousel } from 'antd';

export const CarouselAntStyled = styled(Carousel)`
  position: relative;

  img {
    position: relative;
    width: 100%;
    height: ${({ height }) => height || '20.6vw'};
    max-height: 450px;
    object-fit: cover;
    /* min-height: 230px; */
  }

  .slick-dots li.slick-active button:before {
    opacity: 0;
  }
  li.slick-active button:before {
    opacity: 0;
  }

  .slick-dots {
    margin: 0;
  }

  .slick-list::after {
    content: '';
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(55, 52, 61, 0.6) 0%,
      rgba(55, 52, 61, 0.3) 10.19%,
      rgba(55, 52, 61, 0) 20.66%,
      rgba(55, 52, 61, 0) 58.53%,
      rgba(55, 52, 61, 0) 93.82%
    );
  }

  @media ${({ theme }) => theme.device.laptop1100} {
    img {
      width: 100%;
      height: ${({ heightM }) => heightM || '30.6vw'};
    }
    .slick-track {
      height: ${({ heightM }) => heightM || '100%'};
    }
  }

  @media ${({ theme }) => theme.device.tabletSmall} {
    img {
      width: 100%;
      height: ${({ heightM }) => heightM || '52.6vw'};
    }
  }

  @media ${({ theme }) => theme.device.tablet550} {
    img {
      width: 100%;
      height: ${({ heightM }) => heightM || '62.6vw'};
    }
  }

  @media ${({ theme }) => theme.device.mobileL} {
    img {
      width: 100%;
      height: ${({ heightM }) => heightM || '63.6vw'};
    }
    .slick-track {
      height: ${({ heightM }) => heightM || '100%'};
    }
  }
`;

export const ArrowBtn = styled.div`
  width: 40px;
  height: 40px;
  z-index: 1;
  position: absolute;
  top: ${({ top }) => top || '0'};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  border-radius: 15px;

  &:hover {
    background: #353535;
    opacity: 0.3;
  }

  img {
    height: 40px !important;
  }
  /* &:before {
    content: '→';
    color: #d2d2d2;
    font-size: 40px;
  } */
`;

export const SlideImg = styled.div`
  width: 100%;
  /* height: ${({ height }) => height || '73vw'}; */

  /* background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover; */
  @media ${({ theme }) => theme.device.mobileL} {
    min-height: ${({ heightM }) => heightM || '400px'};
  }

  img {
    max-height: 100vh;
    height: 100vh;
  }

  @media ${({ theme }) => theme.device.laptopL} {
    img {
      max-height: 700px;
      height: 700px;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    img {
      max-height: 400px;
      height: 400px;
    }
  }
`;

export const SlideItem = styled.div`
  position: relative;
  min-height: ${({ heightM }) => heightM};
`;

export const SlideTitle = styled.div`
  color: #fff;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
`;

export const SlideTopBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
  color: #fff;
  position: absolute;
  i {
    font-size: 20px;
    /* z-index: 999; */
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const CardPopularOverlay = styled.div`
  &::after {
    transition: all ease-in-out 0.3s;
    max-width: 400px;
    content: '';
    background: rgba(7, 7, 7, 0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* z-index: -1; */
  }
`;

export const CardPopular = styled.div`
  width: ${({ width }) => width || '100%'};
  max-width: 400px;
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height || '400px'};
  display: block;
  margin: ${({ margin }) => margin || 'auto'};
  background-image: url(${({ bgImg }) => bgImg});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  cursor: grab;
  transition: all ease-in-out 0.3s;
  position: relative;
  &:hover ${CardPopularOverlay} {
    &::after {
      transition: all ease-in-out 0.3s;
      background: rgba(7, 7, 7, 0.25);
    }
  }

  /* &:first-child {
    margin-left: 19px;
  } */

  @media ${({ theme }) => theme.device.tablet} {
    width: ${({ widthM }) => widthM};
    height: ${({ heightMl }) => heightMl || '336px'};
    border-radius: 25px;
  }
`;

export const CardViewedVillas = styled.div`
  max-width: 400px;
  width: 100%;
  padding: 0;
  position: relative;
  z-index: 2;
  /* &::after {
    max-width: 400px;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  } */
`;
