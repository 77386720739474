import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  ContainerCustom, CollapseList, List, ListItem,
} from 'components';
import { useIntl } from 'react-intl';
import { Collapse } from 'antd';

import { DotSVG } from 'lib/icons';

const PopupPlacesAround = ({
  img, title, price, days,
}) => {
  const intl = useIntl();

  const arrOfPanels = [
    {
      header: 'Что поблизости',
      panelList: ['Рестораны', 'Бары', 'Пляжные бары'],
    },
    { header: 'Развлечения', panelList: ['Рестораны', 'Бары', 'Пляжные бары'] },
    { header: 'Спорт', panelList: ['Рестораны', 'Бары', 'Пляжные бары'] },
  ];
  return (
    <ContainerCustom padding="30px" width="300px">
      {/* <Title size="18px" margin="0 0 20px 0" mutedText>
          {intl.formatMessage({
            id: 'popup.places.what-is-around',
          })}
        </Title> */}
      {/* <List>
          <ListItem iconSize="10px" margin=" 0 0 16px 0">
            <DotSVG />В течение 48 ч после бронирования 100% возврат
          </ListItem>
          <ListItem iconSize="10px" margin=" 0 0 16px 0">
            <DotSVG />В течение 48 ч после бронирования 100% возврат
          </ListItem>
        </List> */}
      <CollapseList arrOfPanels={arrOfPanels}>
        {arrOfPanels.map((panel) => (
          <Collapse.Panel header={panel.header} key={uuid()}>
            <List>
              {panel.panelList.map((listItem) => (
                <ListItem iconSize="10px" margin=" 0 0 16px 0">
                  <DotSVG />
                  {' '}
                  {listItem}
                </ListItem>
              ))}
            </List>
          </Collapse.Panel>
        ))}
      </CollapseList>
    </ContainerCustom>
  );
};

export { PopupPlacesAround };
