import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.div`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  color: ${({ theme, color }) => color || theme.colors.text.main};
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  font-family: ${({ fontFamily }) => fontFamily};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: ${({ center, right }) =>
    (center && 'center') || (right && 'end')};
  z-index: ${({ zIndex }) => zIndex};
  display: ${({ display }) => display};
  opacity: ${({ opacity }) => opacity};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};

  overflow: ${({ overflow }) => overflow};
  text-overflow: ${({ textOverflow }) => textOverflow};
  white-space: ${({ whiteSpace }) => whiteSpace};


  i {
    margin-right: 10px;
  }

  ${({ theme, active }) =>
    active &&
    css`
      color: ${theme.colors.primary};
    `}

    @media ${({ theme }) => theme.deviceMin.desktop1890} {
      padding: ${({ paddingFrom1890 }) => paddingFrom1890}
  }

  @media ${({ theme }) => theme.device.desktop1890} {
      padding: ${({ padding1890 }) => padding1890}
  }

    @media ${({ theme }) => theme.device.laptop1100} {
    padding: ${({ padding1100 }) => padding1100};
    max-width: ${({ width1100 }) => width1100};
    font-size: ${({ size1100 }) => size1100};
  }

  @media ${({ theme }) => theme.device.laptop} {
    font-size: ${({ sizeLaptopL }) => sizeLaptopL};
  }

  @media ${({ theme }) => theme.device.laptop} {
    margin: ${({ marginM }) => marginM};
    font-size: ${({ sizeM }) => sizeM};
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: ${({ widthTablet }) => widthTablet};
    margin: ${({ marginTablet }) => marginTablet};
    margin: ${({ marginM }) => marginM};
    font-size: ${({ sizeM }) => sizeM};
    font-size: ${({ size768 }) => size768};
    font-size: ${({ sizeMbl }) => sizeMbl};
    text-align: ${({ alignTablet }) => alignTablet};
    font-weight: ${({ weightTablet }) => weightTablet};
    color: ${({ colorTablet }) => colorTablet};

  }

  @media ${({ theme }) => theme.deviceMin.desktop1890} {
    padding: ${({ paddingFrom1890 }) => paddingFrom1890};
  }

  ${({ primary }) =>
    primary &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}

  ${({ mutedText }) =>
    mutedText &&
    css`
      color: ${({ theme }) => theme.colors.text.muted};
    `}

  ${({ greyText }) =>
    greyText &&
    css`
      color: ${({ theme }) => theme.colors.text.grey};
    `}

  ${({ lightText }) =>
    lightText &&
    css`
      color: ${({ theme }) => theme.colors.text.light};
    `}

 ${({ uppercase }) =>
   uppercase &&
   css`
     text-transform: uppercase;
   `}

 ${({ capitalize }) =>
   capitalize &&
   css`
     text-transform: capitalize;
   `}

  ${({ ubuntu }) =>
    ubuntu &&
    css`
      font-family: Ubuntu;
    `}
`;

export const Span = styled.span`
  font-size: ${({ size }) => size};
  color: ${({ theme, color }) => color || theme.colors.primary};
  margin: ${({ margin }) => margin};

  ${({ primary }) =>
    primary &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}

  ${({ mutedText }) =>
    mutedText &&
    css`
      color: ${({ theme }) => theme.colors.text.muted};
    `}

    ${({ ubuntu }) =>
      ubuntu &&
      css`
        font-family: Ubuntu;
      `}
`;

export const Text = styled.p`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};
  max-width: ${({ maxWidth }) => maxWidth};
  color: ${({ theme, color }) => color || theme.colors.text.main};
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  overflow: ${({ overflow }) => overflow};
  text-overflow: ${({ textOverflow }) => textOverflow};
  text-align: ${({ textAlign }) => textAlign};
  line-height: ${({ lineHeight }) => lineHeight};
  line-height: ${({ lineHeight }) => lineHeight};
  background-color: ${({ background }) => background};
  white-space: ${({ whiteSpace }) => whiteSpace};
  cursor: ${({ cursor }) => cursor};

  @media ${({ theme }) => theme.device.laptop1100} {
    font-size: ${({ size1100 }) => size1100};
  }

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ sizeM }) => sizeM};
    margin: ${({ marginTablet }) => marginTablet};
    max-height: ${({ maxHeightM }) => maxHeightM};
  }

  @media ${({ theme }) => theme.device.mobileM} {
    font-size: ${({ sizeSm }) => sizeSm};
    margin: ${({ marginM }) => marginM};
  }
  @media ${({ theme }) => theme.device.mobileS} {
    margin: ${({ marginM }) => marginM};
  }

  ${({ primary }) =>
    primary &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}

  ${({ mutedText }) =>
    mutedText &&
    css`
      color: ${({ theme }) => theme.colors.text.muted};
    `}


  ${({ greyText }) =>
    greyText &&
    css`
      color: ${({ theme }) => theme.colors.text.grey};
    `}

  ${({ lightText }) =>
    lightText &&
    css`
      color: ${({ theme }) => theme.colors.text.light};
    `}

 ${({ capitalize }) =>
    capitalize &&
    css`
      text-transform: capitalize;
    `}

  ${({ ubuntu }) =>
    ubuntu &&
    css`
      font-family: Ubuntu;
    `}



  ${({ columns }) =>
    columns &&
    css`
      columns: auto;
      columns: ${({ columns }) => columns};
      column-gap: ${({ gap }) => gap};
    `}
`;

export const LinkAnchor = styled.a`
 width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  color: ${({ theme, color }) => color || '#353535'};
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: ${({ center, left }) => (center && 'center') || (left && 'end')};
  z-index: ${({ zIndex }) => zIndex};
  display: ${({ display }) => display};
  align-items: ${({ align }) => align};
  cursor: pointer;

&:hover {
  color: ${({ theme, color }) => color || theme.colors.primary};
}

  i {
    margin-right: 10px;
  }

  @media ${({ theme }) => theme.device.laptop} {
    font-size: ${({ sizeLaptopL }) => sizeLaptopL};
  }

  @media ${({ theme }) => theme.device.laptop} {
    margin: ${({ marginM }) => marginM};
    font-size: ${({ sizeM }) => sizeM};

  }

  @media ${({ theme }) => theme.device.tablet} {
    width: ${({ widthTablet }) => widthTablet};
    margin: ${({ marginTablet }) => marginTablet};
    margin: ${({ marginM }) => marginM};
    font-size: ${({ sizeM }) => sizeM};
    text-align: ${({ alignTablet }) => alignTablet};
  }

  ${({ primary }) =>
    primary &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}

  ${({ mutedText }) =>
    mutedText &&
    css`
      color: ${({ theme }) => theme.colors.text.muted};
    `}

  ${({ greyText }) =>
    greyText &&
    css`
      color: ${({ theme }) => theme.colors.text.grey};
    `}

  ${({ lightText }) =>
    lightText &&
    css`
      color: ${({ theme }) => theme.colors.text.light};
    `}

 ${({ capitalize }) =>
   capitalize &&
   css`
     text-transform: capitalize;
   `}

  ${({ ubuntu }) =>
    ubuntu &&
    css`
      font-family: Ubuntu;
    `}
`;

export const TitleLink = styled(Link)`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  color: ${({ theme, color }) => color || '#353535'};
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: ${({ center, left }) => (center && 'center') || (left && 'end')};
  z-index: ${({ zIndex }) => zIndex};
  display: ${({ display }) => display};
  justify-content: ${({ justify }) => justify};

  cursor: pointer;

&:hover {
  color: ${({ theme, color }) => color || theme.colors.primary};
}

  i {
    margin-right: 10px;
  }

  @media ${({ theme }) => theme.device.laptop} {
    width: ${({ widthLaptopL }) => widthLaptopL};
    font-size: ${({ sizeLaptopL }) => sizeLaptopL};
  }

  @media ${({ theme }) => theme.device.laptop} {
    width: ${({ widthM }) => widthM};
    margin: ${({ marginM }) => marginM};
    font-size: ${({ sizeM }) => sizeM};

  }

  @media ${({ theme }) => theme.device.tablet} {
    width: ${({ widthTablet }) => widthTablet};
    margin: ${({ marginTablet }) => marginTablet};
    margin: ${({ marginM }) => marginM};
    font-size: ${({ sizeM }) => sizeM};
    text-align: ${({ alignTablet }) => alignTablet};
  }

  ${({ primary }) =>
    primary &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}

  ${({ mutedText }) =>
    mutedText &&
    css`
      color: ${({ theme }) => theme.colors.text.muted};
    `}

  ${({ greyText }) =>
    greyText &&
    css`
      color: ${({ theme }) => theme.colors.text.grey};
    `}

  ${({ lightText }) =>
    lightText &&
    css`
      color: ${({ theme }) => theme.colors.text.light};
    `}

 ${({ capitalize }) =>
   capitalize &&
   css`
     text-transform: capitalize;
   `}

  ${({ ubuntu }) =>
    ubuntu &&
    css`
      font-family: Ubuntu;
    `}
`;
