import React from 'react';
import { Stats } from './OwnerStats.style';
import { ContainerCustom, Title, Text } from 'components';
import { useIntl } from 'react-intl';

const OwnerStats = () => {
  const intl = useIntl();

  return (
    <Stats display="flex" direction="column" padding="0 0 0 120px" align="flex-start" justify="center">
      <ContainerCustom>
        <Title
          size="72px"
          weight="bold"
          color="#943FA1"
          lineHeight="128%"
          ubuntu
          width="100%"
        >
          {intl.formatMessage({
            id: 'owner.stats.destinations-number',
          })}
        </Title>
        <Text size="18px" color="#353535" lineHeight="128%" weight="500">
          {intl.formatMessage({
            id: 'owner.stats.destinations',
          })}
        </Text>
      </ContainerCustom>
      <ContainerCustom>
        <Title
          size="72px"
          weight="bold"
          color="#943FA1"
          lineHeight="128%"
          ubuntu
          width="100%"
        >
          {intl.formatMessage({
            id: 'owner.stats.villas-number',
          })}
        </Title>
        <Text size="18px" color="#353535" lineHeight="128%" weight="500">
          {intl.formatMessage({
            id: 'owner.stats.villas',
          })}
        </Text>
      </ContainerCustom>
      <ContainerCustom>
        <Title
          size="72px"
          weight="bold"
          color="#943FA1"
          lineHeight="128%"
          ubuntu
          width="100%"
        >
          {intl.formatMessage({
            id: 'owner.stats.professionals-number',
          })}
        </Title>
        <Text width="53%" size="18px" color="#353535" lineHeight="128%" weight="500">
          {intl.formatMessage({
            id: 'owner.stats.professionals',
          })}
        </Text>
      </ContainerCustom>
    </Stats>
  );
};

export { OwnerStats };
