import React from 'react';
import { NavbarMobile } from './NavbarMobile';
import { Navbar } from './Navbar';
import { SearchBarLessMobile } from './SearchBarLessMobile';
import { SearchBarLess } from './SearchBarLess';
import { useHistory } from 'react-router-dom';



const NavbarCombine = ({ setSearchValues }) => {
  const history = useHistory();
  console.log(history.location.pathname.includes('/faq'))
  return (
      <>
        {history.location.pathname.includes('/faq') ||
        history.location.pathname.includes('/policy') ||
        history.location.pathname.includes('/contacts') ||
        history.location.pathname.includes('/for-owner') ||
        history.location.pathname.includes('/about') ||
        history.location.pathname.includes('/destinations') ? <SearchBarLess /> : <Navbar setSearchValues={setSearchValues} />}

        {history.location.pathname.includes('/faq') ||
        history.location.pathname.includes('/policy') ||
        history.location.pathname.includes('/contacts') ||
        history.location.pathname.includes('/for-owner') ||
        history.location.pathname.includes('/about') ||
        history.location.pathname.includes('/destinations') ? <SearchBarLessMobile /> : <NavbarMobile />}
      </>
    );
}

export default NavbarCombine;
