import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
// import L from 'leaflet';

import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Icon, divIcon } from 'leaflet';
import { PopupShortInfo, Button } from 'components';
import { useIntl } from 'react-intl';
import ReactDOMServer from 'react-dom/server';
import { Row } from 'antd';
import { SVGIconRect } from './SvgMarker';
// import { PopupWrapper } from './MarkerPopups/Popup.styles';
import MapCluster from './MapClusters';

export const PopupWrapper = styled(Popup)`
  /* overflow: hidden; */
  .leaflet-popup-content {
    margin: 0 !important;
    padding: 0 !important;
  }
  .leaflet-popup-content-wrapper {
    padding: 0px !important;
  }
  .leaflet-popup-content-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 20px !important;
    /* border-radius: 20px 20px 0 0 !important; */
  }
  .leaflet-container a.leaflet-popup-close-button {
    top: 6px !important;
    right: 5px !important;
    padding: 0 !important;
  }
  .leaflet-popup-close-button {
    display: none;
  }
`;

export const icon = new Icon({
  iconUrl: '/mapMarker.svg',
  iconSize: [32, 39],
});

// const iconDiv = new divIcon({
//   className: 'custom-icon',
//   html: ReactDOMServer.renderToString(<SVGIconRect perc={10} />),
// });

const MapWrapper = styled.div`
  &&& {
    position: ${({ position }) => position || 'relative'};
    top: ${({ top }) => top};
    .leaflet-container {
      border-radius: 0 0 20px 20px;
      height: ${({ height }) => height || '800px'};
      width: ${({ width }) => width || '100%'};
      margin: 0 auto;
      z-index: 100;
    }

    .leaflet-touch .leaflet-control-layers,
    .leaflet-touch .leaflet-bar {
      border: none;
    }

    .leaflet-control-zoom {
      margin: 25px 0 0 25px;
      box-shadow: none;

      &-in {
        margin-bottom: 10px;
      }

      &-in,
      &-out {
        width: 35px;
        height: 35px;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 10px;
        color: #943fa1;
        box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
      }
    }

    ${({ theme, calcHeight }) =>
      calcHeight &&
      css`
        .leaflet-container {
          border-radius: 20px;
          height: calc(100vh - ${calcHeight});
          width: ${({ width }) => width || '100%'};
          margin: 0 auto;
        }
      `}
    ${({ theme, noBorderRadius }) =>
      noBorderRadius &&
      css`
        .leaflet-container {
          border-radius: 0;
        }
      `}
      @media
      ${({ theme }) => theme.device.tablet} {
      .leaflet-container {
        height: ${({ heightSM }) => heightSM || '800px'};
        width: ${({ width }) => width || '100%'};
        margin: 0 auto;
      }
    }
  }
`;

const MapComponent = ({
  hoverdCard,
  popupComponent,
  height,
  heightSM,
  width,
  places = [],
  calcHeight,
  position,
  top,
  additionalBtn,
  isPopup = true,
  noBorderRadius = false,
}) => {
  const intl = useIntl();

  // const [places, setPlaces] = useState([]);

  const [center, setCenter] = useState([45.383321536272049, -75.3372987731628]);
  const [activePark, setActivePark] = useState(hoverdCard);
  const [satellite, setSatellite] = useState(false);

  useEffect(() => {
    console.log('places', places);
    if (places) setCenter([places[0].geo[0], places[0].geo[1]]);
  }, [places]);

  useEffect(() => {
    setActivePark(hoverdCard);
  }, [hoverdCard]);

  useEffect(() => {
    console.log('activePark', activePark);
  }, [activePark]);

  return (
    <MapWrapper
      height={height}
      heightSM={heightSM}
      width={width}
      calcHeight={calcHeight}
      position={position}
      top={top}
      noBorderRadius={noBorderRadius}
    >
      {/* <MapContainer
      height={height}
      heightSM={heightSM}
      width={width}
      calcHeight={calcHeight}
      position={position}
      top={top}
      noBorderRadius={noBorderRadius}
    > */}
      <Row style={{ position: 'absolute', top: '10px', right: '10px' }}>
        <Button
          size="16px"
          padding="0 12px"
          height="35px"
          bg="white"
          color="#943FA1"
          colorHover="#943FA1"
          onClick={() => {
            setSatellite(!satellite);
          }}
          zIndex="999"
          margin="10px"
        >
          {satellite === true
            ? intl.formatMessage({
                id: 'hotels.map',
              })
            : intl.formatMessage({
                id: 'hotels.satellite-map',
              })}
        </Button>
        {additionalBtn}
      </Row>
      <Map
        center={center}
        zoom={12}
        zoomControl={{
          doubleClickZoom: false,
          closePopupOnClick: false,
          dragging: false,
          zoomSnap: false,
          zoomDelta: false,
          trackResize: false,
          touchZoom: false,
          scrollWheelZoom: false,
        }}
        touchZoom={false}
        scrollWheelZoom={false}
      >
        {satellite === true ? (
          <TileLayer
            url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            attribution='Map data &copy; <a rel="nofollow" href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a rel="nofollow" href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a rel="nofollow" href="https://www.mapbox.com/">Mapbox</a>'
          />
        ) : (
          <TileLayer
            url="https://api.mapbox.com/styles/v1/codeexporter/ckcrs9baz15g41io8cewzwsgq/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiY29kZWV4cG9ydGVyIiwiYSI6ImNrY3Jza2RobDE0NnMyeHQ2ZTlnMGhkcnkifQ.9lxBBCOt_cWl40t-KxHwYA"
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            // subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            attribution='Map data &copy; <a rel="nofollow" href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a rel="nofollow" href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a rel="nofollow" href="https://www.mapbox.com/">Mapbox</a>'
          />
        )}

        {places && <MapCluster setActivePark={setActivePark} places={places} />}

        {isPopup && activePark && (
          <PopupWrapper
            position={[activePark.geo[0], activePark.geo[1]]}
            onClose={() => {
              setActivePark(null);
            }}
          >
            {popupComponent || (
              <PopupShortInfo
                id={activePark.id}
                img={activePark.coverPhoto}
                title={activePark.name}
                price={activePark.price}
                url={activePark.urls ? activePark.urls.canonicalUrl : '/'}
                nights={activePark.nights}
              />
            )}
          </PopupWrapper>
        )}
      </Map>
      {/* </MapContainer> */}
    </MapWrapper>
  );
};

export default MapComponent;
