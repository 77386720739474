import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from './routes';
import { loadReCaptcha } from 'react-recaptcha-google';

function App(props) {
  const history = useHistory();

  React.useEffect(() => {
    console.log('APP history5 => ', window.location.pathname.slice(1, 3));
    loadReCaptcha();
  }, []);
  const isAvaliable = navigator.userAgent === 'ReactSnap';
  if (isAvaliable) return null;
  return <Routes />;
}

export default App;
