import styled, { css } from 'styled-components';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export const NavMobile = styled.div`
  width: 100%;
  padding: ${({ padding }) => padding || '0 10px'};
  z-index: 99;
  a {
    color: #353535;
    &:hover {
      color: black;
    }
  }

  display: none;

  @media ${({ theme }) => theme.device.laptop1100} {
    display: block;
    padding: ${({ padding1100 }) => padding1100};
    height: ${({ height1100 }) => height1100 || "80px"};
  }

  @media ${({ theme }) => theme.device.tabletSmall} {
    padding: ${({ paddingMD }) => paddingMD};
  }

`;

export const MobileTopFilters = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.laptop1100} {
    display: block;
  }

  &&& {
    .ant-collapse > .ant-collapse-item:last-child,
    .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
      padding: 0;
    }

    .collapsed {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;
      transition-property: all;
      transition-duration: 2s;
      transition-timing-function: linear;
      box-shadow: 0px 5px 50px rgba(0, 82, 180, 0.1);
      border-radius: 10px;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 10;
      }

      .mobile-search {
        /* flex-basis: 46%; */
        padding: 0;
        margin: 0;
        border-radius: 0;
        border: none;

        div {
          border-radius: 0;
          border: none;
        }
      }

      .mobile-datepicker {
        flex-basis: 34%;
        width: 48%;
        overflow: hidden;
        padding: 0;
        margin: 0;
        border-radius: 0;
        border: none;

        &::before {
          content: '';
          display: inline-block;
          width: 2px;
          height: 28px;
          position: absolute;
          left: 0;
          background-color: #e4f0f8;
          top: 6px;
          z-index: 10;
        }

        div {
          border-radius: 0;
          position: relative;
          border: none;
          box-shadow: none;
        }
      }

      .mobile-guests-number {
        padding: 0;
        margin: 0;
        height: auto;
        border-radius: 0;
        border: none;
        flex-basis: 19%;

        &::before {
          content: '';
          display: inline-block;
          width: 2px;
          height: 28px;
          position: absolute;
          left: 0;
          background-color: #e4f0f8;
          top: 6px;
          z-index: 10;
        }

        div {
          border-radius: 0;
          width: 62%;

          div {
            box-shadow: none;
            padding: 0;
            margin-left: 2px;
            width: 62%;
            white-space: nowrap;
          }
        }
      }
    }
  }

  /* &&& {
    .searchbar-open {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;

    .mobile-search-open {
      flex-basis: 100%;
      width: 100%;
    }

    .mobile-datepicker-open {
      flex-basis: 100%;
      width: 100%;
    }

    .mobile-guests-number-open {
      flex-basis: 100%;
      width: 100%;
    }
    }
  } */
`;

// const mobileSearchContainer

export const MenuBlock = styled.ul`
  clear: none;
  color: black;
  z-index: 99;
  position: fixed;
  width: 0vw;
  right: -200px;
  top: 0;
  height: 100vh;
  /* transform: translateX(100%); */
  transition: all 0.2s ease-in-out ;
  /* margin-left: -1000px; */
  /* border: 1px solid red; */

  @media ${({ theme }) => theme.device.laptop} {
    clear: both;
    transition: all 0.2s ease-out;
    margin-left: -10px;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
`;

export const MenuBlockSecondLayer = styled.ul`
  clear: none;
  color: black;
  z-index: 99;
  position: fixed;
  width: 0vw;
  right: -200px;
  top: 0;
  height: 100vh;
  /* transform: translateX(100%); */
  transition: all 0.2s ease-in-out ;
  /* margin-left: -1000px; */
  /* border: 1px solid red; */

  @media ${({ theme }) => theme.device.laptop} {
    clear: both;
    transition: all 0.2s ease-out;
    margin-left: -10px;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
`;

export const MenuBlockCurrencyLayer = styled.ul`
  clear: none;
  color: black;
  z-index: 99;
  position: fixed;
  width: 0vw;
  right: -200px;
  top: 0;
  height: 100vh;
  /* transform: translateX(100%); */
  transition: all 0.2s ease-in-out ;
  /* margin-left: -1000px; */
  /* border: 1px solid red; */

  @media ${({ theme }) => theme.device.laptop} {
    clear: both;
    transition: all 0.2s ease-out;
    margin-left: -10px;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
`;

export const MenuItemNavbar = styled.li`
  list-style: none;
  background-color: #ffffff;
  padding: 5px 0 3px;
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const MenuItem = styled.li`
  float: left;
  list-style: none;
  @media ${({ theme }) => theme.device.laptop} {
    float: none;
  }
`;

export const MenuIcon = styled.label`
  display: none;
  @media ${({ theme }) => theme.device.laptop1100} {
    display: flex;
    /* border: 1px solid red; */
    padding: ${({ padding }) => padding || '28px 0 28px 28px'};
    margin: ${({ margin }) => margin};
    position: relative;
    float: right;
    cursor: pointer;
  }
`;

export const NavIcon = styled.span`
  background: ${({ background }) => background || '#353535'};
  background: transparent;
  display: block;
  height: 11px;
  width: 22px;
  margin: ${({ margin }) => margin || '45px 15px 0 0'};
  position: relative;
  transition: all 0.2s ease-in-out;
  /* z-index: 100000; */
  margin-top: 5px !important;

  &:before {
    content: '';
    background: ${({ background }) => background || '#353535'};
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 7px;
    transition: all 0.5s ease-in-out;
  }
  &:after {
    content: '';
    background: ${({ background }) => background || '#353535'};
    display: block;
    height: 3px;
    width: 70%;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.5s ease-in-out;
  }
`;

export const MenuBtn = styled.input`
  display: none;
  transition: all ease-in-out 0.5s;
  &:checked ~ ${MenuBlock} {
    transform: translateX(0%);
    right: 0;
    top: 0;
    height: 100vh;
    position: fixed;
    z-index: 2000;
    width: min(400px, 80vw);
  }

  &:checked ~ ${MenuIcon} ${NavIcon} {
    background-color: transparent;
    &:before {
      transform: rotate(-45deg);
      top: 0;
    }
    &:after {
      width: 100%;
      transform: rotate(45deg);
      top: 0;
    }
  }
`;

export const MenuBtnSecondLayer = styled.input`
  display: none;
  transition: all ease-in-out 0.5s;
  &:checked ~ ${MenuBlockSecondLayer} {
    transform: translateX(0%);
    right: 0;
    top: 0;
    height: 100vh;
    position: fixed;
    z-index: 2000;
    width: min(400px, 80vw);
  }
`;

export const MenuBtnCurrencyLayer = styled.input`
  display: none;
  transition: all ease-in-out 0.5s;
  &:checked ~ ${MenuBlockCurrencyLayer} {
    transform: translateX(0%);
    right: 0;
    top: 0;
    height: 100vh;
    position: fixed;
    z-index: 2000;
    width: min(400px, 80vw);
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
`;

export const LogoContainer = styled.div`
  float: left;
  display: block;
`;

export const LogoTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const LogoSymbol = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-image: url(${({ img }) => img});
  background-color: ${({ theme }) => theme.colors.primary};
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoTitle = styled.div`
  width: 59px;
  height: 22px;
  background-image: url(${({ img }) => img});
  background-position: center;
  background-size: cover;
  margin: 0 6px;
`;

export const SearchBar = styled.div`
  height: 40px;
  width: ${({ width }) => width || 'fit-content'};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  background: #ffffff;
  display: flex;
  align-items: center;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
  border-radius: 10px;
  i {
    color: ${({ theme }) => theme.colors.text.light};
    font-size: ${({ size }) => size || '17px'};
    margin: ${({ marginIcon }) => marginIcon || '0 15px 0 20px'};
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    color: #333;
  }
`;

export const DatePickerContainer = styled.div`
  &&& {
    height: 28px;
    border-left: 1px solid #e4f0f8;
    border-right: 1px solid #e4f0f8;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
    margin: ${({ margin }) => margin || '0'};
    padding: ${({ padding }) => padding || '0'};
    display: flex;
    height: 40px;
    align-items: center;
    position: ${({ position }) => position};
    /* justify-content: space-between; */
    i {
      font-size: 17px;
      color: ${({ theme }) => theme.colors.text.light};
      margin: 0 15px 0 20px;
    }

    .ant-row .ant-form-item {
      margin: 0;
    }

    ${({ calendIconNone }) =>
      calendIconNone &&
      css`
        .anticon-calendar {
          display: none;
        }
        .ant-picker-input {
          width: 105px;
          padding: 0 10px;
        }
      `}
  }
`;

// export const DatePickerContainer = styled.div`
//          width: ${({ width }) => width};
//          height: 28px;
//          padding: ${({ padding }) => padding};
//          margin: ${({ margin }) => margin};
//          border-left: 1px solid #e4f0f8;
//          border-right: 1px solid #e4f0f8;
//         .anticon-calendar {
//           display: none !important;
//         }

//          ${({ borders }) =>
//            borders &&
//            css`
//              height: 54px;
//              border: 1px solid
//                ${({ theme, dropBorderWrapper }) =>
//                  dropBorderWrapper || theme.colors.borders.light};
//              border-radius: 10px;
//              padding-left: 20px;
//              display: flex;
//              align-items: center;
//              .ant-form-item {
//                margin: 0;
//              }
//            `}

//          ${({ calendIconNone }) =>
//            calendIconNone &&
//            css`
//              .anticon-calendar {
//                display: none;
//              }
//              .ant-picker-input {
//                width: 105px;
//                padding: 0 10px;
//              }
//            `}

//          @media ${({ theme }) => theme.device.tablet} {
//            width: ${({ widthSM }) => widthSM};
//            margin: ${({ marginM }) => marginM};
//          }
//        `;

export const PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PhoneImg = styled.img`
  padding: 6px;
  border: 1px solid ${({ theme }) => theme.colors.text.muted};
  border-radius: 100%;
`;

export const RangePickerTransparent = styled(RangePicker)`
  background-color: transparent;
  border: none;
  box-shadow: none;
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  .ant-picker-active-bar {
    background: ${({ theme }) => theme.colors.primary};
  }

  .ant-picker-input > input {
    color: ${({ color }) => color};
    &::placeholder {
      color: ${({ theme }) => theme.colors.text.grey};
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
    }
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const BtnCounter = styled.div`
  width: 25px;
  height: 25px;
  color: #fff;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const DropBtnStyles = styled.div`
  color: ${({ theme }) => theme.colors.text.light};
  i {
    color: ${({ theme }) => theme.colors.text.light};
  }
`;

export const DropPortalContainer = styled.div`
  width: ${({ dropContainerWidth }) => dropContainerWidth};
  min-width: 25px;
  min-height: 25px;
  background-color: #fff;
  border: 1px solid rgba(0, 82, 180, 0.1);
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
  /* transform: translateX(50px); */

  position: absolute;
  top: 35px;
  transition: all ease-in-out 0.3s;
  z-index: 1000;
`;
