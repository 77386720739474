import React from 'react';
import { Title, ContainerCustom, ButtonLink } from 'components';
import { useIntl } from 'react-intl';
import Navbar from 'pages/Layouts/MainPageLayout/Navbar';
import { TitleMain } from '../../NotFoundPage.styles';
import { HeaderContainer, HeaderBg, Headings } from './Header.styles';

const Header = () => {
  const intl = useIntl();

  return (
    <HeaderBg>
      <Navbar />
      <HeaderContainer>
        <ContainerCustom alignSelf="center" alignSelfSm="flex-end">
          <Headings>
            <TitleMain
              size="230px"
              lineHeight="174px"
              sizeM="144px"
              // lineHeight="294px"
              weight="bold"
              color="#fff"
            >
              404
            </TitleMain>
            <TitleMain
              margin="40px 0 0 0"
              size="36px"
              sizeM="24px"
              lineHeight="46px"
              weight="500"
              color="#fff"
            >
              {intl.formatMessage({
                id: 'not-found-page.smth-missing',
                defaultMessage: 'We couldn’t find this page',
              })}
            </TitleMain>
            <Title
              size="18px"
              width="375px"
              weight="normal"
              lineHeight="26px"
              sizeM="16px"
              color="#fff"
              margin="20px 0 0 0"
            >
              {intl.formatMessage({
                id: 'not-found-page.smth-missing-explanation',
              })}
            </Title>
          </Headings>
          <ButtonLink
            to="/"
            size="16px"
            padding="7px 0 0 0"
            width="169px"
            height="40px"
            widthSm="113px"
            heightSm="40px"
            margin="50px 0 0 0"
            primary
          >
            {intl.formatMessage({
              id: 'buttons.home',
            })}
          </ButtonLink>
          {/* <SearchBarPanel /> */}
        </ContainerCustom>
      </HeaderContainer>
    </HeaderBg>
  );
};

export { Header };
