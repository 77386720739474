import styled, { css } from 'styled-components';

export const SelectCustomStyles = styled.div`
  &&& {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      /* padding-right: 18px; */
      padding: 0 0 0 18px;
      align-items: center;
      display: flex;
    }

    .ant-select-selector {
      height: ${({ height }) => height};
    }
    .ant-select-arrow {
      top: 45%;
      right: -1px;
    }
    .ant-select {
      width: ${({ width }) => width || '100%'};
      color: ${({ colorText }) => colorText};
      padding: ${({ paddingTop }) => paddingTop};
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      position: relative;
      /* background-color: #fff;  С белым фоном , наглавной в хедере портится выпадашка с номерами телефонов */
      background-color: transparent;
      border: none;
      border-radius: 2px;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
      height: ${({ height }) => height || '32px'};
      width: ${({ width }) => width};
      padding-left: 0;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
      outline: none;
      box-shadow: none;
    }
    &:focus {
      color: ${({ theme }) => theme.colors.primary};
      outline: none;
      box-shadow: none;
    }

    ${({ borders }) => borders
      && css`
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 1px solid ${({ theme }) => theme.colors.borders.light};
          border-radius: 10px;
          padding: 0 15px;
          align-items: center;
          height: ${({ bordersHeight }) => bordersHeight};
          width: ${({ width }) => width};
        }
      `}
  }
`;
