import styled from 'styled-components';
import { ContainerCustom } from 'components';

export const Stats = styled(ContainerCustom)`
  width: 620px;
  height: 620px;
  border-radius: 50%;
  background-color: #F7F4F8;
  position: absolute;
  right: -100px;

  @media ${({ theme }) => theme.device.laptop} {
    display: none;
  }
`;
