import styled from 'styled-components';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export const TabsStyled = styled(Tabs)`
  color: ${({ theme }) => theme.colors.text.muted};
  margin: ${({ margin }) => margin};
  .ant-tabs-nav-wrap {
    /* margin-bottom: -1px;
    overflow: hidden;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 9999999; 
    
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    */
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
  }
  .ant-tabs-tab {
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  .ant-tabs-tab-active {
    color: ${({ theme }) => theme.colors.primary};
  }
  .ant-tabs-ink-bar {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const TabWrapper = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  /* padding: 0 60px; */
  /* transform: translateX(-50px); */
`;

export const Tab = styled(TabPane)``;
