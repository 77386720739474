import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { SEARCH_HOTELS_AUTOCOMPLETE } from 'graphql/queries/getHotels';
import { GET_REGION_ID_FROM_SLUG } from 'graphql/queries/getHotels';
import moment from 'moment';
import { useOutsideClick } from 'hooks/useClickOutside';

import { Form } from 'antd';

import {
  Title,
  Button,
  DropdownClickOutside,
  ContainerCustom,
  AutoCompletePortal,
  DateRangePickerWrapper,
} from 'components';

import {
  SearchBar,
  DatePickerContainer,
  RangePickerTransparent,
  BtnCounter,
  BtnContainer,
  SearchBarWrapper,
  AirbnbDatePickerContainer,
} from 'components/Navbar/Navbar.styles';

import { useHistory } from 'react-router-dom';

const pluralize = require('numeralize-ru').pluralize;

const SearchBarPanel = (props) => {
  const { seActiveSearchPanel } = props;
  const history = useHistory();
  const queryStringParams = new URLSearchParams(history.location.search);
  const intl = useIntl();

  const [guestNumber, setGuestNumber] = useState({
    adults: queryStringParams.get('adults') || 0,
    children: queryStringParams.get('children') || 0,
    infants: queryStringParams.get('infants') || 0,
  });

  React.useEffect(() => {
    console.log('guestNumber: ', guestNumber);
  }, [guestNumber]);

  const [searchInputValue, setSearchInputValue] = useState({
    region: {
      id: null,
      value: '',
      slug: history.location.pathname.split('/')[3],
    },
    checkinDate: queryStringParams.get('checkindate') || '',
    checkoutDate: queryStringParams.get('checkoutdate') || '',
    adults: guestNumber.adults,
    children: guestNumber.children,
    infants: guestNumber.infants,
    isCalendarReset: false,
  });

  const [searchResult, setSearchResult] = useState([]);

  const {
    loading: loadingAutocomplete,
    error: errorAutocomplete,
    data: dataAutocomplete,
  } = useQuery(SEARCH_HOTELS_AUTOCOMPLETE, {
    variables: {
      lang: `${localStorage.language ? localStorage.language : 'en'}`,
      searchString: searchInputValue.region.value,
    },
  });

  // Autocomplete method start
  const onChangeSearchAutocomplete = (e) => {
    console.log('E: ', e.target.value);
    setSearchInputValue({
      ...searchInputValue,
      region: {
        ...searchInputValue.region,
        value: e.target.value,
      },
    });
  };

  const onSelectSearchAutocomplete = (id, title, slug) => {
    console.log('onSelectSearchAutocomplete: ', id, ' + ', title);
    setSearchInputValue({
      ...searchInputValue,
      region: {
        id,
        value: title,
        slug,
      },
    });
  };

  // Autocomplete method end
  const [isCalendarChanged, setCalendarChanged] = useState(false);

  const onChangeDate = (startDate, endDate) => {
    setCalendarChanged(true);
    setSearchInputValue(() => ({
      ...searchInputValue,
      isCalendarReset: false,
    }));

    if (startDate && !endDate) {
      setSearchInputValue(() => ({
        ...searchInputValue,
        checkinDate: moment(startDate._d).format('YYYY-MM-DD'),
        isCalendarReset: false,
      }));
    }

    if (startDate && endDate) {
      setSearchInputValue(() => ({
        ...searchInputValue,
        checkinDate: moment(startDate._d).format('YYYY-MM-DD'),
        checkoutDate: moment(endDate._d).format('YYYY-MM-DD'),
        isCalendarReset: false,
      }));
    }

    if (!startDate && !endDate) {
      setSearchInputValue({
        ...searchInputValue,
        checkinDate: null,
        checkoutDate: null,
      });
    }
  };

  useEffect(() => {
    if (
      !loadingAutocomplete &&
      dataAutocomplete &&
      dataAutocomplete.regionList
    ) {
      console.log('dataAutocomplete: ', dataAutocomplete);
      console.log('dataAutocomplete er: ', errorAutocomplete);

      setSearchResult(
        dataAutocomplete.regionList.map(
          ({ region: { id, regionType, slug }, name, parents }) => ({
            id,
            title: name,
            subtitle: parents,
            type: regionType.name,
            slug,
            icon: regionType.icon,
          })
        )
      );
    }
  }, [loadingAutocomplete, dataAutocomplete]);

  const [isAlertShown, setIsAlertShown] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const { loading, error, data } = useQuery(GET_REGION_ID_FROM_SLUG, {
    variables: {
      lang: history.location.pathname.split('/')[1],
      regionSlug: history.location.pathname.split('/')[3],
    },
    fetchPolicy: 'network-onlynetwork-only',
  });

  useEffect(() => {
    console.log('ACTION queryStringParams: ', history.location.search);

    console.log('ACTION adults', queryStringParams.get('adults'));
    console.log('ACTION children', queryStringParams.get('children'));
    console.log('ACTION infants', queryStringParams.get('infants'));
    console.log('ACTION checkinDate', queryStringParams.get('checkindate'));
    console.log('ACTION checkoutDate', queryStringParams.get('checkoutdate'));
    console.log('searchInputValue.region.value', searchInputValue.region.value);
    data &&
      data.regionId &&
      setSearchInputValue({
        ...searchInputValue,
        region: {
          ...searchInputValue.region,
          value: data.regionId.name,
        },
      });
  }, [data, loading]);

  const onSubmitSearch = () => {
    console.log('ACTION INPUTS!!!! ', searchInputValue);
    const {
      region: { id: regionId, slug },
      checkinDate,
      checkoutDate,
    } = searchInputValue;

    const { adults, children, infants } = guestNumber;
    if (!data.regionId.name) {
      setIsAlertShown(true);
    } else {
      console.log(
        'ACTION searchInputValue',
        regionId,
        checkinDate,
        checkoutDate,
        adults,
        children,
        infants
      );

      const params = {
        checkinDate,
        checkoutDate,
        adults: adults === 0 ? 2 : adults,
        children,
        infants,
      };

      const urls = {
        checkinDate: `checkindate=${checkinDate}`,
        checkoutDate: `&checkoutdate=${checkoutDate}`,
        adults: `&adults=${adults === 0 ? 2 : adults}`,
        children: `&children=${children}`,
        infants: `&children=${infants}`,
      };

      let url = `/${localStorage.language}/search/${slug}?`;

      for (let prop in params) {
        if (params[prop]) {
          url += urls[prop];
        }
      }

      window.location.href = url;
    }
  };

  const [showFullSearch, setShowFullSearch] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (showFullSearch && !isCalendarChanged) {
      setShowFullSearch(false);
      seActiveSearchPanel(false);
    }
  });

  return data && data.regionId ? (
    <SearchBarWrapper activeSearchPanel={showFullSearch}>
      {/* {show && (
        // <DropContentk>
        <DropContent
          onMouseLeave={() => onHover && setSate(false)}
          ref={ref}
          dropContainerWidth={dropContainerWidth}
        >

      )} */}
      <SearchBar
        onClick={() => {
          setShowFullSearch(true);
          seActiveSearchPanel(true);
        }}
        ref={ref}
        heightSearchBar="100%"
        activeSearchPanel={showFullSearch}
      >
        <div
          style={{
            width: '115px',
            display: 'flex',
            alignItems: 'center',
            // border: '3px green solid',
          }}
        >
          <AutoCompletePortal
            placeholderColor="grey"
            dropContainerHeight="315px"
            dropWrapperWidth="90px"
            dropContainerWidth="520px"
            placeholder={intl.formatMessage({
              id: 'filters.city',
            })}
            name="region"
            value={searchInputValue.region.value}
            defaultValue={data.regionId.name}
            options={[...searchResult]}
            onChange={onChangeSearchAutocomplete}
            onSelect={onSelectSearchAutocomplete}
          />
        </div>

        <DatePickerContainer calendIconNone>
          <Form.Item name="range-picker">
            <AirbnbDatePickerContainer>
              <DateRangePickerWrapper
                onChangeDate={onChangeDate}
                isCalendarReset={searchInputValue.isCalendarReset}
                checkinDate={
                  searchInputValue.checkinDate
                    ? moment(searchInputValue.checkinDate).format('DD.MM.YYYY')
                    : null
                }
                checkoutDate={
                  searchInputValue.checkoutDate
                    ? moment(searchInputValue.checkoutDate).format('DD.MM.YYYY')
                    : null
                }
              />
            </AirbnbDatePickerContainer>

            {/* <RangePickerTransparent
              defaultValue={[
                searchInputValue.checkinDate
                  ? moment(searchInputValue.checkinDate)
                  : null,
                searchInputValue.checkoutDate
                  ? moment(searchInputValue.checkoutDate)
                  : null,
              ]}
              allowClear={true}
              allowEmpty={[true, true]}
              onChange={onChangeDate}
            /> */}
          </Form.Item>
        </DatePickerContainer>

        <DropdownClickOutside
          dropBtnWidth="112px"
          dropBtnPaddingWrapper="3px 0px 3px 20px"
          btnTitle={
            localStorage.language === 'ru'
              ? `${+guestNumber.adults + +guestNumber.children} ${pluralize(
                  +guestNumber.adults + +guestNumber.children,
                  'Гость',
                  'Гостя',
                  'Гостей'
                )}`
              : `${+guestNumber.adults + +guestNumber.children} ${
                  +guestNumber.adults + +guestNumber.children === 1
                    ? 'Guest'
                    : 'Guests'
                }`
          }
          dropBtnColor="#9791A7"
          margin="0 0 0 40px"
        >
          <ContainerCustom padding="15px">
            <ContainerCustom display="flex">
              <Title width="100px">
                {intl.formatMessage({
                  id: 'hotelComplex-page.adults.few',
                })}
              </Title>
              <BtnCounter
                onClick={() =>
                  setGuestNumber({
                    ...guestNumber,
                    adults:
                      guestNumber.adults - 1 < 0 ? 0 : --guestNumber.adults,
                  })
                }
              >
                -
              </BtnCounter>
              <Title width="30px" center>
                {guestNumber.adults}
              </Title>
              <BtnCounter
                onClick={() =>
                  setGuestNumber({
                    ...guestNumber,
                    adults: ++guestNumber.adults,
                  })
                }
              >
                +
              </BtnCounter>
            </ContainerCustom>
            <br />
            <ContainerCustom display="flex">
              <Title width="100px">
                {intl.formatMessage({
                  id: 'hotelComplex-page.children',
                })}
              </Title>
              <BtnCounter
                onClick={() =>
                  setGuestNumber({
                    ...guestNumber,
                    children:
                      guestNumber.children - 1 < 0 ? 0 : --guestNumber.children,
                  })
                }
              >
                -
              </BtnCounter>
              <Title width="30px" center>
                {guestNumber.children}
              </Title>
              <BtnCounter
                onClick={() =>
                  setGuestNumber({
                    ...guestNumber,
                    children: ++guestNumber.children,
                  })
                }
              >
                +
              </BtnCounter>
            </ContainerCustom>
            <br />
            <ContainerCustom display="flex">
              <Title width="100px">
                {intl.formatMessage({
                  id: 'main-page.guests.infants',
                })}
                :
              </Title>
              <BtnCounter
                onClick={() =>
                  setGuestNumber({
                    ...guestNumber,
                    infants:
                      guestNumber.infants - 1 < 0 ? 0 : --guestNumber.infants,
                  })
                }
              >
                -
              </BtnCounter>
              <Title width="30px" center>
                {guestNumber.infants}
              </Title>
              <BtnCounter
                onClick={() =>
                  setGuestNumber({
                    ...guestNumber,
                    infants: ++guestNumber.infants,
                  })
                }
              >
                +
              </BtnCounter>
            </ContainerCustom>
          </ContainerCustom>
        </DropdownClickOutside>
        <BtnContainer activeSearchPanel={showFullSearch}>
          <Button
            size="16px"
            primary
            padding="0"
            size="16px"
            minWidth="91px"
            height="40px"
            onClick={onSubmitSearch}
          >
            {intl.formatMessage({
              id: 'buttons.search',
            })}
          </Button>
        </BtnContainer>
      </SearchBar>
    </SearchBarWrapper>
  ) : null;
};

export { SearchBarPanel };

// const locale = {
//   lang: {
//     placeholder: 'Избери дата',
//     rangePlaceholder: ['Начална дата', 'Крайна дата'],
//     today: 'Днес',
//     now: 'Сега',
//     backToToday: 'Към днес',
//     ok: 'Добре',
//     clear: 'Изчистване',
//     month: 'Месец',
//     year: 'Година',
//     timeSelect: 'Избор на час',
//     dateSelect: 'Избор на дата',
//     monthSelect: 'Избор на месец',
//     yearSelect: 'Избор на година',
//     decadeSelect: 'Десетилетие',
//     previousMonth: 'Предишен месец (PageUp)',
//     nextMonth: 'Следващ месец (PageDown)',
//     previousYear: 'Последна година (Control + left)',
//     nextYear: 'Следваща година (Control + right)',
//     previousDecade: 'Предишно десетилетие',
//     nextDecade: 'Следващо десетилетие',
//     previousCentury: 'Последен век',
//     nextCentury: 'Следващ век',
//     yearFormat: 'YYYY',
//     dateFormat: 'D M YYYY',
//     dayFormat: 'D',
//     dateTimeFormat: 'D M YYYY HH:mm:ss',
//     monthBeforeYear: true,
//   },
//   timePickerLocale: {
//     placeholder: 'Избор на час',
//   },
// };
