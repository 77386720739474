import { useMemo} from 'react';

export const useMainUrl = () => {
  const mainUrl = useMemo(() => {
    if (
      window.location.pathname.slice(1, 3) === 'ru'
    ) {
      return `/${window.location.pathname.slice(1, 3)}`;
    }
    return '';
  }, [window.location.pathname]);

  return [mainUrl];
};
