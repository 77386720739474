import gql from 'graphql-tag';

export const REGISTER = gql`
  mutation createUser(
    $firstName: String!
    $email: String!
    $lastName: String!
    $password: String!
  ) {
    createUser(
      firstName: $firstName
      email: $email
      password: $password
      lastName: $lastName
    ) {
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

// mutation {
//   createUser(email: "alx_sotiropulos@rambler.ru",
//              firstName: "Alekos",
//              lastName: "Sotiropulos",
//              password: "Abcd1234fF@$") {
//     user{
//       id
//       username
//     }
//   }
// }

// # mutation createUser($name: String!, $email: String!, $lastName: String!, $password: String!) {
//   #   createUser(name: $name, email: $email, password: $password, lastName: $lastName) {
//   #     user {
// id
// user
// }
//   #   }
//   # }
