import styled, { css, keyframes } from 'styled-components';
import { Badge } from 'components';
import QuoteSVG from 'assets/icons/mainPage/quote.svg';
import PalmTree from 'assets/PalmTree.svg';

export const CarrouselPaddingWrapper = styled.div`
  padding: 10px 20px;
`;

export const CardContainerNotGrid = styled.div`
  /* one-third - two margins divided equally among 3 items */
  max-width: 379px;
  max-height: ${({ height }) => height || '386px'};

  /* margin-right: 40px;
  margin-bottom: 40px; */
  display: block;
  margin: auto;

  color: ${({ theme }) => theme.colors.text.main};
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
  transition: all ease-in-out 0.2s;

  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.07),
      0 11px 20px 0 rgba(0, 0, 0, 0.06), 0 8px 47px 0 rgba(0, 0, 0, 0.04);
    transform: translateY(-0.3rem);
  }
  &:hover ${Badge} {
    opacity: 1;
    color: green;
  }
`;

const appear = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
    left: 0%;
  }
`;

export const CardContainer = styled.div`
  opacity: 1;
  /* animation: ${appear} 0.28s linear; */
  /* one-third - two margins divided equally among 3 items */
  width: ${({ width }) => width};
  max-height: ${({ height }) => height || '650px'};
  margin-right: ${({ marginRight }) => marginRight || 'min(1.4vw, 28px)'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 'min(1.4vw, 28px)'};

  color: ${({ theme }) => theme.colors.text.main};
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
  transition: all ease-in-out 0.2s;

  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.07),
      0 11px 20px 0 rgba(0, 0, 0, 0.06), 0 8px 47px 0 rgba(0, 0, 0, 0.04);
    transform: translateY(-0.3rem);
  }
  &:hover ${Badge} {
    opacity: 1;
    color: green;
  }

  @media ${({ theme }) => theme.deviceMin.laptop1100} {
    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }

  @media (min-width: 2100px) {
    width: min(${({ widthFrom2100 }) => widthFrom2100}, 800px);
    max-height: ${({ heightFrom2100 }) => heightFrom2100};

    &:nth-child(3n) {
      margin-right: ${({ marginRight3n2100 }) => marginRight3n2100 || '1.4vw'};
    }

    &:nth-child(4n) {
      margin-right: ${({ marginRight4n }) => marginRight4n};
    }
  }

  @media ${({ theme }) => theme.device.desktop2100} {
    width: min(${({ width2100 }) => width2100}, 650px);
    max-height: ${({ height2100 }) => height2100};
  }

  @media ${({ theme }) => theme.device.desktop1920} {
    width: min(${({ width1920 }) => width1920}, 550px);
    max-height: ${({ height1920 }) => height1920};
  }

  @media ${({ theme }) => theme.device.laptop1100} {
    width: min(${({ widthL1100 }) => widthL1100}, 465px);
    max-height: ${({ height1100 }) => height1100};
    margin-right: ${({ marginRight1100 }) => marginRight1100};
    margin-right: ${({ marginBottom1100 }) => marginBottom1100};
    &:nth-child(2n) {
      margin-right: ${({ marginRight2n1100 }) => marginRight2n1100};
    }

    &:nth-last-child(2) {
      margin-right: min(1.4vw, 28px);
    }

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    width: min(${({ widthL }) => widthL}, 459px);
    max-height: ${({ heightLaptop }) => heightLaptop || '486px'};
    margin-right: max(
      ${({ marginRightlaptop }) => marginRightlaptop || '1rem'},
      10px
    );
    margin-bottom: max(
      ${({ marginBottomlaptop }) => marginBottomlaptop || '1rem'},
      10px
    );
    /* &:nth-child(3n) {
      margin-right: 1rem;
    } */
  }

  @media ${({ theme }) => theme.device.tabletSmall} {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.device.tabletSmall} {
    width: min(${({ widthM }) => widthM}, 459px);
    max-height: ${({ heightSm }) => heightSm || '486px'};
    /* margin: 0 0 5rem 0; */
    &:nth-child(3n) {
      margin-right: 0rem;
    }
  }
`;

export const CardPopularDirectionsContainer = styled.div`
  opacity: 1;

  width: calc((100% / 3) - (4rem / 3));
  max-height: ${({ height }) => height || '386px'};
  margin-right: 40px;
  margin-bottom: 40px;
  margin-right: 2rem;
  margin-bottom: 2rem;

  color: ${({ theme }) => theme.colors.text.main};

  box-shadow: none;
  transition: all ease-in-out 0.2s;

  &:hover {
    cursor: pointer;
    box-shadow: none;
    transform: translateY(-0.3rem);
  }
  &:hover ${Badge} {
    opacity: 1;
    color: green;
  }

  @media ${({ theme }) => theme.device.desktop} {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    width: calc((100% / 2) - (1rem / 2) - 1rem);
    max-height: ${({ height }) => height || '386px'};
    margin-right: 1rem;
    margin-bottom: 1rem;
    &:nth-child(3n) {
      margin-right: 1rem;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    max-height: ${({ height }) => height || '386px'};
    margin: 0 0 5rem 0;
    &:nth-child(3n) {
      margin-right: 0rem;
    }
  }
`;

export const CardWithMapContainer = styled.div`
  opacity: 1;
  /* animation: ${appear} 0.28s linear; */
  /* one-third - two margins divided equally among 3 items */
  width: ${({ width }) => width};
  max-height: ${({ height }) => height || '650px'};
  margin-right: min(${({ marginRight }) => marginRight});
  margin-bottom: min(${({ marginBottom }) => marginBottom});

  color: ${({ theme }) => theme.colors.text.main};
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
  transition: all ease-in-out 0.2s;

  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.07),
      0 11px 20px 0 rgba(0, 0, 0, 0.06), 0 8px 47px 0 rgba(0, 0, 0, 0.04);
    transform: translateY(-0.3rem);
  }
  &:hover ${Badge} {
    opacity: 1;
    color: green;
  }

  @media ${({ theme }) => theme.deviceMin.laptop1100} {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (min-width: 2100px) {
    width: min(${({ widthFrom2100 }) => widthFrom2100}, 800px);
    max-height: ${({ heightFrom2100 }) => heightFrom2100};

    &:nth-child(3n) {
      margin-right: 1.4vw;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  @media ${({ theme }) => theme.device.desktop2100} {
    width: min(${({ width2100 }) => width2100}, 650px);
    max-height: ${({ height2100 }) => height2100};
  }

  @media ${({ theme }) => theme.device.desktop1920} {
    width: min(${({ width1920 }) => width1920}, 550px);
    max-height: ${({ height1920 }) => height1920};
  }

  @media ${({ theme }) => theme.device.laptop1100} {
    width: min(${({ widthL1100 }) => widthL1100}, 465px);
    max-height: ${({ height1100 }) => height1100};
    margin-right: ${({ marginRight1100 }) => marginRight1100};
    margin-right: ${({ marginBottom1100 }) => marginBottom1100};
    &:nth-child(2n) {
      margin-right: ${({ marginRight2n1100 }) => marginRight2n1100};
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    width: min(${({ widthL }) => widthL}, 459px);
    max-height: ${({ heightLaptop }) => heightLaptop || '486px'};
    margin-right: max(
      ${({ marginRightlaptop }) => marginRightlaptop || '1rem'},
      17px
    );
    margin-bottom: max(
      ${({ marginBottomlaptop }) => marginBottomlaptop || '1rem'},
      17px
    );
    /* &:nth-child(3n) {
      margin-right: 1rem;
    } */
  }

  @media ${({ theme }) => theme.device.tabletSmall} {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.device.tabletSmall} {
    width: min(${({ widthM }) => widthM}, 459px);
    max-height: ${({ height }) => height || '486px'};
    /* margin: 0 0 5rem 0; */
    &:nth-child(3n) {
      margin-right: 0rem;
    }
  }
`;

// export const CardWithMapContainer = styled.div`
//   width: ${({ width }) => width || ' 380px'};
//   height: 450px;
//   margin-right: 3rem;
//   margin-bottom: 3rem;
//   color: ${({ theme }) => theme.colors.text.main};
//   border-radius: 20px;
//   position: relative;
//   overflow: hidden;
//   box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
//   transition: all ease-in-out 0.2s;

//   &:hover {
//     cursor: pointer;
//     box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.07),
//       0 11px 20px 0 rgba(0, 0, 0, 0.06), 0 8px 47px 0 rgba(0, 0, 0, 0.04);
//     transform: translateY(-0.3rem);
//   }
//   &:hover ${Badge} {
//     opacity: 1;
//     color: green;
//   }
// `;

export const CardInfo = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  padding: ${({ padding }) => padding || '18px 25px'};

  @media ${({ theme }) => theme.device.tablet} {
    height: ${({ heightSm }) => heightSm};
    padding: ${({ paddingSm }) => paddingSm || '15px 11px'};
  }
`;

export const UserRate = styled.span`
  color: ${({ theme }) => theme.colors.text.muted};
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  text-align: right;
  margin: 0 5px;
`;

export const TotalRate = styled(UserRate)`
  color: #943fa1;
`;

export const FeaturesContainer = styled.div`
  width: 100%;
  display: flex;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};

  ${({ theme, justifyBetween }) =>
    justifyBetween &&
    css`
      justify-content: space-between;
    `}
`;
export const FeatureItem = styled.div`
  color: ${({ theme }) => theme.colors.text.light};
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  text-align: center;

  ${({ bricks }) =>
    bricks &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `}

  ${({ line }) =>
    line &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    `}

  ${({ oneline }) =>
    oneline &&
    css`
      display: flex;
      align-items: center;
      i {
        margin-right: 5px;
      }
      /* justify-content: space-around; */
    `}
`;

export const Price = styled.span`
  font-family: Rubik;
  font-style: normal;
  font-weight: ${({ weight }) => weight || '500'};
  font-size: ${({ size }) => size || '18px'};
  line-height: 128%;
  text-align: right;
  color: #943fa1;

  @media ${({ theme }) => theme.device.laptop} {
    font-size: ${({ sizeM }) => sizeM};
    font-weight: ${({ weightM }) => weightM};
  }
`;

export const PriceInfo = styled.span`
  color: ${({ theme }) => theme.colors.text.muted};
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 143%;
  text-align: center;
`;

export const Discount = styled(PriceInfo)`
  text-decoration: line-through;
  margin-right: 6px;

  ${({ theme, lineThroughNone }) =>
    lineThroughNone &&
    css`
      text-decoration: none;
    `}
`;

export const From = styled(PriceInfo)`
  text-decoration: line-through;
  margin-right: 5px;
`;

export const SlideTopBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 0 20px;
  color: #fff;
  position: absolute;
  z-index: 3;
  i {
    font-size: 20px;
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ paddingM }) => paddingM};
  }
`;

export const SliderWrapper = styled.div`
  height: ${({ height }) => height};

  @media ${({ theme }) => theme.device.tablet} {
    height: ${({ heightTablet }) => heightTablet};
  }
`;

export const ImgWrapper = styled.div`
  position: relative;
  min-height: 230px;
  background: url(${PalmTree}) no-repeat center;
  background-size: 50% 50%;

  @media ${({ theme }) => theme.device.tablet} {
    min-height: 185px;
  }
`;

export const SlideInfo = styled.div`
  width: 100%;
  background: linear-gradient(
    360deg,
    #070707 -24.34%,
    rgba(52, 52, 52, 0) 100%
  );
  padding: 10px 0px 12px 20px;

  @media ${({ theme }) => theme.device.tablet} {
    bottom: ${({ bottomTablet }) => bottomTablet};
  }
`;

export const SlideAdditionalInfo = styled.div`
  /* color: #919fa7; */
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.text.light};
  i {
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.text.light};
  }
`;

export const PureCardOuter = styled.div`
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
`;

export const PureCard = styled.div`
  width: ${({ width }) => width};
  min-width: ${({ minwidth }) => minwidth};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  border-radius: 20px;

  background: #ffffff;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.muted};
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align};
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);

  ${({ theme, border }) =>
    border &&
    css`
      box-shadow: none;
      /* border: 1px solid ${theme.colors.text.light}; */
      box-sizing: border-box;
      border-radius: 15px;
    `}

  ${({ center }) =>
    center &&
    css`
      align-items: center;
      justify-content: center;
    `}

  i {
    margin-bottom: ${({ marginIconBottom }) => marginIconBottom || '15px'};
    margin-right: 5px;
    font-size: ${({ iconSize }) => iconSize};
    color: ${({ theme }) => theme.colors.text.lightText};
  }
  .icon-light {
    color: #d7d6e0 !important;
  }

  &:hover {
    box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.18);
    /* box-shadow: 0px 0px 23px rgba(0, 82, 180, 0.1); */
    transform: translateY(-7px);
  }

  ${({ noHover }) =>
    noHover &&
    css`
      &:hover {
        box-shadow: none;
        transform: none;
      }
    `}

  @media ${({ theme }) => theme.device.laptop1100} {
    padding: ${({ padding1100 }) => padding1100};
    width: ${({ width1100 }) => width1100 || '100%'};
    margin: ${({ margin1100 }) => margin1100 || '0 25 0 0'};
    /* border: 1px solid rgba(0, 82, 180, 0.1); */
  }

  @media ${({ theme }) => theme.device.tablet550} {
    width: ${({ widthM }) => widthM || '100%'};
    margin: ${({ marginM }) => marginM || '0 25px 0 0'};
    box-shadow: ${({ shadowMobile }) =>
      shadowMobile ? '0px 10px 50px rgba(0, 82, 180, 0.18)' : 'none'};
    /* border: 1px solid rgba(0, 82, 180, 0.1); */
  }

  @media ${({ theme }) => theme.device.mobileL} {
    margin: ${({ marginMobL }) => marginMobL || '0 25 0 0'};
    /* border: 1px solid rgba(0, 82, 180, 0.1); */
  }
`;

export const CardTestimonialWrapper = styled.div`
  padding: 0 2%;
  /* height: 244px; */
  margin: ${({ margin }) => margin || '60px 0 42px 0'};
  transition: all ease-in-out 0.3s;
  cursor: grab;
  &:hover {
    transform: scale(1.02);
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 2%;
    margin: ${({ marginM }) => marginM || '60px 0 25px 0'};
  }
`;

export const CardTestimonialInfo = styled.div`
  height: 280px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
  text-align: center;
  padding: 56px 26px 20px 26px;
  background: url(${QuoteSVG});
  background-size: 35px;
  background-position-x: 30px;
  background-position-y: 30px;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AvaTestimonialWrapper = styled.div`
  position: relative;
`;

export const VoteBtn = styled.div`
  &&& {
    width: 115px;
    height: 40px;
    background: #f7f4f8;
    border-radius: 15px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 143%;
    i {
      font-size: 15px;
      color: #25282b45;
      margin-right: 10px;
    }

    ${({ theme, primary }) =>
      primary &&
      css`
        i {
          font-size: 15px;
          color: ${theme.colors.primary};
        }
      `}
  }
`;
