/*eslint-disable  spaced-comment */
/*eslint-disable  react/jsx-wrap-multilines*/
/*eslint-disable  no-confusing-arrow*/
/*eslint-disable  import/no-cycle*/
/*eslint-disable  implicit-arrow-linebreak*/

import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  hotels as hotelsRoutes,
  main as mainRoutes,
  villa as villaRoutes,
} from './routesList';

// import ScrollToTop from "../components/ScrollToTop";
import MainLayout from '../pages/Layouts/MainLayout/MainLayout';
import MainPageLayout from '../pages/Layouts/MainPageLayout/MainPageLayout';
import VillaPageLayout from '../pages/Layouts/VillaPageLayout/VillaPageLayout';
import NotFoundPage from '../pages/404/NotFoundPage';
import { PrivateRoute } from './PrivateRoute';
import { Loader, LoaderScreenWrapper } from '../components';
import { LANGUAGES } from '../locales/langlist';
import Auth from '../pages/Auth/Auth';

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ routeType, path, component: Component }, index) => {
        if (routeType === 'private') {
          return (
            <PrivateRoute
              key={index}
              path={path}
              exact
              render={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          );
        }
        return (
          <Route
            key={index}
            path={path}
            exact
            render={(props) => (
              <Layout>
                <Component {...props} />
              </Layout>
            )}
          />
        );
      })
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

const Routes = () => {
  return (
    <Suspense
      fallback={
        <LoaderScreenWrapper>
          <Loader />
        </LoaderScreenWrapper>
      }
    >
      <Router>
        <Switch>
          {/* <Route exact path="/:lang/auth"><Auth /></Route> */}
          {childRoutes(VillaPageLayout, villaRoutes)}
          {childRoutes(MainLayout, hotelsRoutes)}
          {childRoutes(MainPageLayout, mainRoutes)}
          <Route render={() => <NotFoundPage />} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export { Routes };
