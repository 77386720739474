import styled from 'styled-components';
import Slider from 'react-slick';

export const SliderStyled = styled(Slider)`
  .slick-list {
    height: 50%;
  }
  .slick-dots li button::before {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 10px;
  }
`;
