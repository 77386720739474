import React from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

const Portal = ({ children }) => {
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <>
      <div ref={setReferenceElement} />
      {ReactDOM.createPortal(
        <div
          ref={setPopperElement}
          style={{ zIndex: '99999999', ...styles.popper }}
          {...attributes.popper}
        >
          {children}
        </div>,
        document.querySelector('#portal'),
      )}
    </>
  );
};

export { Portal };
