import React from 'react';

import ArrowLeftSVG from 'assets/icons/carousel/leftArrow.svg';
import ArrowRightSVG from 'assets/icons/carousel/rightArrow.svg';
import {
  ArrowBtn,
} from './CarouselAnt.styles';
import { SliderStyled } from './CarouselResponsive.styles';

const SamplePrevArrow = (props) => {
  const {
    className, style, onClick, top, left, prevArrowSVG,
  } = props;
  return (
    <ArrowBtn
      top={top}
      className="slick-arrow"
      style={{
        ...style,
        width: '40px',
        height: '40px',
        display: 'block',
        cursor: 'pointer',
      }}
      onClick={onClick}
      left={left}
    >
      {prevArrowSVG ? (
        <img loading="lazy" width="40" height="40" src={prevArrowSVG} alt="arrow_left" />
      ) : (
        <img loading="lazy" width="40" height="40" src={ArrowLeftSVG} alt="arrow_left" />
      )}
    </ArrowBtn>
  );
};

const SampleNextArrow = (props) => {
  const {
    className, style, onClick, top, right, nextArrowSVG,
  } = props;
  return (
    <ArrowBtn
      top={top}
      className="slick-arrow"
      style={{
        ...style,
        width: '40px',
        height: '40px',
        display: 'block',
        cursor: 'pointer',
      }}
      onClick={onClick}
      right={right}
    >
      {nextArrowSVG ? (
        <img loading="lazy" width="40" height="40" src={nextArrowSVG} alt="arrow_left" />
      ) : (
        <img loading="lazy" width="40" height="40" src={ArrowRightSVG} alt="arrow_left" />
      )}
    </ArrowBtn>
  );
};

const CarouselResponsive = ({
  children,
  adaptiveHeight = false,
  prevArrowSVG,
  nextArrowSVG,
  padding,
  responsive,
  centerMode,
  arrows,
  dotsNot,
  top,
  left,
  right,
}) => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    ...centerMode,
    responsive,
  };
  if (arrows) {
    settings = {
      ...settings,
      dots: false,
      infinite: true,
      adaptiveHeight,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: (
        <SampleNextArrow nextArrowSVG={nextArrowSVG} top={top} right={right} />
      ),
      prevArrow: (
        <SamplePrevArrow prevArrowSVG={prevArrowSVG} top={top} left={left} />
      ),
    };
  }
  if (dotsNot) {
    settings = {
      ...settings,
      dots: false,
    };
  }
  return (
    // <SliderStyled {...settings} padding={padding}>
    <SliderStyled {...settings} padding={padding}>
      {children}
    </SliderStyled>
  );
};

export { CarouselResponsive };

// TODO : THIS IS SAMPLE for responsive prop
// : [
//   {
//     breakpoint: 1400,
//     settings: {
//       slidesToShow: 3,
//       slidesToScroll: 3,
//       infinite: true,
//       dots: true,
//     },
//   },
//   {
//     breakpoint: 1250,
//     settings: {
//       slidesToShow: 2,
//       slidesToScroll: 2,
//       infinite: true,
//       dots: true,
//     },
//   },
//   {
//     breakpoint: 900,
//     settings: {
//       slidesToShow: 1,
//       slidesToScroll: 1,
//       infinite: true,
//       dots: true,
//     },
//   },
//   {
//     breakpoint: 600,
//     settings: {
//       slidesToShow: 1,
//       slidesToScroll: 1,
//       initialSlide: 1,
//     },
//   },
//   {
//     breakpoint: 480,
//     settings: {
//       slidesToShow: 1,
//       slidesToScroll: 1,
//     },
//   },
// ],
