import React from 'react';
import { useIntl } from 'react-intl';
import { Header } from './parts/Header/Header';

const NotFoundPage = () => {
  const intl = useIntl();

  return (
    <>
      <Header />
    </>
  );
};
export default NotFoundPage;
