// Public pages
import React from 'react';

const MainSetLangAndRedirect = React.lazy(() =>
  import('pages/Main/MainSetLangAndRedirect')
);
const Main = React.lazy(() => import('pages/Main/Main'));
const Country = React.lazy(() => import('pages/Main/Country'));
const City = React.lazy(() => import('pages/Main/City'));
const Collection = React.lazy(() => import('pages/Main/Collection'));
const Region = React.lazy(() => import('pages/Main/Region'));
const Hotels = React.lazy(() => import('pages/Hotels/Hotels'));
const HotelSingle = React.lazy(() => import('pages/HotelSingle/HotelSingle'));
const RestaurantService = React.lazy(() =>
  import('pages/Services/RestaurantService')
);
const Faq = React.lazy(() => import('pages/Faq/Faq'));
const FavouritePage = React.lazy(() => import('pages/Favourite/FavouritePage'));

const FavouriteTripSingle = React.lazy(() =>
  import('pages/FavouriteTripSingle/FavouriteTripSingle')
);

const OwnerOfferPage = React.lazy(() =>
  import('pages/OwnerOfferPage/OwnerOfferPage')
);
const AgentOfferPage = React.lazy(() =>
  import('pages/AgentOfferPage/AgentOfferPage')
);

const Contact = React.lazy(() => import('pages/Contact/Contact'));
const ConfidentialPolicy = React.lazy(() =>
  import('pages/ConfidentialPolicy/ConfidentialPolicy')
);
const TermsPolicy = React.lazy(() => import('pages/Terms/TermsPolicy'));
const FavouriteTripCompare = React.lazy(() =>
  import('pages/FavouriteTripCompare/FavouriteTripCompare')
);

const HotelIsComplexPage = React.lazy(() =>
  import('pages/HotelIsComplexPage/HotelIsComplexPage')
);

const RecommendedPage = React.lazy(() =>
  import('pages/Recommended/RecommendedPage')
);

const ReservationPage = React.lazy(() =>
  import('pages/Reservation/Reservation')
);

const ReservationLastStep = React.lazy(() =>
  import('pages/Reservation/ReservationLastStep')
);

const ViewedPage = React.lazy(() => import('pages/ViewedPage/ViewedPage'));
const PopularPage = React.lazy(() => import('pages/PopularPage/PopularPage'));
const Auth = React.lazy(() => import('pages/Auth/Auth'));

const About = React.lazy(() => import('pages/About/About'));
const Destinations = React.lazy(() =>
  import('pages/Destinations/Destinations')
);

const mainRoutes = {
  path: '/',
  name: 'Main Page',
  component: null,
  children: [
    {
      routeType: 'public',
      path: '/',
      name: 'Main Page',
      component: Main,
    },
    {
      routeType: 'public',
      path: '/:lang/auth',
      name: 'Main Page',
      component: Auth,
    },
    {
      routeType: 'public',
      path: '/:lang',
      name: 'Main Page',
      component: Main,
    },
    {
      routeType: 'public',
      path: '/country',
      name: 'Country Page',
      component: Country,
    },
    {
      routeType: 'public',
      path: '/:lang/:region/',
      name: 'Region Page',
      component: Region,
    },
    {
      routeType: 'public',
      path: '/city',
      name: 'City Page',
      component: City,
    },
  ],
};

const singleVillaRoutes = {
  path: '/:lang',
  name: 'Main Page',
  component: null,
  children: [
    {
      // path: '/:lang/:region/:villa',
      path: '/:lang/villas/:villa',
      routeType: 'public',
      name: 'Hotel Page',
      component: HotelIsComplexPage,
    },
  ],
};

const hotelsRoutes = {
  path: '/:lang',
  name: 'Main Page',
  component: null,
  children: [
    {
      routeType: 'public',
      path: '/:lang/search/:slug', // '/:lang/search/:region/:checkinDate/:checkoutDate/:pax/',
      name: 'Hotel Page',
      component: Hotels,
    },
    // {
    //   routeType: 'public',
    //   path: '/hotels/:id/:singleId',
    //   name: 'Hotel Page',
    //   component: HotelSingle,
    // },
    {
      routeType: 'public',
      path: '/:lang/info/for-owner',
      name: 'Hotel Page',
      component: OwnerOfferPage,
    },
    {
      routeType: 'public',
      path: '/:lang/info/for-agency',
      name: 'Hotel Page',
      component: AgentOfferPage,
    },
    {
      routeType: 'public',
      path: '/:lang/info/faq',
      name: 'Hotel Page',
      component: Faq,
    },
    {
      routeType: 'public',
      path: '/:lang/info/about',
      // path: '/:lang/info/faqabout',
      name: 'Hotel Page',
      component: About,
    },
    {
      routeType: 'public',
      path: '/:lang/destinations',
      name: 'Hotel Page',
      component: Destinations,
    },
    {
      routeType: 'public',
      path: '/:lang/:region/destinations',
      name: 'Hotel Page',
      component: Destinations,
    },
    {
      routeType: 'public',
      path: '/:lang/info/policy',
      name: 'Hotel Page',
      component: ConfidentialPolicy,
    },
    {
      routeType: 'public',
      path: '/:lang/info/terms',
      name: 'Hotel Page',
      component: TermsPolicy,
    },
    {
      routeType: 'public',
      path: '/:lang/info/contacts',
      name: 'Contact Us Page',
      component: Contact,
    },
    {
      routeType: 'public',
      path: '/:lang/my/favourite',
      name: 'Hotel Page',
      component: FavouritePage,
    },
    {
      routeType: 'public',
      path: '/:lang/my/recommended',
      name: 'Hotel Page',
      component: RecommendedPage,
    },
    {
      routeType: 'public',
      path: '/:lang/my/viewed',
      name: 'Hotel Page',
      component: ViewedPage,
    },

    {
      routeType: 'public',
      path: '/:lang/my/popular',
      name: 'Hotel Page',
      component: PopularPage,
    },
    /* Closed access */
    {
      routeType: 'public',
      path: '/:lang/:region/:collection', //  /ru/nikiti/villas-with-pool
      name: 'Collection Page',
      component: Collection,
    },
    {
      routeType: 'public',
      path: '/:lang/collections/:collection', //  /ru/collections/villas-with-pool
      name: 'Collection Page',
      component: Collection, // CollectionWithoutRegion,
    },
    // {
    //   routeType: 'public',
    //   path: '/collections/:lang/:region/:collection',
    //   name: 'Collection Page',
    //   component: Collection,
    // },
    {
      routeType: 'public',
      path: '/:lang/services/restaurant',
      name: 'Restaurant Service Page',
      component: RestaurantService,
    },
    {
      routeType: 'public',
      path: '/:lang/my/favourite/:id',
      name: 'Favourite Trip Single Page',
      component: FavouriteTripSingle,
    },
    {
      routeType: 'public',
      path: '/:lang/compare',
      name: 'Favourite Trip Compare',
      component: FavouriteTripCompare,
    },
    {
      routeType: 'public',
      // http://localhost:3000/ru/hotels/1/reservation
      path: '/:lang/hotels/:id/reservation',
      name: 'Reservation Page',
      component: ReservationPage,
    },
    {
      routeType: 'public',
      // http://localhost:3000/ru/hotels/1/reservation/last-step
      path: '/:lang/hotels/:id/reservation/last-step',
      name: 'Reservation Page',
      component: ReservationLastStep,
    },
  ],
};

export const hotels = [hotelsRoutes];
export const main = [mainRoutes];
export const villa = [singleVillaRoutes];

// All routes
export default [main, hotels, villa];
