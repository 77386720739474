import styled from 'styled-components';

export const IconSvgContainer = styled.div`
  /* border: 1px solid red; */
  width: ${({ size }) => size || '40px'};
  height: ${({ size }) => size || '40px'};
  margin: ${({ margin }) => margin};
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 0.3s;
  path {
    fill: ${({ theme, fillColor }) => fillColor || theme.colors.borders.light};
  }
`;
