import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Tooltip } from 'antd';
import {
  Stars,
  CarouselAnt,
  Divider,
  Title,
  Favourite,
  Badge,
  IconSvgContainer,
  RowCustom,
  DeviceBreakpoint,
} from 'components';
import { useIntl } from 'react-intl';
import { ContainerCustom } from 'components/Grid/ContainerCustom.styles';
// import {
//   PeopleSVG,
//   BathSVG,
//   BedSVG,
//   SquareMetersSVG,
//   BeachSVG,
// } from 'lib/icons';

// import { ReactComponent as BeachSVG } from 'assets/icons/bigModal/beach.svg';
import { ReactComponent as SquareSVG } from 'assets/icons/bigModal/square.svg';
import {
  PeopleSVG,
  BathSVG,
  BedSVG,
  SquareMetersSVG,
  BeachSVG,
} from 'lib/icons';

import { Wrapper } from 'components/Wrappers/Wrappers.styles';
import { products } from 'mockData';
import {
  CardContainer,
  CardInfo,
  UserRate,
  TotalRate,
  FeaturesContainer,
  FeatureItem,
  Price,
  PriceInfo,
  SlideTopBlock,
  SliderWrapper,
  SlideInfo,
  SlideAdditionalInfo,
  ImgWrapper,
  Discount,
} from './Card.styles';
const pluralize = require('numeralize-ru').pluralize;

const CardPreview = ({
  nights,
  hotel,
  isMobile,
  setFavoriteVillas,
  favoriteVillas,
  refetchVillas,
}) => {
  const intl = useIntl();
  const { title, images, location } = products;
  const {
    id,
    coverPhoto,
    detailType,
    name,
    livingSpace,
    oPax,
    pPax,
    price,
    priceStr,
    xtype,
    breadcrumbs,
    nbedrooms,
    nbathrooms,
    ndistance,
    averageRating,
  } = hotel;

  React.useEffect(() => {
    console.log('ACTION HOTEL DATA: ', priceStr);
  }, [priceStr]);

  return (
    <CardContainer
      marginRightlaptop="1.4vw"
      marginBottomlaptop="1.4vw"
      marginRight1100="1.4vw"
      marginBottom1100="1.4vw"
      marginRight2n1100="0"
      height1100="445px"
      heightLaptop="445px"
      widthL1100="42vw"
      widthM="90vw"
      width2100="32.3%"
      widthFrom2100="22.5vw"
      marginRight4n="0"
    >
      <SlideTopBlock justify="space-between">
        <Badge style={{ opacity: 0 }}>
          {intl.formatMessage({
            id: 'card.villa',
          })}
        </Badge>
        <i>
          <Favourite
            setFavoriteVillas={setFavoriteVillas}
            favoriteVillas={favoriteVillas}
            refetchVillas={refetchVillas}
            hotel={hotel}
            iconColor={hotel.isInWishlist ? 'purple' : '#fff'}
          />
        </i>
      </SlideTopBlock>
      <SliderWrapper>
        <ImgWrapper>
          <Link to={`${hotel.urls.filterUrl}`} target="_blank">
            <CarouselAnt
              arrows
              images={[
                ...coverPhoto.map(
                  ({ photo }) =>
                    `${process.env.REACT_APP_API_MEDIA_URL}${photo}`
                ),
              ]}
              // heightM="255px"
              // height="291px"
              title={title}
              location={location}
              badge="Вилла"
              favorite
            />
          </Link>
          <ContainerCustom
            width="100%"
            position="absolute"
            bottom="0"
            bottom1100="0"
          >
            <Link to={`${hotel.urls.filterUrl}`} target="_blank">
              <SlideInfo bottomTablet="116px">
                <Title size="18px" weight="500" color="#fff" margin="0 0 5px 0">
                  {hotel.name}
                </Title>
                <SlideAdditionalInfo>
                  <i className="fas fa-map-marker-alt" />
                  {breadcrumbs.length > 0 &&
                    breadcrumbs.map(({ anchor }) => anchor).join(', ')}
                  {/* {breadcrumbs.length > 0  && breadcrumbs.map((({anchor}) => anchor)).join(", ") } */}
                </SlideAdditionalInfo>
              </SlideInfo>
            </Link>
          </ContainerCustom>
        </ImgWrapper>
      </SliderWrapper>
      <Link to={`${hotel.urls.filterUrl}`} target="_blank">
        <CardInfo
          heightSm={nights != 0 ? '119px' : '96px'}
          height="auto"
          paddingSm="10px 17px"
          padding="12px 25px"
        >
          <DeviceBreakpoint
            tabletFrom1100Hidden
            style={{ border: '0px solid green' }}
          >
            <FeaturesContainer justifyBetween margin="0 0 15px 0">
              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.guests',
                })} ${oPax}`}
              >
                <FeatureItem oneline>
                  <PeopleSVG />
                  <Title size="14px" lineHeight="13px" mutedText>
                    {hotel.oPax}{' '}
                    {intl.formatMessage({
                      id: 'card.guests-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.bedrooms',
                })} ${hotel.nbedrooms}`}
              >
                <FeatureItem oneline>
                  <BedSVG />
                  <Title size="14px" lineHeight="13px" mutedText>
                    {hotel.nbedrooms}{' '}
                    {intl.formatMessage({
                      id: 'card.bedrooms-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.bathrooms',
                })} ${hotel.nbathrooms}`}
              >
                <FeatureItem oneline>
                  <BathSVG />
                  <Title size="14px" lineHeight="13px" mutedText>
                    {nbathrooms}{' '}
                    {intl.formatMessage({
                      id: 'card.bathrooms-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>
            </FeaturesContainer>
          </DeviceBreakpoint>

          <DeviceBreakpoint tabletTo1100Hidden>
            <FeaturesContainer justifyBetween margin="0 0 15px 0">
              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.guests',
                })} ${oPax}`}
              >
                <FeatureItem bricks>
                  <PeopleSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    lineHeight="13px"
                    mutedText
                  >
                    {hotel.oPax}{' '}
                    {intl.formatMessage({
                      id: 'card.guests-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.bedrooms',
                })} ${hotel.nbedrooms}`}
              >
                <FeatureItem bricks>
                  <BedSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    lineHeight="13px"
                    mutedText
                  >
                    {nbedrooms}{' '}
                    {intl.formatMessage({
                      id: 'card.bedrooms-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.bathrooms',
                })} ${hotel.nbathrooms}`}
              >
                <FeatureItem bricks>
                  <BathSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    sizeM="16px"
                    lineHeight="13px"
                    mutedText
                  >
                    {nbathrooms}{' '}
                    {intl.formatMessage({
                      id: 'card.bathrooms-short',
                    })}
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.living-space',
                })} ${livingSpace}`}
              >
                <FeatureItem bricks>
                  <SquareMetersSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    sizeM="16px"
                    lineHeight="13px"
                    mutedText
                  >
                    {livingSpace} m2
                  </Title>
                </FeatureItem>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={`${intl.formatMessage({
                  id: 'card.beach-distance',
                })} ${ndistance}m`}
              >
                <FeatureItem bricks>
                  <BeachSVG />
                  <Title
                    size="14px"
                    margin="10px 0 0 0"
                    sizeM="16px"
                    lineHeight="13px"
                    mutedText
                  >
                    {ndistance} m
                  </Title>
                </FeatureItem>
              </Tooltip>
            </FeaturesContainer>
          </DeviceBreakpoint>

          {/* <Divider margin="16px 0 0 0" /> */}

          <Row
            style={{
              borderTop: '1px solid #d7d6e0',
              marginTop: '7px',
              paddingTop: nights ? '12px' : '22px',
            }}
            justify="space-between"
            align="middle"
          >
            <RowCustom
              alignItems={nights ? 'center' : 'baseline'}
              height="22px"
              // style={{ display: 'none', opacity: 0 }}
            >
              <Stars
                style={{ marginTop: '-6px' }}
                count={1}
                defaultValue={1}
                disabled
              />
              <UserRate>{averageRating}</UserRate>
              {/* <TotalRate>(135)</TotalRate> */}
            </RowCustom>

            <Wrapper margin="0 0 0 0">
              {nights != 0 ? (
                <>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Price weight="500">
                      {hotel.searchParams ? hotel.searchParams.price : priceStr}
                      €
                    </Price>
                  </div>
                  <PriceInfo>
                    {localStorage.language === 'ru'
                      ? `${+nights} ${pluralize(
                          +nights,
                          'ночь',
                          'ночи',
                          'ночей'
                        )}`
                      : `${+nights} ${+nights === 1 ? 'night' : 'nights'}`}
                    ,{' '}
                    {Math.ceil(
                      (hotel.searchParams ? hotel.searchParams.price : price) /
                        nights
                    )}
                    €/
                    {intl.formatMessage({
                      id: 'card.day',
                    })}
                  </PriceInfo>
                </>
              ) : (
                <Price size="18px" sizeM="16px" weightM="normal">
                  {intl.formatMessage({
                    id: 'card.from',
                  })}{' '}
                  €{hotel.searchParams ? hotel.searchParams.price : priceStr}/
                  {intl.formatMessage({
                    id: 'card.day',
                  })}
                </Price>
              )}
            </Wrapper>
          </Row>
        </CardInfo>
      </Link>
    </CardContainer>
  );
};

CardPreview.propTypes = {
  product: PropTypes.object, // TODO: isRequired,
};

export { CardPreview };
