import React from 'react';
import { Menu, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { DropdownTransparent, DropButton } from './Dropdown.styles';

const DropdownMenu = ({
  children,
  colorBgBtn,
  onChangeHandler,
  title,
  titleLabel,
  margin,
  colorTitle,
  hoverBg,
  fill,
}) => {
  const handleMenuClick = (e) => {
    console.log('click', e);
    message.info('Click on menu item.');
  };

  const menu = <Menu onClick={onChangeHandler}>{children}</Menu>;
  return (
    <>
      <DropdownTransparent
        placement="bottomRight"
        overlay={menu}
        margin={margin}
        hoverBg={hoverBg}
        fill={fill}
        style={{ border: '1px solid red' }}
      >
        <DropButton colorTitle={colorTitle} colorBgBtn={colorBgBtn}>
          {titleLabel} {title}
          <DownOutlined />
        </DropButton>
      </DropdownTransparent>
    </>
  );
};

export { DropdownMenu };
