import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Tooltip } from 'antd';
import {
  Stars,
  CarouselAnt,
  Text,
  Divider,
  Title,
  Favourite,
  Badge,
  IconSvgContainer,
  RowCustom,
} from 'components';
import { useIntl } from 'react-intl';

// import {
//   PeopleSVG,
//   BathSVG,
//   BedSVG,
//   SquareMetersSVG,
//   BeachSVG,
// } from 'lib/icons';

import { ReactComponent as BeachSVG } from 'assets/icons/bigModal/beach.svg';
import { ReactComponent as BathroomSVG } from 'assets/icons/bigModal/bathroom.svg';
import { ReactComponent as BedSVG } from 'assets/icons/bigModal/bed.svg';
import { ReactComponent as UsersSVG } from 'assets/icons/bigModal/users.svg';
import { ReactComponent as SquareSVG } from 'assets/icons/bigModal/square.svg';
import { Wrapper } from 'components/Wrappers/Wrappers.styles';

import { WrapperFrom } from 'components/Wrappers/Wrappers.styles';
import { products } from 'mockData';
import {
  CardContainer,
  CardWithMapContainer,
  CardInfo,
  UserRate,
  TotalRate,
  FeaturesContainer,
  FeatureItem,
  Price,
  Discount,
  PriceInfo,
  SlideTopBlock,
  SliderWrapper,
  SlideInfo,
  ImgWrapper,
  SlideAdditionalInfo,
} from './Card.styles';

const CardPreviewCountryWithMap = ({ hotel, setFavoriteVillas, favoriteVillas }) => {
  const intl = useIntl();
  const { title, images, location } = products;
  const {
    id,
    coverPhoto,
    detailType,
    name,
    nights,
    oPax,
    pPax,
    price,
    xtype,
    breadcrumbs,
    nbedrooms,
    livingSpace,
    nbathrooms,
    averageRating,
    ndistance,
  } = hotel;

  React.useEffect(() => {
    console.log('=====> dataCollection card: ', hotel.shortDescription);
  }, [hotel]);

  return (
    <CardWithMapContainer
      marginRight="3vw"
      marginBottom="3vw"
      marginRight1100="1.4vw"
      marginBottom1100="1.4vw"
      marginRight2n1100="0"
      height1100="550px"
      heightLaptop="650px"
      widthL1100="42vw"
      widthM="90vw"
      width2100="30.5vw"
      widthFrom2100="22.5vw"
    >
      {/* <CardContainer height="525px"> */}
      <SliderWrapper height="290px">
        <SlideTopBlock justify="space-between">
          {/* {badge && <Badge>{badge}</Badge>} */}
          <Badge style={{ opacity: 0 }}>
            {intl.formatMessage({
              id: 'card.villa',
            })}
          </Badge>
          <i>
            <Favourite
              setFavoriteVillas={setFavoriteVillas}
              favoriteVillas={favoriteVillas}
              hotel={hotel}
              iconColor={hotel.isInWishlist ? 'purple' : '#fff'}
            />
          </i>
        </SlideTopBlock>
        <ImgWrapper>
          <CarouselAnt
            arrows
            images={[
              ...coverPhoto.map(
                ({ photo }) => `${process.env.REACT_APP_API_MEDIA_URL}${photo}`
              ),
            ]}
            height="291px"
            title={title}
            location={location}
            badge="Вилла"
            favorite
          />
          <div
            style={{
              width: '100%',
              position: 'absolute',
              bottom: 0,
            }}
          >
            <Link to={`${hotel.urls.filterUrl}`} target="_blank">
              <SlideInfo>
                <Title size="18px" color="#fff" margin="0 0 10px 0">
                  {name}
                </Title>
                <SlideAdditionalInfo>
                  <i className="fas fa-map-marker-alt" />
                  {breadcrumbs.length > 0 &&
                    breadcrumbs.map(({ anchor }) => anchor).join(', ')}
                </SlideAdditionalInfo>
              </SlideInfo>
            </Link>
          </div>
        </ImgWrapper>
      </SliderWrapper>
      <Link to={`${hotel.urls.filterUrl}`} target="_blank">
        <CardInfo
          // height="234px"
          minHeight="auto"
          padding="12px 25px"
        >
          <FeaturesContainer justifyBetween>
            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.guests',
              })} ${oPax}`}
            >
              <FeatureItem size="25px" bricks>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <UsersSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {oPax} ps
                </Title>
              </FeatureItem>
            </Tooltip>

            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.bedrooms',
              })} ${nbedrooms}`}
            >
              <FeatureItem size="25px" bricks>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <BedSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {' '}
                  {hotel.nbedrooms} bd
                </Title>
              </FeatureItem>
            </Tooltip>

            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.bathrooms',
              })} ${hotel.nbathrooms}`}
            >
              <FeatureItem size="25px" bricks>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <BathroomSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {hotel.nbathrooms}{' '}
                  {intl.formatMessage({
                    id: 'card.bathrooms-short',
                  })}
                </Title>
              </FeatureItem>
            </Tooltip>

            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.living-space',
              })} ${livingSpace}`}
            >
              <FeatureItem size="25px" bricks>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <SquareSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {hotel.livingSpace} m2
                </Title>
              </FeatureItem>
            </Tooltip>

            <Tooltip
              placement="bottom"
              title={`${intl.formatMessage({
                id: 'card.beach-distance',
              })} ${ndistance}m`}
            >
              <FeatureItem size="25px" bricks>
                <IconSvgContainer fillColor="#D7D6E0" size="24px">
                  <BeachSVG />
                </IconSvgContainer>
                <Title
                  size="14px"
                  margin="3px 0 0 0"
                  lineHeight="143%"
                  greyText
                >
                  {hotel.ndistance} m
                </Title>
              </FeatureItem>
            </Tooltip>
          </FeaturesContainer>
          <Row>
            <Text margin="15px 0 18px 0" greyText>
              {hotel.shortDescription}
            </Text>
          </Row>
          <RowCustom
            borderTop="1px solid #D7D6E0"
            alignItems="baseline"
            justify="space-between"
          >
            <RowCustom
              alignItems="baseline"
              height="22px"
              // style={{ display: 'none', opacity: 0 }}
            >
              <Stars count={1} defaultValue={1} disabled />
              <UserRate>{averageRating}</UserRate>
              {/* <TotalRate>(135)</TotalRate> */}
            </RowCustom>

            <Wrapper margin="10px 0 0 0">
              <Price size="18px" sizeM="16px" weightM="normal">
                {intl.formatMessage({
                  id: 'card.from',
                })}{' '}
                {hotel.price}€ /
                {intl.formatMessage({
                  id: 'card.day',
                })}
              </Price>
            </Wrapper>
          </RowCustom>
        </CardInfo>
      </Link>
    </CardWithMapContainer>
  );
};

CardPreviewCountryWithMap.propTypes = {
  product: PropTypes.object, // TODO: isRequired,
};

export { CardPreviewCountryWithMap };
