import React from 'react';
import { Progress, ProgressCapsule } from './ProgressBar.styles';

const ProgressBar = ({ progressValue }) => (
  <ProgressCapsule>
    <Progress progressValue={progressValue} />
  </ProgressCapsule>
);

export { ProgressBar };
