import React from 'react';
import { Select, Row } from 'antd';
import { IconContainer } from 'components';
import { SelectCustomStyles } from './SelectMenu.styles';

const { Option } = Select;

const SelectMenu = ({
  children,
  defaultValue,
  optionList,
  width,
  borders,
  height,
  bordersHeight,
  onHandleChange,
}) => {
  function handleChange(value) {
    // console.log(value); // { key: "lucy", label: "Lucy (101)" }
    onHandleChange(value);
  }

  return borders ? (
    <SelectCustomStyles
      borders
      height={height}
      bordersHeight={bordersHeight}
      width={width}
    >
      <Select
        labelInValue
        // defaultValue={{ key: `${defaultValue}` }}
        defaultValue={{ key: defaultValue }}
        onChange={handleChange}
      >
        {optionList.map(({ icon, title, value }) => (
          <Option value={value} key={title} style={{ display: 'flex' }}>
            <Row align="middle">
              <IconContainer margin="0 15px 0 0" lightText>
                {icon}
              </IconContainer>
              {title}
            </Row>
          </Option>
        ))}
      </Select>
    </SelectCustomStyles>
  ) : (
    <SelectCustomStyles colorText="#353535" paddingTop="3px" width={width}>
      <Select
        labelInValue
        defaultValue={{ key: `${defaultValue}` }}
        onChange={handleChange}
      >
        {optionList.map(({ icon, title, value }) => (
          <Option value={value} key={title}>
            {icon}
            {' '}
            {title}
          </Option>
        ))}
      </Select>
    </SelectCustomStyles>
  );
};

export { SelectMenu };
