import React from 'react';
import { Link } from 'react-router-dom';
import LogoSVG from 'assets/logo/logo.svg';
import { useMainUrl } from 'hooks/useMainUrl';

import { LogoContainer } from './Logo.styles';

const Logo = ({ margin, textColor }) => {
  const [mainUrl] = useMainUrl();

  return (
    <Link to={mainUrl}>
      <LogoContainer margin={margin}>
        <img loading="lazy" src={LogoSVG} alt="" />
      </LogoContainer>
    </Link>
  )
};

export { Logo };
