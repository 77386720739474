import styled from 'styled-components';
import { Rate } from 'antd';

export const Stars = styled(Rate)`
  &&& {
    color: #943fa1;
    .anticon svg {
      color: #943fa1;
    }
  }
`;
//  ${({ theme }) => theme.colors.primary};
