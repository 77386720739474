import React, { useState } from 'react';
import { Modal } from 'antd';
import {
  Button, Title, RowCustom,
} from 'components';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ReactComponent as ArrowRight } from 'assets/icons/bigModal/arrow-right.svg';

const TitleBtn = styled(Title)`
  cursor: pointer;
  margin-left: 12px;
  font-weight: 500;
  font-size: 18px;
`;

const BigModalInfoContainer = styled.div`
  width: 380px;
  margin-right: 3rem;
  margin-bottom: 3rem;

  .ant-modal-close {
    display: none;
  }

  @media ${({ theme }) => theme.device.desktop} {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    width: calc((100% / 2) - (1rem / 2));
    margin-right: 1rem;
    margin-bottom: 1rem;
    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(3n) {
      margin-right: 1rem;
    }
  }
`;

const BigModalStyles = styled.div`
  &&& {
    .ant-modal {
      width: 1200px;
    }
  }
`;

const ModalStyled = styled(Modal)`
  &&& {
    .ant-modal-close-x {
      display: none !important;
      background-color: red;
    }
  }
`;

const BigModalInfo = ({
  children,
  iconBtn,
  iconColor,
  modalBtnOkText,
  modalBtnCancelText,
  modalTitle,
}) => {
  const intl = useIntl();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   console.log('in BIG');
  //   if (!isOpenControl) setVisible(false);
  // }, [isOpenControl]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (e) => {
    setLoading(true);
    setVisible(false);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <>
      <div>
        {iconBtn ? (
          <BigModalInfoContainer onClick={showModal} iconColor={iconColor}>
            {iconBtn}
          </BigModalInfoContainer>
        ) : (
          <Button size="16px" onClick={showModal} outlined>
            {modalBtnOkText}
          </Button>
        )}
        <BigModalStyles>
          <ModalStyled
            // style={{ width: '1200px', transform: 'none', left: '0' }}
            width="99%"
            title={modalTitle}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={
              [
                // <Button size="16px"   key="back" onClick={handleCancel} primary>
                //   {modalBtnCancelText || 'Cancel'}
                // </Button>,
                // <Button size="16px"   key="submit" primary loading={loading} onClick={handleOk}>
                //   {modalBtnOkText || 'Ok'}
                // </Button>,
              ]
            }
          >
            <RowCustom margin="56px 0 25px 50px" align="middle">
              <ArrowRight />
              <TitleBtn onClick={handleCancel} primary>
                {intl.formatMessage({
                  id: 'buttons.back',
                })}
              </TitleBtn>
            </RowCustom>
            {children}
          </ModalStyled>
        </BigModalStyles>
      </div>
    </>
  );
};

export { BigModalInfo };
