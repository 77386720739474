import React from 'react';
import { Collapse } from 'antd';
import { CollapseStyled } from './CollapseList.styles';
import {ReactComponent as CollapseIcon} from "assets/ChevronRight.svg"

const CollapseList = ({
  width,
  widthTablet,
  width1200,
  children,
  margin,
  marginTablet,
  fontSizeTablet,
  defaultActiveKey,
}) => (
  <CollapseStyled
    width={width}
    widthTablet={widthTablet}
    margin={margin}
    marginTablet={marginTablet}
    fontSizeTablet={fontSizeTablet}
    defaultActiveKey={['1']}
  >
    <Collapse
      expandIcon={({ isActive }) => (
        <CollapseIcon style={{transform: `translateY(-50%) rotate(${isActive ? 0 : '180deg'})`}} />
      )}
      expandIconPosition="right"
      accordion
      defaultActiveKey={defaultActiveKey}
    >
      {children}
    </Collapse>
  </CollapseStyled>
);

export { CollapseList };
