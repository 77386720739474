import styled, { css } from 'styled-components';

export const DropContent = styled.div`
  width: ${({ dropContainerWidth }) => dropContainerWidth};
  height: ${({ dropContainerHeight }) => dropContainerHeight};
  min-width: 25px;
  min-height: 25px;
  background-color: ${({ bg }) => bg || '#fff'};
  border: 1px solid rgba(0, 82, 180, 0.1);
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
  /* transform: translateX(50px); */
  padding: ${({ padding }) => padding};

  position: absolute;
  top: ${({ top }) => top || '15px'};
  left: ${({ dropContentLeftPosition }) => dropContentLeftPosition || '-65px'};
  transition: all ease-in-out 0.3s;
  z-index: 999;
  overflow: hidden;
  @media ${({ theme }) => theme.device.tablet} {
    top: 15px;
    left: ${({ dropContLeft }) => dropContLeft};
  }
`;

export const DropWrapper = styled.div`
  width: ${({ dropWrapperWidth }) => dropWrapperWidth};
  height: ${({ dropWrapperHeight }) => dropWrapperHeight};
  padding: ${({ dropBtnPaddingWrapper }) => dropBtnPaddingWrapper};
  margin: ${({ dropBtnMarginWrapper }) => dropBtnMarginWrapper};
  position: relative;

  ${({ borders, bordersHeight }) =>
    borders &&
    css`
      height: ${bordersHeight};

      border: 1px solid
        ${({ theme, dropBorderWrapper }) =>
          dropBorderWrapper || theme.colors.borders.light};
      border-radius: 10px;
      padding-left: 20px;
    `};

  ${({ boxShadow }) =>
    boxShadow &&
    css`
      box-shadow: 0px 10px 50px rgba(0, 82, 180, 0.1);
      border-radius: 10px;
    `};

  @media ${({ theme }) => theme.device.tablet} {
    width: ${({ dropWrapperWidthSM }) => dropWrapperWidthSM};
    margin: ${({ dropWrapperMarginM }) => dropWrapperMarginM};
  }
`;

export const DropBtn = styled.div`
  &&& {
    width: ${({ dropBtnWidth }) => dropBtnWidth || '100%'};
    min-width: ${({ dropBtnMinWidth }) => dropBtnMinWidth || '100%'};

    height: ${({ dropBtnHeight }) => dropBtnHeight || '100%'};
    color: ${({ theme, dropBtnColor }) =>
      dropBtnColor || theme.colors.text.light};
    background-color: ${({ dropBtnBg }) => dropBtnBg};
    backdrop-filter: ${({ dropBtnBlur }) => dropBtnBlur};
    border-radius: ${({ dropBtnBorderRadius }) => dropBtnBorderRadius};
    padding: ${({ dropBtnPadding }) => dropBtnPadding};
    display: flex;
    align-items: center;

    cursor: pointer;
  }
`;

export const OptionList = styled.div`
  /* width: 80%; */
  max-height: ${({ optionListHeight }) => optionListHeight};
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); */
    background-color: transparent; /* scrollbar bg */
    border-radius: 25px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 50px;
    /* background-color: #f5f5f5; */
    background-color: transparent; /* scrollbar bg */
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d7d6e0;

    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px;
    }

    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
    }

    /* 
           background-image: -webkit-linear-gradient(
             top,
             #e4f5fc 0%,
             #bfe8f9 50%,
             #9fd8ef 51%,
             #2ab0ed 100%
           ); */
  }
`;

export const OptionIcon = styled.div`
  &&& {
    background-color: ${({ color }) => color};
    mask-image: url(${process.env.REACT_APP_API_MEDIA_URL}${({ icon }) => icon});
    mask-position: center;
    mask-repeat: no-repeat;
    width: 24px;
    height: 24px;
  }
  transition: all ease-in-out 0.3s;
  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const OptionItem = styled.div`
  /* min-width: 482px; */
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '61px'};
  min-height: ${({ minHeight }) => minHeight};
  max-height: ${({ maxHeight }) => maxHeight};
  color: ${({ theme }) => theme.colors.text.grey} !important;
  padding: ${({ padding }) => padding || '13px 8px 13px 20px'};
  background: white;
  transition: all ease-in-out 0.3s;
  cursor: pointer;

  &:hover {
    background: rgba(148, 63, 161, 0.1);
  }

  &:hover ${OptionIcon} {
    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;
