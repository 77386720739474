import React, { useState } from 'react';
import {
  Title,
  LinkAnchor,
  ContainerCustom,
  RowCustom,
  Icon,
  Input,
  Form,
  Button,
  Divider,
} from 'components';

import { GoogleLogIn } from 'components/ButtonsAuth/GoogleLogIn';
import { FacebookLogIn } from 'components/ButtonsAuth/FacebookLogIn';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { Checkbox, Col } from 'antd';

import { SIGN_IN } from 'graphql/mutations/signin';
import { useMutation } from '@apollo/react-hooks';

import { CheckboxWrapper } from '../Auth.style';

import ClosedEye from 'assets/icons/closed-eye.svg';

import { useHistory } from 'react-router-dom';

const SignIn = () => {
  const history = useHistory();
  const [signIn, { data, errors }] = useMutation(SIGN_IN);
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const responseGoogle = (res) => {
    console.log(res);
  };

  const responseFacebook = (res) => {
    console.log(res);
  };

  React.useEffect(() => {
    console.log(signIn);
    console.log(data);
    console.log(errors);
  }, [signIn, data, errors]);

  React.useEffect(() => {
    errors && console.log('ERROR apollo', errors);
    data && console.log('ERROR apollo', data);
    // console.log('Sign in', data && data.tokenAuth.token);
    if (data && data.tokenAuth && data.tokenAuth.token) {
      const auth1 = { accessToken: data.tokenAuth.token };
      localStorage.setItem('auth', JSON.stringify(auth1));
      history.goBack();
    }
  }, [data]);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, 'Must have a character')
      .max(255, 'Must be shorter than 255')
      .required(),
    email: Yup.string()
      .min(2, 'Must have a character')
      .max(255, 'Must be shorter than 255')
      .required(),
  });

  const intl = useIntl();

  if (errors) return <p>Error :</p>;

  return (
    <ContainerCustom display="flex" direction="column" align="center">
      <Formik
        initialValues={{
          password: '',
          email: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log(values);
          signIn({
            variables: {
              username: values.email,
              password: values.password,
            },
          });
          resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <RowCustom padding="45px 0 0 0" justify="space-between">
              {/* <Error touched={touched.name} message={errors.name} /> */}
              <Input
                placeholderCustomColor="#9791A7"
                border
                borderRadius="10px"
                width="100%"
                height="54px"
                widthSm="100%"
                margin="0 0 30px 0"
                padding="10px 20px"
                boxshadow="0px 10px 50px rgba(0, 82, 180, 0.1)"
                // error={
                //   touched.lastname && errors.lastname ? 'has-error' : null
                // }
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="email"
                placeholder={intl.formatMessage({
                  id: 'signin.email',
                })}
              />
              <RowCustom width="100%" position="relative">
                <Input
                  placeholderCustomColor="#9791A7"
                  border
                  borderRadius="10px"
                  width="100%"
                  height="54px"
                  widthSm="100%"
                  margin="0 0 30px 0"
                  padding="10px 20px"
                  boxshadow="0px 10px 50px rgba(0, 82, 180, 0.1)"
                  // error={touched.name && errors.name}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type={isPasswordVisible ? 'text' : 'password'}
                  name="password"
                  placeholder={intl.formatMessage({
                    id: 'forms.your-pass',
                  })}
                />
                <Icon
                  onClick={() => setPasswordVisibility(!isPasswordVisible)}
                  src={ClosedEye}
                  position="absolute"
                  top="17px"
                  right="14px"
                />
              </RowCustom>
            </RowCustom>

            <CheckboxWrapper>
              <Checkbox.Group>
                {[
                  {
                    value: true,
                    label: intl.formatMessage({
                      id: 'forms.confidential',
                    }),
                  },
                ].map((checkboxItem) => (
                  <Checkbox
                    value={checkboxItem.value}
                    style={{ margin: '0px' }}
                  >
                    {intl.formatMessage({
                      id: 'forms.reg.remember',
                    })}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </CheckboxWrapper>

            <Button
              size="16px"
              sizeM="16px"
              marginSM="25px auto"
              type="submit"
              margin="21px 0 0 0"
              primary
              disabled={isSubmitting}
              width="100%"
              height="54px"
            >
              {intl.formatMessage({
                id: 'forms.reg.sign-in',
              })}
            </Button>
            {/* <LinkAnchor margin="17px 0 35px 0" display="flex" color="#9791A7">
              {intl.formatMessage({
                id: 'forms.forget-password',
              })}
            </LinkAnchor> */}
          </Form>
        )}
      </Formik>

      <Divider margin="35px 0 0 0" />
      <ContainerCustom margin="31px 0 0 0">
        <Title margin="0 0 24px 0" center size="16px" color="#353535">
          {intl.formatMessage({
            id: 'forms.reg.or-select-another',
          })}
        </Title>
        <RowCustom wrap="nowrap" gutter={15}>
          <Col>
            <GoogleLogIn
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
            />
          </Col>
          <Col>
            <FacebookLogIn callback={responseFacebook} />
          </Col>
        </RowCustom>
      </ContainerCustom>
    </ContainerCustom>
  );
};

export default SignIn;
