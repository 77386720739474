import styled from 'styled-components';

export const Cover = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px 20px 0px 0px;
  margin: 0;
  padding: 0;
`;
