import styled from 'styled-components';

export const ModalFavWrapper = styled.div`
  img {
    border-radius: 15px;
  }
`;

export const AdditionalInfo = styled.div`
  /* color: #919fa7; */
  color: #bbbbbb;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  i {
    margin-right: 8px;
  }
`;

export const HeartSVGStyled = styled.div`
  width: ${({ width }) => width || '32px'};
  height: ${({ height }) => height || '32px'};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ iconBg }) => iconBg || 'rgba(53, 53, 53, 0.38)'};

  @media ${({ theme }) => theme.device.laptop1100} {
    width: ${({ widthM }) => widthM};
    height: ${({ heightM }) => heightM};
  }

  i {
    font-size: 20px;
    color: ${({ color }) => color || "#ffffff"};
  }
`;

